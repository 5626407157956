@charset "UTF-8";
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

/* Customization IE */
/* modals */
/* New UI Primary Colors -July19*/
/* New UI Interface Colors -July19*/
/* New UI Job Colors -July19*/
/* 70px auto; */
@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.eot");
  src: url("../assets/fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Roboto-Regular.woff") format("woff"), url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
input.ng-invalid.ng-dirty, input.ng-invalid.ng-touched {
  border-left: 5px solid #a94442;
  border-color: #a94442;
}

select.ng-invalid.ng-dirty, select.ng-invalid.ng-touched {
  border-left: 5px solid #a94442 !important;
  border-color: #a94442 !important;
}

/*input[required].ng-untouched.ng-invalid {
    border-color:#a94442;
}*/
label[required]:after {
  color: #a94442;
  content: " *";
  display: inline;
}

.validation-error {
  color: red;
}

.photo-list .figure {
  margin-right: 10px;
  float: left;
  background: grey;
  border: 2px solid white;
  cursor: pointer;
  padding-bottom: 5px;
}

.photo-list .figure .figure-caption {
  color: white;
}

.photo-list .figure:hover,
.photo-list .figure.active {
  border: 2px solid #E3E8EC;
  background: #E3E8EC;
}

.photo-list .figure:hover .figure-caption,
.photo-list .figure.active .figure-caption {
  color: black;
}

.photo-upload {
  background-color: grey;
  width: 100%;
  height: 100%;
}

.photo-upload label {
  color: white;
}

.table-outline {
  border: 1px solid #dee2e6;
}
.table-outline td {
  vertical-align: middle;
}

.table-align-middle td {
  vertical-align: middle;
}

.table-clear td {
  border: 0;
}

.table-hover tr.active {
  background-color: #E3E8EC;
}
.table-hover tbody tr {
  cursor: pointer;
}

.generic-list .generic-group {
  width: 100%;
  clear: both;
  display: block;
  margin-bottom: 10px;
}
.generic-list .generic-group .groupkey {
  font-weight: bold;
  float: left;
  min-width: 150px;
  width: auto;
}
.generic-list .generic-group .groupvalue {
  min-width: 300px;
  width: auto;
  float: left;
}
.generic-list .generic-item {
  width: 100%;
  background-color: transparent;
}
.generic-list .generic-item:hover {
  cursor: pointer;
  background-color: #E3E8EC;
}
.generic-list .generic-item.active {
  background-color: #E3E8EC;
}

table tr.child-row-header td {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

table tr.child-row-body td {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

table tr.child-row-body:last-child td {
  padding-bottom: 0.75rem;
}

.thead-inverse th {
  color: #EFF3F8;
  background-color: #444D58;
}

.table-inverse {
  color: #EFF3F8;
  background-color: #005598;
}
.table-inverse th,
.table-inverse td,
.table-inverse thead th {
  border-color: #ffffff;
}
.table-inverse.table-bordered {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f0f4f8;
}

.table-hover tr.active, .table-hover tr:hover {
  background-color: #d2dee7 !important;
}

.table-hover tfoot tr:hover {
  background-color: #FFF !important;
}

.btn {
  font-size: 14px;
  height: 38px;
  border-radius: 24px;
  padding: 9px 38px;
  white-space: nowrap;
}
.btn .badge {
  position: absolute;
  top: 2px;
  right: 6px;
  font-size: 9px;
}

.btn:hover, .btn:focus {
  box-shadow: inset 0px -2px 0px rgba(8, 43, 70, 0.15);
}

a.btn-setting {
  cursor: pointer;
}

a {
  cursor: pointer;
}

timepicker .btn-default, timepicker .btn-link {
  padding: 8px 16px;
}

.dropdown-item {
  position: relative;
  padding: 10px 20px;
  border-bottom: 1px solid #E3E8EC;
}
.dropdown-item:last-child {
  border-bottom: 0;
}
.dropdown-item i {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
  margin-left: -10px;
  color: #E3E8EC;
  text-align: center;
}
.dropdown-item .badge {
  position: absolute;
  right: 10px;
  margin-top: 2px;
}

.dropdown-header {
  padding: 8px 20px;
  background: #F1F3F8;
  border-bottom: 1px solid #E3E8EC;
}
.dropdown-header .btn {
  margin-top: -7px;
  color: #6c757d;
}
.dropdown-header .btn:hover {
  color: #212529;
}
.dropdown-header .btn.pull-right {
  margin-right: -20px;
}

.dropdown-menu-lg {
  width: 250px;
}

.input-group-addon,
.input-group-btn {
  min-width: 40px;
  white-space: nowrap;
  vertical-align: middle;
}

.modal-dialog.modal-customsize.lightbox {
  margin: 0px;
}
.modal-dialog.modal-customsize.lightbox .close {
  color: white;
  opacity: 1;
}

.modal-content.lightbox {
  background-color: transparent;
  color: white;
}
.modal-content.lightbox .close {
  position: absolute;
  top: 15px;
  right: 30px;
}

.documents-slider {
  text-align: center;
  height: 100%;
  display: block;
}
.documents-slider .item {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: none;
  min-height: 700px;
}
.documents-slider .item .title {
  text-align: center;
  background-color: #FFF;
  color: #0a4298;
  line-height: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  padding: 8px 10px;
  border-bottom: 1px solid #f1f1f1;
}
.documents-slider .item.active {
  display: inline-block;
}
.documents-slider .slider-navigator-left,
.documents-slider .slider-navigator-right {
  min-height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-weight: bold;
}
.documents-slider .slider-navigator-left {
  cursor: pointer;
  float: left;
  left: 30px;
}
.documents-slider .slider-navigator-right {
  cursor: pointer;
  float: right;
  right: 30px;
}
.documents-slider .slider-navigator-left:hover,
.documents-slider .slider-navigator-right:hover {
  color: #cdcdcd;
}
.documents-slider .fa-lg {
  font-size: 2em;
  line-height: 1em;
}
.documents-slider .fa-chevron-left, .documents-slider .fa-chevron-right {
  color: white;
}

.card {
  margin-bottom: 1.5rem;
}

.card-header i.icon-bg {
  display: inline-block;
  padding: 0.75rem 1.25rem !important;
  margin-top: -0.75rem;
  margin-right: 1.25rem;
  margin-bottom: -0.75rem;
  margin-left: -1.25rem;
  line-height: inherit;
  color: #212529;
  vertical-align: bottom;
  background: transparent;
  border-right: 1px solid #E3E8EC;
}
.card-header ul.nav.nav-tabs {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
  border-bottom: 0;
}
.card-header ul.nav.nav-tabs li.nav-item {
  border-top: 0;
}
.card-header ul.nav.nav-tabs li.nav-item a.nav-link {
  padding: 0.75rem 0.625rem;
  color: #6c757d;
  border-top: 0;
}
.card-header ul.nav.nav-tabs li.nav-item a.nav-link.active {
  color: #212529;
  background: #fff;
}
.card-header.card-header-inverse {
  color: #fff;
}
.card-header.card-header-primary {
  margin: -1px -1px 0;
  background: #031930;
  border-bottom: 1px solid #031930;
}
.card-header.card-header-secondary {
  margin: -1px -1px 0;
  background: #E3E8EC;
  border-bottom: 1px solid #E3E8EC;
}
.card-header.card-header-success {
  margin: -1px -1px 0;
  background: #41ca74;
  border-bottom: 1px solid #41ca74;
}
.card-header.card-header-info {
  margin: -1px -1px 0;
  background: #5dc4f0;
  border-bottom: 1px solid #5dc4f0;
}
.card-header.card-header-warning {
  margin: -1px -1px 0;
  background: #eea633;
  border-bottom: 1px solid #eea633;
}
.card-header.card-header-danger {
  margin: -1px -1px 0;
  background: #e75b5e;
  border-bottom: 1px solid #e75b5e;
}
.card-header .btn {
  margin-top: -0.375rem;
}
.card-header .btn-sm {
  margin-top: -0.25rem;
}
.card-header .btn-lg {
  margin-top: -0.5rem;
}

.card-footer {
  background-color: #FFF;
}
.card-footer ul {
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
  table-layout: fixed;
}
.card-footer ul li {
  display: table-cell;
  padding: 0 1.25rem;
  text-align: center;
}
.card-footer ul li progress {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.card-primary {
  background-color: #031930;
}
.card-primary .card-header {
  border-color: #020d18;
}
.card-primary .card-header, .card-primary .card-footer {
  background-color: #020d18;
}

.card-secondary {
  background-color: #E3E8EC;
}
.card-secondary .card-header {
  border-color: #d4dce2;
}
.card-secondary .card-header, .card-secondary .card-footer {
  background-color: #d4dce2;
}

.card-success {
  background-color: #41ca74;
}
.card-success .card-header {
  border-color: #35bd67;
}
.card-success .card-header, .card-success .card-footer {
  background-color: #35bd67;
}

.card-info {
  background-color: #5dc4f0;
}
.card-info .card-header {
  border-color: #46bcee;
}
.card-info .card-header, .card-info .card-footer {
  background-color: #46bcee;
}

.card-warning {
  background-color: #eea633;
}
.card-warning .card-header {
  border-color: #ec9c1b;
}
.card-warning .card-header, .card-warning .card-footer {
  background-color: #ec9c1b;
}

.card-danger {
  background-color: #e75b5e;
}
.card-danger .card-header {
  border-color: #e44548;
}
.card-danger .card-header, .card-danger .card-footer {
  background-color: #e44548;
}

.card-inverse {
  color: #fff;
}
.card-inverse .text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}

[class*=card-outline-] .card-block {
  background: #fff !important;
}
[class*=card-outline-].card-outline-top {
  border-top-width: 2px;
  border-right-color: #E3E8EC;
  border-bottom-color: #E3E8EC;
  border-left-color: #E3E8EC;
}

.card-accent-primary {
  border-top-width: 2px;
  border-top-color: #20a8d8;
}

.card-accent-secondary {
  border-top-width: 2px;
  border-top-color: #BCC5D0;
}

.card-accent-info {
  border-top-width: 2px;
  border-top-color: #63c2de;
}

.card-accent-success {
  border-top-width: 2px;
  border-top-color: #4dbd74;
}

.card-accent-warning {
  border-top-width: 2px;
  border-top-color: #f8cb00;
}

.card-accent-danger {
  border-top-width: 2px;
  border-top-color: #f86c6b;
}

.card-header > i {
  margin-right: 0.5rem;
}
.card-header .card-actions {
  position: absolute;
  top: 0;
  right: 0;
}
.card-header .card-actions a, .card-header .card-actions button {
  display: block;
  float: left;
  width: 50px;
  padding: 0.75rem 0;
  margin: 0 !important;
  color: #fff;
  text-align: center;
  background: transparent;
  border: 0;
  border-left: 1px solid #E3E8EC;
  box-shadow: 0;
}
.card-header .card-actions a:hover, .card-header .card-actions button:hover {
  text-decoration: none;
}
.card-header .card-actions a [class^=icon-], .card-header .card-actions a [class*=" icon-"], .card-header .card-actions button [class^=icon-], .card-header .card-actions button [class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
}
.card-header .card-actions a i, .card-header .card-actions button i {
  display: inline-block;
  transition: 0.4s;
}
.card-header .card-actions a i.r180, .card-header .card-actions button i.r180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card-header .card-actions .input-group {
  width: 230px;
  margin: 6px;
}
.card-header .card-actions .input-group .input-group-addon {
  background: #fff;
}
.card-header .card-actions .input-group input {
  border-left: 0;
}

.card-full {
  margin-top: -1rem;
  margin-right: -15px;
  margin-left: -15px;
  border: 0;
  border-bottom: 1px solid #E3E8EC;
}

@media (min-width: 576px) {
  .card-columns.cols-2 {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
.card.drag, .card .drag {
  cursor: move;
}

.card-placeholder {
  background: rgba(0, 0, 0, 0.025);
  border: 1px dashed #BCC5D0;
}

.card {
  border: 1px solid #005598;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #005598;
  border-bottom: 1px solid #E3E8EC;
  color: #FFF;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-block {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.badge-pill {
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #466279;
  border-color: #466279;
}

.progress-xs {
  height: 4px;
}

.progress-sm {
  height: 8px;
}

.progress-white {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.progress-white .progress-bar {
  background-color: #fff;
}

.progress-bar {
  background-color: #20a8d8;
}

.modal-primary .modal-content {
  border-color: #031930;
}
.modal-primary .modal-header {
  color: #fff;
  background-color: #031930;
}

.modal-secondary .modal-content {
  border-color: #E3E8EC;
}
.modal-secondary .modal-header {
  color: #fff;
  background-color: #E3E8EC;
}

.modal-success .modal-content {
  border-color: #41ca74;
}
.modal-success .modal-header {
  color: #fff;
  background-color: #41ca74;
}

.modal-info .modal-content {
  border-color: #5dc4f0;
}
.modal-info .modal-header {
  color: #fff;
  background-color: #5dc4f0;
}

.modal-warning .modal-content {
  border-color: #eea633;
}
.modal-warning .modal-header {
  color: #fff;
  background-color: #eea633;
}

.modal-danger .modal-content {
  border-color: #e75b5e;
}
.modal-danger .modal-header {
  color: #fff;
  background-color: #e75b5e;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog, .modal.fade .modal-dialog-custom {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog, .modal.fade .modal-dialog-custom {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog,
.model-dialog-custom {
  position: relative;
  width: auto;
  margin: 0.5rem;
}

.modal.overlayable {
  z-index: 1041;
}

@media (min-width: 576px) {
  .modal-dialog:has(div.larger-modal) {
    max-width: 60vw !important;
  }
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-dialog-custom {
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
  .modal-customsize {
    max-width: none;
  }
}
@media (min-width: 1200px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip-primary .tooltip-inner {
  background-color: #466279;
}

.tooltip-primary.tooltip-left .tooltip-inner::before,
.tooltip-primary.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #466279;
}

.radio-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.75rem;
  font-size: 14px;
}
.radio-container input[type=radio] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-container input[type=radio]:checked ~ .radio-checkmark:after {
  display: block;
}
.radio-container:hover input ~ .radio-checkmark {
  background-color: #9aadbc;
}
.radio-container input[disabled] ~ .radio-checkmark {
  background-color: #F6F9FD;
}
.radio-container .radio-checkmark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #082B46;
}

.radio-checkmark {
  border: 1px solid #dbe4ed;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .is-other-month */
  /* .bs-datepicker-buttons */
}

.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}

.bs-datepicker bs-day-picker {
  float: left;
}

.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active,
.bs-datepicker-btns button:hover,
.bs-datepicker-btns button:focus,
.bs-datepicker-btns button:active,
.bs-datepicker-predefined-btns button:active,
.bs-datepicker-predefined-btns button:focus {
  outline: none;
}

.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}

.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}

.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}

.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #e9edf0;
  /* .bs-datepicker-body table */
}

.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}

.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}

.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
}

.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  position: relative;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker-body table td:not(.disabled) span {
  cursor: pointer;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: #fff;
}

.bs-datepicker-body table td.active {
  position: relative;
}

.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}

.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}

.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after,
.bs-datepicker-body table td.active.select-start span:after,
.bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}

.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -2px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}

.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}

.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}

.bs-datepicker-body table td span[class*=select-],
.bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #fff;
}

.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}

.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}

.bs-datepicker-body table.days span.is-highlighted.in-range:before, .bs-datepicker-body table.days span.in-range.select-end:before {
  background: none;
  right: 0;
  left: 0;
}

.bs-datepicker-body table.days td.select-start + td.select-end:before,
.bs-datepicker-body table.days td.select-start + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.is-highlighted:before,
.bs-datepicker-body table.days td.active + td.select-end:before,
.bs-datepicker-body table.days td.in-range + td.is-highlighted:before,
.bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}

.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}

.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}

.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}

.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}

.bs-datepicker-body table.months td {
  height: 52px;
}

.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}

.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}

.bs-datepicker-multiple {
  display: inline-block;
  border-radius: 4px 0 0 4px;
}

.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}

.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}

.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}

.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}

.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}

.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}

.bs-datepicker-container {
  padding: 15px;
}

.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}

.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}

.bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}

.bs-datepicker .is-other-month {
  color: rgba(0, 0, 0, 0.25);
}

.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #e9edf0;
}

.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}

.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}

.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}

.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}

.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #e9edf0;
}

.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #e9edf0;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}

.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  bs-datepicker-container,
  bs-daterangepicker-container {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td span.selected,
.theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after,
.theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #777;
}

.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after,
.theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5cb85c;
}

.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td span.selected,
.theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5bc0de;
}

.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after,
.theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*=select-]:after,
.theme-red .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #d9534f;
}

.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td span.selected,
.theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*=select-]:after,
.theme-orange .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #f0ad4e;
}

.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}

.k-sr-only {
  position: absolute;
  left: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.k-theme-test-class,
.k-common-test-class {
  opacity: 0;
}

.k-body {
  font-size: var(--kendo-font-size, inherit);
  font-family: var(--kendo-font-family, inherit);
  line-height: var(--kendo-line-height, normal);
  font-weight: var(--kendo-font-weight, normal);
  letter-spacing: var(--kendo-letter-spacing, normal);
  color: var(--kendo-body-text, initial);
  background-color: var(--kendo-body-bg, initial);
  margin: 0;
}

.k-hstack, .k-hbox {
  display: flex;
  flex-flow: row nowrap;
}

.k-vstack, .k-vbox {
  display: flex;
  flex-flow: column nowrap;
}

.k-spacer, .k-flex {
  flex: 1 1 auto;
}

.k-spacer-sized {
  flex: none;
}

.k-float-wrap::after, .k-floatwrap::after {
  content: "";
  display: block;
  clear: both;
}

.k-flex-layout {
  display: flex;
}

.k-grid-layout {
  display: grid;
}

.k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  display: block;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  align-self: stretch;
}

.k-separator-horizontal,
.k-vstack > .k-separator,
.k-vbox > .k-separator {
  width: auto;
  height: 0;
  border-width: 1px 0 0;
}

.k-separator-vertical,
.k-hstack > .k-separator,
.k-hbox > .k-separator {
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
}

hr.k-separator {
  margin: 0;
  padding: 0;
}

.k-hidden {
  display: none !important;
}

.k-rtl {
  direction: rtl;
}

[hidden] {
  display: none !important;
}

script {
  display: none !important;
}

.k-disabled,
.k-widget[disabled],
.k-disabled {
  outline: none;
  cursor: default;
  color: var(--kendo-disabled-text, inherit);
  border-color: var(--kendo-disabled-border, inherit);
  pointer-events: none;
  box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link,
.k-disabled .k-link {
  cursor: default;
  outline: 0;
}

.k-hr {
  margin-block: 16px;
  padding: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: var(--kendo-component-border, inherit);
  display: block;
  float: none;
  clear: both;
}

.k-d-flex-row > .k-hr {
  margin: 0;
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
  margin: 0;
  flex: 0 0 auto;
}

.k-sprite {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-reset {
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  text-decoration: none;
  font: inherit;
  list-style: none;
}

kendo-sortable {
  display: block;
}

.k-link,
.k-link:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

.k-content {
  outline: 0;
}

.k-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-no-click {
  pointer-events: none;
}

.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
  display: none !important;
}

.k-dirty {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  border-block-start-color: currentColor;
  border-block-end-color: transparent;
  border-inline-start-color: transparent;
  border-inline-end-color: currentColor;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

.k-loading-mask,
.k-loading-image,
.k-loading-color {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}
.k-loading-mask *,
.k-loading-mask *::before,
.k-loading-mask *::after, .k-loading-mask::before, .k-loading-mask::after,
.k-loading-image *,
.k-loading-image *::before,
.k-loading-image *::after,
.k-loading-image::before,
.k-loading-image::after,
.k-loading-color *,
.k-loading-color *::before,
.k-loading-color *::after,
.k-loading-color::before,
.k-loading-color::after {
  box-sizing: border-box;
}

.k-loading-mask {
  z-index: 100;
}
.k-loading-mask.k-opaque .k-loading-color {
  opacity: 1;
}

.k-loading-text {
  text-indent: -4000px;
  text-align: center;
  position: absolute;
  color: currentColor;
}

.k-loading-image {
  z-index: 2;
  color: currentColor;
}

.k-loading-color {
  background-color: #ffffff;
  opacity: 0.3;
}

.k-i-loading {
  width: 1em;
  height: 1em;
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  background-color: transparent;
  box-sizing: border-box;
  color: currentColor;
}
.k-i-loading::before, .k-i-loading::after {
  box-sizing: border-box;
}

.k-i-loading::before,
.k-i-loading::after,
.k-loading-image::before,
.k-loading-image::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: 0.05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-icon.k-i-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading::before,
.k-loading-image::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading::after,
.k-loading-image::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.k-loading-image::before,
.k-loading-image::after {
  content: "";
  border-width: 1px;
  border-width: clamp(0.015em, 1px, 1px);
  font-size: 4em;
}

@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.k-marquee {
  position: absolute;
  z-index: 100000;
}

.k-marquee-color,
.k-marquee-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.k-marquee-color {
  color: white;
  background-color: var(--kendo-primary-60, #ff6358);
  border-color: var(--kendo-primary-100, rgba(0, 0, 0, 0.08));
  opacity: 0.6;
}

.k-marquee-text {
  color: white;
}

:root {
  --kendo-elevation-1: 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-2: 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-3: 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-4: 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-5: 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-6: 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-7: 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-8: 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12);
  --kendo-elevation-9: 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-ratio-auto {
  aspect-ratio: auto;
}

.\!k-ratio-auto {
  aspect-ratio: auto !important;
}

.k-ratio-1 {
  aspect-ratio: 1;
}

.\!k-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-aspect-ratio-auto {
  aspect-ratio: auto;
}

.\!k-aspect-ratio-auto {
  aspect-ratio: auto !important;
}

.k-aspect-ratio-1 {
  aspect-ratio: 1;
}

.\!k-aspect-ratio-1 {
  aspect-ratio: 1 !important;
}

.k-aspect-ratio-square {
  aspect-ratio: 1 / 1;
}

.\!k-aspect-ratio-square {
  aspect-ratio: 1 / 1 !important;
}

.k-aspect-ratio-video {
  aspect-ratio: 16 / 9;
}

.\!k-aspect-ratio-video {
  aspect-ratio: 16 / 9 !important;
}

.k-box-sizing-border {
  box-sizing: border-box;
}

.\!k-box-sizing-border {
  box-sizing: border-box !important;
}

.k-box-sizing-content {
  box-sizing: content-box;
}

.\!k-box-sizing-content {
  box-sizing: content-box !important;
}

.k-clear-left {
  clear: left;
}

.\!k-clear-left {
  clear: left !important;
}

.k-clear-right {
  clear: right;
}

.\!k-clear-right {
  clear: right !important;
}

.k-clear-both {
  clear: both;
}

.\!k-clear-both {
  clear: both !important;
}

.k-clear-none {
  clear: none;
}

.\!k-clear-none {
  clear: none !important;
}

.k-columns-1 {
  columns: 1;
}

.\!k-columns-1 {
  columns: 1 !important;
}

.k-columns-2 {
  columns: 2;
}

.\!k-columns-2 {
  columns: 2 !important;
}

.k-columns-3 {
  columns: 3;
}

.\!k-columns-3 {
  columns: 3 !important;
}

.k-columns-4 {
  columns: 4;
}

.\!k-columns-4 {
  columns: 4 !important;
}

.k-columns-5 {
  columns: 5;
}

.\!k-columns-5 {
  columns: 5 !important;
}

.k-columns-6 {
  columns: 6;
}

.\!k-columns-6 {
  columns: 6 !important;
}

.k-columns-7 {
  columns: 7;
}

.\!k-columns-7 {
  columns: 7 !important;
}

.k-columns-8 {
  columns: 8;
}

.\!k-columns-8 {
  columns: 8 !important;
}

.k-columns-9 {
  columns: 9;
}

.\!k-columns-9 {
  columns: 9 !important;
}

.k-columns-10 {
  columns: 10;
}

.\!k-columns-10 {
  columns: 10 !important;
}

.k-columns-11 {
  columns: 11;
}

.\!k-columns-11 {
  columns: 11 !important;
}

.k-columns-12 {
  columns: 12;
}

.\!k-columns-12 {
  columns: 12 !important;
}

.k-columns-auto {
  columns: auto;
}

.\!k-columns-auto {
  columns: auto !important;
}

.k-d-none {
  display: none;
}

.\!k-d-none {
  display: none !important;
}

.k-d-contents {
  display: contents;
}

.\!k-d-contents {
  display: contents !important;
}

.k-d-block {
  display: block;
}

.\!k-d-block {
  display: block !important;
}

.k-d-inline {
  display: inline;
}

.\!k-d-inline {
  display: inline !important;
}

.k-d-inline-block {
  display: inline-block;
}

.\!k-d-inline-block {
  display: inline-block !important;
}

.k-d-flex, .k-d-flex-col, .k-d-flex-row {
  display: flex;
}

.\!k-d-flex, .\!k-d-flex-col, .\!k-d-flex-row {
  display: flex !important;
}

.k-d-inline-flex {
  display: inline-flex;
}

.\!k-d-inline-flex {
  display: inline-flex !important;
}

.k-d-grid {
  display: grid;
}

.\!k-d-grid {
  display: grid !important;
}

.k-d-inline-grid {
  display: inline-grid;
}

.\!k-d-inline-grid {
  display: inline-grid !important;
}

.k-d-table {
  display: table;
}

.\!k-d-table {
  display: table !important;
}

.k-d-inline-table {
  display: inline-table;
}

.\!k-d-inline-table {
  display: inline-table !important;
}

.k-d-table-row {
  display: table-row;
}

.\!k-d-table-row {
  display: table-row !important;
}

.k-d-table-cell {
  display: table-cell;
}

.\!k-d-table-cell {
  display: table-cell !important;
}

.k-d-list-item {
  display: list-item;
}

.\!k-d-list-item {
  display: list-item !important;
}

.k-display-none {
  display: none;
}

.\!k-display-none {
  display: none !important;
}

.k-display-contents {
  display: contents;
}

.\!k-display-contents {
  display: contents !important;
}

.k-display-block {
  display: block;
}

.\!k-display-block {
  display: block !important;
}

.k-display-inline {
  display: inline;
}

.\!k-display-inline {
  display: inline !important;
}

.k-display-inline-block {
  display: inline-block;
}

.\!k-display-inline-block {
  display: inline-block !important;
}

.k-display-flex {
  display: flex;
}

.\!k-display-flex {
  display: flex !important;
}

.k-display-inline-flex {
  display: inline-flex;
}

.\!k-display-inline-flex {
  display: inline-flex !important;
}

.k-display-grid {
  display: grid;
}

.\!k-display-grid {
  display: grid !important;
}

.k-display-inline-grid {
  display: inline-grid;
}

.\!k-display-inline-grid {
  display: inline-grid !important;
}

.k-display-table {
  display: table;
}

.\!k-display-table {
  display: table !important;
}

.k-display-inline-table {
  display: inline-table;
}

.\!k-display-inline-table {
  display: inline-table !important;
}

.k-display-table-row {
  display: table-row;
}

.\!k-display-table-row {
  display: table-row !important;
}

.k-display-table-cell {
  display: table-cell;
}

.\!k-display-table-cell {
  display: table-cell !important;
}

.k-display-list-item {
  display: list-item;
}

.\!k-display-list-item {
  display: list-item !important;
}

.k-float-left {
  float: left;
}

.\!k-float-left {
  float: left !important;
}

.k-float-right {
  float: right;
}

.\!k-float-right {
  float: right !important;
}

.k-float-none {
  float: none;
}

.\!k-float-none {
  float: none !important;
}

.k-object-fit-contain {
  object-fit: contain;
}

.\!k-object-fit-contain {
  object-fit: contain !important;
}

.k-object-fit-cover {
  object-fit: cover;
}

.\!k-object-fit-cover {
  object-fit: cover !important;
}

.k-object-fit-fill {
  object-fit: fill;
}

.\!k-object-fit-fill {
  object-fit: fill !important;
}

.k-object-fit-scale-down {
  object-fit: scale-down;
}

.\!k-object-fit-scale-down {
  object-fit: scale-down !important;
}

.k-object-fit-initial {
  object-fit: initial;
}

.\!k-object-fit-initial {
  object-fit: initial !important;
}

.k-object-fit-none {
  object-fit: none;
}

.\!k-object-fit-none {
  object-fit: none !important;
}

.k-object-position-center {
  object-position: center;
}

.\!k-object-position-center {
  object-position: center !important;
}

.k-object-position-top {
  object-position: top;
}

.\!k-object-position-top {
  object-position: top !important;
}

.k-object-position-right {
  object-position: right;
}

.\!k-object-position-right {
  object-position: right !important;
}

.k-object-position-bottom {
  object-position: bottom;
}

.\!k-object-position-bottom {
  object-position: bottom !important;
}

.k-object-position-left {
  object-position: left;
}

.\!k-object-position-left {
  object-position: left !important;
}

.k-object-position-top-left {
  object-position: top left;
}

.\!k-object-position-top-left {
  object-position: top left !important;
}

.k-object-position-top-right {
  object-position: top right;
}

.\!k-object-position-top-right {
  object-position: top right !important;
}

.k-object-position-bottom-left {
  object-position: bottom left;
}

.\!k-object-position-bottom-left {
  object-position: bottom left !important;
}

.k-object-position-bottom-right {
  object-position: bottom right;
}

.\!k-object-position-bottom-right {
  object-position: bottom right !important;
}

.k-overflow-auto {
  overflow: auto;
}

.\!k-overflow-auto {
  overflow: auto !important;
}

.k-overflow-hidden {
  overflow: hidden;
}

.\!k-overflow-hidden {
  overflow: hidden !important;
}

.k-overflow-visible {
  overflow: visible;
}

.\!k-overflow-visible {
  overflow: visible !important;
}

.k-overflow-scroll {
  overflow: scroll;
}

.\!k-overflow-scroll {
  overflow: scroll !important;
}

.k-overflow-clip {
  overflow: clip;
}

.\!k-overflow-clip {
  overflow: clip !important;
}

.k-overflow-x-auto {
  overflow-x: auto;
}

.\!k-overflow-x-auto {
  overflow-x: auto !important;
}

.k-overflow-x-hidden {
  overflow-x: hidden;
}

.\!k-overflow-x-hidden {
  overflow-x: hidden !important;
}

.k-overflow-x-visible {
  overflow-x: visible;
}

.\!k-overflow-x-visible {
  overflow-x: visible !important;
}

.k-overflow-x-scroll {
  overflow-x: scroll;
}

.\!k-overflow-x-scroll {
  overflow-x: scroll !important;
}

.k-overflow-x-clip {
  overflow-x: clip;
}

.\!k-overflow-x-clip {
  overflow-x: clip !important;
}

.k-overflow-y-auto {
  overflow-y: auto;
}

.\!k-overflow-y-auto {
  overflow-y: auto !important;
}

.k-overflow-y-hidden {
  overflow-y: hidden;
}

.\!k-overflow-y-hidden {
  overflow-y: hidden !important;
}

.k-overflow-y-visible {
  overflow-y: visible;
}

.\!k-overflow-y-visible {
  overflow-y: visible !important;
}

.k-overflow-y-scroll {
  overflow-y: scroll;
}

.\!k-overflow-y-scroll {
  overflow-y: scroll !important;
}

.k-overflow-y-clip {
  overflow-y: clip;
}

.\!k-overflow-y-clip {
  overflow-y: clip !important;
}

.k-top-0 {
  top: 0;
}

.\!k-top-0 {
  top: 0 !important;
}

.k-top-1px {
  top: 1px;
}

.\!k-top-1px {
  top: 1px !important;
}

.k-top-0\.5 {
  top: 2px;
}

.\!k-top-0\.5 {
  top: 2px !important;
}

.k-top-1 {
  top: 4px;
}

.\!k-top-1 {
  top: 4px !important;
}

.k-top-1\.5 {
  top: 6px;
}

.\!k-top-1\.5 {
  top: 6px !important;
}

.k-top-2 {
  top: 8px;
}

.\!k-top-2 {
  top: 8px !important;
}

.k-top-2\.5 {
  top: 10px;
}

.\!k-top-2\.5 {
  top: 10px !important;
}

.k-top-3 {
  top: 12px;
}

.\!k-top-3 {
  top: 12px !important;
}

.k-top-3\.5 {
  top: 14px;
}

.\!k-top-3\.5 {
  top: 14px !important;
}

.k-top-4 {
  top: 16px;
}

.\!k-top-4 {
  top: 16px !important;
}

.k-top-4\.5 {
  top: 18px;
}

.\!k-top-4\.5 {
  top: 18px !important;
}

.k-top-5 {
  top: 20px;
}

.\!k-top-5 {
  top: 20px !important;
}

.k-top-5\.5 {
  top: 22px;
}

.\!k-top-5\.5 {
  top: 22px !important;
}

.k-top-6 {
  top: 24px;
}

.\!k-top-6 {
  top: 24px !important;
}

.k-top-6\.5 {
  top: 26px;
}

.\!k-top-6\.5 {
  top: 26px !important;
}

.k-top-7 {
  top: 28px;
}

.\!k-top-7 {
  top: 28px !important;
}

.k-top-7\.5 {
  top: 30px;
}

.\!k-top-7\.5 {
  top: 30px !important;
}

.k-top-8 {
  top: 32px;
}

.\!k-top-8 {
  top: 32px !important;
}

.k-top-9 {
  top: 36px;
}

.\!k-top-9 {
  top: 36px !important;
}

.k-top-10 {
  top: 40px;
}

.\!k-top-10 {
  top: 40px !important;
}

.k-top-11 {
  top: 44px;
}

.\!k-top-11 {
  top: 44px !important;
}

.k-top-12 {
  top: 48px;
}

.\!k-top-12 {
  top: 48px !important;
}

.k-top-13 {
  top: 52px;
}

.\!k-top-13 {
  top: 52px !important;
}

.k-top-14 {
  top: 56px;
}

.\!k-top-14 {
  top: 56px !important;
}

.k-top-15 {
  top: 60px;
}

.\!k-top-15 {
  top: 60px !important;
}

.k-top-16 {
  top: 64px;
}

.\!k-top-16 {
  top: 64px !important;
}

.k-top-17 {
  top: 68px;
}

.\!k-top-17 {
  top: 68px !important;
}

.k-top-18 {
  top: 72px;
}

.\!k-top-18 {
  top: 72px !important;
}

.k-top-19 {
  top: 76px;
}

.\!k-top-19 {
  top: 76px !important;
}

.k-top-20 {
  top: 80px;
}

.\!k-top-20 {
  top: 80px !important;
}

.k-top-21 {
  top: 84px;
}

.\!k-top-21 {
  top: 84px !important;
}

.k-top-22 {
  top: 88px;
}

.\!k-top-22 {
  top: 88px !important;
}

.k-top-23 {
  top: 92px;
}

.\!k-top-23 {
  top: 92px !important;
}

.k-top-24 {
  top: 96px;
}

.\!k-top-24 {
  top: 96px !important;
}

.k-top--1 {
  top: -1px;
}

.\!k-top--1 {
  top: -1px !important;
}

.k-right-0 {
  right: 0;
}

.\!k-right-0 {
  right: 0 !important;
}

.k-right-1px {
  right: 1px;
}

.\!k-right-1px {
  right: 1px !important;
}

.k-right-0\.5 {
  right: 2px;
}

.\!k-right-0\.5 {
  right: 2px !important;
}

.k-right-1 {
  right: 4px;
}

.\!k-right-1 {
  right: 4px !important;
}

.k-right-1\.5 {
  right: 6px;
}

.\!k-right-1\.5 {
  right: 6px !important;
}

.k-right-2 {
  right: 8px;
}

.\!k-right-2 {
  right: 8px !important;
}

.k-right-2\.5 {
  right: 10px;
}

.\!k-right-2\.5 {
  right: 10px !important;
}

.k-right-3 {
  right: 12px;
}

.\!k-right-3 {
  right: 12px !important;
}

.k-right-3\.5 {
  right: 14px;
}

.\!k-right-3\.5 {
  right: 14px !important;
}

.k-right-4 {
  right: 16px;
}

.\!k-right-4 {
  right: 16px !important;
}

.k-right-4\.5 {
  right: 18px;
}

.\!k-right-4\.5 {
  right: 18px !important;
}

.k-right-5 {
  right: 20px;
}

.\!k-right-5 {
  right: 20px !important;
}

.k-right-5\.5 {
  right: 22px;
}

.\!k-right-5\.5 {
  right: 22px !important;
}

.k-right-6 {
  right: 24px;
}

.\!k-right-6 {
  right: 24px !important;
}

.k-right-6\.5 {
  right: 26px;
}

.\!k-right-6\.5 {
  right: 26px !important;
}

.k-right-7 {
  right: 28px;
}

.\!k-right-7 {
  right: 28px !important;
}

.k-right-7\.5 {
  right: 30px;
}

.\!k-right-7\.5 {
  right: 30px !important;
}

.k-right-8 {
  right: 32px;
}

.\!k-right-8 {
  right: 32px !important;
}

.k-right-9 {
  right: 36px;
}

.\!k-right-9 {
  right: 36px !important;
}

.k-right-10 {
  right: 40px;
}

.\!k-right-10 {
  right: 40px !important;
}

.k-right-11 {
  right: 44px;
}

.\!k-right-11 {
  right: 44px !important;
}

.k-right-12 {
  right: 48px;
}

.\!k-right-12 {
  right: 48px !important;
}

.k-right-13 {
  right: 52px;
}

.\!k-right-13 {
  right: 52px !important;
}

.k-right-14 {
  right: 56px;
}

.\!k-right-14 {
  right: 56px !important;
}

.k-right-15 {
  right: 60px;
}

.\!k-right-15 {
  right: 60px !important;
}

.k-right-16 {
  right: 64px;
}

.\!k-right-16 {
  right: 64px !important;
}

.k-right-17 {
  right: 68px;
}

.\!k-right-17 {
  right: 68px !important;
}

.k-right-18 {
  right: 72px;
}

.\!k-right-18 {
  right: 72px !important;
}

.k-right-19 {
  right: 76px;
}

.\!k-right-19 {
  right: 76px !important;
}

.k-right-20 {
  right: 80px;
}

.\!k-right-20 {
  right: 80px !important;
}

.k-right-21 {
  right: 84px;
}

.\!k-right-21 {
  right: 84px !important;
}

.k-right-22 {
  right: 88px;
}

.\!k-right-22 {
  right: 88px !important;
}

.k-right-23 {
  right: 92px;
}

.\!k-right-23 {
  right: 92px !important;
}

.k-right-24 {
  right: 96px;
}

.\!k-right-24 {
  right: 96px !important;
}

.k-right--1 {
  right: -1px;
}

.\!k-right--1 {
  right: -1px !important;
}

.k-bottom-0 {
  bottom: 0;
}

.\!k-bottom-0 {
  bottom: 0 !important;
}

.k-bottom-1px {
  bottom: 1px;
}

.\!k-bottom-1px {
  bottom: 1px !important;
}

.k-bottom-0\.5 {
  bottom: 2px;
}

.\!k-bottom-0\.5 {
  bottom: 2px !important;
}

.k-bottom-1 {
  bottom: 4px;
}

.\!k-bottom-1 {
  bottom: 4px !important;
}

.k-bottom-1\.5 {
  bottom: 6px;
}

.\!k-bottom-1\.5 {
  bottom: 6px !important;
}

.k-bottom-2 {
  bottom: 8px;
}

.\!k-bottom-2 {
  bottom: 8px !important;
}

.k-bottom-2\.5 {
  bottom: 10px;
}

.\!k-bottom-2\.5 {
  bottom: 10px !important;
}

.k-bottom-3 {
  bottom: 12px;
}

.\!k-bottom-3 {
  bottom: 12px !important;
}

.k-bottom-3\.5 {
  bottom: 14px;
}

.\!k-bottom-3\.5 {
  bottom: 14px !important;
}

.k-bottom-4 {
  bottom: 16px;
}

.\!k-bottom-4 {
  bottom: 16px !important;
}

.k-bottom-4\.5 {
  bottom: 18px;
}

.\!k-bottom-4\.5 {
  bottom: 18px !important;
}

.k-bottom-5 {
  bottom: 20px;
}

.\!k-bottom-5 {
  bottom: 20px !important;
}

.k-bottom-5\.5 {
  bottom: 22px;
}

.\!k-bottom-5\.5 {
  bottom: 22px !important;
}

.k-bottom-6 {
  bottom: 24px;
}

.\!k-bottom-6 {
  bottom: 24px !important;
}

.k-bottom-6\.5 {
  bottom: 26px;
}

.\!k-bottom-6\.5 {
  bottom: 26px !important;
}

.k-bottom-7 {
  bottom: 28px;
}

.\!k-bottom-7 {
  bottom: 28px !important;
}

.k-bottom-7\.5 {
  bottom: 30px;
}

.\!k-bottom-7\.5 {
  bottom: 30px !important;
}

.k-bottom-8 {
  bottom: 32px;
}

.\!k-bottom-8 {
  bottom: 32px !important;
}

.k-bottom-9 {
  bottom: 36px;
}

.\!k-bottom-9 {
  bottom: 36px !important;
}

.k-bottom-10 {
  bottom: 40px;
}

.\!k-bottom-10 {
  bottom: 40px !important;
}

.k-bottom-11 {
  bottom: 44px;
}

.\!k-bottom-11 {
  bottom: 44px !important;
}

.k-bottom-12 {
  bottom: 48px;
}

.\!k-bottom-12 {
  bottom: 48px !important;
}

.k-bottom-13 {
  bottom: 52px;
}

.\!k-bottom-13 {
  bottom: 52px !important;
}

.k-bottom-14 {
  bottom: 56px;
}

.\!k-bottom-14 {
  bottom: 56px !important;
}

.k-bottom-15 {
  bottom: 60px;
}

.\!k-bottom-15 {
  bottom: 60px !important;
}

.k-bottom-16 {
  bottom: 64px;
}

.\!k-bottom-16 {
  bottom: 64px !important;
}

.k-bottom-17 {
  bottom: 68px;
}

.\!k-bottom-17 {
  bottom: 68px !important;
}

.k-bottom-18 {
  bottom: 72px;
}

.\!k-bottom-18 {
  bottom: 72px !important;
}

.k-bottom-19 {
  bottom: 76px;
}

.\!k-bottom-19 {
  bottom: 76px !important;
}

.k-bottom-20 {
  bottom: 80px;
}

.\!k-bottom-20 {
  bottom: 80px !important;
}

.k-bottom-21 {
  bottom: 84px;
}

.\!k-bottom-21 {
  bottom: 84px !important;
}

.k-bottom-22 {
  bottom: 88px;
}

.\!k-bottom-22 {
  bottom: 88px !important;
}

.k-bottom-23 {
  bottom: 92px;
}

.\!k-bottom-23 {
  bottom: 92px !important;
}

.k-bottom-24 {
  bottom: 96px;
}

.\!k-bottom-24 {
  bottom: 96px !important;
}

.k-bottom--1 {
  bottom: -1px;
}

.\!k-bottom--1 {
  bottom: -1px !important;
}

.k-left-0 {
  left: 0;
}

.\!k-left-0 {
  left: 0 !important;
}

.k-left-1px {
  left: 1px;
}

.\!k-left-1px {
  left: 1px !important;
}

.k-left-0\.5 {
  left: 2px;
}

.\!k-left-0\.5 {
  left: 2px !important;
}

.k-left-1 {
  left: 4px;
}

.\!k-left-1 {
  left: 4px !important;
}

.k-left-1\.5 {
  left: 6px;
}

.\!k-left-1\.5 {
  left: 6px !important;
}

.k-left-2 {
  left: 8px;
}

.\!k-left-2 {
  left: 8px !important;
}

.k-left-2\.5 {
  left: 10px;
}

.\!k-left-2\.5 {
  left: 10px !important;
}

.k-left-3 {
  left: 12px;
}

.\!k-left-3 {
  left: 12px !important;
}

.k-left-3\.5 {
  left: 14px;
}

.\!k-left-3\.5 {
  left: 14px !important;
}

.k-left-4 {
  left: 16px;
}

.\!k-left-4 {
  left: 16px !important;
}

.k-left-4\.5 {
  left: 18px;
}

.\!k-left-4\.5 {
  left: 18px !important;
}

.k-left-5 {
  left: 20px;
}

.\!k-left-5 {
  left: 20px !important;
}

.k-left-5\.5 {
  left: 22px;
}

.\!k-left-5\.5 {
  left: 22px !important;
}

.k-left-6 {
  left: 24px;
}

.\!k-left-6 {
  left: 24px !important;
}

.k-left-6\.5 {
  left: 26px;
}

.\!k-left-6\.5 {
  left: 26px !important;
}

.k-left-7 {
  left: 28px;
}

.\!k-left-7 {
  left: 28px !important;
}

.k-left-7\.5 {
  left: 30px;
}

.\!k-left-7\.5 {
  left: 30px !important;
}

.k-left-8 {
  left: 32px;
}

.\!k-left-8 {
  left: 32px !important;
}

.k-left-9 {
  left: 36px;
}

.\!k-left-9 {
  left: 36px !important;
}

.k-left-10 {
  left: 40px;
}

.\!k-left-10 {
  left: 40px !important;
}

.k-left-11 {
  left: 44px;
}

.\!k-left-11 {
  left: 44px !important;
}

.k-left-12 {
  left: 48px;
}

.\!k-left-12 {
  left: 48px !important;
}

.k-left-13 {
  left: 52px;
}

.\!k-left-13 {
  left: 52px !important;
}

.k-left-14 {
  left: 56px;
}

.\!k-left-14 {
  left: 56px !important;
}

.k-left-15 {
  left: 60px;
}

.\!k-left-15 {
  left: 60px !important;
}

.k-left-16 {
  left: 64px;
}

.\!k-left-16 {
  left: 64px !important;
}

.k-left-17 {
  left: 68px;
}

.\!k-left-17 {
  left: 68px !important;
}

.k-left-18 {
  left: 72px;
}

.\!k-left-18 {
  left: 72px !important;
}

.k-left-19 {
  left: 76px;
}

.\!k-left-19 {
  left: 76px !important;
}

.k-left-20 {
  left: 80px;
}

.\!k-left-20 {
  left: 80px !important;
}

.k-left-21 {
  left: 84px;
}

.\!k-left-21 {
  left: 84px !important;
}

.k-left-22 {
  left: 88px;
}

.\!k-left-22 {
  left: 88px !important;
}

.k-left-23 {
  left: 92px;
}

.\!k-left-23 {
  left: 92px !important;
}

.k-left-24 {
  left: 96px;
}

.\!k-left-24 {
  left: 96px !important;
}

.k-left--1 {
  left: -1px;
}

.\!k-left--1 {
  left: -1px !important;
}

.k-top,
.k-pos-top {
  top: 0;
}

.k-right,
.k-pos-right {
  right: 0;
}

.k-bottom,
.k-pos-bottom {
  bottom: 0;
}

.k-left,
.k-pos-left {
  left: 0;
}

.k-inset-0 {
  inset: 0;
}

.\!k-inset-0 {
  inset: 0 !important;
}

.k-inset-1 {
  inset: 1px;
}

.\!k-inset-1 {
  inset: 1px !important;
}

.k-inset--1 {
  inset: -1px;
}

.\!k-inset--1 {
  inset: -1px !important;
}

.k-inset-x-0 {
  inset-inline: 0;
}

.\!k-inset-x-0 {
  inset-inline: 0 !important;
}

.k-inset-x-1 {
  inset-inline: 1px;
}

.\!k-inset-x-1 {
  inset-inline: 1px !important;
}

.k-inset-x--1 {
  inset-inline: -1px;
}

.\!k-inset-x--1 {
  inset-inline: -1px !important;
}

.k-inset-y-0 {
  inset-block: 0;
}

.\!k-inset-y-0 {
  inset-block: 0 !important;
}

.k-inset-y-1 {
  inset-block: 1px;
}

.\!k-inset-y-1 {
  inset-block: 1px !important;
}

.k-inset-y--1 {
  inset-block: -1px;
}

.\!k-inset-y--1 {
  inset-block: -1px !important;
}

.k-top-left-0 {
  top: 0;
  left: 0;
}

.\!k-top-left-0 {
  top: 0 !important;
  left: 0 !important;
}

.k-top-left-1 {
  top: 1px;
  left: 1px;
}

.\!k-top-left-1 {
  top: 1px !important;
  left: 1px !important;
}

.k-top-left--1 {
  top: -1px;
  left: -1px;
}

.\!k-top-left--1 {
  top: -1px !important;
  left: -1px !important;
}

.k-top-right-0 {
  top: 0;
  right: 0;
}

.\!k-top-right-0 {
  top: 0 !important;
  right: 0 !important;
}

.k-top-right-1 {
  top: 1px;
  right: 1px;
}

.\!k-top-right-1 {
  top: 1px !important;
  right: 1px !important;
}

.k-top-right--1 {
  top: -1px;
  right: -1px;
}

.\!k-top-right--1 {
  top: -1px !important;
  right: -1px !important;
}

.k-bottom-left-0 {
  bottom: 0;
  left: 0;
}

.\!k-bottom-left-0 {
  bottom: 0 !important;
  left: 0 !important;
}

.k-bottom-left-1 {
  bottom: 1px;
  left: 1px;
}

.\!k-bottom-left-1 {
  bottom: 1px !important;
  left: 1px !important;
}

.k-bottom-left--1 {
  bottom: -1px;
  left: -1px;
}

.\!k-bottom-left--1 {
  bottom: -1px !important;
  left: -1px !important;
}

.k-bottom-right-0 {
  bottom: 0;
  right: 0;
}

.\!k-bottom-right-0 {
  bottom: 0 !important;
  right: 0 !important;
}

.k-bottom-right-1 {
  bottom: 1px;
  right: 1px;
}

.\!k-bottom-right-1 {
  bottom: 1px !important;
  right: 1px !important;
}

.k-bottom-right--1 {
  bottom: -1px;
  right: -1px;
}

.\!k-bottom-right--1 {
  bottom: -1px !important;
  right: -1px !important;
}

.k-top-start,
.k-pos-top-start {
  top: 0;
  inset-inline-start: 0;
}

.k-top-end,
.k-pos-top-end {
  top: 0;
  inset-inline-end: 0;
}

.k-bottom-start,
.k-pos-bottom-start {
  bottom: 0;
  inset-inline-start: 0;
}

.k-bottom-end,
.k-pos-bottom-end {
  bottom: 0;
  inset-inline-end: 0;
}

.k-top-center,
.k-pos-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-middle-left,
.k-middle-start,
.k-pos-middle-start {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.k-middle-center {
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}

.k-middle-right,
.k-middle-end,
.k-pos-middle-end {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-bottom-center,
.k-pos-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-pos-static, .k-static {
  position: static;
}

.\!k-pos-static, .\!k-static {
  position: static !important;
}

.k-pos-relative, .k-relative {
  position: relative;
}

.\!k-pos-relative, .\!k-relative {
  position: relative !important;
}

.k-pos-absolute, .k-absolute {
  position: absolute;
}

.\!k-pos-absolute, .\!k-absolute {
  position: absolute !important;
}

.k-pos-fixed, .k-fixed {
  position: fixed;
}

.\!k-pos-fixed, .\!k-fixed {
  position: fixed !important;
}

.k-pos-sticky, .k-sticky {
  position: sticky;
}

.\!k-pos-sticky, .\!k-sticky {
  position: sticky !important;
}

.k-visibility-visible, .k-visible {
  visibility: visible;
}

.\!k-visibility-visible, .\!k-visible {
  visibility: visible !important;
}

.k-visibility-invisible {
  visibility: hidden;
}

.\!k-visibility-invisible {
  visibility: hidden !important;
}

.k-visibility-collapse {
  visibility: collapse;
}

.\!k-visibility-collapse {
  visibility: collapse !important;
}

.k-z-0 {
  z-index: 0;
}

.\!k-z-0 {
  z-index: 0 !important;
}

.k-z-10 {
  z-index: 10;
}

.\!k-z-10 {
  z-index: 10 !important;
}

.k-z-20 {
  z-index: 20;
}

.\!k-z-20 {
  z-index: 20 !important;
}

.k-z-30 {
  z-index: 30;
}

.\!k-z-30 {
  z-index: 30 !important;
}

.k-z-40 {
  z-index: 40;
}

.\!k-z-40 {
  z-index: 40 !important;
}

.k-z-50 {
  z-index: 50;
}

.\!k-z-50 {
  z-index: 50 !important;
}

.k-z-auto {
  z-index: auto;
}

.\!k-z-auto {
  z-index: auto !important;
}

.k-align-content-normal {
  align-content: normal;
}

.\!k-align-content-normal {
  align-content: normal !important;
}

.k-align-content-stretch {
  align-content: stretch;
}

.\!k-align-content-stretch {
  align-content: stretch !important;
}

.k-align-content-center {
  align-content: center;
}

.\!k-align-content-center {
  align-content: center !important;
}

.k-align-content-start {
  align-content: start;
}

.\!k-align-content-start {
  align-content: start !important;
}

.k-align-content-end {
  align-content: end;
}

.\!k-align-content-end {
  align-content: end !important;
}

.k-align-content-flex-start {
  align-content: flex-start;
}

.\!k-align-content-flex-start {
  align-content: flex-start !important;
}

.k-align-content-flex-end {
  align-content: flex-end;
}

.\!k-align-content-flex-end {
  align-content: flex-end !important;
}

.k-align-content-baseline {
  align-content: baseline;
}

.\!k-align-content-baseline {
  align-content: baseline !important;
}

.k-align-content-first-baseline {
  align-content: first baseline;
}

.\!k-align-content-first-baseline {
  align-content: first baseline !important;
}

.k-align-content-last-baseline {
  align-content: last baseline;
}

.\!k-align-content-last-baseline {
  align-content: last baseline !important;
}

.k-align-content-between {
  align-content: space-between;
}

.\!k-align-content-between {
  align-content: space-between !important;
}

.k-align-content-around {
  align-content: space-around;
}

.\!k-align-content-around {
  align-content: space-around !important;
}

.k-align-content-evenly {
  align-content: space-evenly;
}

.\!k-align-content-evenly {
  align-content: space-evenly !important;
}

.k-align-items-normal {
  align-items: normal;
}

.\!k-align-items-normal {
  align-items: normal !important;
}

.k-align-items-stretch {
  align-items: stretch;
}

.\!k-align-items-stretch {
  align-items: stretch !important;
}

.k-align-items-center {
  align-items: center;
}

.\!k-align-items-center {
  align-items: center !important;
}

.k-align-items-start {
  align-items: start;
}

.\!k-align-items-start {
  align-items: start !important;
}

.k-align-items-end {
  align-items: end;
}

.\!k-align-items-end {
  align-items: end !important;
}

.k-align-items-flex-start {
  align-items: flex-start;
}

.\!k-align-items-flex-start {
  align-items: flex-start !important;
}

.k-align-items-flex-end {
  align-items: flex-end;
}

.\!k-align-items-flex-end {
  align-items: flex-end !important;
}

.k-align-items-baseline {
  align-items: baseline;
}

.\!k-align-items-baseline {
  align-items: baseline !important;
}

.k-align-items-first-baseline {
  align-items: first baseline;
}

.\!k-align-items-first-baseline {
  align-items: first baseline !important;
}

.k-align-items-last-baseline {
  align-items: last baseline;
}

.\!k-align-items-last-baseline {
  align-items: last baseline !important;
}

.k-align-items-self-start {
  align-items: self-start;
}

.\!k-align-items-self-start {
  align-items: self-start !important;
}

.k-align-items-self-end {
  align-items: self-end;
}

.\!k-align-items-self-end {
  align-items: self-end !important;
}

.k-align-self-auto {
  align-self: auto;
}

.\!k-align-self-auto {
  align-self: auto !important;
}

.k-align-self-normal {
  align-self: normal;
}

.\!k-align-self-normal {
  align-self: normal !important;
}

.k-align-self-stretch {
  align-self: stretch;
}

.\!k-align-self-stretch {
  align-self: stretch !important;
}

.k-align-self-center {
  align-self: center;
}

.\!k-align-self-center {
  align-self: center !important;
}

.k-align-self-start {
  align-self: start;
}

.\!k-align-self-start {
  align-self: start !important;
}

.k-align-self-end {
  align-self: end;
}

.\!k-align-self-end {
  align-self: end !important;
}

.k-align-self-flex-start {
  align-self: flex-start;
}

.\!k-align-self-flex-start {
  align-self: flex-start !important;
}

.k-align-self-flex-end {
  align-self: flex-end;
}

.\!k-align-self-flex-end {
  align-self: flex-end !important;
}

.k-align-self-baseline {
  align-self: baseline;
}

.\!k-align-self-baseline {
  align-self: baseline !important;
}

.k-align-self-first-baseline {
  align-self: first baseline;
}

.\!k-align-self-first-baseline {
  align-self: first baseline !important;
}

.k-align-self-last-baseline {
  align-self: last baseline;
}

.\!k-align-self-last-baseline {
  align-self: last baseline !important;
}

.k-align-self-self-start {
  align-self: self-start;
}

.\!k-align-self-self-start {
  align-self: self-start !important;
}

.k-align-self-self-end {
  align-self: self-end;
}

.\!k-align-self-self-end {
  align-self: self-end !important;
}

.k-basis-0 {
  flex-basis: 0;
}

.\!k-basis-0 {
  flex-basis: 0 !important;
}

.k-basis-1px {
  flex-basis: 1px;
}

.\!k-basis-1px {
  flex-basis: 1px !important;
}

.k-basis-0\.5 {
  flex-basis: 2px;
}

.\!k-basis-0\.5 {
  flex-basis: 2px !important;
}

.k-basis-1 {
  flex-basis: 4px;
}

.\!k-basis-1 {
  flex-basis: 4px !important;
}

.k-basis-1\.5 {
  flex-basis: 6px;
}

.\!k-basis-1\.5 {
  flex-basis: 6px !important;
}

.k-basis-2 {
  flex-basis: 8px;
}

.\!k-basis-2 {
  flex-basis: 8px !important;
}

.k-basis-2\.5 {
  flex-basis: 10px;
}

.\!k-basis-2\.5 {
  flex-basis: 10px !important;
}

.k-basis-3 {
  flex-basis: 12px;
}

.\!k-basis-3 {
  flex-basis: 12px !important;
}

.k-basis-3\.5 {
  flex-basis: 14px;
}

.\!k-basis-3\.5 {
  flex-basis: 14px !important;
}

.k-basis-4 {
  flex-basis: 16px;
}

.\!k-basis-4 {
  flex-basis: 16px !important;
}

.k-basis-4\.5 {
  flex-basis: 18px;
}

.\!k-basis-4\.5 {
  flex-basis: 18px !important;
}

.k-basis-5 {
  flex-basis: 20px;
}

.\!k-basis-5 {
  flex-basis: 20px !important;
}

.k-basis-5\.5 {
  flex-basis: 22px;
}

.\!k-basis-5\.5 {
  flex-basis: 22px !important;
}

.k-basis-6 {
  flex-basis: 24px;
}

.\!k-basis-6 {
  flex-basis: 24px !important;
}

.k-basis-6\.5 {
  flex-basis: 26px;
}

.\!k-basis-6\.5 {
  flex-basis: 26px !important;
}

.k-basis-7 {
  flex-basis: 28px;
}

.\!k-basis-7 {
  flex-basis: 28px !important;
}

.k-basis-7\.5 {
  flex-basis: 30px;
}

.\!k-basis-7\.5 {
  flex-basis: 30px !important;
}

.k-basis-8 {
  flex-basis: 32px;
}

.\!k-basis-8 {
  flex-basis: 32px !important;
}

.k-basis-9 {
  flex-basis: 36px;
}

.\!k-basis-9 {
  flex-basis: 36px !important;
}

.k-basis-10 {
  flex-basis: 40px;
}

.\!k-basis-10 {
  flex-basis: 40px !important;
}

.k-basis-11 {
  flex-basis: 44px;
}

.\!k-basis-11 {
  flex-basis: 44px !important;
}

.k-basis-12 {
  flex-basis: 48px;
}

.\!k-basis-12 {
  flex-basis: 48px !important;
}

.k-basis-13 {
  flex-basis: 52px;
}

.\!k-basis-13 {
  flex-basis: 52px !important;
}

.k-basis-14 {
  flex-basis: 56px;
}

.\!k-basis-14 {
  flex-basis: 56px !important;
}

.k-basis-15 {
  flex-basis: 60px;
}

.\!k-basis-15 {
  flex-basis: 60px !important;
}

.k-basis-16 {
  flex-basis: 64px;
}

.\!k-basis-16 {
  flex-basis: 64px !important;
}

.k-basis-17 {
  flex-basis: 68px;
}

.\!k-basis-17 {
  flex-basis: 68px !important;
}

.k-basis-18 {
  flex-basis: 72px;
}

.\!k-basis-18 {
  flex-basis: 72px !important;
}

.k-basis-19 {
  flex-basis: 76px;
}

.\!k-basis-19 {
  flex-basis: 76px !important;
}

.k-basis-20 {
  flex-basis: 80px;
}

.\!k-basis-20 {
  flex-basis: 80px !important;
}

.k-basis-21 {
  flex-basis: 84px;
}

.\!k-basis-21 {
  flex-basis: 84px !important;
}

.k-basis-22 {
  flex-basis: 88px;
}

.\!k-basis-22 {
  flex-basis: 88px !important;
}

.k-basis-23 {
  flex-basis: 92px;
}

.\!k-basis-23 {
  flex-basis: 92px !important;
}

.k-basis-24 {
  flex-basis: 96px;
}

.\!k-basis-24 {
  flex-basis: 96px !important;
}

.k-basis-auto {
  flex-basis: auto;
}

.\!k-basis-auto {
  flex-basis: auto !important;
}

.k-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-basis-full {
  flex-basis: 100%;
}

.\!k-basis-full {
  flex-basis: 100% !important;
}

.k-basis-min {
  flex-basis: min-content;
}

.\!k-basis-min {
  flex-basis: min-content !important;
}

.k-basis-max {
  flex-basis: max-content;
}

.\!k-basis-max {
  flex-basis: max-content !important;
}

.k-basis-fit {
  flex-basis: fit-content;
}

.\!k-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-basis-0 {
  flex-basis: 0;
}

.\!k-flex-basis-0 {
  flex-basis: 0 !important;
}

.k-flex-basis-1px {
  flex-basis: 1px;
}

.\!k-flex-basis-1px {
  flex-basis: 1px !important;
}

.k-flex-basis-0\.5 {
  flex-basis: 2px;
}

.\!k-flex-basis-0\.5 {
  flex-basis: 2px !important;
}

.k-flex-basis-1 {
  flex-basis: 4px;
}

.\!k-flex-basis-1 {
  flex-basis: 4px !important;
}

.k-flex-basis-1\.5 {
  flex-basis: 6px;
}

.\!k-flex-basis-1\.5 {
  flex-basis: 6px !important;
}

.k-flex-basis-2 {
  flex-basis: 8px;
}

.\!k-flex-basis-2 {
  flex-basis: 8px !important;
}

.k-flex-basis-2\.5 {
  flex-basis: 10px;
}

.\!k-flex-basis-2\.5 {
  flex-basis: 10px !important;
}

.k-flex-basis-3 {
  flex-basis: 12px;
}

.\!k-flex-basis-3 {
  flex-basis: 12px !important;
}

.k-flex-basis-3\.5 {
  flex-basis: 14px;
}

.\!k-flex-basis-3\.5 {
  flex-basis: 14px !important;
}

.k-flex-basis-4 {
  flex-basis: 16px;
}

.\!k-flex-basis-4 {
  flex-basis: 16px !important;
}

.k-flex-basis-4\.5 {
  flex-basis: 18px;
}

.\!k-flex-basis-4\.5 {
  flex-basis: 18px !important;
}

.k-flex-basis-5 {
  flex-basis: 20px;
}

.\!k-flex-basis-5 {
  flex-basis: 20px !important;
}

.k-flex-basis-5\.5 {
  flex-basis: 22px;
}

.\!k-flex-basis-5\.5 {
  flex-basis: 22px !important;
}

.k-flex-basis-6 {
  flex-basis: 24px;
}

.\!k-flex-basis-6 {
  flex-basis: 24px !important;
}

.k-flex-basis-6\.5 {
  flex-basis: 26px;
}

.\!k-flex-basis-6\.5 {
  flex-basis: 26px !important;
}

.k-flex-basis-7 {
  flex-basis: 28px;
}

.\!k-flex-basis-7 {
  flex-basis: 28px !important;
}

.k-flex-basis-7\.5 {
  flex-basis: 30px;
}

.\!k-flex-basis-7\.5 {
  flex-basis: 30px !important;
}

.k-flex-basis-8 {
  flex-basis: 32px;
}

.\!k-flex-basis-8 {
  flex-basis: 32px !important;
}

.k-flex-basis-9 {
  flex-basis: 36px;
}

.\!k-flex-basis-9 {
  flex-basis: 36px !important;
}

.k-flex-basis-10 {
  flex-basis: 40px;
}

.\!k-flex-basis-10 {
  flex-basis: 40px !important;
}

.k-flex-basis-11 {
  flex-basis: 44px;
}

.\!k-flex-basis-11 {
  flex-basis: 44px !important;
}

.k-flex-basis-12 {
  flex-basis: 48px;
}

.\!k-flex-basis-12 {
  flex-basis: 48px !important;
}

.k-flex-basis-13 {
  flex-basis: 52px;
}

.\!k-flex-basis-13 {
  flex-basis: 52px !important;
}

.k-flex-basis-14 {
  flex-basis: 56px;
}

.\!k-flex-basis-14 {
  flex-basis: 56px !important;
}

.k-flex-basis-15 {
  flex-basis: 60px;
}

.\!k-flex-basis-15 {
  flex-basis: 60px !important;
}

.k-flex-basis-16 {
  flex-basis: 64px;
}

.\!k-flex-basis-16 {
  flex-basis: 64px !important;
}

.k-flex-basis-17 {
  flex-basis: 68px;
}

.\!k-flex-basis-17 {
  flex-basis: 68px !important;
}

.k-flex-basis-18 {
  flex-basis: 72px;
}

.\!k-flex-basis-18 {
  flex-basis: 72px !important;
}

.k-flex-basis-19 {
  flex-basis: 76px;
}

.\!k-flex-basis-19 {
  flex-basis: 76px !important;
}

.k-flex-basis-20 {
  flex-basis: 80px;
}

.\!k-flex-basis-20 {
  flex-basis: 80px !important;
}

.k-flex-basis-21 {
  flex-basis: 84px;
}

.\!k-flex-basis-21 {
  flex-basis: 84px !important;
}

.k-flex-basis-22 {
  flex-basis: 88px;
}

.\!k-flex-basis-22 {
  flex-basis: 88px !important;
}

.k-flex-basis-23 {
  flex-basis: 92px;
}

.\!k-flex-basis-23 {
  flex-basis: 92px !important;
}

.k-flex-basis-24 {
  flex-basis: 96px;
}

.\!k-flex-basis-24 {
  flex-basis: 96px !important;
}

.k-flex-basis-auto {
  flex-basis: auto;
}

.\!k-flex-basis-auto {
  flex-basis: auto !important;
}

.k-flex-basis-1\/2 {
  flex-basis: 50%;
}

.\!k-flex-basis-1\/2 {
  flex-basis: 50% !important;
}

.k-flex-basis-1\/3 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-2\/3 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-1\/4 {
  flex-basis: 25%;
}

.\!k-flex-basis-1\/4 {
  flex-basis: 25% !important;
}

.k-flex-basis-2\/4 {
  flex-basis: 50%;
}

.\!k-flex-basis-2\/4 {
  flex-basis: 50% !important;
}

.k-flex-basis-3\/4 {
  flex-basis: 75%;
}

.\!k-flex-basis-3\/4 {
  flex-basis: 75% !important;
}

.k-flex-basis-1\/5 {
  flex-basis: 20%;
}

.\!k-flex-basis-1\/5 {
  flex-basis: 20% !important;
}

.k-flex-basis-2\/5 {
  flex-basis: 40%;
}

.\!k-flex-basis-2\/5 {
  flex-basis: 40% !important;
}

.k-flex-basis-3\/5 {
  flex-basis: 60%;
}

.\!k-flex-basis-3\/5 {
  flex-basis: 60% !important;
}

.k-flex-basis-4\/5 {
  flex-basis: 80%;
}

.\!k-flex-basis-4\/5 {
  flex-basis: 80% !important;
}

.k-flex-basis-1\/6 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-2\/6 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-3\/6 {
  flex-basis: 50%;
}

.\!k-flex-basis-3\/6 {
  flex-basis: 50% !important;
}

.k-flex-basis-4\/6 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-5\/6 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-1\/12 {
  flex-basis: 8.333333%;
}

.\!k-flex-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.k-flex-basis-2\/12 {
  flex-basis: 16.666667%;
}

.\!k-flex-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.k-flex-basis-3\/12 {
  flex-basis: 25%;
}

.\!k-flex-basis-3\/12 {
  flex-basis: 25% !important;
}

.k-flex-basis-4\/12 {
  flex-basis: 33.333333%;
}

.\!k-flex-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.k-flex-basis-5\/12 {
  flex-basis: 41.666667%;
}

.\!k-flex-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.k-flex-basis-6\/12 {
  flex-basis: 50%;
}

.\!k-flex-basis-6\/12 {
  flex-basis: 50% !important;
}

.k-flex-basis-7\/12 {
  flex-basis: 58.333333%;
}

.\!k-flex-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.k-flex-basis-8\/12 {
  flex-basis: 66.666667%;
}

.\!k-flex-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.k-flex-basis-9\/12 {
  flex-basis: 75%;
}

.\!k-flex-basis-9\/12 {
  flex-basis: 75% !important;
}

.k-flex-basis-10\/12 {
  flex-basis: 83.333333%;
}

.\!k-flex-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.k-flex-basis-11\/12 {
  flex-basis: 91.666667%;
}

.\!k-flex-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.k-flex-basis-full {
  flex-basis: 100%;
}

.\!k-flex-basis-full {
  flex-basis: 100% !important;
}

.k-flex-basis-min {
  flex-basis: min-content;
}

.\!k-flex-basis-min {
  flex-basis: min-content !important;
}

.k-flex-basis-max {
  flex-basis: max-content;
}

.\!k-flex-basis-max {
  flex-basis: max-content !important;
}

.k-flex-basis-fit {
  flex-basis: fit-content;
}

.\!k-flex-basis-fit {
  flex-basis: fit-content !important;
}

.k-flex-row, .k-d-flex-row {
  flex-direction: row;
}

.\!k-flex-row, .\!k-d-flex-row, .\!k-d-flex-col {
  flex-direction: row !important;
}

.k-flex-row-reverse {
  flex-direction: row-reverse;
}

.\!k-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.k-flex-col, .k-flex-column, .k-d-flex-col {
  flex-direction: column;
}

.\!k-flex-col {
  flex-direction: column !important;
}

.k-flex-col-reverse, .k-flex-column-reverse {
  flex-direction: column-reverse;
}

.\!k-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.k-grow {
  flex-grow: 1;
}

.\!k-grow {
  flex-grow: 1 !important;
}

.k-grow-0 {
  flex-grow: 0;
}

.\!k-grow-0 {
  flex-grow: 0 !important;
}

.k-flex-grow {
  flex-grow: 1;
}

.\!k-flex-grow {
  flex-grow: 1 !important;
}

.k-flex-grow-0 {
  flex-grow: 0;
}

.\!k-flex-grow-0 {
  flex-grow: 0 !important;
}

.k-shrink {
  flex-shrink: 1;
}

.\!k-shrink {
  flex-shrink: 1 !important;
}

.k-shrink-0 {
  flex-shrink: 0;
}

.\!k-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-shrink {
  flex-shrink: 1;
}

.\!k-flex-shrink {
  flex-shrink: 1 !important;
}

.k-flex-shrink-0 {
  flex-shrink: 0;
}

.\!k-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.k-flex-wrap {
  flex-wrap: wrap;
}

.\!k-flex-wrap {
  flex-wrap: wrap !important;
}

.k-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.\!k-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.k-flex-nowrap {
  flex-wrap: nowrap;
}

.\!k-flex-nowrap {
  flex-wrap: nowrap !important;
}

.k-flex-1 {
  flex: 1 1 0%;
}

.\!k-flex-1 {
  flex: 1 1 0% !important;
}

.k-flex-auto {
  flex: 1 1 auto;
}

.\!k-flex-auto {
  flex: 1 1 auto !important;
}

.k-flex-initial {
  flex: 0 1 auto;
}

.\!k-flex-initial {
  flex: 0 1 auto !important;
}

.k-flex-none {
  flex: none;
}

.\!k-flex-none {
  flex: none !important;
}

.k-gap-0 {
  gap: 0;
}

.\!k-gap-0 {
  gap: 0 !important;
}

.k-gap-1px {
  gap: 1px;
}

.\!k-gap-1px {
  gap: 1px !important;
}

.k-gap-0\.5 {
  gap: 2px;
}

.\!k-gap-0\.5 {
  gap: 2px !important;
}

.k-gap-1 {
  gap: 4px;
}

.\!k-gap-1 {
  gap: 4px !important;
}

.k-gap-1\.5 {
  gap: 6px;
}

.\!k-gap-1\.5 {
  gap: 6px !important;
}

.k-gap-2 {
  gap: 8px;
}

.\!k-gap-2 {
  gap: 8px !important;
}

.k-gap-2\.5 {
  gap: 10px;
}

.\!k-gap-2\.5 {
  gap: 10px !important;
}

.k-gap-3 {
  gap: 12px;
}

.\!k-gap-3 {
  gap: 12px !important;
}

.k-gap-3\.5 {
  gap: 14px;
}

.\!k-gap-3\.5 {
  gap: 14px !important;
}

.k-gap-4 {
  gap: 16px;
}

.\!k-gap-4 {
  gap: 16px !important;
}

.k-gap-4\.5 {
  gap: 18px;
}

.\!k-gap-4\.5 {
  gap: 18px !important;
}

.k-gap-5 {
  gap: 20px;
}

.\!k-gap-5 {
  gap: 20px !important;
}

.k-gap-5\.5 {
  gap: 22px;
}

.\!k-gap-5\.5 {
  gap: 22px !important;
}

.k-gap-6 {
  gap: 24px;
}

.\!k-gap-6 {
  gap: 24px !important;
}

.k-gap-6\.5 {
  gap: 26px;
}

.\!k-gap-6\.5 {
  gap: 26px !important;
}

.k-gap-7 {
  gap: 28px;
}

.\!k-gap-7 {
  gap: 28px !important;
}

.k-gap-7\.5 {
  gap: 30px;
}

.\!k-gap-7\.5 {
  gap: 30px !important;
}

.k-gap-8 {
  gap: 32px;
}

.\!k-gap-8 {
  gap: 32px !important;
}

.k-gap-9 {
  gap: 36px;
}

.\!k-gap-9 {
  gap: 36px !important;
}

.k-gap-10 {
  gap: 40px;
}

.\!k-gap-10 {
  gap: 40px !important;
}

.k-gap-11 {
  gap: 44px;
}

.\!k-gap-11 {
  gap: 44px !important;
}

.k-gap-12 {
  gap: 48px;
}

.\!k-gap-12 {
  gap: 48px !important;
}

.k-gap-13 {
  gap: 52px;
}

.\!k-gap-13 {
  gap: 52px !important;
}

.k-gap-14 {
  gap: 56px;
}

.\!k-gap-14 {
  gap: 56px !important;
}

.k-gap-15 {
  gap: 60px;
}

.\!k-gap-15 {
  gap: 60px !important;
}

.k-gap-16 {
  gap: 64px;
}

.\!k-gap-16 {
  gap: 64px !important;
}

.k-gap-17 {
  gap: 68px;
}

.\!k-gap-17 {
  gap: 68px !important;
}

.k-gap-18 {
  gap: 72px;
}

.\!k-gap-18 {
  gap: 72px !important;
}

.k-gap-19 {
  gap: 76px;
}

.\!k-gap-19 {
  gap: 76px !important;
}

.k-gap-20 {
  gap: 80px;
}

.\!k-gap-20 {
  gap: 80px !important;
}

.k-gap-21 {
  gap: 84px;
}

.\!k-gap-21 {
  gap: 84px !important;
}

.k-gap-22 {
  gap: 88px;
}

.\!k-gap-22 {
  gap: 88px !important;
}

.k-gap-23 {
  gap: 92px;
}

.\!k-gap-23 {
  gap: 92px !important;
}

.k-gap-24 {
  gap: 96px;
}

.\!k-gap-24 {
  gap: 96px !important;
}

.k-gap-xs {
  gap: 4px;
}

.\!k-gap-xs {
  gap: 4px !important;
}

.k-gap-sm {
  gap: 8px;
}

.\!k-gap-sm {
  gap: 8px !important;
}

.k-gap-md {
  gap: 12px;
}

.\!k-gap-md {
  gap: 12px !important;
}

.k-gap-lg {
  gap: 16px;
}

.\!k-gap-lg {
  gap: 16px !important;
}

.k-gap-xl {
  gap: 24px;
}

.\!k-gap-xl {
  gap: 24px !important;
}

.k-gap-thin {
  gap: 2px;
}

.\!k-gap-thin {
  gap: 2px !important;
}

.k-gap-hair {
  gap: 1px;
}

.\!k-gap-hair {
  gap: 1px !important;
}

.k-gap-x-0 {
  column-gap: 0;
}

.\!k-gap-x-0 {
  column-gap: 0 !important;
}

.k-gap-x-1px {
  column-gap: 1px;
}

.\!k-gap-x-1px {
  column-gap: 1px !important;
}

.k-gap-x-0\.5 {
  column-gap: 2px;
}

.\!k-gap-x-0\.5 {
  column-gap: 2px !important;
}

.k-gap-x-1 {
  column-gap: 4px;
}

.\!k-gap-x-1 {
  column-gap: 4px !important;
}

.k-gap-x-1\.5 {
  column-gap: 6px;
}

.\!k-gap-x-1\.5 {
  column-gap: 6px !important;
}

.k-gap-x-2 {
  column-gap: 8px;
}

.\!k-gap-x-2 {
  column-gap: 8px !important;
}

.k-gap-x-2\.5 {
  column-gap: 10px;
}

.\!k-gap-x-2\.5 {
  column-gap: 10px !important;
}

.k-gap-x-3 {
  column-gap: 12px;
}

.\!k-gap-x-3 {
  column-gap: 12px !important;
}

.k-gap-x-3\.5 {
  column-gap: 14px;
}

.\!k-gap-x-3\.5 {
  column-gap: 14px !important;
}

.k-gap-x-4 {
  column-gap: 16px;
}

.\!k-gap-x-4 {
  column-gap: 16px !important;
}

.k-gap-x-4\.5 {
  column-gap: 18px;
}

.\!k-gap-x-4\.5 {
  column-gap: 18px !important;
}

.k-gap-x-5 {
  column-gap: 20px;
}

.\!k-gap-x-5 {
  column-gap: 20px !important;
}

.k-gap-x-5\.5 {
  column-gap: 22px;
}

.\!k-gap-x-5\.5 {
  column-gap: 22px !important;
}

.k-gap-x-6 {
  column-gap: 24px;
}

.\!k-gap-x-6 {
  column-gap: 24px !important;
}

.k-gap-x-6\.5 {
  column-gap: 26px;
}

.\!k-gap-x-6\.5 {
  column-gap: 26px !important;
}

.k-gap-x-7 {
  column-gap: 28px;
}

.\!k-gap-x-7 {
  column-gap: 28px !important;
}

.k-gap-x-7\.5 {
  column-gap: 30px;
}

.\!k-gap-x-7\.5 {
  column-gap: 30px !important;
}

.k-gap-x-8 {
  column-gap: 32px;
}

.\!k-gap-x-8 {
  column-gap: 32px !important;
}

.k-gap-x-9 {
  column-gap: 36px;
}

.\!k-gap-x-9 {
  column-gap: 36px !important;
}

.k-gap-x-10 {
  column-gap: 40px;
}

.\!k-gap-x-10 {
  column-gap: 40px !important;
}

.k-gap-x-11 {
  column-gap: 44px;
}

.\!k-gap-x-11 {
  column-gap: 44px !important;
}

.k-gap-x-12 {
  column-gap: 48px;
}

.\!k-gap-x-12 {
  column-gap: 48px !important;
}

.k-gap-x-13 {
  column-gap: 52px;
}

.\!k-gap-x-13 {
  column-gap: 52px !important;
}

.k-gap-x-14 {
  column-gap: 56px;
}

.\!k-gap-x-14 {
  column-gap: 56px !important;
}

.k-gap-x-15 {
  column-gap: 60px;
}

.\!k-gap-x-15 {
  column-gap: 60px !important;
}

.k-gap-x-16 {
  column-gap: 64px;
}

.\!k-gap-x-16 {
  column-gap: 64px !important;
}

.k-gap-x-17 {
  column-gap: 68px;
}

.\!k-gap-x-17 {
  column-gap: 68px !important;
}

.k-gap-x-18 {
  column-gap: 72px;
}

.\!k-gap-x-18 {
  column-gap: 72px !important;
}

.k-gap-x-19 {
  column-gap: 76px;
}

.\!k-gap-x-19 {
  column-gap: 76px !important;
}

.k-gap-x-20 {
  column-gap: 80px;
}

.\!k-gap-x-20 {
  column-gap: 80px !important;
}

.k-gap-x-21 {
  column-gap: 84px;
}

.\!k-gap-x-21 {
  column-gap: 84px !important;
}

.k-gap-x-22 {
  column-gap: 88px;
}

.\!k-gap-x-22 {
  column-gap: 88px !important;
}

.k-gap-x-23 {
  column-gap: 92px;
}

.\!k-gap-x-23 {
  column-gap: 92px !important;
}

.k-gap-x-24 {
  column-gap: 96px;
}

.\!k-gap-x-24 {
  column-gap: 96px !important;
}

.k-gap-x-xs {
  column-gap: 4px;
}

.\!k-gap-x-xs {
  column-gap: 4px !important;
}

.k-gap-x-sm {
  column-gap: 8px;
}

.\!k-gap-x-sm {
  column-gap: 8px !important;
}

.k-gap-x-md {
  column-gap: 12px;
}

.\!k-gap-x-md {
  column-gap: 12px !important;
}

.k-gap-x-lg {
  column-gap: 16px;
}

.\!k-gap-x-lg {
  column-gap: 16px !important;
}

.k-gap-x-xl {
  column-gap: 24px;
}

.\!k-gap-x-xl {
  column-gap: 24px !important;
}

.k-gap-x-thin {
  column-gap: 2px;
}

.\!k-gap-x-thin {
  column-gap: 2px !important;
}

.k-gap-x-hair {
  column-gap: 1px;
}

.\!k-gap-x-hair {
  column-gap: 1px !important;
}

.k-gap-y-0 {
  row-gap: 0;
}

.\!k-gap-y-0 {
  row-gap: 0 !important;
}

.k-gap-y-1px {
  row-gap: 1px;
}

.\!k-gap-y-1px {
  row-gap: 1px !important;
}

.k-gap-y-0\.5 {
  row-gap: 2px;
}

.\!k-gap-y-0\.5 {
  row-gap: 2px !important;
}

.k-gap-y-1 {
  row-gap: 4px;
}

.\!k-gap-y-1 {
  row-gap: 4px !important;
}

.k-gap-y-1\.5 {
  row-gap: 6px;
}

.\!k-gap-y-1\.5 {
  row-gap: 6px !important;
}

.k-gap-y-2 {
  row-gap: 8px;
}

.\!k-gap-y-2 {
  row-gap: 8px !important;
}

.k-gap-y-2\.5 {
  row-gap: 10px;
}

.\!k-gap-y-2\.5 {
  row-gap: 10px !important;
}

.k-gap-y-3 {
  row-gap: 12px;
}

.\!k-gap-y-3 {
  row-gap: 12px !important;
}

.k-gap-y-3\.5 {
  row-gap: 14px;
}

.\!k-gap-y-3\.5 {
  row-gap: 14px !important;
}

.k-gap-y-4 {
  row-gap: 16px;
}

.\!k-gap-y-4 {
  row-gap: 16px !important;
}

.k-gap-y-4\.5 {
  row-gap: 18px;
}

.\!k-gap-y-4\.5 {
  row-gap: 18px !important;
}

.k-gap-y-5 {
  row-gap: 20px;
}

.\!k-gap-y-5 {
  row-gap: 20px !important;
}

.k-gap-y-5\.5 {
  row-gap: 22px;
}

.\!k-gap-y-5\.5 {
  row-gap: 22px !important;
}

.k-gap-y-6 {
  row-gap: 24px;
}

.\!k-gap-y-6 {
  row-gap: 24px !important;
}

.k-gap-y-6\.5 {
  row-gap: 26px;
}

.\!k-gap-y-6\.5 {
  row-gap: 26px !important;
}

.k-gap-y-7 {
  row-gap: 28px;
}

.\!k-gap-y-7 {
  row-gap: 28px !important;
}

.k-gap-y-7\.5 {
  row-gap: 30px;
}

.\!k-gap-y-7\.5 {
  row-gap: 30px !important;
}

.k-gap-y-8 {
  row-gap: 32px;
}

.\!k-gap-y-8 {
  row-gap: 32px !important;
}

.k-gap-y-9 {
  row-gap: 36px;
}

.\!k-gap-y-9 {
  row-gap: 36px !important;
}

.k-gap-y-10 {
  row-gap: 40px;
}

.\!k-gap-y-10 {
  row-gap: 40px !important;
}

.k-gap-y-11 {
  row-gap: 44px;
}

.\!k-gap-y-11 {
  row-gap: 44px !important;
}

.k-gap-y-12 {
  row-gap: 48px;
}

.\!k-gap-y-12 {
  row-gap: 48px !important;
}

.k-gap-y-13 {
  row-gap: 52px;
}

.\!k-gap-y-13 {
  row-gap: 52px !important;
}

.k-gap-y-14 {
  row-gap: 56px;
}

.\!k-gap-y-14 {
  row-gap: 56px !important;
}

.k-gap-y-15 {
  row-gap: 60px;
}

.\!k-gap-y-15 {
  row-gap: 60px !important;
}

.k-gap-y-16 {
  row-gap: 64px;
}

.\!k-gap-y-16 {
  row-gap: 64px !important;
}

.k-gap-y-17 {
  row-gap: 68px;
}

.\!k-gap-y-17 {
  row-gap: 68px !important;
}

.k-gap-y-18 {
  row-gap: 72px;
}

.\!k-gap-y-18 {
  row-gap: 72px !important;
}

.k-gap-y-19 {
  row-gap: 76px;
}

.\!k-gap-y-19 {
  row-gap: 76px !important;
}

.k-gap-y-20 {
  row-gap: 80px;
}

.\!k-gap-y-20 {
  row-gap: 80px !important;
}

.k-gap-y-21 {
  row-gap: 84px;
}

.\!k-gap-y-21 {
  row-gap: 84px !important;
}

.k-gap-y-22 {
  row-gap: 88px;
}

.\!k-gap-y-22 {
  row-gap: 88px !important;
}

.k-gap-y-23 {
  row-gap: 92px;
}

.\!k-gap-y-23 {
  row-gap: 92px !important;
}

.k-gap-y-24 {
  row-gap: 96px;
}

.\!k-gap-y-24 {
  row-gap: 96px !important;
}

.k-gap-y-xs {
  row-gap: 4px;
}

.\!k-gap-y-xs {
  row-gap: 4px !important;
}

.k-gap-y-sm {
  row-gap: 8px;
}

.\!k-gap-y-sm {
  row-gap: 8px !important;
}

.k-gap-y-md {
  row-gap: 12px;
}

.\!k-gap-y-md {
  row-gap: 12px !important;
}

.k-gap-y-lg {
  row-gap: 16px;
}

.\!k-gap-y-lg {
  row-gap: 16px !important;
}

.k-gap-y-xl {
  row-gap: 24px;
}

.\!k-gap-y-xl {
  row-gap: 24px !important;
}

.k-gap-y-thin {
  row-gap: 2px;
}

.\!k-gap-y-thin {
  row-gap: 2px !important;
}

.k-gap-y-hair {
  row-gap: 1px;
}

.\!k-gap-y-hair {
  row-gap: 1px !important;
}

.k-grid-auto-cols-auto {
  grid-auto-columns: auto;
}

.\!k-grid-auto-cols-auto {
  grid-auto-columns: auto !important;
}

.k-grid-auto-cols-min {
  grid-auto-columns: min-content;
}

.\!k-grid-auto-cols-min {
  grid-auto-columns: min-content !important;
}

.k-grid-auto-cols-max {
  grid-auto-columns: max-content;
}

.\!k-grid-auto-cols-max {
  grid-auto-columns: max-content !important;
}

.k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.\!k-grid-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important;
}

.k-grid-flow-row {
  grid-auto-flow: row;
}

.\!k-grid-flow-row {
  grid-auto-flow: row !important;
}

.k-grid-flow-col {
  grid-auto-flow: column;
}

.\!k-grid-flow-col {
  grid-auto-flow: column !important;
}

.k-grid-flow-dense {
  grid-auto-flow: dense;
}

.\!k-grid-flow-dense {
  grid-auto-flow: dense !important;
}

.k-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.\!k-grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.k-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.\!k-grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.k-grid-flow-unset {
  grid-auto-flow: unset;
}

.\!k-grid-flow-unset {
  grid-auto-flow: unset !important;
}

.k-grid-auto-rows-auto {
  grid-auto-rows: auto;
}

.\!k-grid-auto-rows-auto {
  grid-auto-rows: auto !important;
}

.k-grid-auto-rows-min {
  grid-auto-rows: min-content;
}

.\!k-grid-auto-rows-min {
  grid-auto-rows: min-content !important;
}

.k-grid-auto-rows-max {
  grid-auto-rows: max-content;
}

.\!k-grid-auto-rows-max {
  grid-auto-rows: max-content !important;
}

.k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.\!k-grid-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important;
}

.k-col-start-1 {
  grid-column-start: 1;
}

.\!k-col-start-1 {
  grid-column-start: 1 !important;
}

.k-col-start-2 {
  grid-column-start: 2;
}

.\!k-col-start-2 {
  grid-column-start: 2 !important;
}

.k-col-start-3 {
  grid-column-start: 3;
}

.\!k-col-start-3 {
  grid-column-start: 3 !important;
}

.k-col-start-4 {
  grid-column-start: 4;
}

.\!k-col-start-4 {
  grid-column-start: 4 !important;
}

.k-col-start-5 {
  grid-column-start: 5;
}

.\!k-col-start-5 {
  grid-column-start: 5 !important;
}

.k-col-start-6 {
  grid-column-start: 6;
}

.\!k-col-start-6 {
  grid-column-start: 6 !important;
}

.k-col-start-7 {
  grid-column-start: 7;
}

.\!k-col-start-7 {
  grid-column-start: 7 !important;
}

.k-col-start-8 {
  grid-column-start: 8;
}

.\!k-col-start-8 {
  grid-column-start: 8 !important;
}

.k-col-start-9 {
  grid-column-start: 9;
}

.\!k-col-start-9 {
  grid-column-start: 9 !important;
}

.k-col-start-10 {
  grid-column-start: 10;
}

.\!k-col-start-10 {
  grid-column-start: 10 !important;
}

.k-col-start-11 {
  grid-column-start: 11;
}

.\!k-col-start-11 {
  grid-column-start: 11 !important;
}

.k-col-start-12 {
  grid-column-start: 12;
}

.\!k-col-start-12 {
  grid-column-start: 12 !important;
}

.k-col-start-13 {
  grid-column-start: 13;
}

.\!k-col-start-13 {
  grid-column-start: 13 !important;
}

.k-col-start--1 {
  grid-column-start: -1;
}

.\!k-col-start--1 {
  grid-column-start: -1 !important;
}

.k-col-start--2 {
  grid-column-start: -2;
}

.\!k-col-start--2 {
  grid-column-start: -2 !important;
}

.k-col-start--3 {
  grid-column-start: -3;
}

.\!k-col-start--3 {
  grid-column-start: -3 !important;
}

.k-col-start--4 {
  grid-column-start: -4;
}

.\!k-col-start--4 {
  grid-column-start: -4 !important;
}

.k-col-start--5 {
  grid-column-start: -5;
}

.\!k-col-start--5 {
  grid-column-start: -5 !important;
}

.k-col-start--6 {
  grid-column-start: -6;
}

.\!k-col-start--6 {
  grid-column-start: -6 !important;
}

.k-col-start--7 {
  grid-column-start: -7;
}

.\!k-col-start--7 {
  grid-column-start: -7 !important;
}

.k-col-start--8 {
  grid-column-start: -8;
}

.\!k-col-start--8 {
  grid-column-start: -8 !important;
}

.k-col-start--9 {
  grid-column-start: -9;
}

.\!k-col-start--9 {
  grid-column-start: -9 !important;
}

.k-col-start--10 {
  grid-column-start: -10;
}

.\!k-col-start--10 {
  grid-column-start: -10 !important;
}

.k-col-start--11 {
  grid-column-start: -11;
}

.\!k-col-start--11 {
  grid-column-start: -11 !important;
}

.k-col-start--12 {
  grid-column-start: -12;
}

.\!k-col-start--12 {
  grid-column-start: -12 !important;
}

.k-col-start--13 {
  grid-column-start: -13;
}

.\!k-col-start--13 {
  grid-column-start: -13 !important;
}

.k-col-start-auto {
  grid-column-start: auto;
}

.\!k-col-start-auto {
  grid-column-start: auto !important;
}

.k-col-end-1 {
  grid-column-end: 1;
}

.\!k-col-end-1 {
  grid-column-end: 1 !important;
}

.k-col-end-2 {
  grid-column-end: 2;
}

.\!k-col-end-2 {
  grid-column-end: 2 !important;
}

.k-col-end-3 {
  grid-column-end: 3;
}

.\!k-col-end-3 {
  grid-column-end: 3 !important;
}

.k-col-end-4 {
  grid-column-end: 4;
}

.\!k-col-end-4 {
  grid-column-end: 4 !important;
}

.k-col-end-5 {
  grid-column-end: 5;
}

.\!k-col-end-5 {
  grid-column-end: 5 !important;
}

.k-col-end-6 {
  grid-column-end: 6;
}

.\!k-col-end-6 {
  grid-column-end: 6 !important;
}

.k-col-end-7 {
  grid-column-end: 7;
}

.\!k-col-end-7 {
  grid-column-end: 7 !important;
}

.k-col-end-8 {
  grid-column-end: 8;
}

.\!k-col-end-8 {
  grid-column-end: 8 !important;
}

.k-col-end-9 {
  grid-column-end: 9;
}

.\!k-col-end-9 {
  grid-column-end: 9 !important;
}

.k-col-end-10 {
  grid-column-end: 10;
}

.\!k-col-end-10 {
  grid-column-end: 10 !important;
}

.k-col-end-11 {
  grid-column-end: 11;
}

.\!k-col-end-11 {
  grid-column-end: 11 !important;
}

.k-col-end-12 {
  grid-column-end: 12;
}

.\!k-col-end-12 {
  grid-column-end: 12 !important;
}

.k-col-end-13 {
  grid-column-end: 13;
}

.\!k-col-end-13 {
  grid-column-end: 13 !important;
}

.k-col-end--1 {
  grid-column-end: -1;
}

.\!k-col-end--1 {
  grid-column-end: -1 !important;
}

.k-col-end--2 {
  grid-column-end: -2;
}

.\!k-col-end--2 {
  grid-column-end: -2 !important;
}

.k-col-end--3 {
  grid-column-end: -3;
}

.\!k-col-end--3 {
  grid-column-end: -3 !important;
}

.k-col-end--4 {
  grid-column-end: -4;
}

.\!k-col-end--4 {
  grid-column-end: -4 !important;
}

.k-col-end--5 {
  grid-column-end: -5;
}

.\!k-col-end--5 {
  grid-column-end: -5 !important;
}

.k-col-end--6 {
  grid-column-end: -6;
}

.\!k-col-end--6 {
  grid-column-end: -6 !important;
}

.k-col-end--7 {
  grid-column-end: -7;
}

.\!k-col-end--7 {
  grid-column-end: -7 !important;
}

.k-col-end--8 {
  grid-column-end: -8;
}

.\!k-col-end--8 {
  grid-column-end: -8 !important;
}

.k-col-end--9 {
  grid-column-end: -9;
}

.\!k-col-end--9 {
  grid-column-end: -9 !important;
}

.k-col-end--10 {
  grid-column-end: -10;
}

.\!k-col-end--10 {
  grid-column-end: -10 !important;
}

.k-col-end--11 {
  grid-column-end: -11;
}

.\!k-col-end--11 {
  grid-column-end: -11 !important;
}

.k-col-end--12 {
  grid-column-end: -12;
}

.\!k-col-end--12 {
  grid-column-end: -12 !important;
}

.k-col-end--13 {
  grid-column-end: -13;
}

.\!k-col-end--13 {
  grid-column-end: -13 !important;
}

.k-col-end-auto {
  grid-column-end: auto;
}

.\!k-col-end-auto {
  grid-column-end: auto !important;
}

.k-col-span-1 {
  grid-column: span 1/span 1;
}

.\!k-col-span-1 {
  grid-column: span 1/span 1 !important;
}

.k-col-span-2 {
  grid-column: span 2/span 2;
}

.\!k-col-span-2 {
  grid-column: span 2/span 2 !important;
}

.k-col-span-3 {
  grid-column: span 3/span 3;
}

.\!k-col-span-3 {
  grid-column: span 3/span 3 !important;
}

.k-col-span-4 {
  grid-column: span 4/span 4;
}

.\!k-col-span-4 {
  grid-column: span 4/span 4 !important;
}

.k-col-span-5 {
  grid-column: span 5/span 5;
}

.\!k-col-span-5 {
  grid-column: span 5/span 5 !important;
}

.k-col-span-6 {
  grid-column: span 6/span 6;
}

.\!k-col-span-6 {
  grid-column: span 6/span 6 !important;
}

.k-col-span-7 {
  grid-column: span 7/span 7;
}

.\!k-col-span-7 {
  grid-column: span 7/span 7 !important;
}

.k-col-span-8 {
  grid-column: span 8/span 8;
}

.\!k-col-span-8 {
  grid-column: span 8/span 8 !important;
}

.k-col-span-9 {
  grid-column: span 9/span 9;
}

.\!k-col-span-9 {
  grid-column: span 9/span 9 !important;
}

.k-col-span-10 {
  grid-column: span 10/span 10;
}

.\!k-col-span-10 {
  grid-column: span 10/span 10 !important;
}

.k-col-span-11 {
  grid-column: span 11/span 11;
}

.\!k-col-span-11 {
  grid-column: span 11/span 11 !important;
}

.k-col-span-12 {
  grid-column: span 12/span 12;
}

.\!k-col-span-12 {
  grid-column: span 12/span 12 !important;
}

.k-col-span-full {
  grid-column: 1 / -1;
}

.\!k-col-span-full {
  grid-column: 1 / -1 !important;
}

.k-col-span-auto {
  grid-column: auto;
}

.\!k-col-span-auto {
  grid-column: auto !important;
}

.k-colspan-1 {
  grid-column: span 1/span 1;
}

.\!k-colspan-1 {
  grid-column: span 1/span 1 !important;
}

.k-colspan-2 {
  grid-column: span 2/span 2;
}

.\!k-colspan-2 {
  grid-column: span 2/span 2 !important;
}

.k-colspan-3 {
  grid-column: span 3/span 3;
}

.\!k-colspan-3 {
  grid-column: span 3/span 3 !important;
}

.k-colspan-4 {
  grid-column: span 4/span 4;
}

.\!k-colspan-4 {
  grid-column: span 4/span 4 !important;
}

.k-colspan-5 {
  grid-column: span 5/span 5;
}

.\!k-colspan-5 {
  grid-column: span 5/span 5 !important;
}

.k-colspan-6 {
  grid-column: span 6/span 6;
}

.\!k-colspan-6 {
  grid-column: span 6/span 6 !important;
}

.k-colspan-7 {
  grid-column: span 7/span 7;
}

.\!k-colspan-7 {
  grid-column: span 7/span 7 !important;
}

.k-colspan-8 {
  grid-column: span 8/span 8;
}

.\!k-colspan-8 {
  grid-column: span 8/span 8 !important;
}

.k-colspan-9 {
  grid-column: span 9/span 9;
}

.\!k-colspan-9 {
  grid-column: span 9/span 9 !important;
}

.k-colspan-10 {
  grid-column: span 10/span 10;
}

.\!k-colspan-10 {
  grid-column: span 10/span 10 !important;
}

.k-colspan-11 {
  grid-column: span 11/span 11;
}

.\!k-colspan-11 {
  grid-column: span 11/span 11 !important;
}

.k-colspan-12 {
  grid-column: span 12/span 12;
}

.\!k-colspan-12 {
  grid-column: span 12/span 12 !important;
}

.k-colspan-full, .k-colspan-all {
  grid-column: 1 / -1;
}

.\!k-colspan-full, .\!k-colspan-all {
  grid-column: 1 / -1 !important;
}

.k-colspan-auto {
  grid-column: auto;
}

.\!k-colspan-auto {
  grid-column: auto !important;
}

.k-row-start-1 {
  grid-row-start: 1;
}

.\!k-row-start-1 {
  grid-row-start: 1 !important;
}

.k-row-start-2 {
  grid-row-start: 2;
}

.\!k-row-start-2 {
  grid-row-start: 2 !important;
}

.k-row-start-3 {
  grid-row-start: 3;
}

.\!k-row-start-3 {
  grid-row-start: 3 !important;
}

.k-row-start-4 {
  grid-row-start: 4;
}

.\!k-row-start-4 {
  grid-row-start: 4 !important;
}

.k-row-start-5 {
  grid-row-start: 5;
}

.\!k-row-start-5 {
  grid-row-start: 5 !important;
}

.k-row-start-6 {
  grid-row-start: 6;
}

.\!k-row-start-6 {
  grid-row-start: 6 !important;
}

.k-row-start-7 {
  grid-row-start: 7;
}

.\!k-row-start-7 {
  grid-row-start: 7 !important;
}

.k-row-start-8 {
  grid-row-start: 8;
}

.\!k-row-start-8 {
  grid-row-start: 8 !important;
}

.k-row-start-9 {
  grid-row-start: 9;
}

.\!k-row-start-9 {
  grid-row-start: 9 !important;
}

.k-row-start-10 {
  grid-row-start: 10;
}

.\!k-row-start-10 {
  grid-row-start: 10 !important;
}

.k-row-start-11 {
  grid-row-start: 11;
}

.\!k-row-start-11 {
  grid-row-start: 11 !important;
}

.k-row-start-12 {
  grid-row-start: 12;
}

.\!k-row-start-12 {
  grid-row-start: 12 !important;
}

.k-row-start-13 {
  grid-row-start: 13;
}

.\!k-row-start-13 {
  grid-row-start: 13 !important;
}

.k-row-start--1 {
  grid-row-start: -1;
}

.\!k-row-start--1 {
  grid-row-start: -1 !important;
}

.k-row-start--2 {
  grid-row-start: -2;
}

.\!k-row-start--2 {
  grid-row-start: -2 !important;
}

.k-row-start--3 {
  grid-row-start: -3;
}

.\!k-row-start--3 {
  grid-row-start: -3 !important;
}

.k-row-start--4 {
  grid-row-start: -4;
}

.\!k-row-start--4 {
  grid-row-start: -4 !important;
}

.k-row-start--5 {
  grid-row-start: -5;
}

.\!k-row-start--5 {
  grid-row-start: -5 !important;
}

.k-row-start--6 {
  grid-row-start: -6;
}

.\!k-row-start--6 {
  grid-row-start: -6 !important;
}

.k-row-start--7 {
  grid-row-start: -7;
}

.\!k-row-start--7 {
  grid-row-start: -7 !important;
}

.k-row-start--8 {
  grid-row-start: -8;
}

.\!k-row-start--8 {
  grid-row-start: -8 !important;
}

.k-row-start--9 {
  grid-row-start: -9;
}

.\!k-row-start--9 {
  grid-row-start: -9 !important;
}

.k-row-start--10 {
  grid-row-start: -10;
}

.\!k-row-start--10 {
  grid-row-start: -10 !important;
}

.k-row-start--11 {
  grid-row-start: -11;
}

.\!k-row-start--11 {
  grid-row-start: -11 !important;
}

.k-row-start--12 {
  grid-row-start: -12;
}

.\!k-row-start--12 {
  grid-row-start: -12 !important;
}

.k-row-start--13 {
  grid-row-start: -13;
}

.\!k-row-start--13 {
  grid-row-start: -13 !important;
}

.k-row-start-auto {
  grid-row-start: auto;
}

.\!k-row-start-auto {
  grid-row-start: auto !important;
}

.k-row-end-1 {
  grid-row-end: 1;
}

.\!k-row-end-1 {
  grid-row-end: 1 !important;
}

.k-row-end-2 {
  grid-row-end: 2;
}

.\!k-row-end-2 {
  grid-row-end: 2 !important;
}

.k-row-end-3 {
  grid-row-end: 3;
}

.\!k-row-end-3 {
  grid-row-end: 3 !important;
}

.k-row-end-4 {
  grid-row-end: 4;
}

.\!k-row-end-4 {
  grid-row-end: 4 !important;
}

.k-row-end-5 {
  grid-row-end: 5;
}

.\!k-row-end-5 {
  grid-row-end: 5 !important;
}

.k-row-end-6 {
  grid-row-end: 6;
}

.\!k-row-end-6 {
  grid-row-end: 6 !important;
}

.k-row-end-7 {
  grid-row-end: 7;
}

.\!k-row-end-7 {
  grid-row-end: 7 !important;
}

.k-row-end-8 {
  grid-row-end: 8;
}

.\!k-row-end-8 {
  grid-row-end: 8 !important;
}

.k-row-end-9 {
  grid-row-end: 9;
}

.\!k-row-end-9 {
  grid-row-end: 9 !important;
}

.k-row-end-10 {
  grid-row-end: 10;
}

.\!k-row-end-10 {
  grid-row-end: 10 !important;
}

.k-row-end-11 {
  grid-row-end: 11;
}

.\!k-row-end-11 {
  grid-row-end: 11 !important;
}

.k-row-end-12 {
  grid-row-end: 12;
}

.\!k-row-end-12 {
  grid-row-end: 12 !important;
}

.k-row-end-13 {
  grid-row-end: 13;
}

.\!k-row-end-13 {
  grid-row-end: 13 !important;
}

.k-row-end--1 {
  grid-row-end: -1;
}

.\!k-row-end--1 {
  grid-row-end: -1 !important;
}

.k-row-end--2 {
  grid-row-end: -2;
}

.\!k-row-end--2 {
  grid-row-end: -2 !important;
}

.k-row-end--3 {
  grid-row-end: -3;
}

.\!k-row-end--3 {
  grid-row-end: -3 !important;
}

.k-row-end--4 {
  grid-row-end: -4;
}

.\!k-row-end--4 {
  grid-row-end: -4 !important;
}

.k-row-end--5 {
  grid-row-end: -5;
}

.\!k-row-end--5 {
  grid-row-end: -5 !important;
}

.k-row-end--6 {
  grid-row-end: -6;
}

.\!k-row-end--6 {
  grid-row-end: -6 !important;
}

.k-row-end--7 {
  grid-row-end: -7;
}

.\!k-row-end--7 {
  grid-row-end: -7 !important;
}

.k-row-end--8 {
  grid-row-end: -8;
}

.\!k-row-end--8 {
  grid-row-end: -8 !important;
}

.k-row-end--9 {
  grid-row-end: -9;
}

.\!k-row-end--9 {
  grid-row-end: -9 !important;
}

.k-row-end--10 {
  grid-row-end: -10;
}

.\!k-row-end--10 {
  grid-row-end: -10 !important;
}

.k-row-end--11 {
  grid-row-end: -11;
}

.\!k-row-end--11 {
  grid-row-end: -11 !important;
}

.k-row-end--12 {
  grid-row-end: -12;
}

.\!k-row-end--12 {
  grid-row-end: -12 !important;
}

.k-row-end--13 {
  grid-row-end: -13;
}

.\!k-row-end--13 {
  grid-row-end: -13 !important;
}

.k-row-end-auto {
  grid-row-end: auto;
}

.\!k-row-end-auto {
  grid-row-end: auto !important;
}

.k-row-span-1 {
  grid-row: span 1/span 1;
}

.\!k-row-span-1 {
  grid-row: span 1/span 1 !important;
}

.k-row-span-2 {
  grid-row: span 2/span 2;
}

.\!k-row-span-2 {
  grid-row: span 2/span 2 !important;
}

.k-row-span-3 {
  grid-row: span 3/span 3;
}

.\!k-row-span-3 {
  grid-row: span 3/span 3 !important;
}

.k-row-span-4 {
  grid-row: span 4/span 4;
}

.\!k-row-span-4 {
  grid-row: span 4/span 4 !important;
}

.k-row-span-5 {
  grid-row: span 5/span 5;
}

.\!k-row-span-5 {
  grid-row: span 5/span 5 !important;
}

.k-row-span-6 {
  grid-row: span 6/span 6;
}

.\!k-row-span-6 {
  grid-row: span 6/span 6 !important;
}

.k-row-span-7 {
  grid-row: span 7/span 7;
}

.\!k-row-span-7 {
  grid-row: span 7/span 7 !important;
}

.k-row-span-8 {
  grid-row: span 8/span 8;
}

.\!k-row-span-8 {
  grid-row: span 8/span 8 !important;
}

.k-row-span-9 {
  grid-row: span 9/span 9;
}

.\!k-row-span-9 {
  grid-row: span 9/span 9 !important;
}

.k-row-span-10 {
  grid-row: span 10/span 10;
}

.\!k-row-span-10 {
  grid-row: span 10/span 10 !important;
}

.k-row-span-11 {
  grid-row: span 11/span 11;
}

.\!k-row-span-11 {
  grid-row: span 11/span 11 !important;
}

.k-row-span-12 {
  grid-row: span 12/span 12;
}

.\!k-row-span-12 {
  grid-row: span 12/span 12 !important;
}

.k-row-span-full {
  grid-row: span 1/span -1;
}

.\!k-row-span-full {
  grid-row: span 1/span -1 !important;
}

.k-row-span-auto {
  grid-row: auto;
}

.\!k-row-span-auto {
  grid-row: auto !important;
}

.k-rowspan-1 {
  grid-row: span 1/span 1;
}

.\!k-rowspan-1 {
  grid-row: span 1/span 1 !important;
}

.k-rowspan-2 {
  grid-row: span 2/span 2;
}

.\!k-rowspan-2 {
  grid-row: span 2/span 2 !important;
}

.k-rowspan-3 {
  grid-row: span 3/span 3;
}

.\!k-rowspan-3 {
  grid-row: span 3/span 3 !important;
}

.k-rowspan-4 {
  grid-row: span 4/span 4;
}

.\!k-rowspan-4 {
  grid-row: span 4/span 4 !important;
}

.k-rowspan-5 {
  grid-row: span 5/span 5;
}

.\!k-rowspan-5 {
  grid-row: span 5/span 5 !important;
}

.k-rowspan-6 {
  grid-row: span 6/span 6;
}

.\!k-rowspan-6 {
  grid-row: span 6/span 6 !important;
}

.k-rowspan-7 {
  grid-row: span 7/span 7;
}

.\!k-rowspan-7 {
  grid-row: span 7/span 7 !important;
}

.k-rowspan-8 {
  grid-row: span 8/span 8;
}

.\!k-rowspan-8 {
  grid-row: span 8/span 8 !important;
}

.k-rowspan-9 {
  grid-row: span 9/span 9;
}

.\!k-rowspan-9 {
  grid-row: span 9/span 9 !important;
}

.k-rowspan-10 {
  grid-row: span 10/span 10;
}

.\!k-rowspan-10 {
  grid-row: span 10/span 10 !important;
}

.k-rowspan-11 {
  grid-row: span 11/span 11;
}

.\!k-rowspan-11 {
  grid-row: span 11/span 11 !important;
}

.k-rowspan-12 {
  grid-row: span 12/span 12;
}

.\!k-rowspan-12 {
  grid-row: span 12/span 12 !important;
}

.k-rowspan-full, .k-rowspan-all {
  grid-row: span 1/span -1;
}

.\!k-rowspan-full, .\!k-rowspan-all {
  grid-row: span 1/span -1 !important;
}

.k-rowspan-auto {
  grid-row: auto;
}

.\!k-rowspan-auto {
  grid-row: auto !important;
}

.k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.\!k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.\!k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.\!k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.\!k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.\!k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.\!k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.\!k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.\!k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.\!k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.\!k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.\!k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.\!k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-cols-none {
  grid-template-columns: none;
}

.\!k-grid-cols-none {
  grid-template-columns: none !important;
}

.k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.\!k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.\!k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.\!k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.\!k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.\!k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.\!k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.\!k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
}

.k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.\!k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
}

.k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.\!k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
}

.k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.\!k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
}

.k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}

.\!k-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
}

.k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}

.\!k-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
}

.k-grid-rows-none {
  grid-template-rows: none;
}

.\!k-grid-rows-none {
  grid-template-rows: none !important;
}

.k-justify-content-normal {
  justify-content: normal;
}

.\!k-justify-content-normal {
  justify-content: normal !important;
}

.k-justify-content-stretch {
  justify-content: stretch;
}

.\!k-justify-content-stretch {
  justify-content: stretch !important;
}

.k-justify-content-center {
  justify-content: center;
}

.\!k-justify-content-center {
  justify-content: center !important;
}

.k-justify-content-start {
  justify-content: start;
}

.\!k-justify-content-start {
  justify-content: start !important;
}

.k-justify-content-end {
  justify-content: end;
}

.\!k-justify-content-end {
  justify-content: end !important;
}

.k-justify-content-flex-start {
  justify-content: flex-start;
}

.\!k-justify-content-flex-start {
  justify-content: flex-start !important;
}

.k-justify-content-flex-end {
  justify-content: flex-end;
}

.\!k-justify-content-flex-end {
  justify-content: flex-end !important;
}

.k-justify-content-left {
  justify-content: left;
}

.\!k-justify-content-left {
  justify-content: left !important;
}

.k-justify-content-right {
  justify-content: right;
}

.\!k-justify-content-right {
  justify-content: right !important;
}

.k-justify-content-baseline {
  justify-content: baseline;
}

.\!k-justify-content-baseline {
  justify-content: baseline !important;
}

.k-justify-content-first-baseline {
  justify-content: first baseline;
}

.\!k-justify-content-first-baseline {
  justify-content: first baseline !important;
}

.k-justify-content-last-baseline {
  justify-content: last baseline;
}

.\!k-justify-content-last-baseline {
  justify-content: last baseline !important;
}

.k-justify-content-between {
  justify-content: space-between;
}

.\!k-justify-content-between {
  justify-content: space-between !important;
}

.k-justify-content-around {
  justify-content: space-around;
}

.\!k-justify-content-around {
  justify-content: space-around !important;
}

.k-justify-content-evenly {
  justify-content: space-evenly;
}

.\!k-justify-content-evenly {
  justify-content: space-evenly !important;
}

.k-justify-content-stretch > * {
  flex: 1 0 0%;
}

.\!.k-justify-content-stretch > * {
  flex: 1 0 0% !important;
}

.k-justify-items-normal {
  justify-items: normal;
}

.\!k-justify-items-normal {
  justify-items: normal !important;
}

.k-justify-items-stretch {
  justify-items: stretch;
}

.\!k-justify-items-stretch {
  justify-items: stretch !important;
}

.k-justify-items-center {
  justify-items: center;
}

.\!k-justify-items-center {
  justify-items: center !important;
}

.k-justify-items-start {
  justify-items: start;
}

.\!k-justify-items-start {
  justify-items: start !important;
}

.k-justify-items-end {
  justify-items: end;
}

.\!k-justify-items-end {
  justify-items: end !important;
}

.k-justify-items-flex-start {
  justify-items: flex-start;
}

.\!k-justify-items-flex-start {
  justify-items: flex-start !important;
}

.k-justify-items-flex-end {
  justify-items: flex-end;
}

.\!k-justify-items-flex-end {
  justify-items: flex-end !important;
}

.k-justify-items-self-start {
  justify-items: self-start;
}

.\!k-justify-items-self-start {
  justify-items: self-start !important;
}

.k-justify-items-self-end {
  justify-items: self-end;
}

.\!k-justify-items-self-end {
  justify-items: self-end !important;
}

.k-justify-items-left {
  justify-items: left;
}

.\!k-justify-items-left {
  justify-items: left !important;
}

.k-justify-items-right {
  justify-items: right;
}

.\!k-justify-items-right {
  justify-items: right !important;
}

.k-justify-items-baseline {
  justify-items: baseline;
}

.\!k-justify-items-baseline {
  justify-items: baseline !important;
}

.k-justify-items-first-baseline {
  justify-items: first baseline;
}

.\!k-justify-items-first-baseline {
  justify-items: first baseline !important;
}

.k-justify-items-last-baseline {
  justify-items: last baseline;
}

.\!k-justify-items-last-baseline {
  justify-items: last baseline !important;
}

.k-justify-self-auto {
  justify-self: auto;
}

.\!k-justify-self-auto {
  justify-self: auto !important;
}

.k-justify-self-normal {
  justify-self: normal;
}

.\!k-justify-self-normal {
  justify-self: normal !important;
}

.k-justify-self-stretch {
  justify-self: stretch;
}

.\!k-justify-self-stretch {
  justify-self: stretch !important;
}

.k-justify-self-center {
  justify-self: center;
}

.\!k-justify-self-center {
  justify-self: center !important;
}

.k-justify-self-start {
  justify-self: start;
}

.\!k-justify-self-start {
  justify-self: start !important;
}

.k-justify-self-end {
  justify-self: end;
}

.\!k-justify-self-end {
  justify-self: end !important;
}

.k-justify-self-flex-start {
  justify-self: flex-start;
}

.\!k-justify-self-flex-start {
  justify-self: flex-start !important;
}

.k-justify-self-flex-end {
  justify-self: flex-end;
}

.\!k-justify-self-flex-end {
  justify-self: flex-end !important;
}

.k-justify-self-self-start {
  justify-self: self-start;
}

.\!k-justify-self-self-start {
  justify-self: self-start !important;
}

.k-justify-self-self-end {
  justify-self: self-end;
}

.\!k-justify-self-self-end {
  justify-self: self-end !important;
}

.k-justify-self-baseline {
  justify-self: baseline;
}

.\!k-justify-self-baseline {
  justify-self: baseline !important;
}

.k-justify-self-first-baseline {
  justify-self: first baseline;
}

.\!k-justify-self-first-baseline {
  justify-self: first baseline !important;
}

.k-justify-self-last-baseline {
  justify-self: last baseline;
}

.\!k-justify-self-last-baseline {
  justify-self: last baseline !important;
}

.k-order-1 {
  order: 1;
}

.\!k-order-1 {
  order: 1 !important;
}

.k-order-2 {
  order: 2;
}

.\!k-order-2 {
  order: 2 !important;
}

.k-order-3 {
  order: 3;
}

.\!k-order-3 {
  order: 3 !important;
}

.k-order-4 {
  order: 4;
}

.\!k-order-4 {
  order: 4 !important;
}

.k-order-5 {
  order: 5;
}

.\!k-order-5 {
  order: 5 !important;
}

.k-order-6 {
  order: 6;
}

.\!k-order-6 {
  order: 6 !important;
}

.k-order-7 {
  order: 7;
}

.\!k-order-7 {
  order: 7 !important;
}

.k-order-8 {
  order: 8;
}

.\!k-order-8 {
  order: 8 !important;
}

.k-order-9 {
  order: 9;
}

.\!k-order-9 {
  order: 9 !important;
}

.k-order-10 {
  order: 10;
}

.\!k-order-10 {
  order: 10 !important;
}

.k-order-11 {
  order: 11;
}

.\!k-order-11 {
  order: 11 !important;
}

.k-order-12 {
  order: 12;
}

.\!k-order-12 {
  order: 12 !important;
}

.k-order-first {
  order: -9999;
}

.\!k-order-first {
  order: -9999 !important;
}

.k-order-last {
  order: 9999;
}

.\!k-order-last {
  order: 9999 !important;
}

.k-order-none {
  order: 0;
}

.\!k-order-none {
  order: 0 !important;
}

.k-place-content-normal {
  place-content: normal;
}

.\!k-place-content-normal {
  place-content: normal !important;
}

.k-place-content-stretch {
  place-content: stretch;
}

.\!k-place-content-stretch {
  place-content: stretch !important;
}

.k-place-content-center {
  place-content: center;
}

.\!k-place-content-center {
  place-content: center !important;
}

.k-place-content-start {
  place-content: start;
}

.\!k-place-content-start {
  place-content: start !important;
}

.k-place-content-end {
  place-content: end;
}

.\!k-place-content-end {
  place-content: end !important;
}

.k-place-content-flex-start {
  place-content: flex-start;
}

.\!k-place-content-flex-start {
  place-content: flex-start !important;
}

.k-place-content-flex-end {
  place-content: flex-end;
}

.\!k-place-content-flex-end {
  place-content: flex-end !important;
}

.k-place-content-baseline {
  place-content: baseline;
}

.\!k-place-content-baseline {
  place-content: baseline !important;
}

.k-place-content-between {
  place-content: space-between;
}

.\!k-place-content-between {
  place-content: space-between !important;
}

.k-place-content-around {
  place-content: space-around;
}

.\!k-place-content-around {
  place-content: space-around !important;
}

.k-place-content-evenly {
  place-content: space-evenly;
}

.\!k-place-content-evenly {
  place-content: space-evenly !important;
}

.k-place-items-normal {
  place-items: normal;
}

.\!k-place-items-normal {
  place-items: normal !important;
}

.k-place-items-stretch {
  place-items: stretch;
}

.\!k-place-items-stretch {
  place-items: stretch !important;
}

.k-place-items-center {
  place-items: center;
}

.\!k-place-items-center {
  place-items: center !important;
}

.k-place-items-start {
  place-items: start;
}

.\!k-place-items-start {
  place-items: start !important;
}

.k-place-items-end {
  place-items: end;
}

.\!k-place-items-end {
  place-items: end !important;
}

.k-place-items-flex-start {
  place-items: flex-start;
}

.\!k-place-items-flex-start {
  place-items: flex-start !important;
}

.k-place-items-flex-end {
  place-items: flex-end;
}

.\!k-place-items-flex-end {
  place-items: flex-end !important;
}

.k-place-items-self-start {
  place-items: self-start;
}

.\!k-place-items-self-start {
  place-items: self-start !important;
}

.k-place-items-self-end {
  place-items: self-end;
}

.\!k-place-items-self-end {
  place-items: self-end !important;
}

.k-place-items-baseline {
  place-items: baseline;
}

.\!k-place-items-baseline {
  place-items: baseline !important;
}

.k-place-self-auto {
  place-self: auto;
}

.\!k-place-self-auto {
  place-self: auto !important;
}

.k-place-self-normal {
  place-self: normal;
}

.\!k-place-self-normal {
  place-self: normal !important;
}

.k-place-self-stretch {
  place-self: stretch;
}

.\!k-place-self-stretch {
  place-self: stretch !important;
}

.k-place-self-center {
  place-self: center;
}

.\!k-place-self-center {
  place-self: center !important;
}

.k-place-self-start {
  place-self: start;
}

.\!k-place-self-start {
  place-self: start !important;
}

.k-place-self-end {
  place-self: end;
}

.\!k-place-self-end {
  place-self: end !important;
}

.k-place-self-flex-start {
  place-self: flex-start;
}

.\!k-place-self-flex-start {
  place-self: flex-start !important;
}

.k-place-self-flex-end {
  place-self: flex-end;
}

.\!k-place-self-flex-end {
  place-self: flex-end !important;
}

.k-place-self-self-start {
  place-self: self-start;
}

.\!k-place-self-self-start {
  place-self: self-start !important;
}

.k-place-self-self-end {
  place-self: self-end;
}

.\!k-place-self-self-end {
  place-self: self-end !important;
}

.k-place-self-baseline {
  place-self: baseline;
}

.\!k-place-self-baseline {
  place-self: baseline !important;
}

.k-m-0 {
  margin: 0;
}

.\!k-m-0 {
  margin: 0 !important;
}

.k-m-1px {
  margin: 1px;
}

.\!k-m-1px {
  margin: 1px !important;
}

.k-m-0\.5 {
  margin: 2px;
}

.\!k-m-0\.5 {
  margin: 2px !important;
}

.k-m-1 {
  margin: 4px;
}

.\!k-m-1 {
  margin: 4px !important;
}

.k-m-1\.5 {
  margin: 6px;
}

.\!k-m-1\.5 {
  margin: 6px !important;
}

.k-m-2 {
  margin: 8px;
}

.\!k-m-2 {
  margin: 8px !important;
}

.k-m-2\.5 {
  margin: 10px;
}

.\!k-m-2\.5 {
  margin: 10px !important;
}

.k-m-3 {
  margin: 12px;
}

.\!k-m-3 {
  margin: 12px !important;
}

.k-m-3\.5 {
  margin: 14px;
}

.\!k-m-3\.5 {
  margin: 14px !important;
}

.k-m-4 {
  margin: 16px;
}

.\!k-m-4 {
  margin: 16px !important;
}

.k-m-4\.5 {
  margin: 18px;
}

.\!k-m-4\.5 {
  margin: 18px !important;
}

.k-m-5 {
  margin: 20px;
}

.\!k-m-5 {
  margin: 20px !important;
}

.k-m-5\.5 {
  margin: 22px;
}

.\!k-m-5\.5 {
  margin: 22px !important;
}

.k-m-6 {
  margin: 24px;
}

.\!k-m-6 {
  margin: 24px !important;
}

.k-m-6\.5 {
  margin: 26px;
}

.\!k-m-6\.5 {
  margin: 26px !important;
}

.k-m-7 {
  margin: 28px;
}

.\!k-m-7 {
  margin: 28px !important;
}

.k-m-7\.5 {
  margin: 30px;
}

.\!k-m-7\.5 {
  margin: 30px !important;
}

.k-m-8 {
  margin: 32px;
}

.\!k-m-8 {
  margin: 32px !important;
}

.k-m-9 {
  margin: 36px;
}

.\!k-m-9 {
  margin: 36px !important;
}

.k-m-10 {
  margin: 40px;
}

.\!k-m-10 {
  margin: 40px !important;
}

.k-m-11 {
  margin: 44px;
}

.\!k-m-11 {
  margin: 44px !important;
}

.k-m-12 {
  margin: 48px;
}

.\!k-m-12 {
  margin: 48px !important;
}

.k-m-13 {
  margin: 52px;
}

.\!k-m-13 {
  margin: 52px !important;
}

.k-m-14 {
  margin: 56px;
}

.\!k-m-14 {
  margin: 56px !important;
}

.k-m-15 {
  margin: 60px;
}

.\!k-m-15 {
  margin: 60px !important;
}

.k-m-16 {
  margin: 64px;
}

.\!k-m-16 {
  margin: 64px !important;
}

.k-m-17 {
  margin: 68px;
}

.\!k-m-17 {
  margin: 68px !important;
}

.k-m-18 {
  margin: 72px;
}

.\!k-m-18 {
  margin: 72px !important;
}

.k-m-19 {
  margin: 76px;
}

.\!k-m-19 {
  margin: 76px !important;
}

.k-m-20 {
  margin: 80px;
}

.\!k-m-20 {
  margin: 80px !important;
}

.k-m-21 {
  margin: 84px;
}

.\!k-m-21 {
  margin: 84px !important;
}

.k-m-22 {
  margin: 88px;
}

.\!k-m-22 {
  margin: 88px !important;
}

.k-m-23 {
  margin: 92px;
}

.\!k-m-23 {
  margin: 92px !important;
}

.k-m-24 {
  margin: 96px;
}

.\!k-m-24 {
  margin: 96px !important;
}

.k-m--1px {
  margin: -1px;
}

.\!k-m--1px {
  margin: -1px !important;
}

.k-m--0\.5 {
  margin: -2px;
}

.\!k-m--0\.5 {
  margin: -2px !important;
}

.k-m--1 {
  margin: -4px;
}

.\!k-m--1 {
  margin: -4px !important;
}

.k-m--1\.5 {
  margin: -6px;
}

.\!k-m--1\.5 {
  margin: -6px !important;
}

.k-m--2 {
  margin: -8px;
}

.\!k-m--2 {
  margin: -8px !important;
}

.k-m--2\.5 {
  margin: -10px;
}

.\!k-m--2\.5 {
  margin: -10px !important;
}

.k-m--3 {
  margin: -12px;
}

.\!k-m--3 {
  margin: -12px !important;
}

.k-m--3\.5 {
  margin: -14px;
}

.\!k-m--3\.5 {
  margin: -14px !important;
}

.k-m--4 {
  margin: -16px;
}

.\!k-m--4 {
  margin: -16px !important;
}

.k-m--4\.5 {
  margin: -18px;
}

.\!k-m--4\.5 {
  margin: -18px !important;
}

.k-m--5 {
  margin: -20px;
}

.\!k-m--5 {
  margin: -20px !important;
}

.k-m--5\.5 {
  margin: -22px;
}

.\!k-m--5\.5 {
  margin: -22px !important;
}

.k-m--6 {
  margin: -24px;
}

.\!k-m--6 {
  margin: -24px !important;
}

.k-m--6\.5 {
  margin: -26px;
}

.\!k-m--6\.5 {
  margin: -26px !important;
}

.k-m--7 {
  margin: -28px;
}

.\!k-m--7 {
  margin: -28px !important;
}

.k-m--7\.5 {
  margin: -30px;
}

.\!k-m--7\.5 {
  margin: -30px !important;
}

.k-m--8 {
  margin: -32px;
}

.\!k-m--8 {
  margin: -32px !important;
}

.k-m--9 {
  margin: -36px;
}

.\!k-m--9 {
  margin: -36px !important;
}

.k-m--10 {
  margin: -40px;
}

.\!k-m--10 {
  margin: -40px !important;
}

.k-m--11 {
  margin: -44px;
}

.\!k-m--11 {
  margin: -44px !important;
}

.k-m--12 {
  margin: -48px;
}

.\!k-m--12 {
  margin: -48px !important;
}

.k-m--13 {
  margin: -52px;
}

.\!k-m--13 {
  margin: -52px !important;
}

.k-m--14 {
  margin: -56px;
}

.\!k-m--14 {
  margin: -56px !important;
}

.k-m--15 {
  margin: -60px;
}

.\!k-m--15 {
  margin: -60px !important;
}

.k-m--16 {
  margin: -64px;
}

.\!k-m--16 {
  margin: -64px !important;
}

.k-m--17 {
  margin: -68px;
}

.\!k-m--17 {
  margin: -68px !important;
}

.k-m--18 {
  margin: -72px;
}

.\!k-m--18 {
  margin: -72px !important;
}

.k-m--19 {
  margin: -76px;
}

.\!k-m--19 {
  margin: -76px !important;
}

.k-m--20 {
  margin: -80px;
}

.\!k-m--20 {
  margin: -80px !important;
}

.k-m--21 {
  margin: -84px;
}

.\!k-m--21 {
  margin: -84px !important;
}

.k-m--22 {
  margin: -88px;
}

.\!k-m--22 {
  margin: -88px !important;
}

.k-m--23 {
  margin: -92px;
}

.\!k-m--23 {
  margin: -92px !important;
}

.k-m--24 {
  margin: -96px;
}

.\!k-m--24 {
  margin: -96px !important;
}

.k-m-xs {
  margin: 4px;
}

.\!k-m-xs {
  margin: 4px !important;
}

.k-m-sm {
  margin: 8px;
}

.\!k-m-sm {
  margin: 8px !important;
}

.k-m-md {
  margin: 12px;
}

.\!k-m-md {
  margin: 12px !important;
}

.k-m-lg {
  margin: 16px;
}

.\!k-m-lg {
  margin: 16px !important;
}

.k-m-xl {
  margin: 24px;
}

.\!k-m-xl {
  margin: 24px !important;
}

.k-m-thin {
  margin: 2px;
}

.\!k-m-thin {
  margin: 2px !important;
}

.k-m-hair {
  margin: 1px;
}

.\!k-m-hair {
  margin: 1px !important;
}

.k-m-auto {
  margin: auto;
}

.\!k-m-auto {
  margin: auto !important;
}

.k-mt-0 {
  margin-top: 0;
}

.\!k-mt-0 {
  margin-top: 0 !important;
}

.k-mt-1px {
  margin-top: 1px;
}

.\!k-mt-1px {
  margin-top: 1px !important;
}

.k-mt-0\.5 {
  margin-top: 2px;
}

.\!k-mt-0\.5 {
  margin-top: 2px !important;
}

.k-mt-1 {
  margin-top: 4px;
}

.\!k-mt-1 {
  margin-top: 4px !important;
}

.k-mt-1\.5 {
  margin-top: 6px;
}

.\!k-mt-1\.5 {
  margin-top: 6px !important;
}

.k-mt-2 {
  margin-top: 8px;
}

.\!k-mt-2 {
  margin-top: 8px !important;
}

.k-mt-2\.5 {
  margin-top: 10px;
}

.\!k-mt-2\.5 {
  margin-top: 10px !important;
}

.k-mt-3 {
  margin-top: 12px;
}

.\!k-mt-3 {
  margin-top: 12px !important;
}

.k-mt-3\.5 {
  margin-top: 14px;
}

.\!k-mt-3\.5 {
  margin-top: 14px !important;
}

.k-mt-4 {
  margin-top: 16px;
}

.\!k-mt-4 {
  margin-top: 16px !important;
}

.k-mt-4\.5 {
  margin-top: 18px;
}

.\!k-mt-4\.5 {
  margin-top: 18px !important;
}

.k-mt-5 {
  margin-top: 20px;
}

.\!k-mt-5 {
  margin-top: 20px !important;
}

.k-mt-5\.5 {
  margin-top: 22px;
}

.\!k-mt-5\.5 {
  margin-top: 22px !important;
}

.k-mt-6 {
  margin-top: 24px;
}

.\!k-mt-6 {
  margin-top: 24px !important;
}

.k-mt-6\.5 {
  margin-top: 26px;
}

.\!k-mt-6\.5 {
  margin-top: 26px !important;
}

.k-mt-7 {
  margin-top: 28px;
}

.\!k-mt-7 {
  margin-top: 28px !important;
}

.k-mt-7\.5 {
  margin-top: 30px;
}

.\!k-mt-7\.5 {
  margin-top: 30px !important;
}

.k-mt-8 {
  margin-top: 32px;
}

.\!k-mt-8 {
  margin-top: 32px !important;
}

.k-mt-9 {
  margin-top: 36px;
}

.\!k-mt-9 {
  margin-top: 36px !important;
}

.k-mt-10 {
  margin-top: 40px;
}

.\!k-mt-10 {
  margin-top: 40px !important;
}

.k-mt-11 {
  margin-top: 44px;
}

.\!k-mt-11 {
  margin-top: 44px !important;
}

.k-mt-12 {
  margin-top: 48px;
}

.\!k-mt-12 {
  margin-top: 48px !important;
}

.k-mt-13 {
  margin-top: 52px;
}

.\!k-mt-13 {
  margin-top: 52px !important;
}

.k-mt-14 {
  margin-top: 56px;
}

.\!k-mt-14 {
  margin-top: 56px !important;
}

.k-mt-15 {
  margin-top: 60px;
}

.\!k-mt-15 {
  margin-top: 60px !important;
}

.k-mt-16 {
  margin-top: 64px;
}

.\!k-mt-16 {
  margin-top: 64px !important;
}

.k-mt-17 {
  margin-top: 68px;
}

.\!k-mt-17 {
  margin-top: 68px !important;
}

.k-mt-18 {
  margin-top: 72px;
}

.\!k-mt-18 {
  margin-top: 72px !important;
}

.k-mt-19 {
  margin-top: 76px;
}

.\!k-mt-19 {
  margin-top: 76px !important;
}

.k-mt-20 {
  margin-top: 80px;
}

.\!k-mt-20 {
  margin-top: 80px !important;
}

.k-mt-21 {
  margin-top: 84px;
}

.\!k-mt-21 {
  margin-top: 84px !important;
}

.k-mt-22 {
  margin-top: 88px;
}

.\!k-mt-22 {
  margin-top: 88px !important;
}

.k-mt-23 {
  margin-top: 92px;
}

.\!k-mt-23 {
  margin-top: 92px !important;
}

.k-mt-24 {
  margin-top: 96px;
}

.\!k-mt-24 {
  margin-top: 96px !important;
}

.k-mt--1px {
  margin-top: -1px;
}

.\!k-mt--1px {
  margin-top: -1px !important;
}

.k-mt--0\.5 {
  margin-top: -2px;
}

.\!k-mt--0\.5 {
  margin-top: -2px !important;
}

.k-mt--1 {
  margin-top: -4px;
}

.\!k-mt--1 {
  margin-top: -4px !important;
}

.k-mt--1\.5 {
  margin-top: -6px;
}

.\!k-mt--1\.5 {
  margin-top: -6px !important;
}

.k-mt--2 {
  margin-top: -8px;
}

.\!k-mt--2 {
  margin-top: -8px !important;
}

.k-mt--2\.5 {
  margin-top: -10px;
}

.\!k-mt--2\.5 {
  margin-top: -10px !important;
}

.k-mt--3 {
  margin-top: -12px;
}

.\!k-mt--3 {
  margin-top: -12px !important;
}

.k-mt--3\.5 {
  margin-top: -14px;
}

.\!k-mt--3\.5 {
  margin-top: -14px !important;
}

.k-mt--4 {
  margin-top: -16px;
}

.\!k-mt--4 {
  margin-top: -16px !important;
}

.k-mt--4\.5 {
  margin-top: -18px;
}

.\!k-mt--4\.5 {
  margin-top: -18px !important;
}

.k-mt--5 {
  margin-top: -20px;
}

.\!k-mt--5 {
  margin-top: -20px !important;
}

.k-mt--5\.5 {
  margin-top: -22px;
}

.\!k-mt--5\.5 {
  margin-top: -22px !important;
}

.k-mt--6 {
  margin-top: -24px;
}

.\!k-mt--6 {
  margin-top: -24px !important;
}

.k-mt--6\.5 {
  margin-top: -26px;
}

.\!k-mt--6\.5 {
  margin-top: -26px !important;
}

.k-mt--7 {
  margin-top: -28px;
}

.\!k-mt--7 {
  margin-top: -28px !important;
}

.k-mt--7\.5 {
  margin-top: -30px;
}

.\!k-mt--7\.5 {
  margin-top: -30px !important;
}

.k-mt--8 {
  margin-top: -32px;
}

.\!k-mt--8 {
  margin-top: -32px !important;
}

.k-mt--9 {
  margin-top: -36px;
}

.\!k-mt--9 {
  margin-top: -36px !important;
}

.k-mt--10 {
  margin-top: -40px;
}

.\!k-mt--10 {
  margin-top: -40px !important;
}

.k-mt--11 {
  margin-top: -44px;
}

.\!k-mt--11 {
  margin-top: -44px !important;
}

.k-mt--12 {
  margin-top: -48px;
}

.\!k-mt--12 {
  margin-top: -48px !important;
}

.k-mt--13 {
  margin-top: -52px;
}

.\!k-mt--13 {
  margin-top: -52px !important;
}

.k-mt--14 {
  margin-top: -56px;
}

.\!k-mt--14 {
  margin-top: -56px !important;
}

.k-mt--15 {
  margin-top: -60px;
}

.\!k-mt--15 {
  margin-top: -60px !important;
}

.k-mt--16 {
  margin-top: -64px;
}

.\!k-mt--16 {
  margin-top: -64px !important;
}

.k-mt--17 {
  margin-top: -68px;
}

.\!k-mt--17 {
  margin-top: -68px !important;
}

.k-mt--18 {
  margin-top: -72px;
}

.\!k-mt--18 {
  margin-top: -72px !important;
}

.k-mt--19 {
  margin-top: -76px;
}

.\!k-mt--19 {
  margin-top: -76px !important;
}

.k-mt--20 {
  margin-top: -80px;
}

.\!k-mt--20 {
  margin-top: -80px !important;
}

.k-mt--21 {
  margin-top: -84px;
}

.\!k-mt--21 {
  margin-top: -84px !important;
}

.k-mt--22 {
  margin-top: -88px;
}

.\!k-mt--22 {
  margin-top: -88px !important;
}

.k-mt--23 {
  margin-top: -92px;
}

.\!k-mt--23 {
  margin-top: -92px !important;
}

.k-mt--24 {
  margin-top: -96px;
}

.\!k-mt--24 {
  margin-top: -96px !important;
}

.k-mt-xs {
  margin-top: 4px;
}

.\!k-mt-xs {
  margin-top: 4px !important;
}

.k-mt-sm {
  margin-top: 8px;
}

.\!k-mt-sm {
  margin-top: 8px !important;
}

.k-mt-md {
  margin-top: 12px;
}

.\!k-mt-md {
  margin-top: 12px !important;
}

.k-mt-lg {
  margin-top: 16px;
}

.\!k-mt-lg {
  margin-top: 16px !important;
}

.k-mt-xl {
  margin-top: 24px;
}

.\!k-mt-xl {
  margin-top: 24px !important;
}

.k-mt-thin {
  margin-top: 2px;
}

.\!k-mt-thin {
  margin-top: 2px !important;
}

.k-mt-hair {
  margin-top: 1px;
}

.\!k-mt-hair {
  margin-top: 1px !important;
}

.k-mt-auto {
  margin-top: auto;
}

.\!k-mt-auto {
  margin-top: auto !important;
}

.k-mr-0 {
  margin-right: 0;
}

.\!k-mr-0 {
  margin-right: 0 !important;
}

.k-mr-1px {
  margin-right: 1px;
}

.\!k-mr-1px {
  margin-right: 1px !important;
}

.k-mr-0\.5 {
  margin-right: 2px;
}

.\!k-mr-0\.5 {
  margin-right: 2px !important;
}

.k-mr-1 {
  margin-right: 4px;
}

.\!k-mr-1 {
  margin-right: 4px !important;
}

.k-mr-1\.5 {
  margin-right: 6px;
}

.\!k-mr-1\.5 {
  margin-right: 6px !important;
}

.k-mr-2 {
  margin-right: 8px;
}

.\!k-mr-2 {
  margin-right: 8px !important;
}

.k-mr-2\.5 {
  margin-right: 10px;
}

.\!k-mr-2\.5 {
  margin-right: 10px !important;
}

.k-mr-3 {
  margin-right: 12px;
}

.\!k-mr-3 {
  margin-right: 12px !important;
}

.k-mr-3\.5 {
  margin-right: 14px;
}

.\!k-mr-3\.5 {
  margin-right: 14px !important;
}

.k-mr-4 {
  margin-right: 16px;
}

.\!k-mr-4 {
  margin-right: 16px !important;
}

.k-mr-4\.5 {
  margin-right: 18px;
}

.\!k-mr-4\.5 {
  margin-right: 18px !important;
}

.k-mr-5 {
  margin-right: 20px;
}

.\!k-mr-5 {
  margin-right: 20px !important;
}

.k-mr-5\.5 {
  margin-right: 22px;
}

.\!k-mr-5\.5 {
  margin-right: 22px !important;
}

.k-mr-6 {
  margin-right: 24px;
}

.\!k-mr-6 {
  margin-right: 24px !important;
}

.k-mr-6\.5 {
  margin-right: 26px;
}

.\!k-mr-6\.5 {
  margin-right: 26px !important;
}

.k-mr-7 {
  margin-right: 28px;
}

.\!k-mr-7 {
  margin-right: 28px !important;
}

.k-mr-7\.5 {
  margin-right: 30px;
}

.\!k-mr-7\.5 {
  margin-right: 30px !important;
}

.k-mr-8 {
  margin-right: 32px;
}

.\!k-mr-8 {
  margin-right: 32px !important;
}

.k-mr-9 {
  margin-right: 36px;
}

.\!k-mr-9 {
  margin-right: 36px !important;
}

.k-mr-10 {
  margin-right: 40px;
}

.\!k-mr-10 {
  margin-right: 40px !important;
}

.k-mr-11 {
  margin-right: 44px;
}

.\!k-mr-11 {
  margin-right: 44px !important;
}

.k-mr-12 {
  margin-right: 48px;
}

.\!k-mr-12 {
  margin-right: 48px !important;
}

.k-mr-13 {
  margin-right: 52px;
}

.\!k-mr-13 {
  margin-right: 52px !important;
}

.k-mr-14 {
  margin-right: 56px;
}

.\!k-mr-14 {
  margin-right: 56px !important;
}

.k-mr-15 {
  margin-right: 60px;
}

.\!k-mr-15 {
  margin-right: 60px !important;
}

.k-mr-16 {
  margin-right: 64px;
}

.\!k-mr-16 {
  margin-right: 64px !important;
}

.k-mr-17 {
  margin-right: 68px;
}

.\!k-mr-17 {
  margin-right: 68px !important;
}

.k-mr-18 {
  margin-right: 72px;
}

.\!k-mr-18 {
  margin-right: 72px !important;
}

.k-mr-19 {
  margin-right: 76px;
}

.\!k-mr-19 {
  margin-right: 76px !important;
}

.k-mr-20 {
  margin-right: 80px;
}

.\!k-mr-20 {
  margin-right: 80px !important;
}

.k-mr-21 {
  margin-right: 84px;
}

.\!k-mr-21 {
  margin-right: 84px !important;
}

.k-mr-22 {
  margin-right: 88px;
}

.\!k-mr-22 {
  margin-right: 88px !important;
}

.k-mr-23 {
  margin-right: 92px;
}

.\!k-mr-23 {
  margin-right: 92px !important;
}

.k-mr-24 {
  margin-right: 96px;
}

.\!k-mr-24 {
  margin-right: 96px !important;
}

.k-mr--1px {
  margin-right: -1px;
}

.\!k-mr--1px {
  margin-right: -1px !important;
}

.k-mr--0\.5 {
  margin-right: -2px;
}

.\!k-mr--0\.5 {
  margin-right: -2px !important;
}

.k-mr--1 {
  margin-right: -4px;
}

.\!k-mr--1 {
  margin-right: -4px !important;
}

.k-mr--1\.5 {
  margin-right: -6px;
}

.\!k-mr--1\.5 {
  margin-right: -6px !important;
}

.k-mr--2 {
  margin-right: -8px;
}

.\!k-mr--2 {
  margin-right: -8px !important;
}

.k-mr--2\.5 {
  margin-right: -10px;
}

.\!k-mr--2\.5 {
  margin-right: -10px !important;
}

.k-mr--3 {
  margin-right: -12px;
}

.\!k-mr--3 {
  margin-right: -12px !important;
}

.k-mr--3\.5 {
  margin-right: -14px;
}

.\!k-mr--3\.5 {
  margin-right: -14px !important;
}

.k-mr--4 {
  margin-right: -16px;
}

.\!k-mr--4 {
  margin-right: -16px !important;
}

.k-mr--4\.5 {
  margin-right: -18px;
}

.\!k-mr--4\.5 {
  margin-right: -18px !important;
}

.k-mr--5 {
  margin-right: -20px;
}

.\!k-mr--5 {
  margin-right: -20px !important;
}

.k-mr--5\.5 {
  margin-right: -22px;
}

.\!k-mr--5\.5 {
  margin-right: -22px !important;
}

.k-mr--6 {
  margin-right: -24px;
}

.\!k-mr--6 {
  margin-right: -24px !important;
}

.k-mr--6\.5 {
  margin-right: -26px;
}

.\!k-mr--6\.5 {
  margin-right: -26px !important;
}

.k-mr--7 {
  margin-right: -28px;
}

.\!k-mr--7 {
  margin-right: -28px !important;
}

.k-mr--7\.5 {
  margin-right: -30px;
}

.\!k-mr--7\.5 {
  margin-right: -30px !important;
}

.k-mr--8 {
  margin-right: -32px;
}

.\!k-mr--8 {
  margin-right: -32px !important;
}

.k-mr--9 {
  margin-right: -36px;
}

.\!k-mr--9 {
  margin-right: -36px !important;
}

.k-mr--10 {
  margin-right: -40px;
}

.\!k-mr--10 {
  margin-right: -40px !important;
}

.k-mr--11 {
  margin-right: -44px;
}

.\!k-mr--11 {
  margin-right: -44px !important;
}

.k-mr--12 {
  margin-right: -48px;
}

.\!k-mr--12 {
  margin-right: -48px !important;
}

.k-mr--13 {
  margin-right: -52px;
}

.\!k-mr--13 {
  margin-right: -52px !important;
}

.k-mr--14 {
  margin-right: -56px;
}

.\!k-mr--14 {
  margin-right: -56px !important;
}

.k-mr--15 {
  margin-right: -60px;
}

.\!k-mr--15 {
  margin-right: -60px !important;
}

.k-mr--16 {
  margin-right: -64px;
}

.\!k-mr--16 {
  margin-right: -64px !important;
}

.k-mr--17 {
  margin-right: -68px;
}

.\!k-mr--17 {
  margin-right: -68px !important;
}

.k-mr--18 {
  margin-right: -72px;
}

.\!k-mr--18 {
  margin-right: -72px !important;
}

.k-mr--19 {
  margin-right: -76px;
}

.\!k-mr--19 {
  margin-right: -76px !important;
}

.k-mr--20 {
  margin-right: -80px;
}

.\!k-mr--20 {
  margin-right: -80px !important;
}

.k-mr--21 {
  margin-right: -84px;
}

.\!k-mr--21 {
  margin-right: -84px !important;
}

.k-mr--22 {
  margin-right: -88px;
}

.\!k-mr--22 {
  margin-right: -88px !important;
}

.k-mr--23 {
  margin-right: -92px;
}

.\!k-mr--23 {
  margin-right: -92px !important;
}

.k-mr--24 {
  margin-right: -96px;
}

.\!k-mr--24 {
  margin-right: -96px !important;
}

.k-mr-xs {
  margin-right: 4px;
}

.\!k-mr-xs {
  margin-right: 4px !important;
}

.k-mr-sm {
  margin-right: 8px;
}

.\!k-mr-sm {
  margin-right: 8px !important;
}

.k-mr-md {
  margin-right: 12px;
}

.\!k-mr-md {
  margin-right: 12px !important;
}

.k-mr-lg {
  margin-right: 16px;
}

.\!k-mr-lg {
  margin-right: 16px !important;
}

.k-mr-xl {
  margin-right: 24px;
}

.\!k-mr-xl {
  margin-right: 24px !important;
}

.k-mr-thin {
  margin-right: 2px;
}

.\!k-mr-thin {
  margin-right: 2px !important;
}

.k-mr-hair {
  margin-right: 1px;
}

.\!k-mr-hair {
  margin-right: 1px !important;
}

.k-mr-auto {
  margin-right: auto;
}

.\!k-mr-auto {
  margin-right: auto !important;
}

.k-mb-0 {
  margin-bottom: 0;
}

.\!k-mb-0 {
  margin-bottom: 0 !important;
}

.k-mb-1px {
  margin-bottom: 1px;
}

.\!k-mb-1px {
  margin-bottom: 1px !important;
}

.k-mb-0\.5 {
  margin-bottom: 2px;
}

.\!k-mb-0\.5 {
  margin-bottom: 2px !important;
}

.k-mb-1 {
  margin-bottom: 4px;
}

.\!k-mb-1 {
  margin-bottom: 4px !important;
}

.k-mb-1\.5 {
  margin-bottom: 6px;
}

.\!k-mb-1\.5 {
  margin-bottom: 6px !important;
}

.k-mb-2 {
  margin-bottom: 8px;
}

.\!k-mb-2 {
  margin-bottom: 8px !important;
}

.k-mb-2\.5 {
  margin-bottom: 10px;
}

.\!k-mb-2\.5 {
  margin-bottom: 10px !important;
}

.k-mb-3 {
  margin-bottom: 12px;
}

.\!k-mb-3 {
  margin-bottom: 12px !important;
}

.k-mb-3\.5 {
  margin-bottom: 14px;
}

.\!k-mb-3\.5 {
  margin-bottom: 14px !important;
}

.k-mb-4 {
  margin-bottom: 16px;
}

.\!k-mb-4 {
  margin-bottom: 16px !important;
}

.k-mb-4\.5 {
  margin-bottom: 18px;
}

.\!k-mb-4\.5 {
  margin-bottom: 18px !important;
}

.k-mb-5 {
  margin-bottom: 20px;
}

.\!k-mb-5 {
  margin-bottom: 20px !important;
}

.k-mb-5\.5 {
  margin-bottom: 22px;
}

.\!k-mb-5\.5 {
  margin-bottom: 22px !important;
}

.k-mb-6 {
  margin-bottom: 24px;
}

.\!k-mb-6 {
  margin-bottom: 24px !important;
}

.k-mb-6\.5 {
  margin-bottom: 26px;
}

.\!k-mb-6\.5 {
  margin-bottom: 26px !important;
}

.k-mb-7 {
  margin-bottom: 28px;
}

.\!k-mb-7 {
  margin-bottom: 28px !important;
}

.k-mb-7\.5 {
  margin-bottom: 30px;
}

.\!k-mb-7\.5 {
  margin-bottom: 30px !important;
}

.k-mb-8 {
  margin-bottom: 32px;
}

.\!k-mb-8 {
  margin-bottom: 32px !important;
}

.k-mb-9 {
  margin-bottom: 36px;
}

.\!k-mb-9 {
  margin-bottom: 36px !important;
}

.k-mb-10 {
  margin-bottom: 40px;
}

.\!k-mb-10 {
  margin-bottom: 40px !important;
}

.k-mb-11 {
  margin-bottom: 44px;
}

.\!k-mb-11 {
  margin-bottom: 44px !important;
}

.k-mb-12 {
  margin-bottom: 48px;
}

.\!k-mb-12 {
  margin-bottom: 48px !important;
}

.k-mb-13 {
  margin-bottom: 52px;
}

.\!k-mb-13 {
  margin-bottom: 52px !important;
}

.k-mb-14 {
  margin-bottom: 56px;
}

.\!k-mb-14 {
  margin-bottom: 56px !important;
}

.k-mb-15 {
  margin-bottom: 60px;
}

.\!k-mb-15 {
  margin-bottom: 60px !important;
}

.k-mb-16 {
  margin-bottom: 64px;
}

.\!k-mb-16 {
  margin-bottom: 64px !important;
}

.k-mb-17 {
  margin-bottom: 68px;
}

.\!k-mb-17 {
  margin-bottom: 68px !important;
}

.k-mb-18 {
  margin-bottom: 72px;
}

.\!k-mb-18 {
  margin-bottom: 72px !important;
}

.k-mb-19 {
  margin-bottom: 76px;
}

.\!k-mb-19 {
  margin-bottom: 76px !important;
}

.k-mb-20 {
  margin-bottom: 80px;
}

.\!k-mb-20 {
  margin-bottom: 80px !important;
}

.k-mb-21 {
  margin-bottom: 84px;
}

.\!k-mb-21 {
  margin-bottom: 84px !important;
}

.k-mb-22 {
  margin-bottom: 88px;
}

.\!k-mb-22 {
  margin-bottom: 88px !important;
}

.k-mb-23 {
  margin-bottom: 92px;
}

.\!k-mb-23 {
  margin-bottom: 92px !important;
}

.k-mb-24 {
  margin-bottom: 96px;
}

.\!k-mb-24 {
  margin-bottom: 96px !important;
}

.k-mb--1px {
  margin-bottom: -1px;
}

.\!k-mb--1px {
  margin-bottom: -1px !important;
}

.k-mb--0\.5 {
  margin-bottom: -2px;
}

.\!k-mb--0\.5 {
  margin-bottom: -2px !important;
}

.k-mb--1 {
  margin-bottom: -4px;
}

.\!k-mb--1 {
  margin-bottom: -4px !important;
}

.k-mb--1\.5 {
  margin-bottom: -6px;
}

.\!k-mb--1\.5 {
  margin-bottom: -6px !important;
}

.k-mb--2 {
  margin-bottom: -8px;
}

.\!k-mb--2 {
  margin-bottom: -8px !important;
}

.k-mb--2\.5 {
  margin-bottom: -10px;
}

.\!k-mb--2\.5 {
  margin-bottom: -10px !important;
}

.k-mb--3 {
  margin-bottom: -12px;
}

.\!k-mb--3 {
  margin-bottom: -12px !important;
}

.k-mb--3\.5 {
  margin-bottom: -14px;
}

.\!k-mb--3\.5 {
  margin-bottom: -14px !important;
}

.k-mb--4 {
  margin-bottom: -16px;
}

.\!k-mb--4 {
  margin-bottom: -16px !important;
}

.k-mb--4\.5 {
  margin-bottom: -18px;
}

.\!k-mb--4\.5 {
  margin-bottom: -18px !important;
}

.k-mb--5 {
  margin-bottom: -20px;
}

.\!k-mb--5 {
  margin-bottom: -20px !important;
}

.k-mb--5\.5 {
  margin-bottom: -22px;
}

.\!k-mb--5\.5 {
  margin-bottom: -22px !important;
}

.k-mb--6 {
  margin-bottom: -24px;
}

.\!k-mb--6 {
  margin-bottom: -24px !important;
}

.k-mb--6\.5 {
  margin-bottom: -26px;
}

.\!k-mb--6\.5 {
  margin-bottom: -26px !important;
}

.k-mb--7 {
  margin-bottom: -28px;
}

.\!k-mb--7 {
  margin-bottom: -28px !important;
}

.k-mb--7\.5 {
  margin-bottom: -30px;
}

.\!k-mb--7\.5 {
  margin-bottom: -30px !important;
}

.k-mb--8 {
  margin-bottom: -32px;
}

.\!k-mb--8 {
  margin-bottom: -32px !important;
}

.k-mb--9 {
  margin-bottom: -36px;
}

.\!k-mb--9 {
  margin-bottom: -36px !important;
}

.k-mb--10 {
  margin-bottom: -40px;
}

.\!k-mb--10 {
  margin-bottom: -40px !important;
}

.k-mb--11 {
  margin-bottom: -44px;
}

.\!k-mb--11 {
  margin-bottom: -44px !important;
}

.k-mb--12 {
  margin-bottom: -48px;
}

.\!k-mb--12 {
  margin-bottom: -48px !important;
}

.k-mb--13 {
  margin-bottom: -52px;
}

.\!k-mb--13 {
  margin-bottom: -52px !important;
}

.k-mb--14 {
  margin-bottom: -56px;
}

.\!k-mb--14 {
  margin-bottom: -56px !important;
}

.k-mb--15 {
  margin-bottom: -60px;
}

.\!k-mb--15 {
  margin-bottom: -60px !important;
}

.k-mb--16 {
  margin-bottom: -64px;
}

.\!k-mb--16 {
  margin-bottom: -64px !important;
}

.k-mb--17 {
  margin-bottom: -68px;
}

.\!k-mb--17 {
  margin-bottom: -68px !important;
}

.k-mb--18 {
  margin-bottom: -72px;
}

.\!k-mb--18 {
  margin-bottom: -72px !important;
}

.k-mb--19 {
  margin-bottom: -76px;
}

.\!k-mb--19 {
  margin-bottom: -76px !important;
}

.k-mb--20 {
  margin-bottom: -80px;
}

.\!k-mb--20 {
  margin-bottom: -80px !important;
}

.k-mb--21 {
  margin-bottom: -84px;
}

.\!k-mb--21 {
  margin-bottom: -84px !important;
}

.k-mb--22 {
  margin-bottom: -88px;
}

.\!k-mb--22 {
  margin-bottom: -88px !important;
}

.k-mb--23 {
  margin-bottom: -92px;
}

.\!k-mb--23 {
  margin-bottom: -92px !important;
}

.k-mb--24 {
  margin-bottom: -96px;
}

.\!k-mb--24 {
  margin-bottom: -96px !important;
}

.k-mb-xs {
  margin-bottom: 4px;
}

.\!k-mb-xs {
  margin-bottom: 4px !important;
}

.k-mb-sm {
  margin-bottom: 8px;
}

.\!k-mb-sm {
  margin-bottom: 8px !important;
}

.k-mb-md {
  margin-bottom: 12px;
}

.\!k-mb-md {
  margin-bottom: 12px !important;
}

.k-mb-lg {
  margin-bottom: 16px;
}

.\!k-mb-lg {
  margin-bottom: 16px !important;
}

.k-mb-xl {
  margin-bottom: 24px;
}

.\!k-mb-xl {
  margin-bottom: 24px !important;
}

.k-mb-thin {
  margin-bottom: 2px;
}

.\!k-mb-thin {
  margin-bottom: 2px !important;
}

.k-mb-hair {
  margin-bottom: 1px;
}

.\!k-mb-hair {
  margin-bottom: 1px !important;
}

.k-mb-auto {
  margin-bottom: auto;
}

.\!k-mb-auto {
  margin-bottom: auto !important;
}

.k-ml-0 {
  margin-left: 0;
}

.\!k-ml-0 {
  margin-left: 0 !important;
}

.k-ml-1px {
  margin-left: 1px;
}

.\!k-ml-1px {
  margin-left: 1px !important;
}

.k-ml-0\.5 {
  margin-left: 2px;
}

.\!k-ml-0\.5 {
  margin-left: 2px !important;
}

.k-ml-1 {
  margin-left: 4px;
}

.\!k-ml-1 {
  margin-left: 4px !important;
}

.k-ml-1\.5 {
  margin-left: 6px;
}

.\!k-ml-1\.5 {
  margin-left: 6px !important;
}

.k-ml-2 {
  margin-left: 8px;
}

.\!k-ml-2 {
  margin-left: 8px !important;
}

.k-ml-2\.5 {
  margin-left: 10px;
}

.\!k-ml-2\.5 {
  margin-left: 10px !important;
}

.k-ml-3 {
  margin-left: 12px;
}

.\!k-ml-3 {
  margin-left: 12px !important;
}

.k-ml-3\.5 {
  margin-left: 14px;
}

.\!k-ml-3\.5 {
  margin-left: 14px !important;
}

.k-ml-4 {
  margin-left: 16px;
}

.\!k-ml-4 {
  margin-left: 16px !important;
}

.k-ml-4\.5 {
  margin-left: 18px;
}

.\!k-ml-4\.5 {
  margin-left: 18px !important;
}

.k-ml-5 {
  margin-left: 20px;
}

.\!k-ml-5 {
  margin-left: 20px !important;
}

.k-ml-5\.5 {
  margin-left: 22px;
}

.\!k-ml-5\.5 {
  margin-left: 22px !important;
}

.k-ml-6 {
  margin-left: 24px;
}

.\!k-ml-6 {
  margin-left: 24px !important;
}

.k-ml-6\.5 {
  margin-left: 26px;
}

.\!k-ml-6\.5 {
  margin-left: 26px !important;
}

.k-ml-7 {
  margin-left: 28px;
}

.\!k-ml-7 {
  margin-left: 28px !important;
}

.k-ml-7\.5 {
  margin-left: 30px;
}

.\!k-ml-7\.5 {
  margin-left: 30px !important;
}

.k-ml-8 {
  margin-left: 32px;
}

.\!k-ml-8 {
  margin-left: 32px !important;
}

.k-ml-9 {
  margin-left: 36px;
}

.\!k-ml-9 {
  margin-left: 36px !important;
}

.k-ml-10 {
  margin-left: 40px;
}

.\!k-ml-10 {
  margin-left: 40px !important;
}

.k-ml-11 {
  margin-left: 44px;
}

.\!k-ml-11 {
  margin-left: 44px !important;
}

.k-ml-12 {
  margin-left: 48px;
}

.\!k-ml-12 {
  margin-left: 48px !important;
}

.k-ml-13 {
  margin-left: 52px;
}

.\!k-ml-13 {
  margin-left: 52px !important;
}

.k-ml-14 {
  margin-left: 56px;
}

.\!k-ml-14 {
  margin-left: 56px !important;
}

.k-ml-15 {
  margin-left: 60px;
}

.\!k-ml-15 {
  margin-left: 60px !important;
}

.k-ml-16 {
  margin-left: 64px;
}

.\!k-ml-16 {
  margin-left: 64px !important;
}

.k-ml-17 {
  margin-left: 68px;
}

.\!k-ml-17 {
  margin-left: 68px !important;
}

.k-ml-18 {
  margin-left: 72px;
}

.\!k-ml-18 {
  margin-left: 72px !important;
}

.k-ml-19 {
  margin-left: 76px;
}

.\!k-ml-19 {
  margin-left: 76px !important;
}

.k-ml-20 {
  margin-left: 80px;
}

.\!k-ml-20 {
  margin-left: 80px !important;
}

.k-ml-21 {
  margin-left: 84px;
}

.\!k-ml-21 {
  margin-left: 84px !important;
}

.k-ml-22 {
  margin-left: 88px;
}

.\!k-ml-22 {
  margin-left: 88px !important;
}

.k-ml-23 {
  margin-left: 92px;
}

.\!k-ml-23 {
  margin-left: 92px !important;
}

.k-ml-24 {
  margin-left: 96px;
}

.\!k-ml-24 {
  margin-left: 96px !important;
}

.k-ml--1px {
  margin-left: -1px;
}

.\!k-ml--1px {
  margin-left: -1px !important;
}

.k-ml--0\.5 {
  margin-left: -2px;
}

.\!k-ml--0\.5 {
  margin-left: -2px !important;
}

.k-ml--1 {
  margin-left: -4px;
}

.\!k-ml--1 {
  margin-left: -4px !important;
}

.k-ml--1\.5 {
  margin-left: -6px;
}

.\!k-ml--1\.5 {
  margin-left: -6px !important;
}

.k-ml--2 {
  margin-left: -8px;
}

.\!k-ml--2 {
  margin-left: -8px !important;
}

.k-ml--2\.5 {
  margin-left: -10px;
}

.\!k-ml--2\.5 {
  margin-left: -10px !important;
}

.k-ml--3 {
  margin-left: -12px;
}

.\!k-ml--3 {
  margin-left: -12px !important;
}

.k-ml--3\.5 {
  margin-left: -14px;
}

.\!k-ml--3\.5 {
  margin-left: -14px !important;
}

.k-ml--4 {
  margin-left: -16px;
}

.\!k-ml--4 {
  margin-left: -16px !important;
}

.k-ml--4\.5 {
  margin-left: -18px;
}

.\!k-ml--4\.5 {
  margin-left: -18px !important;
}

.k-ml--5 {
  margin-left: -20px;
}

.\!k-ml--5 {
  margin-left: -20px !important;
}

.k-ml--5\.5 {
  margin-left: -22px;
}

.\!k-ml--5\.5 {
  margin-left: -22px !important;
}

.k-ml--6 {
  margin-left: -24px;
}

.\!k-ml--6 {
  margin-left: -24px !important;
}

.k-ml--6\.5 {
  margin-left: -26px;
}

.\!k-ml--6\.5 {
  margin-left: -26px !important;
}

.k-ml--7 {
  margin-left: -28px;
}

.\!k-ml--7 {
  margin-left: -28px !important;
}

.k-ml--7\.5 {
  margin-left: -30px;
}

.\!k-ml--7\.5 {
  margin-left: -30px !important;
}

.k-ml--8 {
  margin-left: -32px;
}

.\!k-ml--8 {
  margin-left: -32px !important;
}

.k-ml--9 {
  margin-left: -36px;
}

.\!k-ml--9 {
  margin-left: -36px !important;
}

.k-ml--10 {
  margin-left: -40px;
}

.\!k-ml--10 {
  margin-left: -40px !important;
}

.k-ml--11 {
  margin-left: -44px;
}

.\!k-ml--11 {
  margin-left: -44px !important;
}

.k-ml--12 {
  margin-left: -48px;
}

.\!k-ml--12 {
  margin-left: -48px !important;
}

.k-ml--13 {
  margin-left: -52px;
}

.\!k-ml--13 {
  margin-left: -52px !important;
}

.k-ml--14 {
  margin-left: -56px;
}

.\!k-ml--14 {
  margin-left: -56px !important;
}

.k-ml--15 {
  margin-left: -60px;
}

.\!k-ml--15 {
  margin-left: -60px !important;
}

.k-ml--16 {
  margin-left: -64px;
}

.\!k-ml--16 {
  margin-left: -64px !important;
}

.k-ml--17 {
  margin-left: -68px;
}

.\!k-ml--17 {
  margin-left: -68px !important;
}

.k-ml--18 {
  margin-left: -72px;
}

.\!k-ml--18 {
  margin-left: -72px !important;
}

.k-ml--19 {
  margin-left: -76px;
}

.\!k-ml--19 {
  margin-left: -76px !important;
}

.k-ml--20 {
  margin-left: -80px;
}

.\!k-ml--20 {
  margin-left: -80px !important;
}

.k-ml--21 {
  margin-left: -84px;
}

.\!k-ml--21 {
  margin-left: -84px !important;
}

.k-ml--22 {
  margin-left: -88px;
}

.\!k-ml--22 {
  margin-left: -88px !important;
}

.k-ml--23 {
  margin-left: -92px;
}

.\!k-ml--23 {
  margin-left: -92px !important;
}

.k-ml--24 {
  margin-left: -96px;
}

.\!k-ml--24 {
  margin-left: -96px !important;
}

.k-ml-xs {
  margin-left: 4px;
}

.\!k-ml-xs {
  margin-left: 4px !important;
}

.k-ml-sm {
  margin-left: 8px;
}

.\!k-ml-sm {
  margin-left: 8px !important;
}

.k-ml-md {
  margin-left: 12px;
}

.\!k-ml-md {
  margin-left: 12px !important;
}

.k-ml-lg {
  margin-left: 16px;
}

.\!k-ml-lg {
  margin-left: 16px !important;
}

.k-ml-xl {
  margin-left: 24px;
}

.\!k-ml-xl {
  margin-left: 24px !important;
}

.k-ml-thin {
  margin-left: 2px;
}

.\!k-ml-thin {
  margin-left: 2px !important;
}

.k-ml-hair {
  margin-left: 1px;
}

.\!k-ml-hair {
  margin-left: 1px !important;
}

.k-ml-auto {
  margin-left: auto;
}

.\!k-ml-auto {
  margin-left: auto !important;
}

.k-mx-0 {
  margin-inline: 0;
}

.\!k-mx-0 {
  margin-inline: 0 !important;
}

.k-mx-1px {
  margin-inline: 1px;
}

.\!k-mx-1px {
  margin-inline: 1px !important;
}

.k-mx-0\.5 {
  margin-inline: 2px;
}

.\!k-mx-0\.5 {
  margin-inline: 2px !important;
}

.k-mx-1 {
  margin-inline: 4px;
}

.\!k-mx-1 {
  margin-inline: 4px !important;
}

.k-mx-1\.5 {
  margin-inline: 6px;
}

.\!k-mx-1\.5 {
  margin-inline: 6px !important;
}

.k-mx-2 {
  margin-inline: 8px;
}

.\!k-mx-2 {
  margin-inline: 8px !important;
}

.k-mx-2\.5 {
  margin-inline: 10px;
}

.\!k-mx-2\.5 {
  margin-inline: 10px !important;
}

.k-mx-3 {
  margin-inline: 12px;
}

.\!k-mx-3 {
  margin-inline: 12px !important;
}

.k-mx-3\.5 {
  margin-inline: 14px;
}

.\!k-mx-3\.5 {
  margin-inline: 14px !important;
}

.k-mx-4 {
  margin-inline: 16px;
}

.\!k-mx-4 {
  margin-inline: 16px !important;
}

.k-mx-4\.5 {
  margin-inline: 18px;
}

.\!k-mx-4\.5 {
  margin-inline: 18px !important;
}

.k-mx-5 {
  margin-inline: 20px;
}

.\!k-mx-5 {
  margin-inline: 20px !important;
}

.k-mx-5\.5 {
  margin-inline: 22px;
}

.\!k-mx-5\.5 {
  margin-inline: 22px !important;
}

.k-mx-6 {
  margin-inline: 24px;
}

.\!k-mx-6 {
  margin-inline: 24px !important;
}

.k-mx-6\.5 {
  margin-inline: 26px;
}

.\!k-mx-6\.5 {
  margin-inline: 26px !important;
}

.k-mx-7 {
  margin-inline: 28px;
}

.\!k-mx-7 {
  margin-inline: 28px !important;
}

.k-mx-7\.5 {
  margin-inline: 30px;
}

.\!k-mx-7\.5 {
  margin-inline: 30px !important;
}

.k-mx-8 {
  margin-inline: 32px;
}

.\!k-mx-8 {
  margin-inline: 32px !important;
}

.k-mx-9 {
  margin-inline: 36px;
}

.\!k-mx-9 {
  margin-inline: 36px !important;
}

.k-mx-10 {
  margin-inline: 40px;
}

.\!k-mx-10 {
  margin-inline: 40px !important;
}

.k-mx-11 {
  margin-inline: 44px;
}

.\!k-mx-11 {
  margin-inline: 44px !important;
}

.k-mx-12 {
  margin-inline: 48px;
}

.\!k-mx-12 {
  margin-inline: 48px !important;
}

.k-mx-13 {
  margin-inline: 52px;
}

.\!k-mx-13 {
  margin-inline: 52px !important;
}

.k-mx-14 {
  margin-inline: 56px;
}

.\!k-mx-14 {
  margin-inline: 56px !important;
}

.k-mx-15 {
  margin-inline: 60px;
}

.\!k-mx-15 {
  margin-inline: 60px !important;
}

.k-mx-16 {
  margin-inline: 64px;
}

.\!k-mx-16 {
  margin-inline: 64px !important;
}

.k-mx-17 {
  margin-inline: 68px;
}

.\!k-mx-17 {
  margin-inline: 68px !important;
}

.k-mx-18 {
  margin-inline: 72px;
}

.\!k-mx-18 {
  margin-inline: 72px !important;
}

.k-mx-19 {
  margin-inline: 76px;
}

.\!k-mx-19 {
  margin-inline: 76px !important;
}

.k-mx-20 {
  margin-inline: 80px;
}

.\!k-mx-20 {
  margin-inline: 80px !important;
}

.k-mx-21 {
  margin-inline: 84px;
}

.\!k-mx-21 {
  margin-inline: 84px !important;
}

.k-mx-22 {
  margin-inline: 88px;
}

.\!k-mx-22 {
  margin-inline: 88px !important;
}

.k-mx-23 {
  margin-inline: 92px;
}

.\!k-mx-23 {
  margin-inline: 92px !important;
}

.k-mx-24 {
  margin-inline: 96px;
}

.\!k-mx-24 {
  margin-inline: 96px !important;
}

.k-mx--1px {
  margin-inline: -1px;
}

.\!k-mx--1px {
  margin-inline: -1px !important;
}

.k-mx--0\.5 {
  margin-inline: -2px;
}

.\!k-mx--0\.5 {
  margin-inline: -2px !important;
}

.k-mx--1 {
  margin-inline: -4px;
}

.\!k-mx--1 {
  margin-inline: -4px !important;
}

.k-mx--1\.5 {
  margin-inline: -6px;
}

.\!k-mx--1\.5 {
  margin-inline: -6px !important;
}

.k-mx--2 {
  margin-inline: -8px;
}

.\!k-mx--2 {
  margin-inline: -8px !important;
}

.k-mx--2\.5 {
  margin-inline: -10px;
}

.\!k-mx--2\.5 {
  margin-inline: -10px !important;
}

.k-mx--3 {
  margin-inline: -12px;
}

.\!k-mx--3 {
  margin-inline: -12px !important;
}

.k-mx--3\.5 {
  margin-inline: -14px;
}

.\!k-mx--3\.5 {
  margin-inline: -14px !important;
}

.k-mx--4 {
  margin-inline: -16px;
}

.\!k-mx--4 {
  margin-inline: -16px !important;
}

.k-mx--4\.5 {
  margin-inline: -18px;
}

.\!k-mx--4\.5 {
  margin-inline: -18px !important;
}

.k-mx--5 {
  margin-inline: -20px;
}

.\!k-mx--5 {
  margin-inline: -20px !important;
}

.k-mx--5\.5 {
  margin-inline: -22px;
}

.\!k-mx--5\.5 {
  margin-inline: -22px !important;
}

.k-mx--6 {
  margin-inline: -24px;
}

.\!k-mx--6 {
  margin-inline: -24px !important;
}

.k-mx--6\.5 {
  margin-inline: -26px;
}

.\!k-mx--6\.5 {
  margin-inline: -26px !important;
}

.k-mx--7 {
  margin-inline: -28px;
}

.\!k-mx--7 {
  margin-inline: -28px !important;
}

.k-mx--7\.5 {
  margin-inline: -30px;
}

.\!k-mx--7\.5 {
  margin-inline: -30px !important;
}

.k-mx--8 {
  margin-inline: -32px;
}

.\!k-mx--8 {
  margin-inline: -32px !important;
}

.k-mx--9 {
  margin-inline: -36px;
}

.\!k-mx--9 {
  margin-inline: -36px !important;
}

.k-mx--10 {
  margin-inline: -40px;
}

.\!k-mx--10 {
  margin-inline: -40px !important;
}

.k-mx--11 {
  margin-inline: -44px;
}

.\!k-mx--11 {
  margin-inline: -44px !important;
}

.k-mx--12 {
  margin-inline: -48px;
}

.\!k-mx--12 {
  margin-inline: -48px !important;
}

.k-mx--13 {
  margin-inline: -52px;
}

.\!k-mx--13 {
  margin-inline: -52px !important;
}

.k-mx--14 {
  margin-inline: -56px;
}

.\!k-mx--14 {
  margin-inline: -56px !important;
}

.k-mx--15 {
  margin-inline: -60px;
}

.\!k-mx--15 {
  margin-inline: -60px !important;
}

.k-mx--16 {
  margin-inline: -64px;
}

.\!k-mx--16 {
  margin-inline: -64px !important;
}

.k-mx--17 {
  margin-inline: -68px;
}

.\!k-mx--17 {
  margin-inline: -68px !important;
}

.k-mx--18 {
  margin-inline: -72px;
}

.\!k-mx--18 {
  margin-inline: -72px !important;
}

.k-mx--19 {
  margin-inline: -76px;
}

.\!k-mx--19 {
  margin-inline: -76px !important;
}

.k-mx--20 {
  margin-inline: -80px;
}

.\!k-mx--20 {
  margin-inline: -80px !important;
}

.k-mx--21 {
  margin-inline: -84px;
}

.\!k-mx--21 {
  margin-inline: -84px !important;
}

.k-mx--22 {
  margin-inline: -88px;
}

.\!k-mx--22 {
  margin-inline: -88px !important;
}

.k-mx--23 {
  margin-inline: -92px;
}

.\!k-mx--23 {
  margin-inline: -92px !important;
}

.k-mx--24 {
  margin-inline: -96px;
}

.\!k-mx--24 {
  margin-inline: -96px !important;
}

.k-mx-xs {
  margin-inline: 4px;
}

.\!k-mx-xs {
  margin-inline: 4px !important;
}

.k-mx-sm {
  margin-inline: 8px;
}

.\!k-mx-sm {
  margin-inline: 8px !important;
}

.k-mx-md {
  margin-inline: 12px;
}

.\!k-mx-md {
  margin-inline: 12px !important;
}

.k-mx-lg {
  margin-inline: 16px;
}

.\!k-mx-lg {
  margin-inline: 16px !important;
}

.k-mx-xl {
  margin-inline: 24px;
}

.\!k-mx-xl {
  margin-inline: 24px !important;
}

.k-mx-thin {
  margin-inline: 2px;
}

.\!k-mx-thin {
  margin-inline: 2px !important;
}

.k-mx-hair {
  margin-inline: 1px;
}

.\!k-mx-hair {
  margin-inline: 1px !important;
}

.k-mx-auto {
  margin-inline: auto;
}

.\!k-mx-auto {
  margin-inline: auto !important;
}

.k-my-0 {
  margin-block: 0;
}

.\!k-my-0 {
  margin-block: 0 !important;
}

.k-my-1px {
  margin-block: 1px;
}

.\!k-my-1px {
  margin-block: 1px !important;
}

.k-my-0\.5 {
  margin-block: 2px;
}

.\!k-my-0\.5 {
  margin-block: 2px !important;
}

.k-my-1 {
  margin-block: 4px;
}

.\!k-my-1 {
  margin-block: 4px !important;
}

.k-my-1\.5 {
  margin-block: 6px;
}

.\!k-my-1\.5 {
  margin-block: 6px !important;
}

.k-my-2 {
  margin-block: 8px;
}

.\!k-my-2 {
  margin-block: 8px !important;
}

.k-my-2\.5 {
  margin-block: 10px;
}

.\!k-my-2\.5 {
  margin-block: 10px !important;
}

.k-my-3 {
  margin-block: 12px;
}

.\!k-my-3 {
  margin-block: 12px !important;
}

.k-my-3\.5 {
  margin-block: 14px;
}

.\!k-my-3\.5 {
  margin-block: 14px !important;
}

.k-my-4 {
  margin-block: 16px;
}

.\!k-my-4 {
  margin-block: 16px !important;
}

.k-my-4\.5 {
  margin-block: 18px;
}

.\!k-my-4\.5 {
  margin-block: 18px !important;
}

.k-my-5 {
  margin-block: 20px;
}

.\!k-my-5 {
  margin-block: 20px !important;
}

.k-my-5\.5 {
  margin-block: 22px;
}

.\!k-my-5\.5 {
  margin-block: 22px !important;
}

.k-my-6 {
  margin-block: 24px;
}

.\!k-my-6 {
  margin-block: 24px !important;
}

.k-my-6\.5 {
  margin-block: 26px;
}

.\!k-my-6\.5 {
  margin-block: 26px !important;
}

.k-my-7 {
  margin-block: 28px;
}

.\!k-my-7 {
  margin-block: 28px !important;
}

.k-my-7\.5 {
  margin-block: 30px;
}

.\!k-my-7\.5 {
  margin-block: 30px !important;
}

.k-my-8 {
  margin-block: 32px;
}

.\!k-my-8 {
  margin-block: 32px !important;
}

.k-my-9 {
  margin-block: 36px;
}

.\!k-my-9 {
  margin-block: 36px !important;
}

.k-my-10 {
  margin-block: 40px;
}

.\!k-my-10 {
  margin-block: 40px !important;
}

.k-my-11 {
  margin-block: 44px;
}

.\!k-my-11 {
  margin-block: 44px !important;
}

.k-my-12 {
  margin-block: 48px;
}

.\!k-my-12 {
  margin-block: 48px !important;
}

.k-my-13 {
  margin-block: 52px;
}

.\!k-my-13 {
  margin-block: 52px !important;
}

.k-my-14 {
  margin-block: 56px;
}

.\!k-my-14 {
  margin-block: 56px !important;
}

.k-my-15 {
  margin-block: 60px;
}

.\!k-my-15 {
  margin-block: 60px !important;
}

.k-my-16 {
  margin-block: 64px;
}

.\!k-my-16 {
  margin-block: 64px !important;
}

.k-my-17 {
  margin-block: 68px;
}

.\!k-my-17 {
  margin-block: 68px !important;
}

.k-my-18 {
  margin-block: 72px;
}

.\!k-my-18 {
  margin-block: 72px !important;
}

.k-my-19 {
  margin-block: 76px;
}

.\!k-my-19 {
  margin-block: 76px !important;
}

.k-my-20 {
  margin-block: 80px;
}

.\!k-my-20 {
  margin-block: 80px !important;
}

.k-my-21 {
  margin-block: 84px;
}

.\!k-my-21 {
  margin-block: 84px !important;
}

.k-my-22 {
  margin-block: 88px;
}

.\!k-my-22 {
  margin-block: 88px !important;
}

.k-my-23 {
  margin-block: 92px;
}

.\!k-my-23 {
  margin-block: 92px !important;
}

.k-my-24 {
  margin-block: 96px;
}

.\!k-my-24 {
  margin-block: 96px !important;
}

.k-my--1px {
  margin-block: -1px;
}

.\!k-my--1px {
  margin-block: -1px !important;
}

.k-my--0\.5 {
  margin-block: -2px;
}

.\!k-my--0\.5 {
  margin-block: -2px !important;
}

.k-my--1 {
  margin-block: -4px;
}

.\!k-my--1 {
  margin-block: -4px !important;
}

.k-my--1\.5 {
  margin-block: -6px;
}

.\!k-my--1\.5 {
  margin-block: -6px !important;
}

.k-my--2 {
  margin-block: -8px;
}

.\!k-my--2 {
  margin-block: -8px !important;
}

.k-my--2\.5 {
  margin-block: -10px;
}

.\!k-my--2\.5 {
  margin-block: -10px !important;
}

.k-my--3 {
  margin-block: -12px;
}

.\!k-my--3 {
  margin-block: -12px !important;
}

.k-my--3\.5 {
  margin-block: -14px;
}

.\!k-my--3\.5 {
  margin-block: -14px !important;
}

.k-my--4 {
  margin-block: -16px;
}

.\!k-my--4 {
  margin-block: -16px !important;
}

.k-my--4\.5 {
  margin-block: -18px;
}

.\!k-my--4\.5 {
  margin-block: -18px !important;
}

.k-my--5 {
  margin-block: -20px;
}

.\!k-my--5 {
  margin-block: -20px !important;
}

.k-my--5\.5 {
  margin-block: -22px;
}

.\!k-my--5\.5 {
  margin-block: -22px !important;
}

.k-my--6 {
  margin-block: -24px;
}

.\!k-my--6 {
  margin-block: -24px !important;
}

.k-my--6\.5 {
  margin-block: -26px;
}

.\!k-my--6\.5 {
  margin-block: -26px !important;
}

.k-my--7 {
  margin-block: -28px;
}

.\!k-my--7 {
  margin-block: -28px !important;
}

.k-my--7\.5 {
  margin-block: -30px;
}

.\!k-my--7\.5 {
  margin-block: -30px !important;
}

.k-my--8 {
  margin-block: -32px;
}

.\!k-my--8 {
  margin-block: -32px !important;
}

.k-my--9 {
  margin-block: -36px;
}

.\!k-my--9 {
  margin-block: -36px !important;
}

.k-my--10 {
  margin-block: -40px;
}

.\!k-my--10 {
  margin-block: -40px !important;
}

.k-my--11 {
  margin-block: -44px;
}

.\!k-my--11 {
  margin-block: -44px !important;
}

.k-my--12 {
  margin-block: -48px;
}

.\!k-my--12 {
  margin-block: -48px !important;
}

.k-my--13 {
  margin-block: -52px;
}

.\!k-my--13 {
  margin-block: -52px !important;
}

.k-my--14 {
  margin-block: -56px;
}

.\!k-my--14 {
  margin-block: -56px !important;
}

.k-my--15 {
  margin-block: -60px;
}

.\!k-my--15 {
  margin-block: -60px !important;
}

.k-my--16 {
  margin-block: -64px;
}

.\!k-my--16 {
  margin-block: -64px !important;
}

.k-my--17 {
  margin-block: -68px;
}

.\!k-my--17 {
  margin-block: -68px !important;
}

.k-my--18 {
  margin-block: -72px;
}

.\!k-my--18 {
  margin-block: -72px !important;
}

.k-my--19 {
  margin-block: -76px;
}

.\!k-my--19 {
  margin-block: -76px !important;
}

.k-my--20 {
  margin-block: -80px;
}

.\!k-my--20 {
  margin-block: -80px !important;
}

.k-my--21 {
  margin-block: -84px;
}

.\!k-my--21 {
  margin-block: -84px !important;
}

.k-my--22 {
  margin-block: -88px;
}

.\!k-my--22 {
  margin-block: -88px !important;
}

.k-my--23 {
  margin-block: -92px;
}

.\!k-my--23 {
  margin-block: -92px !important;
}

.k-my--24 {
  margin-block: -96px;
}

.\!k-my--24 {
  margin-block: -96px !important;
}

.k-my-xs {
  margin-block: 4px;
}

.\!k-my-xs {
  margin-block: 4px !important;
}

.k-my-sm {
  margin-block: 8px;
}

.\!k-my-sm {
  margin-block: 8px !important;
}

.k-my-md {
  margin-block: 12px;
}

.\!k-my-md {
  margin-block: 12px !important;
}

.k-my-lg {
  margin-block: 16px;
}

.\!k-my-lg {
  margin-block: 16px !important;
}

.k-my-xl {
  margin-block: 24px;
}

.\!k-my-xl {
  margin-block: 24px !important;
}

.k-my-thin {
  margin-block: 2px;
}

.\!k-my-thin {
  margin-block: 2px !important;
}

.k-my-hair {
  margin-block: 1px;
}

.\!k-my-hair {
  margin-block: 1px !important;
}

.k-my-auto {
  margin-block: auto;
}

.\!k-my-auto {
  margin-block: auto !important;
}

.k-p-0 {
  padding: 0;
}

.\!k-p-0 {
  padding: 0 !important;
}

.k-p-1px {
  padding: 1px;
}

.\!k-p-1px {
  padding: 1px !important;
}

.k-p-0\.5 {
  padding: 2px;
}

.\!k-p-0\.5 {
  padding: 2px !important;
}

.k-p-1 {
  padding: 4px;
}

.\!k-p-1 {
  padding: 4px !important;
}

.k-p-1\.5 {
  padding: 6px;
}

.\!k-p-1\.5 {
  padding: 6px !important;
}

.k-p-2 {
  padding: 8px;
}

.\!k-p-2 {
  padding: 8px !important;
}

.k-p-2\.5 {
  padding: 10px;
}

.\!k-p-2\.5 {
  padding: 10px !important;
}

.k-p-3 {
  padding: 12px;
}

.\!k-p-3 {
  padding: 12px !important;
}

.k-p-3\.5 {
  padding: 14px;
}

.\!k-p-3\.5 {
  padding: 14px !important;
}

.k-p-4 {
  padding: 16px;
}

.\!k-p-4 {
  padding: 16px !important;
}

.k-p-4\.5 {
  padding: 18px;
}

.\!k-p-4\.5 {
  padding: 18px !important;
}

.k-p-5 {
  padding: 20px;
}

.\!k-p-5 {
  padding: 20px !important;
}

.k-p-5\.5 {
  padding: 22px;
}

.\!k-p-5\.5 {
  padding: 22px !important;
}

.k-p-6 {
  padding: 24px;
}

.\!k-p-6 {
  padding: 24px !important;
}

.k-p-6\.5 {
  padding: 26px;
}

.\!k-p-6\.5 {
  padding: 26px !important;
}

.k-p-7 {
  padding: 28px;
}

.\!k-p-7 {
  padding: 28px !important;
}

.k-p-7\.5 {
  padding: 30px;
}

.\!k-p-7\.5 {
  padding: 30px !important;
}

.k-p-8 {
  padding: 32px;
}

.\!k-p-8 {
  padding: 32px !important;
}

.k-p-9 {
  padding: 36px;
}

.\!k-p-9 {
  padding: 36px !important;
}

.k-p-10 {
  padding: 40px;
}

.\!k-p-10 {
  padding: 40px !important;
}

.k-p-11 {
  padding: 44px;
}

.\!k-p-11 {
  padding: 44px !important;
}

.k-p-12 {
  padding: 48px;
}

.\!k-p-12 {
  padding: 48px !important;
}

.k-p-13 {
  padding: 52px;
}

.\!k-p-13 {
  padding: 52px !important;
}

.k-p-14 {
  padding: 56px;
}

.\!k-p-14 {
  padding: 56px !important;
}

.k-p-15 {
  padding: 60px;
}

.\!k-p-15 {
  padding: 60px !important;
}

.k-p-16 {
  padding: 64px;
}

.\!k-p-16 {
  padding: 64px !important;
}

.k-p-17 {
  padding: 68px;
}

.\!k-p-17 {
  padding: 68px !important;
}

.k-p-18 {
  padding: 72px;
}

.\!k-p-18 {
  padding: 72px !important;
}

.k-p-19 {
  padding: 76px;
}

.\!k-p-19 {
  padding: 76px !important;
}

.k-p-20 {
  padding: 80px;
}

.\!k-p-20 {
  padding: 80px !important;
}

.k-p-21 {
  padding: 84px;
}

.\!k-p-21 {
  padding: 84px !important;
}

.k-p-22 {
  padding: 88px;
}

.\!k-p-22 {
  padding: 88px !important;
}

.k-p-23 {
  padding: 92px;
}

.\!k-p-23 {
  padding: 92px !important;
}

.k-p-24 {
  padding: 96px;
}

.\!k-p-24 {
  padding: 96px !important;
}

.k-p-xs {
  padding: 4px;
}

.\!k-p-xs {
  padding: 4px !important;
}

.k-p-sm {
  padding: 8px;
}

.\!k-p-sm {
  padding: 8px !important;
}

.k-p-md {
  padding: 12px;
}

.\!k-p-md {
  padding: 12px !important;
}

.k-p-lg {
  padding: 16px;
}

.\!k-p-lg {
  padding: 16px !important;
}

.k-p-xl {
  padding: 24px;
}

.\!k-p-xl {
  padding: 24px !important;
}

.k-p-thin {
  padding: 2px;
}

.\!k-p-thin {
  padding: 2px !important;
}

.k-p-hair {
  padding: 1px;
}

.\!k-p-hair {
  padding: 1px !important;
}

.k-pt-0 {
  padding-top: 0;
}

.\!k-pt-0 {
  padding-top: 0 !important;
}

.k-pt-1px {
  padding-top: 1px;
}

.\!k-pt-1px {
  padding-top: 1px !important;
}

.k-pt-0\.5 {
  padding-top: 2px;
}

.\!k-pt-0\.5 {
  padding-top: 2px !important;
}

.k-pt-1 {
  padding-top: 4px;
}

.\!k-pt-1 {
  padding-top: 4px !important;
}

.k-pt-1\.5 {
  padding-top: 6px;
}

.\!k-pt-1\.5 {
  padding-top: 6px !important;
}

.k-pt-2 {
  padding-top: 8px;
}

.\!k-pt-2 {
  padding-top: 8px !important;
}

.k-pt-2\.5 {
  padding-top: 10px;
}

.\!k-pt-2\.5 {
  padding-top: 10px !important;
}

.k-pt-3 {
  padding-top: 12px;
}

.\!k-pt-3 {
  padding-top: 12px !important;
}

.k-pt-3\.5 {
  padding-top: 14px;
}

.\!k-pt-3\.5 {
  padding-top: 14px !important;
}

.k-pt-4 {
  padding-top: 16px;
}

.\!k-pt-4 {
  padding-top: 16px !important;
}

.k-pt-4\.5 {
  padding-top: 18px;
}

.\!k-pt-4\.5 {
  padding-top: 18px !important;
}

.k-pt-5 {
  padding-top: 20px;
}

.\!k-pt-5 {
  padding-top: 20px !important;
}

.k-pt-5\.5 {
  padding-top: 22px;
}

.\!k-pt-5\.5 {
  padding-top: 22px !important;
}

.k-pt-6 {
  padding-top: 24px;
}

.\!k-pt-6 {
  padding-top: 24px !important;
}

.k-pt-6\.5 {
  padding-top: 26px;
}

.\!k-pt-6\.5 {
  padding-top: 26px !important;
}

.k-pt-7 {
  padding-top: 28px;
}

.\!k-pt-7 {
  padding-top: 28px !important;
}

.k-pt-7\.5 {
  padding-top: 30px;
}

.\!k-pt-7\.5 {
  padding-top: 30px !important;
}

.k-pt-8 {
  padding-top: 32px;
}

.\!k-pt-8 {
  padding-top: 32px !important;
}

.k-pt-9 {
  padding-top: 36px;
}

.\!k-pt-9 {
  padding-top: 36px !important;
}

.k-pt-10 {
  padding-top: 40px;
}

.\!k-pt-10 {
  padding-top: 40px !important;
}

.k-pt-11 {
  padding-top: 44px;
}

.\!k-pt-11 {
  padding-top: 44px !important;
}

.k-pt-12 {
  padding-top: 48px;
}

.\!k-pt-12 {
  padding-top: 48px !important;
}

.k-pt-13 {
  padding-top: 52px;
}

.\!k-pt-13 {
  padding-top: 52px !important;
}

.k-pt-14 {
  padding-top: 56px;
}

.\!k-pt-14 {
  padding-top: 56px !important;
}

.k-pt-15 {
  padding-top: 60px;
}

.\!k-pt-15 {
  padding-top: 60px !important;
}

.k-pt-16 {
  padding-top: 64px;
}

.\!k-pt-16 {
  padding-top: 64px !important;
}

.k-pt-17 {
  padding-top: 68px;
}

.\!k-pt-17 {
  padding-top: 68px !important;
}

.k-pt-18 {
  padding-top: 72px;
}

.\!k-pt-18 {
  padding-top: 72px !important;
}

.k-pt-19 {
  padding-top: 76px;
}

.\!k-pt-19 {
  padding-top: 76px !important;
}

.k-pt-20 {
  padding-top: 80px;
}

.\!k-pt-20 {
  padding-top: 80px !important;
}

.k-pt-21 {
  padding-top: 84px;
}

.\!k-pt-21 {
  padding-top: 84px !important;
}

.k-pt-22 {
  padding-top: 88px;
}

.\!k-pt-22 {
  padding-top: 88px !important;
}

.k-pt-23 {
  padding-top: 92px;
}

.\!k-pt-23 {
  padding-top: 92px !important;
}

.k-pt-24 {
  padding-top: 96px;
}

.\!k-pt-24 {
  padding-top: 96px !important;
}

.k-pt-xs {
  padding-top: 4px;
}

.\!k-pt-xs {
  padding-top: 4px !important;
}

.k-pt-sm {
  padding-top: 8px;
}

.\!k-pt-sm {
  padding-top: 8px !important;
}

.k-pt-md {
  padding-top: 12px;
}

.\!k-pt-md {
  padding-top: 12px !important;
}

.k-pt-lg {
  padding-top: 16px;
}

.\!k-pt-lg {
  padding-top: 16px !important;
}

.k-pt-xl {
  padding-top: 24px;
}

.\!k-pt-xl {
  padding-top: 24px !important;
}

.k-pt-thin {
  padding-top: 2px;
}

.\!k-pt-thin {
  padding-top: 2px !important;
}

.k-pt-hair {
  padding-top: 1px;
}

.\!k-pt-hair {
  padding-top: 1px !important;
}

.k-pr-0 {
  padding-right: 0;
}

.\!k-pr-0 {
  padding-right: 0 !important;
}

.k-pr-1px {
  padding-right: 1px;
}

.\!k-pr-1px {
  padding-right: 1px !important;
}

.k-pr-0\.5 {
  padding-right: 2px;
}

.\!k-pr-0\.5 {
  padding-right: 2px !important;
}

.k-pr-1 {
  padding-right: 4px;
}

.\!k-pr-1 {
  padding-right: 4px !important;
}

.k-pr-1\.5 {
  padding-right: 6px;
}

.\!k-pr-1\.5 {
  padding-right: 6px !important;
}

.k-pr-2 {
  padding-right: 8px;
}

.\!k-pr-2 {
  padding-right: 8px !important;
}

.k-pr-2\.5 {
  padding-right: 10px;
}

.\!k-pr-2\.5 {
  padding-right: 10px !important;
}

.k-pr-3 {
  padding-right: 12px;
}

.\!k-pr-3 {
  padding-right: 12px !important;
}

.k-pr-3\.5 {
  padding-right: 14px;
}

.\!k-pr-3\.5 {
  padding-right: 14px !important;
}

.k-pr-4 {
  padding-right: 16px;
}

.\!k-pr-4 {
  padding-right: 16px !important;
}

.k-pr-4\.5 {
  padding-right: 18px;
}

.\!k-pr-4\.5 {
  padding-right: 18px !important;
}

.k-pr-5 {
  padding-right: 20px;
}

.\!k-pr-5 {
  padding-right: 20px !important;
}

.k-pr-5\.5 {
  padding-right: 22px;
}

.\!k-pr-5\.5 {
  padding-right: 22px !important;
}

.k-pr-6 {
  padding-right: 24px;
}

.\!k-pr-6 {
  padding-right: 24px !important;
}

.k-pr-6\.5 {
  padding-right: 26px;
}

.\!k-pr-6\.5 {
  padding-right: 26px !important;
}

.k-pr-7 {
  padding-right: 28px;
}

.\!k-pr-7 {
  padding-right: 28px !important;
}

.k-pr-7\.5 {
  padding-right: 30px;
}

.\!k-pr-7\.5 {
  padding-right: 30px !important;
}

.k-pr-8 {
  padding-right: 32px;
}

.\!k-pr-8 {
  padding-right: 32px !important;
}

.k-pr-9 {
  padding-right: 36px;
}

.\!k-pr-9 {
  padding-right: 36px !important;
}

.k-pr-10 {
  padding-right: 40px;
}

.\!k-pr-10 {
  padding-right: 40px !important;
}

.k-pr-11 {
  padding-right: 44px;
}

.\!k-pr-11 {
  padding-right: 44px !important;
}

.k-pr-12 {
  padding-right: 48px;
}

.\!k-pr-12 {
  padding-right: 48px !important;
}

.k-pr-13 {
  padding-right: 52px;
}

.\!k-pr-13 {
  padding-right: 52px !important;
}

.k-pr-14 {
  padding-right: 56px;
}

.\!k-pr-14 {
  padding-right: 56px !important;
}

.k-pr-15 {
  padding-right: 60px;
}

.\!k-pr-15 {
  padding-right: 60px !important;
}

.k-pr-16 {
  padding-right: 64px;
}

.\!k-pr-16 {
  padding-right: 64px !important;
}

.k-pr-17 {
  padding-right: 68px;
}

.\!k-pr-17 {
  padding-right: 68px !important;
}

.k-pr-18 {
  padding-right: 72px;
}

.\!k-pr-18 {
  padding-right: 72px !important;
}

.k-pr-19 {
  padding-right: 76px;
}

.\!k-pr-19 {
  padding-right: 76px !important;
}

.k-pr-20 {
  padding-right: 80px;
}

.\!k-pr-20 {
  padding-right: 80px !important;
}

.k-pr-21 {
  padding-right: 84px;
}

.\!k-pr-21 {
  padding-right: 84px !important;
}

.k-pr-22 {
  padding-right: 88px;
}

.\!k-pr-22 {
  padding-right: 88px !important;
}

.k-pr-23 {
  padding-right: 92px;
}

.\!k-pr-23 {
  padding-right: 92px !important;
}

.k-pr-24 {
  padding-right: 96px;
}

.\!k-pr-24 {
  padding-right: 96px !important;
}

.k-pr-xs {
  padding-right: 4px;
}

.\!k-pr-xs {
  padding-right: 4px !important;
}

.k-pr-sm {
  padding-right: 8px;
}

.\!k-pr-sm {
  padding-right: 8px !important;
}

.k-pr-md {
  padding-right: 12px;
}

.\!k-pr-md {
  padding-right: 12px !important;
}

.k-pr-lg {
  padding-right: 16px;
}

.\!k-pr-lg {
  padding-right: 16px !important;
}

.k-pr-xl {
  padding-right: 24px;
}

.\!k-pr-xl {
  padding-right: 24px !important;
}

.k-pr-thin {
  padding-right: 2px;
}

.\!k-pr-thin {
  padding-right: 2px !important;
}

.k-pr-hair {
  padding-right: 1px;
}

.\!k-pr-hair {
  padding-right: 1px !important;
}

.k-pb-0 {
  padding-bottom: 0;
}

.\!k-pb-0 {
  padding-bottom: 0 !important;
}

.k-pb-1px {
  padding-bottom: 1px;
}

.\!k-pb-1px {
  padding-bottom: 1px !important;
}

.k-pb-0\.5 {
  padding-bottom: 2px;
}

.\!k-pb-0\.5 {
  padding-bottom: 2px !important;
}

.k-pb-1 {
  padding-bottom: 4px;
}

.\!k-pb-1 {
  padding-bottom: 4px !important;
}

.k-pb-1\.5 {
  padding-bottom: 6px;
}

.\!k-pb-1\.5 {
  padding-bottom: 6px !important;
}

.k-pb-2 {
  padding-bottom: 8px;
}

.\!k-pb-2 {
  padding-bottom: 8px !important;
}

.k-pb-2\.5 {
  padding-bottom: 10px;
}

.\!k-pb-2\.5 {
  padding-bottom: 10px !important;
}

.k-pb-3 {
  padding-bottom: 12px;
}

.\!k-pb-3 {
  padding-bottom: 12px !important;
}

.k-pb-3\.5 {
  padding-bottom: 14px;
}

.\!k-pb-3\.5 {
  padding-bottom: 14px !important;
}

.k-pb-4 {
  padding-bottom: 16px;
}

.\!k-pb-4 {
  padding-bottom: 16px !important;
}

.k-pb-4\.5 {
  padding-bottom: 18px;
}

.\!k-pb-4\.5 {
  padding-bottom: 18px !important;
}

.k-pb-5 {
  padding-bottom: 20px;
}

.\!k-pb-5 {
  padding-bottom: 20px !important;
}

.k-pb-5\.5 {
  padding-bottom: 22px;
}

.\!k-pb-5\.5 {
  padding-bottom: 22px !important;
}

.k-pb-6 {
  padding-bottom: 24px;
}

.\!k-pb-6 {
  padding-bottom: 24px !important;
}

.k-pb-6\.5 {
  padding-bottom: 26px;
}

.\!k-pb-6\.5 {
  padding-bottom: 26px !important;
}

.k-pb-7 {
  padding-bottom: 28px;
}

.\!k-pb-7 {
  padding-bottom: 28px !important;
}

.k-pb-7\.5 {
  padding-bottom: 30px;
}

.\!k-pb-7\.5 {
  padding-bottom: 30px !important;
}

.k-pb-8 {
  padding-bottom: 32px;
}

.\!k-pb-8 {
  padding-bottom: 32px !important;
}

.k-pb-9 {
  padding-bottom: 36px;
}

.\!k-pb-9 {
  padding-bottom: 36px !important;
}

.k-pb-10 {
  padding-bottom: 40px;
}

.\!k-pb-10 {
  padding-bottom: 40px !important;
}

.k-pb-11 {
  padding-bottom: 44px;
}

.\!k-pb-11 {
  padding-bottom: 44px !important;
}

.k-pb-12 {
  padding-bottom: 48px;
}

.\!k-pb-12 {
  padding-bottom: 48px !important;
}

.k-pb-13 {
  padding-bottom: 52px;
}

.\!k-pb-13 {
  padding-bottom: 52px !important;
}

.k-pb-14 {
  padding-bottom: 56px;
}

.\!k-pb-14 {
  padding-bottom: 56px !important;
}

.k-pb-15 {
  padding-bottom: 60px;
}

.\!k-pb-15 {
  padding-bottom: 60px !important;
}

.k-pb-16 {
  padding-bottom: 64px;
}

.\!k-pb-16 {
  padding-bottom: 64px !important;
}

.k-pb-17 {
  padding-bottom: 68px;
}

.\!k-pb-17 {
  padding-bottom: 68px !important;
}

.k-pb-18 {
  padding-bottom: 72px;
}

.\!k-pb-18 {
  padding-bottom: 72px !important;
}

.k-pb-19 {
  padding-bottom: 76px;
}

.\!k-pb-19 {
  padding-bottom: 76px !important;
}

.k-pb-20 {
  padding-bottom: 80px;
}

.\!k-pb-20 {
  padding-bottom: 80px !important;
}

.k-pb-21 {
  padding-bottom: 84px;
}

.\!k-pb-21 {
  padding-bottom: 84px !important;
}

.k-pb-22 {
  padding-bottom: 88px;
}

.\!k-pb-22 {
  padding-bottom: 88px !important;
}

.k-pb-23 {
  padding-bottom: 92px;
}

.\!k-pb-23 {
  padding-bottom: 92px !important;
}

.k-pb-24 {
  padding-bottom: 96px;
}

.\!k-pb-24 {
  padding-bottom: 96px !important;
}

.k-pb-xs {
  padding-bottom: 4px;
}

.\!k-pb-xs {
  padding-bottom: 4px !important;
}

.k-pb-sm {
  padding-bottom: 8px;
}

.\!k-pb-sm {
  padding-bottom: 8px !important;
}

.k-pb-md {
  padding-bottom: 12px;
}

.\!k-pb-md {
  padding-bottom: 12px !important;
}

.k-pb-lg {
  padding-bottom: 16px;
}

.\!k-pb-lg {
  padding-bottom: 16px !important;
}

.k-pb-xl {
  padding-bottom: 24px;
}

.\!k-pb-xl {
  padding-bottom: 24px !important;
}

.k-pb-thin {
  padding-bottom: 2px;
}

.\!k-pb-thin {
  padding-bottom: 2px !important;
}

.k-pb-hair {
  padding-bottom: 1px;
}

.\!k-pb-hair {
  padding-bottom: 1px !important;
}

.k-pl-0 {
  padding-left: 0;
}

.\!k-pl-0 {
  padding-left: 0 !important;
}

.k-pl-1px {
  padding-left: 1px;
}

.\!k-pl-1px {
  padding-left: 1px !important;
}

.k-pl-0\.5 {
  padding-left: 2px;
}

.\!k-pl-0\.5 {
  padding-left: 2px !important;
}

.k-pl-1 {
  padding-left: 4px;
}

.\!k-pl-1 {
  padding-left: 4px !important;
}

.k-pl-1\.5 {
  padding-left: 6px;
}

.\!k-pl-1\.5 {
  padding-left: 6px !important;
}

.k-pl-2 {
  padding-left: 8px;
}

.\!k-pl-2 {
  padding-left: 8px !important;
}

.k-pl-2\.5 {
  padding-left: 10px;
}

.\!k-pl-2\.5 {
  padding-left: 10px !important;
}

.k-pl-3 {
  padding-left: 12px;
}

.\!k-pl-3 {
  padding-left: 12px !important;
}

.k-pl-3\.5 {
  padding-left: 14px;
}

.\!k-pl-3\.5 {
  padding-left: 14px !important;
}

.k-pl-4 {
  padding-left: 16px;
}

.\!k-pl-4 {
  padding-left: 16px !important;
}

.k-pl-4\.5 {
  padding-left: 18px;
}

.\!k-pl-4\.5 {
  padding-left: 18px !important;
}

.k-pl-5 {
  padding-left: 20px;
}

.\!k-pl-5 {
  padding-left: 20px !important;
}

.k-pl-5\.5 {
  padding-left: 22px;
}

.\!k-pl-5\.5 {
  padding-left: 22px !important;
}

.k-pl-6 {
  padding-left: 24px;
}

.\!k-pl-6 {
  padding-left: 24px !important;
}

.k-pl-6\.5 {
  padding-left: 26px;
}

.\!k-pl-6\.5 {
  padding-left: 26px !important;
}

.k-pl-7 {
  padding-left: 28px;
}

.\!k-pl-7 {
  padding-left: 28px !important;
}

.k-pl-7\.5 {
  padding-left: 30px;
}

.\!k-pl-7\.5 {
  padding-left: 30px !important;
}

.k-pl-8 {
  padding-left: 32px;
}

.\!k-pl-8 {
  padding-left: 32px !important;
}

.k-pl-9 {
  padding-left: 36px;
}

.\!k-pl-9 {
  padding-left: 36px !important;
}

.k-pl-10 {
  padding-left: 40px;
}

.\!k-pl-10 {
  padding-left: 40px !important;
}

.k-pl-11 {
  padding-left: 44px;
}

.\!k-pl-11 {
  padding-left: 44px !important;
}

.k-pl-12 {
  padding-left: 48px;
}

.\!k-pl-12 {
  padding-left: 48px !important;
}

.k-pl-13 {
  padding-left: 52px;
}

.\!k-pl-13 {
  padding-left: 52px !important;
}

.k-pl-14 {
  padding-left: 56px;
}

.\!k-pl-14 {
  padding-left: 56px !important;
}

.k-pl-15 {
  padding-left: 60px;
}

.\!k-pl-15 {
  padding-left: 60px !important;
}

.k-pl-16 {
  padding-left: 64px;
}

.\!k-pl-16 {
  padding-left: 64px !important;
}

.k-pl-17 {
  padding-left: 68px;
}

.\!k-pl-17 {
  padding-left: 68px !important;
}

.k-pl-18 {
  padding-left: 72px;
}

.\!k-pl-18 {
  padding-left: 72px !important;
}

.k-pl-19 {
  padding-left: 76px;
}

.\!k-pl-19 {
  padding-left: 76px !important;
}

.k-pl-20 {
  padding-left: 80px;
}

.\!k-pl-20 {
  padding-left: 80px !important;
}

.k-pl-21 {
  padding-left: 84px;
}

.\!k-pl-21 {
  padding-left: 84px !important;
}

.k-pl-22 {
  padding-left: 88px;
}

.\!k-pl-22 {
  padding-left: 88px !important;
}

.k-pl-23 {
  padding-left: 92px;
}

.\!k-pl-23 {
  padding-left: 92px !important;
}

.k-pl-24 {
  padding-left: 96px;
}

.\!k-pl-24 {
  padding-left: 96px !important;
}

.k-pl-xs {
  padding-left: 4px;
}

.\!k-pl-xs {
  padding-left: 4px !important;
}

.k-pl-sm {
  padding-left: 8px;
}

.\!k-pl-sm {
  padding-left: 8px !important;
}

.k-pl-md {
  padding-left: 12px;
}

.\!k-pl-md {
  padding-left: 12px !important;
}

.k-pl-lg {
  padding-left: 16px;
}

.\!k-pl-lg {
  padding-left: 16px !important;
}

.k-pl-xl {
  padding-left: 24px;
}

.\!k-pl-xl {
  padding-left: 24px !important;
}

.k-pl-thin {
  padding-left: 2px;
}

.\!k-pl-thin {
  padding-left: 2px !important;
}

.k-pl-hair {
  padding-left: 1px;
}

.\!k-pl-hair {
  padding-left: 1px !important;
}

.k-px-0 {
  padding-inline: 0;
}

.\!k-px-0 {
  padding-inline: 0 !important;
}

.k-px-1px {
  padding-inline: 1px;
}

.\!k-px-1px {
  padding-inline: 1px !important;
}

.k-px-0\.5 {
  padding-inline: 2px;
}

.\!k-px-0\.5 {
  padding-inline: 2px !important;
}

.k-px-1 {
  padding-inline: 4px;
}

.\!k-px-1 {
  padding-inline: 4px !important;
}

.k-px-1\.5 {
  padding-inline: 6px;
}

.\!k-px-1\.5 {
  padding-inline: 6px !important;
}

.k-px-2 {
  padding-inline: 8px;
}

.\!k-px-2 {
  padding-inline: 8px !important;
}

.k-px-2\.5 {
  padding-inline: 10px;
}

.\!k-px-2\.5 {
  padding-inline: 10px !important;
}

.k-px-3 {
  padding-inline: 12px;
}

.\!k-px-3 {
  padding-inline: 12px !important;
}

.k-px-3\.5 {
  padding-inline: 14px;
}

.\!k-px-3\.5 {
  padding-inline: 14px !important;
}

.k-px-4 {
  padding-inline: 16px;
}

.\!k-px-4 {
  padding-inline: 16px !important;
}

.k-px-4\.5 {
  padding-inline: 18px;
}

.\!k-px-4\.5 {
  padding-inline: 18px !important;
}

.k-px-5 {
  padding-inline: 20px;
}

.\!k-px-5 {
  padding-inline: 20px !important;
}

.k-px-5\.5 {
  padding-inline: 22px;
}

.\!k-px-5\.5 {
  padding-inline: 22px !important;
}

.k-px-6 {
  padding-inline: 24px;
}

.\!k-px-6 {
  padding-inline: 24px !important;
}

.k-px-6\.5 {
  padding-inline: 26px;
}

.\!k-px-6\.5 {
  padding-inline: 26px !important;
}

.k-px-7 {
  padding-inline: 28px;
}

.\!k-px-7 {
  padding-inline: 28px !important;
}

.k-px-7\.5 {
  padding-inline: 30px;
}

.\!k-px-7\.5 {
  padding-inline: 30px !important;
}

.k-px-8 {
  padding-inline: 32px;
}

.\!k-px-8 {
  padding-inline: 32px !important;
}

.k-px-9 {
  padding-inline: 36px;
}

.\!k-px-9 {
  padding-inline: 36px !important;
}

.k-px-10 {
  padding-inline: 40px;
}

.\!k-px-10 {
  padding-inline: 40px !important;
}

.k-px-11 {
  padding-inline: 44px;
}

.\!k-px-11 {
  padding-inline: 44px !important;
}

.k-px-12 {
  padding-inline: 48px;
}

.\!k-px-12 {
  padding-inline: 48px !important;
}

.k-px-13 {
  padding-inline: 52px;
}

.\!k-px-13 {
  padding-inline: 52px !important;
}

.k-px-14 {
  padding-inline: 56px;
}

.\!k-px-14 {
  padding-inline: 56px !important;
}

.k-px-15 {
  padding-inline: 60px;
}

.\!k-px-15 {
  padding-inline: 60px !important;
}

.k-px-16 {
  padding-inline: 64px;
}

.\!k-px-16 {
  padding-inline: 64px !important;
}

.k-px-17 {
  padding-inline: 68px;
}

.\!k-px-17 {
  padding-inline: 68px !important;
}

.k-px-18 {
  padding-inline: 72px;
}

.\!k-px-18 {
  padding-inline: 72px !important;
}

.k-px-19 {
  padding-inline: 76px;
}

.\!k-px-19 {
  padding-inline: 76px !important;
}

.k-px-20 {
  padding-inline: 80px;
}

.\!k-px-20 {
  padding-inline: 80px !important;
}

.k-px-21 {
  padding-inline: 84px;
}

.\!k-px-21 {
  padding-inline: 84px !important;
}

.k-px-22 {
  padding-inline: 88px;
}

.\!k-px-22 {
  padding-inline: 88px !important;
}

.k-px-23 {
  padding-inline: 92px;
}

.\!k-px-23 {
  padding-inline: 92px !important;
}

.k-px-24 {
  padding-inline: 96px;
}

.\!k-px-24 {
  padding-inline: 96px !important;
}

.k-px-xs {
  padding-inline: 4px;
}

.\!k-px-xs {
  padding-inline: 4px !important;
}

.k-px-sm {
  padding-inline: 8px;
}

.\!k-px-sm {
  padding-inline: 8px !important;
}

.k-px-md {
  padding-inline: 12px;
}

.\!k-px-md {
  padding-inline: 12px !important;
}

.k-px-lg {
  padding-inline: 16px;
}

.\!k-px-lg {
  padding-inline: 16px !important;
}

.k-px-xl {
  padding-inline: 24px;
}

.\!k-px-xl {
  padding-inline: 24px !important;
}

.k-px-thin {
  padding-inline: 2px;
}

.\!k-px-thin {
  padding-inline: 2px !important;
}

.k-px-hair {
  padding-inline: 1px;
}

.\!k-px-hair {
  padding-inline: 1px !important;
}

.k-py-0 {
  padding-block: 0;
}

.\!k-py-0 {
  padding-block: 0 !important;
}

.k-py-1px {
  padding-block: 1px;
}

.\!k-py-1px {
  padding-block: 1px !important;
}

.k-py-0\.5 {
  padding-block: 2px;
}

.\!k-py-0\.5 {
  padding-block: 2px !important;
}

.k-py-1 {
  padding-block: 4px;
}

.\!k-py-1 {
  padding-block: 4px !important;
}

.k-py-1\.5 {
  padding-block: 6px;
}

.\!k-py-1\.5 {
  padding-block: 6px !important;
}

.k-py-2 {
  padding-block: 8px;
}

.\!k-py-2 {
  padding-block: 8px !important;
}

.k-py-2\.5 {
  padding-block: 10px;
}

.\!k-py-2\.5 {
  padding-block: 10px !important;
}

.k-py-3 {
  padding-block: 12px;
}

.\!k-py-3 {
  padding-block: 12px !important;
}

.k-py-3\.5 {
  padding-block: 14px;
}

.\!k-py-3\.5 {
  padding-block: 14px !important;
}

.k-py-4 {
  padding-block: 16px;
}

.\!k-py-4 {
  padding-block: 16px !important;
}

.k-py-4\.5 {
  padding-block: 18px;
}

.\!k-py-4\.5 {
  padding-block: 18px !important;
}

.k-py-5 {
  padding-block: 20px;
}

.\!k-py-5 {
  padding-block: 20px !important;
}

.k-py-5\.5 {
  padding-block: 22px;
}

.\!k-py-5\.5 {
  padding-block: 22px !important;
}

.k-py-6 {
  padding-block: 24px;
}

.\!k-py-6 {
  padding-block: 24px !important;
}

.k-py-6\.5 {
  padding-block: 26px;
}

.\!k-py-6\.5 {
  padding-block: 26px !important;
}

.k-py-7 {
  padding-block: 28px;
}

.\!k-py-7 {
  padding-block: 28px !important;
}

.k-py-7\.5 {
  padding-block: 30px;
}

.\!k-py-7\.5 {
  padding-block: 30px !important;
}

.k-py-8 {
  padding-block: 32px;
}

.\!k-py-8 {
  padding-block: 32px !important;
}

.k-py-9 {
  padding-block: 36px;
}

.\!k-py-9 {
  padding-block: 36px !important;
}

.k-py-10 {
  padding-block: 40px;
}

.\!k-py-10 {
  padding-block: 40px !important;
}

.k-py-11 {
  padding-block: 44px;
}

.\!k-py-11 {
  padding-block: 44px !important;
}

.k-py-12 {
  padding-block: 48px;
}

.\!k-py-12 {
  padding-block: 48px !important;
}

.k-py-13 {
  padding-block: 52px;
}

.\!k-py-13 {
  padding-block: 52px !important;
}

.k-py-14 {
  padding-block: 56px;
}

.\!k-py-14 {
  padding-block: 56px !important;
}

.k-py-15 {
  padding-block: 60px;
}

.\!k-py-15 {
  padding-block: 60px !important;
}

.k-py-16 {
  padding-block: 64px;
}

.\!k-py-16 {
  padding-block: 64px !important;
}

.k-py-17 {
  padding-block: 68px;
}

.\!k-py-17 {
  padding-block: 68px !important;
}

.k-py-18 {
  padding-block: 72px;
}

.\!k-py-18 {
  padding-block: 72px !important;
}

.k-py-19 {
  padding-block: 76px;
}

.\!k-py-19 {
  padding-block: 76px !important;
}

.k-py-20 {
  padding-block: 80px;
}

.\!k-py-20 {
  padding-block: 80px !important;
}

.k-py-21 {
  padding-block: 84px;
}

.\!k-py-21 {
  padding-block: 84px !important;
}

.k-py-22 {
  padding-block: 88px;
}

.\!k-py-22 {
  padding-block: 88px !important;
}

.k-py-23 {
  padding-block: 92px;
}

.\!k-py-23 {
  padding-block: 92px !important;
}

.k-py-24 {
  padding-block: 96px;
}

.\!k-py-24 {
  padding-block: 96px !important;
}

.k-py-xs {
  padding-block: 4px;
}

.\!k-py-xs {
  padding-block: 4px !important;
}

.k-py-sm {
  padding-block: 8px;
}

.\!k-py-sm {
  padding-block: 8px !important;
}

.k-py-md {
  padding-block: 12px;
}

.\!k-py-md {
  padding-block: 12px !important;
}

.k-py-lg {
  padding-block: 16px;
}

.\!k-py-lg {
  padding-block: 16px !important;
}

.k-py-xl {
  padding-block: 24px;
}

.\!k-py-xl {
  padding-block: 24px !important;
}

.k-py-thin {
  padding-block: 2px;
}

.\!k-py-thin {
  padding-block: 2px !important;
}

.k-py-hair {
  padding-block: 1px;
}

.\!k-py-hair {
  padding-block: 1px !important;
}

.k-h-0 {
  height: 0;
}

.\!k-h-0 {
  height: 0 !important;
}

.k-h-1px {
  height: 1px;
}

.\!k-h-1px {
  height: 1px !important;
}

.k-h-0\.5 {
  height: 2px;
}

.\!k-h-0\.5 {
  height: 2px !important;
}

.k-h-1 {
  height: 4px;
}

.\!k-h-1 {
  height: 4px !important;
}

.k-h-1\.5 {
  height: 6px;
}

.\!k-h-1\.5 {
  height: 6px !important;
}

.k-h-2 {
  height: 8px;
}

.\!k-h-2 {
  height: 8px !important;
}

.k-h-2\.5 {
  height: 10px;
}

.\!k-h-2\.5 {
  height: 10px !important;
}

.k-h-3 {
  height: 12px;
}

.\!k-h-3 {
  height: 12px !important;
}

.k-h-3\.5 {
  height: 14px;
}

.\!k-h-3\.5 {
  height: 14px !important;
}

.k-h-4 {
  height: 16px;
}

.\!k-h-4 {
  height: 16px !important;
}

.k-h-4\.5 {
  height: 18px;
}

.\!k-h-4\.5 {
  height: 18px !important;
}

.k-h-5 {
  height: 20px;
}

.\!k-h-5 {
  height: 20px !important;
}

.k-h-5\.5 {
  height: 22px;
}

.\!k-h-5\.5 {
  height: 22px !important;
}

.k-h-6 {
  height: 24px;
}

.\!k-h-6 {
  height: 24px !important;
}

.k-h-6\.5 {
  height: 26px;
}

.\!k-h-6\.5 {
  height: 26px !important;
}

.k-h-7 {
  height: 28px;
}

.\!k-h-7 {
  height: 28px !important;
}

.k-h-7\.5 {
  height: 30px;
}

.\!k-h-7\.5 {
  height: 30px !important;
}

.k-h-8 {
  height: 32px;
}

.\!k-h-8 {
  height: 32px !important;
}

.k-h-9 {
  height: 36px;
}

.\!k-h-9 {
  height: 36px !important;
}

.k-h-10 {
  height: 40px;
}

.\!k-h-10 {
  height: 40px !important;
}

.k-h-11 {
  height: 44px;
}

.\!k-h-11 {
  height: 44px !important;
}

.k-h-12 {
  height: 48px;
}

.\!k-h-12 {
  height: 48px !important;
}

.k-h-13 {
  height: 52px;
}

.\!k-h-13 {
  height: 52px !important;
}

.k-h-14 {
  height: 56px;
}

.\!k-h-14 {
  height: 56px !important;
}

.k-h-15 {
  height: 60px;
}

.\!k-h-15 {
  height: 60px !important;
}

.k-h-16 {
  height: 64px;
}

.\!k-h-16 {
  height: 64px !important;
}

.k-h-17 {
  height: 68px;
}

.\!k-h-17 {
  height: 68px !important;
}

.k-h-18 {
  height: 72px;
}

.\!k-h-18 {
  height: 72px !important;
}

.k-h-19 {
  height: 76px;
}

.\!k-h-19 {
  height: 76px !important;
}

.k-h-20 {
  height: 80px;
}

.\!k-h-20 {
  height: 80px !important;
}

.k-h-21 {
  height: 84px;
}

.\!k-h-21 {
  height: 84px !important;
}

.k-h-22 {
  height: 88px;
}

.\!k-h-22 {
  height: 88px !important;
}

.k-h-23 {
  height: 92px;
}

.\!k-h-23 {
  height: 92px !important;
}

.k-h-24 {
  height: 96px;
}

.\!k-h-24 {
  height: 96px !important;
}

.k-h-auto {
  height: auto;
}

.\!k-h-auto {
  height: auto !important;
}

.k-h-1\/2 {
  height: 50%;
}

.\!k-h-1\/2 {
  height: 50% !important;
}

.k-h-1\/3 {
  height: 33.333333%;
}

.\!k-h-1\/3 {
  height: 33.333333% !important;
}

.k-h-2\/3 {
  height: 66.666667%;
}

.\!k-h-2\/3 {
  height: 66.666667% !important;
}

.k-h-1\/4 {
  height: 25%;
}

.\!k-h-1\/4 {
  height: 25% !important;
}

.k-h-2\/4 {
  height: 50%;
}

.\!k-h-2\/4 {
  height: 50% !important;
}

.k-h-3\/4 {
  height: 75%;
}

.\!k-h-3\/4 {
  height: 75% !important;
}

.k-h-1\/5 {
  height: 20%;
}

.\!k-h-1\/5 {
  height: 20% !important;
}

.k-h-2\/5 {
  height: 40%;
}

.\!k-h-2\/5 {
  height: 40% !important;
}

.k-h-3\/5 {
  height: 60%;
}

.\!k-h-3\/5 {
  height: 60% !important;
}

.k-h-4\/5 {
  height: 80%;
}

.\!k-h-4\/5 {
  height: 80% !important;
}

.k-h-1\/6 {
  height: 16.666667%;
}

.\!k-h-1\/6 {
  height: 16.666667% !important;
}

.k-h-2\/6 {
  height: 33.333333%;
}

.\!k-h-2\/6 {
  height: 33.333333% !important;
}

.k-h-3\/6 {
  height: 50%;
}

.\!k-h-3\/6 {
  height: 50% !important;
}

.k-h-4\/6 {
  height: 66.666667%;
}

.\!k-h-4\/6 {
  height: 66.666667% !important;
}

.k-h-5\/6 {
  height: 83.333333%;
}

.\!k-h-5\/6 {
  height: 83.333333% !important;
}

.k-h-1\/12 {
  height: 8.333333%;
}

.\!k-h-1\/12 {
  height: 8.333333% !important;
}

.k-h-2\/12 {
  height: 16.666667%;
}

.\!k-h-2\/12 {
  height: 16.666667% !important;
}

.k-h-3\/12 {
  height: 25%;
}

.\!k-h-3\/12 {
  height: 25% !important;
}

.k-h-4\/12 {
  height: 33.333333%;
}

.\!k-h-4\/12 {
  height: 33.333333% !important;
}

.k-h-5\/12 {
  height: 41.666667%;
}

.\!k-h-5\/12 {
  height: 41.666667% !important;
}

.k-h-6\/12 {
  height: 50%;
}

.\!k-h-6\/12 {
  height: 50% !important;
}

.k-h-7\/12 {
  height: 58.333333%;
}

.\!k-h-7\/12 {
  height: 58.333333% !important;
}

.k-h-8\/12 {
  height: 66.666667%;
}

.\!k-h-8\/12 {
  height: 66.666667% !important;
}

.k-h-9\/12 {
  height: 75%;
}

.\!k-h-9\/12 {
  height: 75% !important;
}

.k-h-10\/12 {
  height: 83.333333%;
}

.\!k-h-10\/12 {
  height: 83.333333% !important;
}

.k-h-11\/12 {
  height: 91.666667%;
}

.\!k-h-11\/12 {
  height: 91.666667% !important;
}

.k-h-full {
  height: 100%;
}

.\!k-h-full {
  height: 100% !important;
}

.k-h-min {
  height: min-content;
}

.\!k-h-min {
  height: min-content !important;
}

.k-h-max {
  height: max-content;
}

.\!k-h-max {
  height: max-content !important;
}

.k-h-fit {
  height: fit-content;
}

.\!k-h-fit {
  height: fit-content !important;
}

.k-h-screen {
  height: 100vh;
}

.\!k-h-screen {
  height: 100vh !important;
}

.k-min-h-0 {
  min-height: 0;
}

.\!k-min-h-0 {
  min-height: 0 !important;
}

.k-min-h-full {
  min-height: 100%;
}

.\!k-min-h-full {
  min-height: 100% !important;
}

.k-min-h-screen {
  min-height: 100vh;
}

.\!k-min-h-screen {
  min-height: 100vh !important;
}

.k-min-h-min {
  min-height: min-content;
}

.\!k-min-h-min {
  min-height: min-content !important;
}

.k-min-h-max {
  min-height: max-content;
}

.\!k-min-h-max {
  min-height: max-content !important;
}

.k-min-h-fit {
  min-height: fit-content;
}

.\!k-min-h-fit {
  min-height: fit-content !important;
}

.k-max-h-none {
  max-height: none;
}

.\!k-max-h-none {
  max-height: none !important;
}

.k-max-h-0 {
  max-height: 0;
}

.\!k-max-h-0 {
  max-height: 0 !important;
}

.k-max-h-full {
  max-height: 100%;
}

.\!k-max-h-full {
  max-height: 100% !important;
}

.k-max-h-screen {
  max-height: 100vh;
}

.\!k-max-h-screen {
  max-height: 100vh !important;
}

.k-max-h-min {
  max-height: min-content;
}

.\!k-max-h-min {
  max-height: min-content !important;
}

.k-max-h-max {
  max-height: max-content;
}

.\!k-max-h-max {
  max-height: max-content !important;
}

.k-max-h-fit {
  max-height: fit-content;
}

.\!k-max-h-fit {
  max-height: fit-content !important;
}

.k-w-0 {
  width: 0;
}

.\!k-w-0 {
  width: 0 !important;
}

.k-w-1px {
  width: 1px;
}

.\!k-w-1px {
  width: 1px !important;
}

.k-w-0\.5 {
  width: 2px;
}

.\!k-w-0\.5 {
  width: 2px !important;
}

.k-w-1 {
  width: 4px;
}

.\!k-w-1 {
  width: 4px !important;
}

.k-w-1\.5 {
  width: 6px;
}

.\!k-w-1\.5 {
  width: 6px !important;
}

.k-w-2 {
  width: 8px;
}

.\!k-w-2 {
  width: 8px !important;
}

.k-w-2\.5 {
  width: 10px;
}

.\!k-w-2\.5 {
  width: 10px !important;
}

.k-w-3 {
  width: 12px;
}

.\!k-w-3 {
  width: 12px !important;
}

.k-w-3\.5 {
  width: 14px;
}

.\!k-w-3\.5 {
  width: 14px !important;
}

.k-w-4 {
  width: 16px;
}

.\!k-w-4 {
  width: 16px !important;
}

.k-w-4\.5 {
  width: 18px;
}

.\!k-w-4\.5 {
  width: 18px !important;
}

.k-w-5 {
  width: 20px;
}

.\!k-w-5 {
  width: 20px !important;
}

.k-w-5\.5 {
  width: 22px;
}

.\!k-w-5\.5 {
  width: 22px !important;
}

.k-w-6 {
  width: 24px;
}

.\!k-w-6 {
  width: 24px !important;
}

.k-w-6\.5 {
  width: 26px;
}

.\!k-w-6\.5 {
  width: 26px !important;
}

.k-w-7 {
  width: 28px;
}

.\!k-w-7 {
  width: 28px !important;
}

.k-w-7\.5 {
  width: 30px;
}

.\!k-w-7\.5 {
  width: 30px !important;
}

.k-w-8 {
  width: 32px;
}

.\!k-w-8 {
  width: 32px !important;
}

.k-w-9 {
  width: 36px;
}

.\!k-w-9 {
  width: 36px !important;
}

.k-w-10 {
  width: 40px;
}

.\!k-w-10 {
  width: 40px !important;
}

.k-w-11 {
  width: 44px;
}

.\!k-w-11 {
  width: 44px !important;
}

.k-w-12 {
  width: 48px;
}

.\!k-w-12 {
  width: 48px !important;
}

.k-w-13 {
  width: 52px;
}

.\!k-w-13 {
  width: 52px !important;
}

.k-w-14 {
  width: 56px;
}

.\!k-w-14 {
  width: 56px !important;
}

.k-w-15 {
  width: 60px;
}

.\!k-w-15 {
  width: 60px !important;
}

.k-w-16 {
  width: 64px;
}

.\!k-w-16 {
  width: 64px !important;
}

.k-w-17 {
  width: 68px;
}

.\!k-w-17 {
  width: 68px !important;
}

.k-w-18 {
  width: 72px;
}

.\!k-w-18 {
  width: 72px !important;
}

.k-w-19 {
  width: 76px;
}

.\!k-w-19 {
  width: 76px !important;
}

.k-w-20 {
  width: 80px;
}

.\!k-w-20 {
  width: 80px !important;
}

.k-w-21 {
  width: 84px;
}

.\!k-w-21 {
  width: 84px !important;
}

.k-w-22 {
  width: 88px;
}

.\!k-w-22 {
  width: 88px !important;
}

.k-w-23 {
  width: 92px;
}

.\!k-w-23 {
  width: 92px !important;
}

.k-w-24 {
  width: 96px;
}

.\!k-w-24 {
  width: 96px !important;
}

.k-w-auto {
  width: auto;
}

.\!k-w-auto {
  width: auto !important;
}

.k-w-1\/2 {
  width: 50%;
}

.\!k-w-1\/2 {
  width: 50% !important;
}

.k-w-1\/3 {
  width: 33.333333%;
}

.\!k-w-1\/3 {
  width: 33.333333% !important;
}

.k-w-2\/3 {
  width: 66.666667%;
}

.\!k-w-2\/3 {
  width: 66.666667% !important;
}

.k-w-1\/4 {
  width: 25%;
}

.\!k-w-1\/4 {
  width: 25% !important;
}

.k-w-2\/4 {
  width: 50%;
}

.\!k-w-2\/4 {
  width: 50% !important;
}

.k-w-3\/4 {
  width: 75%;
}

.\!k-w-3\/4 {
  width: 75% !important;
}

.k-w-1\/5 {
  width: 20%;
}

.\!k-w-1\/5 {
  width: 20% !important;
}

.k-w-2\/5 {
  width: 40%;
}

.\!k-w-2\/5 {
  width: 40% !important;
}

.k-w-3\/5 {
  width: 60%;
}

.\!k-w-3\/5 {
  width: 60% !important;
}

.k-w-4\/5 {
  width: 80%;
}

.\!k-w-4\/5 {
  width: 80% !important;
}

.k-w-1\/6 {
  width: 16.666667%;
}

.\!k-w-1\/6 {
  width: 16.666667% !important;
}

.k-w-2\/6 {
  width: 33.333333%;
}

.\!k-w-2\/6 {
  width: 33.333333% !important;
}

.k-w-3\/6 {
  width: 50%;
}

.\!k-w-3\/6 {
  width: 50% !important;
}

.k-w-4\/6 {
  width: 66.666667%;
}

.\!k-w-4\/6 {
  width: 66.666667% !important;
}

.k-w-5\/6 {
  width: 83.333333%;
}

.\!k-w-5\/6 {
  width: 83.333333% !important;
}

.k-w-1\/12 {
  width: 8.333333%;
}

.\!k-w-1\/12 {
  width: 8.333333% !important;
}

.k-w-2\/12 {
  width: 16.666667%;
}

.\!k-w-2\/12 {
  width: 16.666667% !important;
}

.k-w-3\/12 {
  width: 25%;
}

.\!k-w-3\/12 {
  width: 25% !important;
}

.k-w-4\/12 {
  width: 33.333333%;
}

.\!k-w-4\/12 {
  width: 33.333333% !important;
}

.k-w-5\/12 {
  width: 41.666667%;
}

.\!k-w-5\/12 {
  width: 41.666667% !important;
}

.k-w-6\/12 {
  width: 50%;
}

.\!k-w-6\/12 {
  width: 50% !important;
}

.k-w-7\/12 {
  width: 58.333333%;
}

.\!k-w-7\/12 {
  width: 58.333333% !important;
}

.k-w-8\/12 {
  width: 66.666667%;
}

.\!k-w-8\/12 {
  width: 66.666667% !important;
}

.k-w-9\/12 {
  width: 75%;
}

.\!k-w-9\/12 {
  width: 75% !important;
}

.k-w-10\/12 {
  width: 83.333333%;
}

.\!k-w-10\/12 {
  width: 83.333333% !important;
}

.k-w-11\/12 {
  width: 91.666667%;
}

.\!k-w-11\/12 {
  width: 91.666667% !important;
}

.k-w-full {
  width: 100%;
}

.\!k-w-full {
  width: 100% !important;
}

.k-w-min {
  width: min-content;
}

.\!k-w-min {
  width: min-content !important;
}

.k-w-max {
  width: max-content;
}

.\!k-w-max {
  width: max-content !important;
}

.k-w-fit {
  width: fit-content;
}

.\!k-w-fit {
  width: fit-content !important;
}

.k-w-screen {
  width: 100vw;
}

.\!k-w-screen {
  width: 100vw !important;
}

.k-min-w-0 {
  min-width: 0;
}

.\!k-min-w-0 {
  min-width: 0 !important;
}

.k-min-w-full {
  min-width: 100%;
}

.\!k-min-w-full {
  min-width: 100% !important;
}

.k-min-w-screen {
  min-width: 100vw;
}

.\!k-min-w-screen {
  min-width: 100vw !important;
}

.k-min-w-min {
  min-width: min-content;
}

.\!k-min-w-min {
  min-width: min-content !important;
}

.k-min-w-max {
  min-width: max-content;
}

.\!k-min-w-max {
  min-width: max-content !important;
}

.k-min-w-fit {
  min-width: fit-content;
}

.\!k-min-w-fit {
  min-width: fit-content !important;
}

.k-max-w-none {
  max-width: none;
}

.\!k-max-w-none {
  max-width: none !important;
}

.k-max-w-0 {
  max-width: 0;
}

.\!k-max-w-0 {
  max-width: 0 !important;
}

.k-max-w-full {
  max-width: 100%;
}

.\!k-max-w-full {
  max-width: 100% !important;
}

.k-max-w-screen {
  max-width: 100vw;
}

.\!k-max-w-screen {
  max-width: 100vw !important;
}

.k-max-w-min {
  max-width: min-content;
}

.\!k-max-w-min {
  max-width: min-content !important;
}

.k-max-w-max {
  max-width: max-content;
}

.\!k-max-w-max {
  max-width: max-content !important;
}

.k-max-w-fit {
  max-width: fit-content;
}

.\!k-max-w-fit {
  max-width: fit-content !important;
}

.k-font-size-xs {
  font-size: 10px;
}

.\!k-font-size-xs {
  font-size: 10px !important;
}

.k-font-size-sm {
  font-size: 12px;
}

.\!k-font-size-sm {
  font-size: 12px !important;
}

.k-font-size-md {
  font-size: 14px;
}

.\!k-font-size-md {
  font-size: 14px !important;
}

.k-font-size-lg {
  font-size: 16px;
}

.\!k-font-size-lg {
  font-size: 16px !important;
}

.k-font-size-xl {
  font-size: 20px;
}

.\!k-font-size-xl {
  font-size: 20px !important;
}

.k-fs-xs {
  font-size: 10px;
}

.\!k-fs-xs {
  font-size: 10px !important;
}

.k-fs-sm {
  font-size: 12px;
}

.\!k-fs-sm {
  font-size: 12px !important;
}

.k-fs-md {
  font-size: 14px;
}

.\!k-fs-md {
  font-size: 14px !important;
}

.k-fs-lg {
  font-size: 16px;
}

.\!k-fs-lg {
  font-size: 16px !important;
}

.k-fs-xl {
  font-size: 20px;
}

.\!k-fs-xl {
  font-size: 20px !important;
}

.k-font-italic {
  font-style: italic;
}

.\!k-font-italic {
  font-style: italic !important;
}

.k-font-non-italic {
  font-style: normal;
}

.\!k-font-non-italic {
  font-style: normal !important;
}

.k-font-thin {
  font-weight: 100;
}

.\!k-font-thin {
  font-weight: 100 !important;
}

.k-font-extralight {
  font-weight: 200;
}

.\!k-font-extralight {
  font-weight: 200 !important;
}

.k-font-light {
  font-weight: 300;
}

.\!k-font-light {
  font-weight: 300 !important;
}

.k-font-normal {
  font-weight: 400;
}

.\!k-font-normal {
  font-weight: 400 !important;
}

.k-font-medium {
  font-weight: 500;
}

.\!k-font-medium {
  font-weight: 500 !important;
}

.k-font-semibold {
  font-weight: 600;
}

.\!k-font-semibold {
  font-weight: 600 !important;
}

.k-font-bold {
  font-weight: 700;
}

.\!k-font-bold {
  font-weight: 700 !important;
}

.k-font-extrabold {
  font-weight: 800;
}

.\!k-font-extrabold {
  font-weight: 800 !important;
}

.k-font-black {
  font-weight: 900;
}

.\!k-font-black {
  font-weight: 900 !important;
}

.k-font-weight-thin {
  font-weight: 100;
}

.\!k-font-weight-thin {
  font-weight: 100 !important;
}

.k-font-weight-extralight {
  font-weight: 200;
}

.\!k-font-weight-extralight {
  font-weight: 200 !important;
}

.k-font-weight-light {
  font-weight: 300;
}

.\!k-font-weight-light {
  font-weight: 300 !important;
}

.k-font-weight-normal {
  font-weight: 400;
}

.\!k-font-weight-normal {
  font-weight: 400 !important;
}

.k-font-weight-medium {
  font-weight: 500;
}

.\!k-font-weight-medium {
  font-weight: 500 !important;
}

.k-font-weight-semibold {
  font-weight: 600;
}

.\!k-font-weight-semibold {
  font-weight: 600 !important;
}

.k-font-weight-bold {
  font-weight: 700;
}

.\!k-font-weight-bold {
  font-weight: 700 !important;
}

.k-font-weight-extrabold {
  font-weight: 800;
}

.\!k-font-weight-extrabold {
  font-weight: 800 !important;
}

.k-font-weight-black {
  font-weight: 900;
}

.\!k-font-weight-black {
  font-weight: 900 !important;
}

.k-list-none {
  list-style-type: none;
}

.\!k-list-none {
  list-style-type: none !important;
}

.k-list-disc {
  list-style-type: disc;
}

.\!k-list-disc {
  list-style-type: disc !important;
}

.k-list-decimal {
  list-style-type: decimal;
}

.\!k-list-decimal {
  list-style-type: decimal !important;
}

.k-text-left {
  text-align: left;
}

.\!k-text-left {
  text-align: left !important;
}

.k-text-right {
  text-align: right;
}

.\!k-text-right {
  text-align: right !important;
}

.k-text-center {
  text-align: center;
}

.\!k-text-center {
  text-align: center !important;
}

.k-text-justify {
  text-align: justify;
}

.\!k-text-justify {
  text-align: justify !important;
}

.k-text-start {
  text-align: start;
}

.\!k-text-start {
  text-align: start !important;
}

.k-text-end {
  text-align: end;
}

.\!k-text-end {
  text-align: end !important;
}

.k-text-primary {
  color: #ff6358;
}

.\!k-text-primary {
  color: #ff6358 !important;
}

.k-text-secondary {
  color: #666666;
}

.\!k-text-secondary {
  color: #666666 !important;
}

.k-text-tertiary {
  color: #03a9f4;
}

.\!k-text-tertiary {
  color: #03a9f4 !important;
}

.k-text-info {
  color: #0058e9;
}

.\!k-text-info {
  color: #0058e9 !important;
}

.k-text-success {
  color: #37b400;
}

.\!k-text-success {
  color: #37b400 !important;
}

.k-text-warning {
  color: #ffc000;
}

.\!k-text-warning {
  color: #ffc000 !important;
}

.k-text-error {
  color: #f31700;
}

.\!k-text-error {
  color: #f31700 !important;
}

.k-text-dark {
  color: #424242;
}

.\!k-text-dark {
  color: #424242 !important;
}

.k-text-light {
  color: #ebebeb;
}

.\!k-text-light {
  color: #ebebeb !important;
}

.k-text-inverse {
  color: #424242;
}

.\!k-text-inverse {
  color: #424242 !important;
}

.k-text-inherit {
  color: inherit;
}

.\!k-text-inherit {
  color: inherit !important;
}

.k-text-current {
  color: currentColor;
}

.\!k-text-current {
  color: currentColor !important;
}

.k-text-transparent {
  color: transparent;
}

.\!k-text-transparent {
  color: transparent !important;
}

.k-text-black {
  color: black;
}

.\!k-text-black {
  color: black !important;
}

.k-text-white {
  color: white;
}

.\!k-text-white {
  color: white !important;
}

.k-color-primary {
  color: #ff6358;
}

.\!k-color-primary {
  color: #ff6358 !important;
}

.k-color-secondary {
  color: #666666;
}

.\!k-color-secondary {
  color: #666666 !important;
}

.k-color-tertiary {
  color: #03a9f4;
}

.\!k-color-tertiary {
  color: #03a9f4 !important;
}

.k-color-info {
  color: #0058e9;
}

.\!k-color-info {
  color: #0058e9 !important;
}

.k-color-success {
  color: #37b400;
}

.\!k-color-success {
  color: #37b400 !important;
}

.k-color-warning {
  color: #ffc000;
}

.\!k-color-warning {
  color: #ffc000 !important;
}

.k-color-error {
  color: #f31700;
}

.\!k-color-error {
  color: #f31700 !important;
}

.k-color-dark {
  color: #424242;
}

.\!k-color-dark {
  color: #424242 !important;
}

.k-color-light {
  color: #ebebeb;
}

.\!k-color-light {
  color: #ebebeb !important;
}

.k-color-inverse {
  color: #424242;
}

.\!k-color-inverse {
  color: #424242 !important;
}

.k-color-inherit {
  color: inherit;
}

.\!k-color-inherit {
  color: inherit !important;
}

.k-color-current {
  color: currentColor;
}

.\!k-color-current {
  color: currentColor !important;
}

.k-color-transparent {
  color: transparent;
}

.\!k-color-transparent {
  color: transparent !important;
}

.k-color-black {
  color: black;
}

.\!k-color-black {
  color: black !important;
}

.k-color-white {
  color: white;
}

.\!k-color-white {
  color: white !important;
}

.k-text-underline {
  text-decoration: underline;
}

.\!k-text-underline {
  text-decoration: underline !important;
}

.k-text-overline {
  text-decoration: overline;
}

.\!k-text-overline {
  text-decoration: overline !important;
}

.k-text-line-through {
  text-decoration: line-through;
}

.\!k-text-line-through {
  text-decoration: line-through !important;
}

.k-text-no-underline {
  text-decoration: none;
}

.\!k-text-no-underline {
  text-decoration: none !important;
}

.k-text-clip {
  text-overflow: clip;
}

.\!k-text-clip {
  text-overflow: clip !important;
}

.k-text-ellipsis {
  text-overflow: ellipsis;
}

.\!k-text-ellipsis {
  text-overflow: ellipsis !important;
}

.k-text-truncate, .k-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.\!k-text-truncate, .\!k-text-ellipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.k-text-uppercase {
  text-transform: uppercase;
}

.\!k-text-uppercase {
  text-transform: uppercase !important;
}

.k-text-lowercase {
  text-transform: lowercase;
}

.\!k-text-lowercase {
  text-transform: lowercase !important;
}

.k-text-capitalize {
  text-transform: capitalize;
}

.\!k-text-capitalize {
  text-transform: capitalize !important;
}

.k-text-normal-case {
  text-transform: none;
}

.\!k-text-normal-case {
  text-transform: none !important;
}

.k-align-baseline {
  vertical-align: baseline;
}

.\!k-align-baseline {
  vertical-align: baseline !important;
}

.k-align-top {
  vertical-align: top;
}

.\!k-align-top {
  vertical-align: top !important;
}

.k-align-middle {
  vertical-align: middle;
}

.\!k-align-middle {
  vertical-align: middle !important;
}

.k-align-bottom {
  vertical-align: bottom;
}

.\!k-align-bottom {
  vertical-align: bottom !important;
}

.k-align-text-top {
  vertical-align: text-top;
}

.\!k-align-text-top {
  vertical-align: text-top !important;
}

.k-align-text-bottom {
  vertical-align: text-bottom;
}

.\!k-align-text-bottom {
  vertical-align: text-bottom !important;
}

.k-align-sub {
  vertical-align: sub;
}

.\!k-align-sub {
  vertical-align: sub !important;
}

.k-align-super {
  vertical-align: super;
}

.\!k-align-super {
  vertical-align: super !important;
}

.k-white-space-normal {
  white-space: normal;
}

.\!k-white-space-normal {
  white-space: normal !important;
}

.k-white-space-nowrap, .k-text-nowrap {
  white-space: nowrap;
}

.\!k-white-space-nowrap {
  white-space: nowrap !important;
}

.k-white-space-pre {
  white-space: pre;
}

.\!k-white-space-pre {
  white-space: pre !important;
}

.k-white-space-pre-line {
  white-space: pre-line;
}

.\!k-white-space-pre-line {
  white-space: pre-line !important;
}

.k-white-space-pre-wrap {
  white-space: pre-wrap;
}

.\!k-white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.k-white-space-break-spaces {
  white-space: break-spaces;
}

.\!k-white-space-break-spaces {
  white-space: break-spaces !important;
}

.k-whitespace-normal {
  white-space: normal;
}

.\!k-whitespace-normal {
  white-space: normal !important;
}

.k-whitespace-nowrap {
  white-space: nowrap;
}

.\!k-whitespace-nowrap {
  white-space: nowrap !important;
}

.k-whitespace-pre {
  white-space: pre;
}

.\!k-whitespace-pre {
  white-space: pre !important;
}

.k-whitespace-pre-line {
  white-space: pre-line;
}

.\!k-whitespace-pre-line {
  white-space: pre-line !important;
}

.k-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.\!k-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.k-whitespace-break-spaces {
  white-space: break-spaces;
}

.\!k-whitespace-break-spaces {
  white-space: break-spaces !important;
}

.k-bg-clip-border {
  background-clip: border-box;
}

.\!k-bg-clip-border {
  background-clip: border-box !important;
}

.k-bg-clip-padding {
  background-clip: padding-box;
}

.\!k-bg-clip-padding {
  background-clip: padding-box !important;
}

.k-bg-clip-content {
  background-clip: content-box;
}

.\!k-bg-clip-content {
  background-clip: content-box !important;
}

.k-bg-clip-text {
  background-clip: text;
}

.\!k-bg-clip-text {
  background-clip: text !important;
}

.k-bg-primary {
  background-color: #ff6358;
}

.\!k-bg-primary {
  background-color: #ff6358 !important;
}

.k-bg-secondary {
  background-color: #666666;
}

.\!k-bg-secondary {
  background-color: #666666 !important;
}

.k-bg-tertiary {
  background-color: #03a9f4;
}

.\!k-bg-tertiary {
  background-color: #03a9f4 !important;
}

.k-bg-info {
  background-color: #0058e9;
}

.\!k-bg-info {
  background-color: #0058e9 !important;
}

.k-bg-success {
  background-color: #37b400;
}

.\!k-bg-success {
  background-color: #37b400 !important;
}

.k-bg-warning {
  background-color: #ffc000;
}

.\!k-bg-warning {
  background-color: #ffc000 !important;
}

.k-bg-error {
  background-color: #f31700;
}

.\!k-bg-error {
  background-color: #f31700 !important;
}

.k-bg-dark {
  background-color: #424242;
}

.\!k-bg-dark {
  background-color: #424242 !important;
}

.k-bg-light {
  background-color: #ebebeb;
}

.\!k-bg-light {
  background-color: #ebebeb !important;
}

.k-bg-inverse {
  background-color: #424242;
}

.\!k-bg-inverse {
  background-color: #424242 !important;
}

.k-bg-inherit {
  background-color: inherit;
}

.\!k-bg-inherit {
  background-color: inherit !important;
}

.k-bg-transparent {
  background-color: transparent;
}

.\!k-bg-transparent {
  background-color: transparent !important;
}

.k-bg-black {
  background-color: black;
}

.\!k-bg-black {
  background-color: black !important;
}

.k-bg-white {
  background-color: white;
}

.\!k-bg-white {
  background-color: white !important;
}

.k-bg-center {
  background-position: center;
}

.\!k-bg-center {
  background-position: center !important;
}

.k-bg-top {
  background-position: top;
}

.\!k-bg-top {
  background-position: top !important;
}

.k-bg-right {
  background-position: right;
}

.\!k-bg-right {
  background-position: right !important;
}

.k-bg-bottom {
  background-position: bottom;
}

.\!k-bg-bottom {
  background-position: bottom !important;
}

.k-bg-left {
  background-position: left;
}

.\!k-bg-left {
  background-position: left !important;
}

.k-bg-top-left {
  background-position: top left;
}

.\!k-bg-top-left {
  background-position: top left !important;
}

.k-bg-top-right {
  background-position: top right;
}

.\!k-bg-top-right {
  background-position: top right !important;
}

.k-bg-bottom-left {
  background-position: bottom left;
}

.\!k-bg-bottom-left {
  background-position: bottom left !important;
}

.k-bg-bottom-right {
  background-position: bottom right;
}

.\!k-bg-bottom-right {
  background-position: bottom right !important;
}

.k-bg-auto {
  background-size: auto;
}

.\!k-bg-auto {
  background-size: auto !important;
}

.k-bg-cover {
  background-size: cover;
}

.\!k-bg-cover {
  background-size: cover !important;
}

.k-bg-contain {
  background-size: contain;
}

.\!k-bg-contain {
  background-size: contain !important;
}

.k-border-primary {
  border-color: #ff6358;
}

.\!k-border-primary {
  border-color: #ff6358 !important;
}

.k-border-secondary {
  border-color: #666666;
}

.\!k-border-secondary {
  border-color: #666666 !important;
}

.k-border-tertiary {
  border-color: #03a9f4;
}

.\!k-border-tertiary {
  border-color: #03a9f4 !important;
}

.k-border-info {
  border-color: #0058e9;
}

.\!k-border-info {
  border-color: #0058e9 !important;
}

.k-border-success {
  border-color: #37b400;
}

.\!k-border-success {
  border-color: #37b400 !important;
}

.k-border-warning {
  border-color: #ffc000;
}

.\!k-border-warning {
  border-color: #ffc000 !important;
}

.k-border-error {
  border-color: #f31700;
}

.\!k-border-error {
  border-color: #f31700 !important;
}

.k-border-dark {
  border-color: #424242;
}

.\!k-border-dark {
  border-color: #424242 !important;
}

.k-border-light {
  border-color: #ebebeb;
}

.\!k-border-light {
  border-color: #ebebeb !important;
}

.k-border-inverse {
  border-color: #424242;
}

.\!k-border-inverse {
  border-color: #424242 !important;
}

.k-border-inherit {
  border-color: inherit;
}

.\!k-border-inherit {
  border-color: inherit !important;
}

.k-border-current {
  border-color: currentColor;
}

.\!k-border-current {
  border-color: currentColor !important;
}

.k-border-transparent {
  border-color: transparent;
}

.\!k-border-transparent {
  border-color: transparent !important;
}

.k-border-black {
  border-color: black;
}

.\!k-border-black {
  border-color: black !important;
}

.k-border-white {
  border-color: white;
}

.\!k-border-white {
  border-color: white !important;
}

.k-border-t-primary {
  border-top-color: #ff6358;
}

.\!k-border-t-primary {
  border-top-color: #ff6358 !important;
}

.k-border-t-secondary {
  border-top-color: #666666;
}

.\!k-border-t-secondary {
  border-top-color: #666666 !important;
}

.k-border-t-tertiary {
  border-top-color: #03a9f4;
}

.\!k-border-t-tertiary {
  border-top-color: #03a9f4 !important;
}

.k-border-t-info {
  border-top-color: #0058e9;
}

.\!k-border-t-info {
  border-top-color: #0058e9 !important;
}

.k-border-t-success {
  border-top-color: #37b400;
}

.\!k-border-t-success {
  border-top-color: #37b400 !important;
}

.k-border-t-warning {
  border-top-color: #ffc000;
}

.\!k-border-t-warning {
  border-top-color: #ffc000 !important;
}

.k-border-t-error {
  border-top-color: #f31700;
}

.\!k-border-t-error {
  border-top-color: #f31700 !important;
}

.k-border-t-dark {
  border-top-color: #424242;
}

.\!k-border-t-dark {
  border-top-color: #424242 !important;
}

.k-border-t-light {
  border-top-color: #ebebeb;
}

.\!k-border-t-light {
  border-top-color: #ebebeb !important;
}

.k-border-t-inverse {
  border-top-color: #424242;
}

.\!k-border-t-inverse {
  border-top-color: #424242 !important;
}

.k-border-t-inherit {
  border-top-color: inherit;
}

.\!k-border-t-inherit {
  border-top-color: inherit !important;
}

.k-border-t-current {
  border-top-color: currentColor;
}

.\!k-border-t-current {
  border-top-color: currentColor !important;
}

.k-border-t-transparent {
  border-top-color: transparent;
}

.\!k-border-t-transparent {
  border-top-color: transparent !important;
}

.k-border-t-black {
  border-top-color: black;
}

.\!k-border-t-black {
  border-top-color: black !important;
}

.k-border-t-white {
  border-top-color: white;
}

.\!k-border-t-white {
  border-top-color: white !important;
}

.k-border-r-primary {
  border-right-color: #ff6358;
}

.\!k-border-r-primary {
  border-right-color: #ff6358 !important;
}

.k-border-r-secondary {
  border-right-color: #666666;
}

.\!k-border-r-secondary {
  border-right-color: #666666 !important;
}

.k-border-r-tertiary {
  border-right-color: #03a9f4;
}

.\!k-border-r-tertiary {
  border-right-color: #03a9f4 !important;
}

.k-border-r-info {
  border-right-color: #0058e9;
}

.\!k-border-r-info {
  border-right-color: #0058e9 !important;
}

.k-border-r-success {
  border-right-color: #37b400;
}

.\!k-border-r-success {
  border-right-color: #37b400 !important;
}

.k-border-r-warning {
  border-right-color: #ffc000;
}

.\!k-border-r-warning {
  border-right-color: #ffc000 !important;
}

.k-border-r-error {
  border-right-color: #f31700;
}

.\!k-border-r-error {
  border-right-color: #f31700 !important;
}

.k-border-r-dark {
  border-right-color: #424242;
}

.\!k-border-r-dark {
  border-right-color: #424242 !important;
}

.k-border-r-light {
  border-right-color: #ebebeb;
}

.\!k-border-r-light {
  border-right-color: #ebebeb !important;
}

.k-border-r-inverse {
  border-right-color: #424242;
}

.\!k-border-r-inverse {
  border-right-color: #424242 !important;
}

.k-border-r-inherit {
  border-right-color: inherit;
}

.\!k-border-r-inherit {
  border-right-color: inherit !important;
}

.k-border-r-current {
  border-right-color: currentColor;
}

.\!k-border-r-current {
  border-right-color: currentColor !important;
}

.k-border-r-transparent {
  border-right-color: transparent;
}

.\!k-border-r-transparent {
  border-right-color: transparent !important;
}

.k-border-r-black {
  border-right-color: black;
}

.\!k-border-r-black {
  border-right-color: black !important;
}

.k-border-r-white {
  border-right-color: white;
}

.\!k-border-r-white {
  border-right-color: white !important;
}

.k-border-b-primary {
  border-bottom-color: #ff6358;
}

.\!k-border-b-primary {
  border-bottom-color: #ff6358 !important;
}

.k-border-b-secondary {
  border-bottom-color: #666666;
}

.\!k-border-b-secondary {
  border-bottom-color: #666666 !important;
}

.k-border-b-tertiary {
  border-bottom-color: #03a9f4;
}

.\!k-border-b-tertiary {
  border-bottom-color: #03a9f4 !important;
}

.k-border-b-info {
  border-bottom-color: #0058e9;
}

.\!k-border-b-info {
  border-bottom-color: #0058e9 !important;
}

.k-border-b-success {
  border-bottom-color: #37b400;
}

.\!k-border-b-success {
  border-bottom-color: #37b400 !important;
}

.k-border-b-warning {
  border-bottom-color: #ffc000;
}

.\!k-border-b-warning {
  border-bottom-color: #ffc000 !important;
}

.k-border-b-error {
  border-bottom-color: #f31700;
}

.\!k-border-b-error {
  border-bottom-color: #f31700 !important;
}

.k-border-b-dark {
  border-bottom-color: #424242;
}

.\!k-border-b-dark {
  border-bottom-color: #424242 !important;
}

.k-border-b-light {
  border-bottom-color: #ebebeb;
}

.\!k-border-b-light {
  border-bottom-color: #ebebeb !important;
}

.k-border-b-inverse {
  border-bottom-color: #424242;
}

.\!k-border-b-inverse {
  border-bottom-color: #424242 !important;
}

.k-border-b-inherit {
  border-bottom-color: inherit;
}

.\!k-border-b-inherit {
  border-bottom-color: inherit !important;
}

.k-border-b-current {
  border-bottom-color: currentColor;
}

.\!k-border-b-current {
  border-bottom-color: currentColor !important;
}

.k-border-b-transparent {
  border-bottom-color: transparent;
}

.\!k-border-b-transparent {
  border-bottom-color: transparent !important;
}

.k-border-b-black {
  border-bottom-color: black;
}

.\!k-border-b-black {
  border-bottom-color: black !important;
}

.k-border-b-white {
  border-bottom-color: white;
}

.\!k-border-b-white {
  border-bottom-color: white !important;
}

.k-border-l-primary {
  border-left-color: #ff6358;
}

.\!k-border-l-primary {
  border-left-color: #ff6358 !important;
}

.k-border-l-secondary {
  border-left-color: #666666;
}

.\!k-border-l-secondary {
  border-left-color: #666666 !important;
}

.k-border-l-tertiary {
  border-left-color: #03a9f4;
}

.\!k-border-l-tertiary {
  border-left-color: #03a9f4 !important;
}

.k-border-l-info {
  border-left-color: #0058e9;
}

.\!k-border-l-info {
  border-left-color: #0058e9 !important;
}

.k-border-l-success {
  border-left-color: #37b400;
}

.\!k-border-l-success {
  border-left-color: #37b400 !important;
}

.k-border-l-warning {
  border-left-color: #ffc000;
}

.\!k-border-l-warning {
  border-left-color: #ffc000 !important;
}

.k-border-l-error {
  border-left-color: #f31700;
}

.\!k-border-l-error {
  border-left-color: #f31700 !important;
}

.k-border-l-dark {
  border-left-color: #424242;
}

.\!k-border-l-dark {
  border-left-color: #424242 !important;
}

.k-border-l-light {
  border-left-color: #ebebeb;
}

.\!k-border-l-light {
  border-left-color: #ebebeb !important;
}

.k-border-l-inverse {
  border-left-color: #424242;
}

.\!k-border-l-inverse {
  border-left-color: #424242 !important;
}

.k-border-l-inherit {
  border-left-color: inherit;
}

.\!k-border-l-inherit {
  border-left-color: inherit !important;
}

.k-border-l-current {
  border-left-color: currentColor;
}

.\!k-border-l-current {
  border-left-color: currentColor !important;
}

.k-border-l-transparent {
  border-left-color: transparent;
}

.\!k-border-l-transparent {
  border-left-color: transparent !important;
}

.k-border-l-black {
  border-left-color: black;
}

.\!k-border-l-black {
  border-left-color: black !important;
}

.k-border-l-white {
  border-left-color: white;
}

.\!k-border-l-white {
  border-left-color: white !important;
}

.k-border-x-primary {
  border-inline-color: #ff6358;
}

.\!k-border-x-primary {
  border-inline-color: #ff6358 !important;
}

.k-border-x-secondary {
  border-inline-color: #666666;
}

.\!k-border-x-secondary {
  border-inline-color: #666666 !important;
}

.k-border-x-tertiary {
  border-inline-color: #03a9f4;
}

.\!k-border-x-tertiary {
  border-inline-color: #03a9f4 !important;
}

.k-border-x-info {
  border-inline-color: #0058e9;
}

.\!k-border-x-info {
  border-inline-color: #0058e9 !important;
}

.k-border-x-success {
  border-inline-color: #37b400;
}

.\!k-border-x-success {
  border-inline-color: #37b400 !important;
}

.k-border-x-warning {
  border-inline-color: #ffc000;
}

.\!k-border-x-warning {
  border-inline-color: #ffc000 !important;
}

.k-border-x-error {
  border-inline-color: #f31700;
}

.\!k-border-x-error {
  border-inline-color: #f31700 !important;
}

.k-border-x-dark {
  border-inline-color: #424242;
}

.\!k-border-x-dark {
  border-inline-color: #424242 !important;
}

.k-border-x-light {
  border-inline-color: #ebebeb;
}

.\!k-border-x-light {
  border-inline-color: #ebebeb !important;
}

.k-border-x-inverse {
  border-inline-color: #424242;
}

.\!k-border-x-inverse {
  border-inline-color: #424242 !important;
}

.k-border-x-inherit {
  border-inline-color: inherit;
}

.\!k-border-x-inherit {
  border-inline-color: inherit !important;
}

.k-border-x-current {
  border-inline-color: currentColor;
}

.\!k-border-x-current {
  border-inline-color: currentColor !important;
}

.k-border-x-transparent {
  border-inline-color: transparent;
}

.\!k-border-x-transparent {
  border-inline-color: transparent !important;
}

.k-border-x-black {
  border-inline-color: black;
}

.\!k-border-x-black {
  border-inline-color: black !important;
}

.k-border-x-white {
  border-inline-color: white;
}

.\!k-border-x-white {
  border-inline-color: white !important;
}

.k-border-y-primary {
  border-block-color: #ff6358;
}

.\!k-border-y-primary {
  border-block-color: #ff6358 !important;
}

.k-border-y-secondary {
  border-block-color: #666666;
}

.\!k-border-y-secondary {
  border-block-color: #666666 !important;
}

.k-border-y-tertiary {
  border-block-color: #03a9f4;
}

.\!k-border-y-tertiary {
  border-block-color: #03a9f4 !important;
}

.k-border-y-info {
  border-block-color: #0058e9;
}

.\!k-border-y-info {
  border-block-color: #0058e9 !important;
}

.k-border-y-success {
  border-block-color: #37b400;
}

.\!k-border-y-success {
  border-block-color: #37b400 !important;
}

.k-border-y-warning {
  border-block-color: #ffc000;
}

.\!k-border-y-warning {
  border-block-color: #ffc000 !important;
}

.k-border-y-error {
  border-block-color: #f31700;
}

.\!k-border-y-error {
  border-block-color: #f31700 !important;
}

.k-border-y-dark {
  border-block-color: #424242;
}

.\!k-border-y-dark {
  border-block-color: #424242 !important;
}

.k-border-y-light {
  border-block-color: #ebebeb;
}

.\!k-border-y-light {
  border-block-color: #ebebeb !important;
}

.k-border-y-inverse {
  border-block-color: #424242;
}

.\!k-border-y-inverse {
  border-block-color: #424242 !important;
}

.k-border-y-inherit {
  border-block-color: inherit;
}

.\!k-border-y-inherit {
  border-block-color: inherit !important;
}

.k-border-y-current {
  border-block-color: currentColor;
}

.\!k-border-y-current {
  border-block-color: currentColor !important;
}

.k-border-y-transparent {
  border-block-color: transparent;
}

.\!k-border-y-transparent {
  border-block-color: transparent !important;
}

.k-border-y-black {
  border-block-color: black;
}

.\!k-border-y-black {
  border-block-color: black !important;
}

.k-border-y-white {
  border-block-color: white;
}

.\!k-border-y-white {
  border-block-color: white !important;
}

.k-rounded {
  border-radius: 4px;
}

.\!k-rounded {
  border-radius: 4px !important;
}

.k-rounded-0 {
  border-radius: 0;
}

.\!k-rounded-0 {
  border-radius: 0 !important;
}

.k-rounded-sm {
  border-radius: 2px;
}

.\!k-rounded-sm {
  border-radius: 2px !important;
}

.k-rounded-md {
  border-radius: 4px;
}

.\!k-rounded-md {
  border-radius: 4px !important;
}

.k-rounded-lg {
  border-radius: 6px;
}

.\!k-rounded-lg {
  border-radius: 6px !important;
}

.k-rounded-full {
  border-radius: 9999px;
}

.\!k-rounded-full {
  border-radius: 9999px !important;
}

.k-rounded-tl {
  border-top-left-radius: 4px;
}

.\!k-rounded-tl {
  border-top-left-radius: 4px !important;
}

.k-rounded-tl-0 {
  border-top-left-radius: 0;
}

.\!k-rounded-tl-0 {
  border-top-left-radius: 0 !important;
}

.k-rounded-tl-sm {
  border-top-left-radius: 2px;
}

.\!k-rounded-tl-sm {
  border-top-left-radius: 2px !important;
}

.k-rounded-tl-md {
  border-top-left-radius: 4px;
}

.\!k-rounded-tl-md {
  border-top-left-radius: 4px !important;
}

.k-rounded-tl-lg {
  border-top-left-radius: 6px;
}

.\!k-rounded-tl-lg {
  border-top-left-radius: 6px !important;
}

.k-rounded-tl-full {
  border-top-left-radius: 9999px;
}

.\!k-rounded-tl-full {
  border-top-left-radius: 9999px !important;
}

.k-rounded-tr {
  border-top-right-radius: 4px;
}

.\!k-rounded-tr {
  border-top-right-radius: 4px !important;
}

.k-rounded-tr-0 {
  border-top-right-radius: 0;
}

.\!k-rounded-tr-0 {
  border-top-right-radius: 0 !important;
}

.k-rounded-tr-sm {
  border-top-right-radius: 2px;
}

.\!k-rounded-tr-sm {
  border-top-right-radius: 2px !important;
}

.k-rounded-tr-md {
  border-top-right-radius: 4px;
}

.\!k-rounded-tr-md {
  border-top-right-radius: 4px !important;
}

.k-rounded-tr-lg {
  border-top-right-radius: 6px;
}

.\!k-rounded-tr-lg {
  border-top-right-radius: 6px !important;
}

.k-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.\!k-rounded-tr-full {
  border-top-right-radius: 9999px !important;
}

.k-rounded-br {
  border-bottom-right-radius: 4px;
}

.\!k-rounded-br {
  border-bottom-right-radius: 4px !important;
}

.k-rounded-br-0 {
  border-bottom-right-radius: 0;
}

.\!k-rounded-br-0 {
  border-bottom-right-radius: 0 !important;
}

.k-rounded-br-sm {
  border-bottom-right-radius: 2px;
}

.\!k-rounded-br-sm {
  border-bottom-right-radius: 2px !important;
}

.k-rounded-br-md {
  border-bottom-right-radius: 4px;
}

.\!k-rounded-br-md {
  border-bottom-right-radius: 4px !important;
}

.k-rounded-br-lg {
  border-bottom-right-radius: 6px;
}

.\!k-rounded-br-lg {
  border-bottom-right-radius: 6px !important;
}

.k-rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.\!k-rounded-br-full {
  border-bottom-right-radius: 9999px !important;
}

.k-rounded-bl {
  border-bottom-left-radius: 4px;
}

.\!k-rounded-bl {
  border-bottom-left-radius: 4px !important;
}

.k-rounded-bl-0 {
  border-bottom-left-radius: 0;
}

.\!k-rounded-bl-0 {
  border-bottom-left-radius: 0 !important;
}

.k-rounded-bl-sm {
  border-bottom-left-radius: 2px;
}

.\!k-rounded-bl-sm {
  border-bottom-left-radius: 2px !important;
}

.k-rounded-bl-md {
  border-bottom-left-radius: 4px;
}

.\!k-rounded-bl-md {
  border-bottom-left-radius: 4px !important;
}

.k-rounded-bl-lg {
  border-bottom-left-radius: 6px;
}

.\!k-rounded-bl-lg {
  border-bottom-left-radius: 6px !important;
}

.k-rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.\!k-rounded-bl-full {
  border-bottom-left-radius: 9999px !important;
}

.k-rounded-t {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.\!k-rounded-t {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.k-rounded-t-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.\!k-rounded-t-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.k-rounded-t-sm {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.\!k-rounded-t-sm {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}

.k-rounded-t-md {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.\!k-rounded-t-md {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.k-rounded-t-lg {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.\!k-rounded-t-lg {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.k-rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.\!k-rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.k-rounded-r {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.\!k-rounded-r {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.k-rounded-r-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.\!k-rounded-r-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.k-rounded-r-sm {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.\!k-rounded-r-sm {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.k-rounded-r-md {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.\!k-rounded-r-md {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.k-rounded-r-lg {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.\!k-rounded-r-lg {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.k-rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.\!k-rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.k-rounded-b {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.\!k-rounded-b {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.k-rounded-b-0 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.\!k-rounded-b-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.k-rounded-b-sm {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.\!k-rounded-b-sm {
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.k-rounded-b-md {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.\!k-rounded-b-md {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.k-rounded-b-lg {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.\!k-rounded-b-lg {
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.k-rounded-b-full {
  border-bottom-left-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.\!k-rounded-b-full {
  border-bottom-left-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.k-rounded-l {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.\!k-rounded-l {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.k-rounded-l-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.\!k-rounded-l-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.k-rounded-l-sm {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.\!k-rounded-l-sm {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}

.k-rounded-l-md {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.\!k-rounded-l-md {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.k-rounded-l-lg {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.\!k-rounded-l-lg {
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}

.k-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.\!k-rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.k-border-solid {
  border-style: solid;
}

.\!k-border-solid {
  border-style: solid !important;
}

.k-border-dashed {
  border-style: dashed;
}

.\!k-border-dashed {
  border-style: dashed !important;
}

.k-border-dotted {
  border-style: dotted;
}

.\!k-border-dotted {
  border-style: dotted !important;
}

.k-border-double {
  border-style: double;
}

.\!k-border-double {
  border-style: double !important;
}

.k-border-inset {
  border-style: inset;
}

.\!k-border-inset {
  border-style: inset !important;
}

.k-border-outset {
  border-style: outset;
}

.\!k-border-outset {
  border-style: outset !important;
}

.k-border-hidden {
  border-style: hidden;
}

.\!k-border-hidden {
  border-style: hidden !important;
}

.k-border-none {
  border-style: none;
}

.\!k-border-none {
  border-style: none !important;
}

.k-border-t-solid {
  border-top-style: solid;
}

.\!k-border-t-solid {
  border-top-style: solid !important;
}

.k-border-t-dashed {
  border-top-style: dashed;
}

.\!k-border-t-dashed {
  border-top-style: dashed !important;
}

.k-border-t-dotted {
  border-top-style: dotted;
}

.\!k-border-t-dotted {
  border-top-style: dotted !important;
}

.k-border-t-double {
  border-top-style: double;
}

.\!k-border-t-double {
  border-top-style: double !important;
}

.k-border-t-inset {
  border-top-style: inset;
}

.\!k-border-t-inset {
  border-top-style: inset !important;
}

.k-border-t-outset {
  border-top-style: outset;
}

.\!k-border-t-outset {
  border-top-style: outset !important;
}

.k-border-t-hidden {
  border-top-style: hidden;
}

.\!k-border-t-hidden {
  border-top-style: hidden !important;
}

.k-border-t-none {
  border-top-style: none;
}

.\!k-border-t-none {
  border-top-style: none !important;
}

.k-border-r-solid {
  border-right-style: solid;
}

.\!k-border-r-solid {
  border-right-style: solid !important;
}

.k-border-r-dashed {
  border-right-style: dashed;
}

.\!k-border-r-dashed {
  border-right-style: dashed !important;
}

.k-border-r-dotted {
  border-right-style: dotted;
}

.\!k-border-r-dotted {
  border-right-style: dotted !important;
}

.k-border-r-double {
  border-right-style: double;
}

.\!k-border-r-double {
  border-right-style: double !important;
}

.k-border-r-inset {
  border-right-style: inset;
}

.\!k-border-r-inset {
  border-right-style: inset !important;
}

.k-border-r-outset {
  border-right-style: outset;
}

.\!k-border-r-outset {
  border-right-style: outset !important;
}

.k-border-r-hidden {
  border-right-style: hidden;
}

.\!k-border-r-hidden {
  border-right-style: hidden !important;
}

.k-border-r-none {
  border-right-style: none;
}

.\!k-border-r-none {
  border-right-style: none !important;
}

.k-border-b-solid {
  border-bottom-style: solid;
}

.\!k-border-b-solid {
  border-bottom-style: solid !important;
}

.k-border-b-dashed {
  border-bottom-style: dashed;
}

.\!k-border-b-dashed {
  border-bottom-style: dashed !important;
}

.k-border-b-dotted {
  border-bottom-style: dotted;
}

.\!k-border-b-dotted {
  border-bottom-style: dotted !important;
}

.k-border-b-double {
  border-bottom-style: double;
}

.\!k-border-b-double {
  border-bottom-style: double !important;
}

.k-border-b-inset {
  border-bottom-style: inset;
}

.\!k-border-b-inset {
  border-bottom-style: inset !important;
}

.k-border-b-outset {
  border-bottom-style: outset;
}

.\!k-border-b-outset {
  border-bottom-style: outset !important;
}

.k-border-b-hidden {
  border-bottom-style: hidden;
}

.\!k-border-b-hidden {
  border-bottom-style: hidden !important;
}

.k-border-b-none {
  border-bottom-style: none;
}

.\!k-border-b-none {
  border-bottom-style: none !important;
}

.k-border-l-solid {
  border-left-style: solid;
}

.\!k-border-l-solid {
  border-left-style: solid !important;
}

.k-border-l-dashed {
  border-left-style: dashed;
}

.\!k-border-l-dashed {
  border-left-style: dashed !important;
}

.k-border-l-dotted {
  border-left-style: dotted;
}

.\!k-border-l-dotted {
  border-left-style: dotted !important;
}

.k-border-l-double {
  border-left-style: double;
}

.\!k-border-l-double {
  border-left-style: double !important;
}

.k-border-l-inset {
  border-left-style: inset;
}

.\!k-border-l-inset {
  border-left-style: inset !important;
}

.k-border-l-outset {
  border-left-style: outset;
}

.\!k-border-l-outset {
  border-left-style: outset !important;
}

.k-border-l-hidden {
  border-left-style: hidden;
}

.\!k-border-l-hidden {
  border-left-style: hidden !important;
}

.k-border-l-none {
  border-left-style: none;
}

.\!k-border-l-none {
  border-left-style: none !important;
}

.k-border-x-solid {
  border-inline-style: solid;
}

.\!k-border-x-solid {
  border-inline-style: solid !important;
}

.k-border-x-dashed {
  border-inline-style: dashed;
}

.\!k-border-x-dashed {
  border-inline-style: dashed !important;
}

.k-border-x-dotted {
  border-inline-style: dotted;
}

.\!k-border-x-dotted {
  border-inline-style: dotted !important;
}

.k-border-x-double {
  border-inline-style: double;
}

.\!k-border-x-double {
  border-inline-style: double !important;
}

.k-border-x-inset {
  border-inline-style: inset;
}

.\!k-border-x-inset {
  border-inline-style: inset !important;
}

.k-border-x-outset {
  border-inline-style: outset;
}

.\!k-border-x-outset {
  border-inline-style: outset !important;
}

.k-border-x-hidden {
  border-inline-style: hidden;
}

.\!k-border-x-hidden {
  border-inline-style: hidden !important;
}

.k-border-x-none {
  border-inline-style: none;
}

.\!k-border-x-none {
  border-inline-style: none !important;
}

.k-border-y-solid {
  border-block-style: solid;
}

.\!k-border-y-solid {
  border-block-style: solid !important;
}

.k-border-y-dashed {
  border-block-style: dashed;
}

.\!k-border-y-dashed {
  border-block-style: dashed !important;
}

.k-border-y-dotted {
  border-block-style: dotted;
}

.\!k-border-y-dotted {
  border-block-style: dotted !important;
}

.k-border-y-double {
  border-block-style: double;
}

.\!k-border-y-double {
  border-block-style: double !important;
}

.k-border-y-inset {
  border-block-style: inset;
}

.\!k-border-y-inset {
  border-block-style: inset !important;
}

.k-border-y-outset {
  border-block-style: outset;
}

.\!k-border-y-outset {
  border-block-style: outset !important;
}

.k-border-y-hidden {
  border-block-style: hidden;
}

.\!k-border-y-hidden {
  border-block-style: hidden !important;
}

.k-border-y-none {
  border-block-style: none;
}

.\!k-border-y-none {
  border-block-style: none !important;
}

.k-border {
  border-width: 1px;
}

.\!k-border {
  border-width: 1px !important;
}

.k-border-0 {
  border-width: 0;
}

.\!k-border-0 {
  border-width: 0 !important;
}

.k-border-2 {
  border-width: 2px;
}

.\!k-border-2 {
  border-width: 2px !important;
}

.k-border-4 {
  border-width: 4px;
}

.\!k-border-4 {
  border-width: 4px !important;
}

.k-border-8 {
  border-width: 8px;
}

.\!k-border-8 {
  border-width: 8px !important;
}

.k-border-t {
  border-top-width: 1px;
}

.\!k-border-t {
  border-top-width: 1px !important;
}

.k-border-t-0 {
  border-top-width: 0;
}

.\!k-border-t-0 {
  border-top-width: 0 !important;
}

.k-border-t-2 {
  border-top-width: 2px;
}

.\!k-border-t-2 {
  border-top-width: 2px !important;
}

.k-border-t-4 {
  border-top-width: 4px;
}

.\!k-border-t-4 {
  border-top-width: 4px !important;
}

.k-border-t-8 {
  border-top-width: 8px;
}

.\!k-border-t-8 {
  border-top-width: 8px !important;
}

.k-border-r {
  border-right-width: 1px;
}

.\!k-border-r {
  border-right-width: 1px !important;
}

.k-border-r-0 {
  border-right-width: 0;
}

.\!k-border-r-0 {
  border-right-width: 0 !important;
}

.k-border-r-2 {
  border-right-width: 2px;
}

.\!k-border-r-2 {
  border-right-width: 2px !important;
}

.k-border-r-4 {
  border-right-width: 4px;
}

.\!k-border-r-4 {
  border-right-width: 4px !important;
}

.k-border-r-8 {
  border-right-width: 8px;
}

.\!k-border-r-8 {
  border-right-width: 8px !important;
}

.k-border-b {
  border-bottom-width: 1px;
}

.\!k-border-b {
  border-bottom-width: 1px !important;
}

.k-border-b-0 {
  border-bottom-width: 0;
}

.\!k-border-b-0 {
  border-bottom-width: 0 !important;
}

.k-border-b-2 {
  border-bottom-width: 2px;
}

.\!k-border-b-2 {
  border-bottom-width: 2px !important;
}

.k-border-b-4 {
  border-bottom-width: 4px;
}

.\!k-border-b-4 {
  border-bottom-width: 4px !important;
}

.k-border-b-8 {
  border-bottom-width: 8px;
}

.\!k-border-b-8 {
  border-bottom-width: 8px !important;
}

.k-border-l {
  border-left-width: 1px;
}

.\!k-border-l {
  border-left-width: 1px !important;
}

.k-border-l-0 {
  border-left-width: 0;
}

.\!k-border-l-0 {
  border-left-width: 0 !important;
}

.k-border-l-2 {
  border-left-width: 2px;
}

.\!k-border-l-2 {
  border-left-width: 2px !important;
}

.k-border-l-4 {
  border-left-width: 4px;
}

.\!k-border-l-4 {
  border-left-width: 4px !important;
}

.k-border-l-8 {
  border-left-width: 8px;
}

.\!k-border-l-8 {
  border-left-width: 8px !important;
}

.k-border-x {
  border-inline-width: 1px;
}

.\!k-border-x {
  border-inline-width: 1px !important;
}

.k-border-x-0 {
  border-inline-width: 0;
}

.\!k-border-x-0 {
  border-inline-width: 0 !important;
}

.k-border-x-2 {
  border-inline-width: 2px;
}

.\!k-border-x-2 {
  border-inline-width: 2px !important;
}

.k-border-x-4 {
  border-inline-width: 4px;
}

.\!k-border-x-4 {
  border-inline-width: 4px !important;
}

.k-border-x-8 {
  border-inline-width: 8px;
}

.\!k-border-x-8 {
  border-inline-width: 8px !important;
}

.k-border-y {
  border-block-width: 1px;
}

.\!k-border-y {
  border-block-width: 1px !important;
}

.k-border-y-0 {
  border-block-width: 0;
}

.\!k-border-y-0 {
  border-block-width: 0 !important;
}

.k-border-y-2 {
  border-block-width: 2px;
}

.\!k-border-y-2 {
  border-block-width: 2px !important;
}

.k-border-y-4 {
  border-block-width: 4px;
}

.\!k-border-y-4 {
  border-block-width: 4px !important;
}

.k-border-y-8 {
  border-block-width: 8px;
}

.\!k-border-y-8 {
  border-block-width: 8px !important;
}

.k-border-top {
  border-top-width: 1px;
}

.\!k-border-top {
  border-top-width: 1px !important;
}

.k-border-top-0 {
  border-top-width: 0;
}

.\!k-border-top-0 {
  border-top-width: 0 !important;
}

.k-border-top-2 {
  border-top-width: 2px;
}

.\!k-border-top-2 {
  border-top-width: 2px !important;
}

.k-border-top-4 {
  border-top-width: 4px;
}

.\!k-border-top-4 {
  border-top-width: 4px !important;
}

.k-border-top-8 {
  border-top-width: 8px;
}

.\!k-border-top-8 {
  border-top-width: 8px !important;
}

.k-border-right {
  border-right-width: 1px;
}

.\!k-border-right {
  border-right-width: 1px !important;
}

.k-border-right-0 {
  border-right-width: 0;
}

.\!k-border-right-0 {
  border-right-width: 0 !important;
}

.k-border-right-2 {
  border-right-width: 2px;
}

.\!k-border-right-2 {
  border-right-width: 2px !important;
}

.k-border-right-4 {
  border-right-width: 4px;
}

.\!k-border-right-4 {
  border-right-width: 4px !important;
}

.k-border-right-8 {
  border-right-width: 8px;
}

.\!k-border-right-8 {
  border-right-width: 8px !important;
}

.k-border-bottom {
  border-bottom-width: 1px;
}

.\!k-border-bottom {
  border-bottom-width: 1px !important;
}

.k-border-bottom-0 {
  border-bottom-width: 0;
}

.\!k-border-bottom-0 {
  border-bottom-width: 0 !important;
}

.k-border-bottom-2 {
  border-bottom-width: 2px;
}

.\!k-border-bottom-2 {
  border-bottom-width: 2px !important;
}

.k-border-bottom-4 {
  border-bottom-width: 4px;
}

.\!k-border-bottom-4 {
  border-bottom-width: 4px !important;
}

.k-border-bottom-8 {
  border-bottom-width: 8px;
}

.\!k-border-bottom-8 {
  border-bottom-width: 8px !important;
}

.k-border-left {
  border-left-width: 1px;
}

.\!k-border-left {
  border-left-width: 1px !important;
}

.k-border-left-0 {
  border-left-width: 0;
}

.\!k-border-left-0 {
  border-left-width: 0 !important;
}

.k-border-left-2 {
  border-left-width: 2px;
}

.\!k-border-left-2 {
  border-left-width: 2px !important;
}

.k-border-left-4 {
  border-left-width: 4px;
}

.\!k-border-left-4 {
  border-left-width: 4px !important;
}

.k-border-left-8 {
  border-left-width: 8px;
}

.\!k-border-left-8 {
  border-left-width: 8px !important;
}

.k-outline-primary {
  outline-color: #ff6358;
}

.\!k-outline-primary {
  outline-color: #ff6358 !important;
}

.k-outline-secondary {
  outline-color: #666666;
}

.\!k-outline-secondary {
  outline-color: #666666 !important;
}

.k-outline-tertiary {
  outline-color: #03a9f4;
}

.\!k-outline-tertiary {
  outline-color: #03a9f4 !important;
}

.k-outline-info {
  outline-color: #0058e9;
}

.\!k-outline-info {
  outline-color: #0058e9 !important;
}

.k-outline-success {
  outline-color: #37b400;
}

.\!k-outline-success {
  outline-color: #37b400 !important;
}

.k-outline-warning {
  outline-color: #ffc000;
}

.\!k-outline-warning {
  outline-color: #ffc000 !important;
}

.k-outline-error {
  outline-color: #f31700;
}

.\!k-outline-error {
  outline-color: #f31700 !important;
}

.k-outline-dark {
  outline-color: #424242;
}

.\!k-outline-dark {
  outline-color: #424242 !important;
}

.k-outline-light {
  outline-color: #ebebeb;
}

.\!k-outline-light {
  outline-color: #ebebeb !important;
}

.k-outline-inverse {
  outline-color: #424242;
}

.\!k-outline-inverse {
  outline-color: #424242 !important;
}

.k-outline-inherit {
  outline-color: inherit;
}

.\!k-outline-inherit {
  outline-color: inherit !important;
}

.k-outline-current {
  outline-color: currentColor;
}

.\!k-outline-current {
  outline-color: currentColor !important;
}

.k-outline-transparent {
  outline-color: transparent;
}

.\!k-outline-transparent {
  outline-color: transparent !important;
}

.k-outline-black {
  outline-color: black;
}

.\!k-outline-black {
  outline-color: black !important;
}

.k-outline-white {
  outline-color: white;
}

.\!k-outline-white {
  outline-color: white !important;
}

.k-outline-offset-0 {
  outline-offset: 0;
}

.\!k-outline-offset-0 {
  outline-offset: 0 !important;
}

.k-outline-offset-1 {
  outline-offset: 1px;
}

.\!k-outline-offset-1 {
  outline-offset: 1px !important;
}

.k-outline-offset-2 {
  outline-offset: 2px;
}

.\!k-outline-offset-2 {
  outline-offset: 2px !important;
}

.k-outline-offset-4 {
  outline-offset: 4px;
}

.\!k-outline-offset-4 {
  outline-offset: 4px !important;
}

.k-outline-offset-8 {
  outline-offset: 8px;
}

.\!k-outline-offset-8 {
  outline-offset: 8px !important;
}

.k-outline-solid {
  outline-style: solid;
}

.\!k-outline-solid {
  outline-style: solid !important;
}

.k-outline-dashed {
  outline-style: dashed;
}

.\!k-outline-dashed {
  outline-style: dashed !important;
}

.k-outline-dotted {
  outline-style: dotted;
}

.\!k-outline-dotted {
  outline-style: dotted !important;
}

.k-outline-double {
  outline-style: double;
}

.\!k-outline-double {
  outline-style: double !important;
}

.k-outline-inset {
  outline-style: inset;
}

.\!k-outline-inset {
  outline-style: inset !important;
}

.k-outline-outset {
  outline-style: outset;
}

.\!k-outline-outset {
  outline-style: outset !important;
}

.k-outline-none {
  outline-style: none;
}

.\!k-outline-none {
  outline-style: none !important;
}

.k-outline {
  outline-width: 1px;
}

.\!k-outline {
  outline-width: 1px !important;
}

.k-outline-0 {
  outline-width: 0;
}

.\!k-outline-0 {
  outline-width: 0 !important;
}

.k-outline-2 {
  outline-width: 2px;
}

.\!k-outline-2 {
  outline-width: 2px !important;
}

.k-outline-4 {
  outline-width: 4px;
}

.\!k-outline-4 {
  outline-width: 4px !important;
}

.k-outline-8 {
  outline-width: 8px;
}

.\!k-outline-8 {
  outline-width: 8px !important;
}

.k-opacity-0 {
  opacity: 0;
}

.\!k-opacity-0 {
  opacity: 0 !important;
}

.k-opacity-5 {
  opacity: 0.05;
}

.\!k-opacity-5 {
  opacity: 0.05 !important;
}

.k-opacity-10 {
  opacity: 0.1;
}

.\!k-opacity-10 {
  opacity: 0.1 !important;
}

.k-opacity-20 {
  opacity: 0.2;
}

.\!k-opacity-20 {
  opacity: 0.2 !important;
}

.k-opacity-30 {
  opacity: 0.3;
}

.\!k-opacity-30 {
  opacity: 0.3 !important;
}

.k-opacity-40 {
  opacity: 0.4;
}

.\!k-opacity-40 {
  opacity: 0.4 !important;
}

.k-opacity-50 {
  opacity: 0.5;
}

.\!k-opacity-50 {
  opacity: 0.5 !important;
}

.k-opacity-60 {
  opacity: 0.6;
}

.\!k-opacity-60 {
  opacity: 0.6 !important;
}

.k-opacity-70 {
  opacity: 0.7;
}

.\!k-opacity-70 {
  opacity: 0.7 !important;
}

.k-opacity-80 {
  opacity: 0.8;
}

.\!k-opacity-80 {
  opacity: 0.8 !important;
}

.k-opacity-90 {
  opacity: 0.9;
}

.\!k-opacity-90 {
  opacity: 0.9 !important;
}

.k-opacity-100 {
  opacity: 1;
}

.\!k-opacity-100 {
  opacity: 1 !important;
}

.k-backdrop-blur {
  backdrop-filter: blur(8px);
}

.\!k-backdrop-blur {
  backdrop-filter: blur(8px) !important;
}

.k-backdrop-blur-none {
  backdrop-filter: blur(0);
}

.\!k-backdrop-blur-none {
  backdrop-filter: blur(0) !important;
}

.k-backdrop-blur-sm {
  backdrop-filter: blur(4px);
}

.\!k-backdrop-blur-sm {
  backdrop-filter: blur(4px) !important;
}

.k-backdrop-blur-md {
  backdrop-filter: blur(6px);
}

.\!k-backdrop-blur-md {
  backdrop-filter: blur(6px) !important;
}

.k-backdrop-blur-lg {
  backdrop-filter: blur(12px);
}

.\!k-backdrop-blur-lg {
  backdrop-filter: blur(12px) !important;
}

.k-border-collapse {
  border-collapse: collapse;
}

.\!k-border-collapse {
  border-collapse: collapse !important;
}

.k-border-separate {
  border-collapse: separate;
}

.\!k-border-separate {
  border-collapse: separate !important;
}

.k-table-auto {
  table-layout: auto;
}

.\!k-table-auto {
  table-layout: auto !important;
}

.k-table-fixed {
  table-layout: fixed;
}

.\!k-table-fixed {
  table-layout: fixed !important;
}

.k-table-layout-auto {
  table-layout: auto;
}

.\!k-table-layout-auto {
  table-layout: auto !important;
}

.k-table-layout-fixed {
  table-layout: fixed;
}

.\!k-table-layout-fixed {
  table-layout: fixed !important;
}

.k-flip-h, .k-flip-x {
  transform: scaleX(-1);
}

.\!k-flip-h, .\!k-flip-x {
  transform: scaleX(-1) !important;
}

.k-flip-v, .k-flip-y {
  transform: scaleY(-1);
}

.\!k-flip-v, .\!k-flip-y {
  transform: scaleY(-1) !important;
}

.k-flip-h.k-flip-v, .k-flip-h.k-flip-y, .k-flip-v.k-flip-x, .k-flip-x.k-flip-y {
  transform: scale(-1, -1);
}

.\!k-flip-h.\!k-flip-v, .\!k-flip-h.\!k-flip-y, .\!k-flip-v.\!k-flip-x, .\!k-flip-x.\!k-flip-y {
  transform: scale(-1, -1) !important;
}

.k-origin-center {
  transform-origin: center;
}

.\!k-origin-center {
  transform-origin: center !important;
}

.k-origin-top {
  transform-origin: top;
}

.\!k-origin-top {
  transform-origin: top !important;
}

.k-origin-right {
  transform-origin: right;
}

.\!k-origin-right {
  transform-origin: right !important;
}

.k-origin-bottom {
  transform-origin: bottom;
}

.\!k-origin-bottom {
  transform-origin: bottom !important;
}

.k-origin-left {
  transform-origin: left;
}

.\!k-origin-left {
  transform-origin: left !important;
}

.k-origin-top-left {
  transform-origin: top left;
}

.\!k-origin-top-left {
  transform-origin: top left !important;
}

.k-origin-top-right {
  transform-origin: top right;
}

.\!k-origin-top-right {
  transform-origin: top right !important;
}

.k-origin-bottom-left {
  transform-origin: bottom left;
}

.\!k-origin-bottom-left {
  transform-origin: bottom left !important;
}

.k-origin-bottom-right {
  transform-origin: bottom right;
}

.\!k-origin-bottom-right {
  transform-origin: bottom right !important;
}

.k-rotate-0 {
  transform: rotate(0deg);
}

.\!k-rotate-0 {
  transform: rotate(0deg) !important;
}

.k-rotate-45 {
  transform: rotate(45deg);
}

.\!k-rotate-45 {
  transform: rotate(45deg) !important;
}

.k-rotate-90 {
  transform: rotate(90deg);
}

.\!k-rotate-90 {
  transform: rotate(90deg) !important;
}

.k-rotate-135 {
  transform: rotate(135deg);
}

.\!k-rotate-135 {
  transform: rotate(135deg) !important;
}

.k-rotate-180 {
  transform: rotate(180deg);
}

.\!k-rotate-180 {
  transform: rotate(180deg) !important;
}

.k-rotate-225 {
  transform: rotate(225deg);
}

.\!k-rotate-225 {
  transform: rotate(225deg) !important;
}

.k-rotate-270 {
  transform: rotate(270deg);
}

.\!k-rotate-270 {
  transform: rotate(270deg) !important;
}

.k-rotate-315 {
  transform: rotate(315deg);
}

.\!k-rotate-315 {
  transform: rotate(315deg) !important;
}

.k-scale-0 {
  transform: scale(0);
}

.\!k-scale-0 {
  transform: scale(0) !important;
}

.k-scale-0\.25 {
  transform: scale(0.25);
}

.\!k-scale-0\.25 {
  transform: scale(0.25) !important;
}

.k-scale-0\.5 {
  transform: scale(0.5);
}

.\!k-scale-0\.5 {
  transform: scale(0.5) !important;
}

.k-scale-0\.75 {
  transform: scale(0.75);
}

.\!k-scale-0\.75 {
  transform: scale(0.75) !important;
}

.k-scale-1 {
  transform: scale(1);
}

.\!k-scale-1 {
  transform: scale(1) !important;
}

.k-scale-1\.25 {
  transform: scale(1.25);
}

.\!k-scale-1\.25 {
  transform: scale(1.25) !important;
}

.k-scale-1\.5 {
  transform: scale(1.5);
}

.\!k-scale-1\.5 {
  transform: scale(1.5) !important;
}

.k-scale-2 {
  transform: scale(2);
}

.\!k-scale-2 {
  transform: scale(2) !important;
}

.k-scale-x-0 {
  transform: scaleX(0);
}

.\!k-scale-x-0 {
  transform: scaleX(0) !important;
}

.k-scale-x-0\.25 {
  transform: scaleX(0.25);
}

.\!k-scale-x-0\.25 {
  transform: scaleX(0.25) !important;
}

.k-scale-x-0\.5 {
  transform: scaleX(0.5);
}

.\!k-scale-x-0\.5 {
  transform: scaleX(0.5) !important;
}

.k-scale-x-0\.75 {
  transform: scaleX(0.75);
}

.\!k-scale-x-0\.75 {
  transform: scaleX(0.75) !important;
}

.k-scale-x-1 {
  transform: scaleX(1);
}

.\!k-scale-x-1 {
  transform: scaleX(1) !important;
}

.k-scale-x-1\.25 {
  transform: scaleX(1.25);
}

.\!k-scale-x-1\.25 {
  transform: scaleX(1.25) !important;
}

.k-scale-x-1\.5 {
  transform: scaleX(1.5);
}

.\!k-scale-x-1\.5 {
  transform: scaleX(1.5) !important;
}

.k-scale-x-2 {
  transform: scaleX(2);
}

.\!k-scale-x-2 {
  transform: scaleX(2) !important;
}

.k-scale-y-0 {
  transform: scaleY(0);
}

.\!k-scale-y-0 {
  transform: scaleY(0) !important;
}

.k-scale-y-0\.25 {
  transform: scaleY(0.25);
}

.\!k-scale-y-0\.25 {
  transform: scaleY(0.25) !important;
}

.k-scale-y-0\.5 {
  transform: scaleY(0.5);
}

.\!k-scale-y-0\.5 {
  transform: scaleY(0.5) !important;
}

.k-scale-y-0\.75 {
  transform: scaleY(0.75);
}

.\!k-scale-y-0\.75 {
  transform: scaleY(0.75) !important;
}

.k-scale-y-1 {
  transform: scaleY(1);
}

.\!k-scale-y-1 {
  transform: scaleY(1) !important;
}

.k-scale-y-1\.25 {
  transform: scaleY(1.25);
}

.\!k-scale-y-1\.25 {
  transform: scaleY(1.25) !important;
}

.k-scale-y-1\.5 {
  transform: scaleY(1.5);
}

.\!k-scale-y-1\.5 {
  transform: scaleY(1.5) !important;
}

.k-scale-y-2 {
  transform: scaleY(2);
}

.\!k-scale-y-2 {
  transform: scaleY(2) !important;
}

.k-skew-x-0 {
  transform: skewX(0deg);
}

.\!k-skew-x-0 {
  transform: skewX(0deg) !important;
}

.k-skew-x-1 {
  transform: skewX(1deg);
}

.\!k-skew-x-1 {
  transform: skewX(1deg) !important;
}

.k-skew-x-2 {
  transform: skewX(2deg);
}

.\!k-skew-x-2 {
  transform: skewX(2deg) !important;
}

.k-skew-x-3 {
  transform: skewX(3deg);
}

.\!k-skew-x-3 {
  transform: skewX(3deg) !important;
}

.k-skew-x-4 {
  transform: skewX(4deg);
}

.\!k-skew-x-4 {
  transform: skewX(4deg) !important;
}

.k-skew-x-5 {
  transform: skewX(5deg);
}

.\!k-skew-x-5 {
  transform: skewX(5deg) !important;
}

.k-skew-x-6 {
  transform: skewX(6deg);
}

.\!k-skew-x-6 {
  transform: skewX(6deg) !important;
}

.k-skew-x-7 {
  transform: skewX(7deg);
}

.\!k-skew-x-7 {
  transform: skewX(7deg) !important;
}

.k-skew-x-8 {
  transform: skewX(8deg);
}

.\!k-skew-x-8 {
  transform: skewX(8deg) !important;
}

.k-skew-x-9 {
  transform: skewX(9deg);
}

.\!k-skew-x-9 {
  transform: skewX(9deg) !important;
}

.k-skew-x-10 {
  transform: skewX(10deg);
}

.\!k-skew-x-10 {
  transform: skewX(10deg) !important;
}

.k-skew-x-11 {
  transform: skewX(11deg);
}

.\!k-skew-x-11 {
  transform: skewX(11deg) !important;
}

.k-skew-x-12 {
  transform: skewX(12deg);
}

.\!k-skew-x-12 {
  transform: skewX(12deg) !important;
}

.k-skew-y-0 {
  transform: skewY(0deg);
}

.\!k-skew-y-0 {
  transform: skewY(0deg) !important;
}

.k-skew-y-1 {
  transform: skewY(1deg);
}

.\!k-skew-y-1 {
  transform: skewY(1deg) !important;
}

.k-skew-y-2 {
  transform: skewY(2deg);
}

.\!k-skew-y-2 {
  transform: skewY(2deg) !important;
}

.k-skew-y-3 {
  transform: skewY(3deg);
}

.\!k-skew-y-3 {
  transform: skewY(3deg) !important;
}

.k-skew-y-4 {
  transform: skewY(4deg);
}

.\!k-skew-y-4 {
  transform: skewY(4deg) !important;
}

.k-skew-y-5 {
  transform: skewY(5deg);
}

.\!k-skew-y-5 {
  transform: skewY(5deg) !important;
}

.k-skew-y-6 {
  transform: skewY(6deg);
}

.\!k-skew-y-6 {
  transform: skewY(6deg) !important;
}

.k-skew-y-7 {
  transform: skewY(7deg);
}

.\!k-skew-y-7 {
  transform: skewY(7deg) !important;
}

.k-skew-y-8 {
  transform: skewY(8deg);
}

.\!k-skew-y-8 {
  transform: skewY(8deg) !important;
}

.k-skew-y-9 {
  transform: skewY(9deg);
}

.\!k-skew-y-9 {
  transform: skewY(9deg) !important;
}

.k-skew-y-10 {
  transform: skewY(10deg);
}

.\!k-skew-y-10 {
  transform: skewY(10deg) !important;
}

.k-skew-y-11 {
  transform: skewY(11deg);
}

.\!k-skew-y-11 {
  transform: skewY(11deg) !important;
}

.k-skew-y-12 {
  transform: skewY(12deg);
}

.\!k-skew-y-12 {
  transform: skewY(12deg) !important;
}

.k-translate-0 {
  transform: translate(0, 0);
}

.\!k-translate-0 {
  transform: translate(0, 0) !important;
}

.k-translate-0-50 {
  transform: translate(0, 50%);
}

.\!k-translate-0-50 {
  transform: translate(0, 50%) !important;
}

.k-translate-0-100 {
  transform: translate(0, 100%);
}

.\!k-translate-0-100 {
  transform: translate(0, 100%) !important;
}

.k-translate-50-0 {
  transform: translate(50%, 0);
}

.\!k-translate-50-0 {
  transform: translate(50%, 0) !important;
}

.k-translate-50-50 {
  transform: translate(50%, 50%);
}

.\!k-translate-50-50 {
  transform: translate(50%, 50%) !important;
}

.k-translate-50-100 {
  transform: translate(50%, 100%);
}

.\!k-translate-50-100 {
  transform: translate(50%, 100%) !important;
}

.k-translate-100-0 {
  transform: translate(100%, 0);
}

.\!k-translate-100-0 {
  transform: translate(100%, 0) !important;
}

.k-translate-100-50 {
  transform: translate(100%, 50%);
}

.\!k-translate-100-50 {
  transform: translate(100%, 50%) !important;
}

.k-translate-100-100 {
  transform: translate(100%, 100%);
}

.\!k-translate-100-100 {
  transform: translate(100%, 100%) !important;
}

.k-translate-x-0 {
  transform: translateX(0);
}

.\!k-translate-x-0 {
  transform: translateX(0) !important;
}

.k-translate-x-1px {
  transform: translateX(1px);
}

.\!k-translate-x-1px {
  transform: translateX(1px) !important;
}

.k-translate-x-0\.5 {
  transform: translateX(2px);
}

.\!k-translate-x-0\.5 {
  transform: translateX(2px) !important;
}

.k-translate-x-1 {
  transform: translateX(4px);
}

.\!k-translate-x-1 {
  transform: translateX(4px) !important;
}

.k-translate-x-1\.5 {
  transform: translateX(6px);
}

.\!k-translate-x-1\.5 {
  transform: translateX(6px) !important;
}

.k-translate-x-2 {
  transform: translateX(8px);
}

.\!k-translate-x-2 {
  transform: translateX(8px) !important;
}

.k-translate-x-2\.5 {
  transform: translateX(10px);
}

.\!k-translate-x-2\.5 {
  transform: translateX(10px) !important;
}

.k-translate-x-3 {
  transform: translateX(12px);
}

.\!k-translate-x-3 {
  transform: translateX(12px) !important;
}

.k-translate-x-3\.5 {
  transform: translateX(14px);
}

.\!k-translate-x-3\.5 {
  transform: translateX(14px) !important;
}

.k-translate-x-4 {
  transform: translateX(16px);
}

.\!k-translate-x-4 {
  transform: translateX(16px) !important;
}

.k-translate-x-4\.5 {
  transform: translateX(18px);
}

.\!k-translate-x-4\.5 {
  transform: translateX(18px) !important;
}

.k-translate-x-5 {
  transform: translateX(20px);
}

.\!k-translate-x-5 {
  transform: translateX(20px) !important;
}

.k-translate-x-5\.5 {
  transform: translateX(22px);
}

.\!k-translate-x-5\.5 {
  transform: translateX(22px) !important;
}

.k-translate-x-6 {
  transform: translateX(24px);
}

.\!k-translate-x-6 {
  transform: translateX(24px) !important;
}

.k-translate-x-6\.5 {
  transform: translateX(26px);
}

.\!k-translate-x-6\.5 {
  transform: translateX(26px) !important;
}

.k-translate-x-7 {
  transform: translateX(28px);
}

.\!k-translate-x-7 {
  transform: translateX(28px) !important;
}

.k-translate-x-7\.5 {
  transform: translateX(30px);
}

.\!k-translate-x-7\.5 {
  transform: translateX(30px) !important;
}

.k-translate-x-8 {
  transform: translateX(32px);
}

.\!k-translate-x-8 {
  transform: translateX(32px) !important;
}

.k-translate-x-9 {
  transform: translateX(36px);
}

.\!k-translate-x-9 {
  transform: translateX(36px) !important;
}

.k-translate-x-10 {
  transform: translateX(40px);
}

.\!k-translate-x-10 {
  transform: translateX(40px) !important;
}

.k-translate-x-11 {
  transform: translateX(44px);
}

.\!k-translate-x-11 {
  transform: translateX(44px) !important;
}

.k-translate-x-12 {
  transform: translateX(48px);
}

.\!k-translate-x-12 {
  transform: translateX(48px) !important;
}

.k-translate-x-13 {
  transform: translateX(52px);
}

.\!k-translate-x-13 {
  transform: translateX(52px) !important;
}

.k-translate-x-14 {
  transform: translateX(56px);
}

.\!k-translate-x-14 {
  transform: translateX(56px) !important;
}

.k-translate-x-15 {
  transform: translateX(60px);
}

.\!k-translate-x-15 {
  transform: translateX(60px) !important;
}

.k-translate-x-16 {
  transform: translateX(64px);
}

.\!k-translate-x-16 {
  transform: translateX(64px) !important;
}

.k-translate-x-17 {
  transform: translateX(68px);
}

.\!k-translate-x-17 {
  transform: translateX(68px) !important;
}

.k-translate-x-18 {
  transform: translateX(72px);
}

.\!k-translate-x-18 {
  transform: translateX(72px) !important;
}

.k-translate-x-19 {
  transform: translateX(76px);
}

.\!k-translate-x-19 {
  transform: translateX(76px) !important;
}

.k-translate-x-20 {
  transform: translateX(80px);
}

.\!k-translate-x-20 {
  transform: translateX(80px) !important;
}

.k-translate-x-21 {
  transform: translateX(84px);
}

.\!k-translate-x-21 {
  transform: translateX(84px) !important;
}

.k-translate-x-22 {
  transform: translateX(88px);
}

.\!k-translate-x-22 {
  transform: translateX(88px) !important;
}

.k-translate-x-23 {
  transform: translateX(92px);
}

.\!k-translate-x-23 {
  transform: translateX(92px) !important;
}

.k-translate-x-24 {
  transform: translateX(96px);
}

.\!k-translate-x-24 {
  transform: translateX(96px) !important;
}

.k-translate-x-1\/2 {
  transform: translateX(50%);
}

.\!k-translate-x-1\/2 {
  transform: translateX(50%) !important;
}

.k-translate-x-1\/3 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-1\/3 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-2\/3 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-2\/3 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-1\/4 {
  transform: translateX(25%);
}

.\!k-translate-x-1\/4 {
  transform: translateX(25%) !important;
}

.k-translate-x-2\/4 {
  transform: translateX(50%);
}

.\!k-translate-x-2\/4 {
  transform: translateX(50%) !important;
}

.k-translate-x-3\/4 {
  transform: translateX(75%);
}

.\!k-translate-x-3\/4 {
  transform: translateX(75%) !important;
}

.k-translate-x-1\/5 {
  transform: translateX(20%);
}

.\!k-translate-x-1\/5 {
  transform: translateX(20%) !important;
}

.k-translate-x-2\/5 {
  transform: translateX(40%);
}

.\!k-translate-x-2\/5 {
  transform: translateX(40%) !important;
}

.k-translate-x-3\/5 {
  transform: translateX(60%);
}

.\!k-translate-x-3\/5 {
  transform: translateX(60%) !important;
}

.k-translate-x-4\/5 {
  transform: translateX(80%);
}

.\!k-translate-x-4\/5 {
  transform: translateX(80%) !important;
}

.k-translate-x-1\/6 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-1\/6 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-2\/6 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-2\/6 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-3\/6 {
  transform: translateX(50%);
}

.\!k-translate-x-3\/6 {
  transform: translateX(50%) !important;
}

.k-translate-x-4\/6 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-4\/6 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-5\/6 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-5\/6 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-1\/12 {
  transform: translateX(8.333333%);
}

.\!k-translate-x-1\/12 {
  transform: translateX(8.333333%) !important;
}

.k-translate-x-2\/12 {
  transform: translateX(16.666667%);
}

.\!k-translate-x-2\/12 {
  transform: translateX(16.666667%) !important;
}

.k-translate-x-3\/12 {
  transform: translateX(25%);
}

.\!k-translate-x-3\/12 {
  transform: translateX(25%) !important;
}

.k-translate-x-4\/12 {
  transform: translateX(33.333333%);
}

.\!k-translate-x-4\/12 {
  transform: translateX(33.333333%) !important;
}

.k-translate-x-5\/12 {
  transform: translateX(41.666667%);
}

.\!k-translate-x-5\/12 {
  transform: translateX(41.666667%) !important;
}

.k-translate-x-6\/12 {
  transform: translateX(50%);
}

.\!k-translate-x-6\/12 {
  transform: translateX(50%) !important;
}

.k-translate-x-7\/12 {
  transform: translateX(58.333333%);
}

.\!k-translate-x-7\/12 {
  transform: translateX(58.333333%) !important;
}

.k-translate-x-8\/12 {
  transform: translateX(66.666667%);
}

.\!k-translate-x-8\/12 {
  transform: translateX(66.666667%) !important;
}

.k-translate-x-9\/12 {
  transform: translateX(75%);
}

.\!k-translate-x-9\/12 {
  transform: translateX(75%) !important;
}

.k-translate-x-10\/12 {
  transform: translateX(83.333333%);
}

.\!k-translate-x-10\/12 {
  transform: translateX(83.333333%) !important;
}

.k-translate-x-11\/12 {
  transform: translateX(91.666667%);
}

.\!k-translate-x-11\/12 {
  transform: translateX(91.666667%) !important;
}

.k-translate-x-full {
  transform: translateX(100%);
}

.\!k-translate-x-full {
  transform: translateX(100%) !important;
}

.k-translate-y-0 {
  transform: translateY(0);
}

.\!k-translate-y-0 {
  transform: translateY(0) !important;
}

.k-translate-y-1px {
  transform: translateY(1px);
}

.\!k-translate-y-1px {
  transform: translateY(1px) !important;
}

.k-translate-y-0\.5 {
  transform: translateY(2px);
}

.\!k-translate-y-0\.5 {
  transform: translateY(2px) !important;
}

.k-translate-y-1 {
  transform: translateY(4px);
}

.\!k-translate-y-1 {
  transform: translateY(4px) !important;
}

.k-translate-y-1\.5 {
  transform: translateY(6px);
}

.\!k-translate-y-1\.5 {
  transform: translateY(6px) !important;
}

.k-translate-y-2 {
  transform: translateY(8px);
}

.\!k-translate-y-2 {
  transform: translateY(8px) !important;
}

.k-translate-y-2\.5 {
  transform: translateY(10px);
}

.\!k-translate-y-2\.5 {
  transform: translateY(10px) !important;
}

.k-translate-y-3 {
  transform: translateY(12px);
}

.\!k-translate-y-3 {
  transform: translateY(12px) !important;
}

.k-translate-y-3\.5 {
  transform: translateY(14px);
}

.\!k-translate-y-3\.5 {
  transform: translateY(14px) !important;
}

.k-translate-y-4 {
  transform: translateY(16px);
}

.\!k-translate-y-4 {
  transform: translateY(16px) !important;
}

.k-translate-y-4\.5 {
  transform: translateY(18px);
}

.\!k-translate-y-4\.5 {
  transform: translateY(18px) !important;
}

.k-translate-y-5 {
  transform: translateY(20px);
}

.\!k-translate-y-5 {
  transform: translateY(20px) !important;
}

.k-translate-y-5\.5 {
  transform: translateY(22px);
}

.\!k-translate-y-5\.5 {
  transform: translateY(22px) !important;
}

.k-translate-y-6 {
  transform: translateY(24px);
}

.\!k-translate-y-6 {
  transform: translateY(24px) !important;
}

.k-translate-y-6\.5 {
  transform: translateY(26px);
}

.\!k-translate-y-6\.5 {
  transform: translateY(26px) !important;
}

.k-translate-y-7 {
  transform: translateY(28px);
}

.\!k-translate-y-7 {
  transform: translateY(28px) !important;
}

.k-translate-y-7\.5 {
  transform: translateY(30px);
}

.\!k-translate-y-7\.5 {
  transform: translateY(30px) !important;
}

.k-translate-y-8 {
  transform: translateY(32px);
}

.\!k-translate-y-8 {
  transform: translateY(32px) !important;
}

.k-translate-y-9 {
  transform: translateY(36px);
}

.\!k-translate-y-9 {
  transform: translateY(36px) !important;
}

.k-translate-y-10 {
  transform: translateY(40px);
}

.\!k-translate-y-10 {
  transform: translateY(40px) !important;
}

.k-translate-y-11 {
  transform: translateY(44px);
}

.\!k-translate-y-11 {
  transform: translateY(44px) !important;
}

.k-translate-y-12 {
  transform: translateY(48px);
}

.\!k-translate-y-12 {
  transform: translateY(48px) !important;
}

.k-translate-y-13 {
  transform: translateY(52px);
}

.\!k-translate-y-13 {
  transform: translateY(52px) !important;
}

.k-translate-y-14 {
  transform: translateY(56px);
}

.\!k-translate-y-14 {
  transform: translateY(56px) !important;
}

.k-translate-y-15 {
  transform: translateY(60px);
}

.\!k-translate-y-15 {
  transform: translateY(60px) !important;
}

.k-translate-y-16 {
  transform: translateY(64px);
}

.\!k-translate-y-16 {
  transform: translateY(64px) !important;
}

.k-translate-y-17 {
  transform: translateY(68px);
}

.\!k-translate-y-17 {
  transform: translateY(68px) !important;
}

.k-translate-y-18 {
  transform: translateY(72px);
}

.\!k-translate-y-18 {
  transform: translateY(72px) !important;
}

.k-translate-y-19 {
  transform: translateY(76px);
}

.\!k-translate-y-19 {
  transform: translateY(76px) !important;
}

.k-translate-y-20 {
  transform: translateY(80px);
}

.\!k-translate-y-20 {
  transform: translateY(80px) !important;
}

.k-translate-y-21 {
  transform: translateY(84px);
}

.\!k-translate-y-21 {
  transform: translateY(84px) !important;
}

.k-translate-y-22 {
  transform: translateY(88px);
}

.\!k-translate-y-22 {
  transform: translateY(88px) !important;
}

.k-translate-y-23 {
  transform: translateY(92px);
}

.\!k-translate-y-23 {
  transform: translateY(92px) !important;
}

.k-translate-y-24 {
  transform: translateY(96px);
}

.\!k-translate-y-24 {
  transform: translateY(96px) !important;
}

.k-translate-y-1\/2 {
  transform: translateY(50%);
}

.\!k-translate-y-1\/2 {
  transform: translateY(50%) !important;
}

.k-translate-y-1\/3 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-1\/3 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-2\/3 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-2\/3 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-1\/4 {
  transform: translateY(25%);
}

.\!k-translate-y-1\/4 {
  transform: translateY(25%) !important;
}

.k-translate-y-2\/4 {
  transform: translateY(50%);
}

.\!k-translate-y-2\/4 {
  transform: translateY(50%) !important;
}

.k-translate-y-3\/4 {
  transform: translateY(75%);
}

.\!k-translate-y-3\/4 {
  transform: translateY(75%) !important;
}

.k-translate-y-1\/5 {
  transform: translateY(20%);
}

.\!k-translate-y-1\/5 {
  transform: translateY(20%) !important;
}

.k-translate-y-2\/5 {
  transform: translateY(40%);
}

.\!k-translate-y-2\/5 {
  transform: translateY(40%) !important;
}

.k-translate-y-3\/5 {
  transform: translateY(60%);
}

.\!k-translate-y-3\/5 {
  transform: translateY(60%) !important;
}

.k-translate-y-4\/5 {
  transform: translateY(80%);
}

.\!k-translate-y-4\/5 {
  transform: translateY(80%) !important;
}

.k-translate-y-1\/6 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-1\/6 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-2\/6 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-2\/6 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-3\/6 {
  transform: translateY(50%);
}

.\!k-translate-y-3\/6 {
  transform: translateY(50%) !important;
}

.k-translate-y-4\/6 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-4\/6 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-5\/6 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-5\/6 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-1\/12 {
  transform: translateY(8.333333%);
}

.\!k-translate-y-1\/12 {
  transform: translateY(8.333333%) !important;
}

.k-translate-y-2\/12 {
  transform: translateY(16.666667%);
}

.\!k-translate-y-2\/12 {
  transform: translateY(16.666667%) !important;
}

.k-translate-y-3\/12 {
  transform: translateY(25%);
}

.\!k-translate-y-3\/12 {
  transform: translateY(25%) !important;
}

.k-translate-y-4\/12 {
  transform: translateY(33.333333%);
}

.\!k-translate-y-4\/12 {
  transform: translateY(33.333333%) !important;
}

.k-translate-y-5\/12 {
  transform: translateY(41.666667%);
}

.\!k-translate-y-5\/12 {
  transform: translateY(41.666667%) !important;
}

.k-translate-y-6\/12 {
  transform: translateY(50%);
}

.\!k-translate-y-6\/12 {
  transform: translateY(50%) !important;
}

.k-translate-y-7\/12 {
  transform: translateY(58.333333%);
}

.\!k-translate-y-7\/12 {
  transform: translateY(58.333333%) !important;
}

.k-translate-y-8\/12 {
  transform: translateY(66.666667%);
}

.\!k-translate-y-8\/12 {
  transform: translateY(66.666667%) !important;
}

.k-translate-y-9\/12 {
  transform: translateY(75%);
}

.\!k-translate-y-9\/12 {
  transform: translateY(75%) !important;
}

.k-translate-y-10\/12 {
  transform: translateY(83.333333%);
}

.\!k-translate-y-10\/12 {
  transform: translateY(83.333333%) !important;
}

.k-translate-y-11\/12 {
  transform: translateY(91.666667%);
}

.\!k-translate-y-11\/12 {
  transform: translateY(91.666667%) !important;
}

.k-translate-y-full {
  transform: translateY(100%);
}

.\!k-translate-y-full {
  transform: translateY(100%) !important;
}

.k-appearance-none {
  appearance: none;
}

.\!k-appearance-none {
  appearance: none !important;
}

.k-appearance-auto {
  appearance: auto;
}

.\!k-appearance-auto {
  appearance: auto !important;
}

.k-cursor-none {
  cursor: none;
}

.\!k-cursor-none {
  cursor: none !important;
}

.k-cursor-auto {
  cursor: auto;
}

.\!k-cursor-auto {
  cursor: auto !important;
}

.k-cursor-default {
  cursor: default;
}

.\!k-cursor-default {
  cursor: default !important;
}

.k-cursor-context-menu {
  cursor: context-menu;
}

.\!k-cursor-context-menu {
  cursor: context-menu !important;
}

.k-cursor-help {
  cursor: help;
}

.\!k-cursor-help {
  cursor: help !important;
}

.k-cursor-pointer {
  cursor: pointer;
}

.\!k-cursor-pointer {
  cursor: pointer !important;
}

.k-cursor-progress {
  cursor: progress;
}

.\!k-cursor-progress {
  cursor: progress !important;
}

.k-cursor-wait {
  cursor: wait;
}

.\!k-cursor-wait {
  cursor: wait !important;
}

.k-cursor-cell {
  cursor: cell;
}

.\!k-cursor-cell {
  cursor: cell !important;
}

.k-cursor-crosshair {
  cursor: crosshair;
}

.\!k-cursor-crosshair {
  cursor: crosshair !important;
}

.k-cursor-text {
  cursor: text;
}

.\!k-cursor-text {
  cursor: text !important;
}

.k-cursor-vertical-text {
  cursor: vertical-text;
}

.\!k-cursor-vertical-text {
  cursor: vertical-text !important;
}

.k-cursor-alias {
  cursor: alias;
}

.\!k-cursor-alias {
  cursor: alias !important;
}

.k-cursor-copy {
  cursor: copy;
}

.\!k-cursor-copy {
  cursor: copy !important;
}

.k-cursor-move {
  cursor: move;
}

.\!k-cursor-move {
  cursor: move !important;
}

.k-cursor-no-drop {
  cursor: no-drop;
}

.\!k-cursor-no-drop {
  cursor: no-drop !important;
}

.k-cursor-not-allowed {
  cursor: not-allowed;
}

.\!k-cursor-not-allowed {
  cursor: not-allowed !important;
}

.k-cursor-e-resize {
  cursor: e-resize;
}

.\!k-cursor-e-resize {
  cursor: e-resize !important;
}

.k-cursor-n-resize {
  cursor: n-resize;
}

.\!k-cursor-n-resize {
  cursor: n-resize !important;
}

.k-cursor-ne-resize {
  cursor: ne-resize;
}

.\!k-cursor-ne-resize {
  cursor: ne-resize !important;
}

.k-cursor-nw-resize {
  cursor: nw-resize;
}

.\!k-cursor-nw-resize {
  cursor: nw-resize !important;
}

.k-cursor-s-resize {
  cursor: s-resize;
}

.\!k-cursor-s-resize {
  cursor: s-resize !important;
}

.k-cursor-se-resize {
  cursor: se-resize;
}

.\!k-cursor-se-resize {
  cursor: se-resize !important;
}

.k-cursor-sw-resize {
  cursor: sw-resize;
}

.\!k-cursor-sw-resize {
  cursor: sw-resize !important;
}

.k-cursor-w-resize {
  cursor: w-resize;
}

.\!k-cursor-w-resize {
  cursor: w-resize !important;
}

.k-cursor-ew-resize {
  cursor: ew-resize;
}

.\!k-cursor-ew-resize {
  cursor: ew-resize !important;
}

.k-cursor-ns-resize {
  cursor: ns-resize;
}

.\!k-cursor-ns-resize {
  cursor: ns-resize !important;
}

.k-cursor-nesw-resize {
  cursor: nesw-resize;
}

.\!k-cursor-nesw-resize {
  cursor: nesw-resize !important;
}

.k-cursor-nwse-resize {
  cursor: nwse-resize;
}

.\!k-cursor-nwse-resize {
  cursor: nwse-resize !important;
}

.k-cursor-col-resize {
  cursor: col-resize;
}

.\!k-cursor-col-resize {
  cursor: col-resize !important;
}

.k-cursor-row-resize {
  cursor: row-resize;
}

.\!k-cursor-row-resize {
  cursor: row-resize !important;
}

.k-cursor-all-scroll {
  cursor: all-scroll;
}

.\!k-cursor-all-scroll {
  cursor: all-scroll !important;
}

.k-cursor-zoom-in {
  cursor: zoom-in;
}

.\!k-cursor-zoom-in {
  cursor: zoom-in !important;
}

.k-cursor-zoom-out {
  cursor: zoom-out;
}

.\!k-cursor-zoom-out {
  cursor: zoom-out !important;
}

.k-cursor-grab {
  cursor: grab;
}

.\!k-cursor-grab {
  cursor: grab !important;
}

.k-cursor-grabbing {
  cursor: grabbing;
}

.\!k-cursor-grabbing {
  cursor: grabbing !important;
}

.k-pointer-events-none {
  pointer-events: none;
}

.\!k-pointer-events-none {
  pointer-events: none !important;
}

.k-pointer-events-auto {
  pointer-events: auto;
}

.\!k-pointer-events-auto {
  pointer-events: auto !important;
}

.k-resize {
  resize: both;
}

.\!k-resize {
  resize: both !important;
}

.k-resize-none {
  resize: none;
}

.\!k-resize-none {
  resize: none !important;
}

.k-resize-x {
  resize: horizontal;
}

.\!k-resize-x {
  resize: horizontal !important;
}

.k-resize-y {
  resize: vertical;
}

.\!k-resize-y {
  resize: vertical !important;
}

.k-scroll-auto {
  scroll-behavior: auto;
}

.\!k-scroll-auto {
  scroll-behavior: auto !important;
}

.k-scroll-smooth {
  scroll-behavior: smooth;
}

.\!k-scroll-smooth {
  scroll-behavior: smooth !important;
}

.k-scroll-snap-none {
  scroll-snap-type: none;
}

.\!k-scroll-snap-none {
  scroll-snap-type: none !important;
}

.k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-x {
  scroll-snap-type: x var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-y {
  scroll-snap-type: y var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness);
}

.\!k-scroll-snap-snap-both {
  scroll-snap-type: both var(--k-scroll-snap-strictness) !important;
}

.k-scroll-snap-mandatory {
  --k-scroll-snap-strictness: mandatory;
}

.k-scroll-snap-proximity {
  --k-scroll-snap-strictness: proximity;
}

.k-scroll-snap-stop-normal {
  scroll-snap-stop: normal;
}

.\!k-scroll-snap-stop-normal {
  scroll-snap-stop: normal !important;
}

.k-scroll-snap-stop-always {
  scroll-snap-stop: always;
}

.\!k-scroll-snap-stop-always {
  scroll-snap-stop: always !important;
}

.k-scroll-snap-align-start {
  scroll-snap-align: start;
}

.\!k-scroll-snap-align-start {
  scroll-snap-align: start !important;
}

.k-scroll-snap-align-end {
  scroll-snap-align: end;
}

.\!k-scroll-snap-align-end {
  scroll-snap-align: end !important;
}

.k-scroll-snap-align-center {
  scroll-snap-align: center;
}

.\!k-scroll-snap-align-center {
  scroll-snap-align: center !important;
}

.k-scroll-snap-align-none {
  scroll-snap-align: none;
}

.\!k-scroll-snap-align-none {
  scroll-snap-align: none !important;
}

.k-scroll-m-0 {
  scroll-margin: 0;
}

.\!k-scroll-m-0 {
  scroll-margin: 0 !important;
}

.k-scroll-m-1px {
  scroll-margin: 1px;
}

.\!k-scroll-m-1px {
  scroll-margin: 1px !important;
}

.k-scroll-m-0\.5 {
  scroll-margin: 2px;
}

.\!k-scroll-m-0\.5 {
  scroll-margin: 2px !important;
}

.k-scroll-m-1 {
  scroll-margin: 4px;
}

.\!k-scroll-m-1 {
  scroll-margin: 4px !important;
}

.k-scroll-m-1\.5 {
  scroll-margin: 6px;
}

.\!k-scroll-m-1\.5 {
  scroll-margin: 6px !important;
}

.k-scroll-m-2 {
  scroll-margin: 8px;
}

.\!k-scroll-m-2 {
  scroll-margin: 8px !important;
}

.k-scroll-m-2\.5 {
  scroll-margin: 10px;
}

.\!k-scroll-m-2\.5 {
  scroll-margin: 10px !important;
}

.k-scroll-m-3 {
  scroll-margin: 12px;
}

.\!k-scroll-m-3 {
  scroll-margin: 12px !important;
}

.k-scroll-m-3\.5 {
  scroll-margin: 14px;
}

.\!k-scroll-m-3\.5 {
  scroll-margin: 14px !important;
}

.k-scroll-m-4 {
  scroll-margin: 16px;
}

.\!k-scroll-m-4 {
  scroll-margin: 16px !important;
}

.k-scroll-m-4\.5 {
  scroll-margin: 18px;
}

.\!k-scroll-m-4\.5 {
  scroll-margin: 18px !important;
}

.k-scroll-m-5 {
  scroll-margin: 20px;
}

.\!k-scroll-m-5 {
  scroll-margin: 20px !important;
}

.k-scroll-m-5\.5 {
  scroll-margin: 22px;
}

.\!k-scroll-m-5\.5 {
  scroll-margin: 22px !important;
}

.k-scroll-m-6 {
  scroll-margin: 24px;
}

.\!k-scroll-m-6 {
  scroll-margin: 24px !important;
}

.k-scroll-m-6\.5 {
  scroll-margin: 26px;
}

.\!k-scroll-m-6\.5 {
  scroll-margin: 26px !important;
}

.k-scroll-m-7 {
  scroll-margin: 28px;
}

.\!k-scroll-m-7 {
  scroll-margin: 28px !important;
}

.k-scroll-m-7\.5 {
  scroll-margin: 30px;
}

.\!k-scroll-m-7\.5 {
  scroll-margin: 30px !important;
}

.k-scroll-m-8 {
  scroll-margin: 32px;
}

.\!k-scroll-m-8 {
  scroll-margin: 32px !important;
}

.k-scroll-m-9 {
  scroll-margin: 36px;
}

.\!k-scroll-m-9 {
  scroll-margin: 36px !important;
}

.k-scroll-m-10 {
  scroll-margin: 40px;
}

.\!k-scroll-m-10 {
  scroll-margin: 40px !important;
}

.k-scroll-m-11 {
  scroll-margin: 44px;
}

.\!k-scroll-m-11 {
  scroll-margin: 44px !important;
}

.k-scroll-m-12 {
  scroll-margin: 48px;
}

.\!k-scroll-m-12 {
  scroll-margin: 48px !important;
}

.k-scroll-m-13 {
  scroll-margin: 52px;
}

.\!k-scroll-m-13 {
  scroll-margin: 52px !important;
}

.k-scroll-m-14 {
  scroll-margin: 56px;
}

.\!k-scroll-m-14 {
  scroll-margin: 56px !important;
}

.k-scroll-m-15 {
  scroll-margin: 60px;
}

.\!k-scroll-m-15 {
  scroll-margin: 60px !important;
}

.k-scroll-m-16 {
  scroll-margin: 64px;
}

.\!k-scroll-m-16 {
  scroll-margin: 64px !important;
}

.k-scroll-m-17 {
  scroll-margin: 68px;
}

.\!k-scroll-m-17 {
  scroll-margin: 68px !important;
}

.k-scroll-m-18 {
  scroll-margin: 72px;
}

.\!k-scroll-m-18 {
  scroll-margin: 72px !important;
}

.k-scroll-m-19 {
  scroll-margin: 76px;
}

.\!k-scroll-m-19 {
  scroll-margin: 76px !important;
}

.k-scroll-m-20 {
  scroll-margin: 80px;
}

.\!k-scroll-m-20 {
  scroll-margin: 80px !important;
}

.k-scroll-m-21 {
  scroll-margin: 84px;
}

.\!k-scroll-m-21 {
  scroll-margin: 84px !important;
}

.k-scroll-m-22 {
  scroll-margin: 88px;
}

.\!k-scroll-m-22 {
  scroll-margin: 88px !important;
}

.k-scroll-m-23 {
  scroll-margin: 92px;
}

.\!k-scroll-m-23 {
  scroll-margin: 92px !important;
}

.k-scroll-m-24 {
  scroll-margin: 96px;
}

.\!k-scroll-m-24 {
  scroll-margin: 96px !important;
}

.k-scroll-mt-0 {
  scroll-margin-top: 0;
}

.\!k-scroll-mt-0 {
  scroll-margin-top: 0 !important;
}

.k-scroll-mt-1px {
  scroll-margin-top: 1px;
}

.\!k-scroll-mt-1px {
  scroll-margin-top: 1px !important;
}

.k-scroll-mt-0\.5 {
  scroll-margin-top: 2px;
}

.\!k-scroll-mt-0\.5 {
  scroll-margin-top: 2px !important;
}

.k-scroll-mt-1 {
  scroll-margin-top: 4px;
}

.\!k-scroll-mt-1 {
  scroll-margin-top: 4px !important;
}

.k-scroll-mt-1\.5 {
  scroll-margin-top: 6px;
}

.\!k-scroll-mt-1\.5 {
  scroll-margin-top: 6px !important;
}

.k-scroll-mt-2 {
  scroll-margin-top: 8px;
}

.\!k-scroll-mt-2 {
  scroll-margin-top: 8px !important;
}

.k-scroll-mt-2\.5 {
  scroll-margin-top: 10px;
}

.\!k-scroll-mt-2\.5 {
  scroll-margin-top: 10px !important;
}

.k-scroll-mt-3 {
  scroll-margin-top: 12px;
}

.\!k-scroll-mt-3 {
  scroll-margin-top: 12px !important;
}

.k-scroll-mt-3\.5 {
  scroll-margin-top: 14px;
}

.\!k-scroll-mt-3\.5 {
  scroll-margin-top: 14px !important;
}

.k-scroll-mt-4 {
  scroll-margin-top: 16px;
}

.\!k-scroll-mt-4 {
  scroll-margin-top: 16px !important;
}

.k-scroll-mt-4\.5 {
  scroll-margin-top: 18px;
}

.\!k-scroll-mt-4\.5 {
  scroll-margin-top: 18px !important;
}

.k-scroll-mt-5 {
  scroll-margin-top: 20px;
}

.\!k-scroll-mt-5 {
  scroll-margin-top: 20px !important;
}

.k-scroll-mt-5\.5 {
  scroll-margin-top: 22px;
}

.\!k-scroll-mt-5\.5 {
  scroll-margin-top: 22px !important;
}

.k-scroll-mt-6 {
  scroll-margin-top: 24px;
}

.\!k-scroll-mt-6 {
  scroll-margin-top: 24px !important;
}

.k-scroll-mt-6\.5 {
  scroll-margin-top: 26px;
}

.\!k-scroll-mt-6\.5 {
  scroll-margin-top: 26px !important;
}

.k-scroll-mt-7 {
  scroll-margin-top: 28px;
}

.\!k-scroll-mt-7 {
  scroll-margin-top: 28px !important;
}

.k-scroll-mt-7\.5 {
  scroll-margin-top: 30px;
}

.\!k-scroll-mt-7\.5 {
  scroll-margin-top: 30px !important;
}

.k-scroll-mt-8 {
  scroll-margin-top: 32px;
}

.\!k-scroll-mt-8 {
  scroll-margin-top: 32px !important;
}

.k-scroll-mt-9 {
  scroll-margin-top: 36px;
}

.\!k-scroll-mt-9 {
  scroll-margin-top: 36px !important;
}

.k-scroll-mt-10 {
  scroll-margin-top: 40px;
}

.\!k-scroll-mt-10 {
  scroll-margin-top: 40px !important;
}

.k-scroll-mt-11 {
  scroll-margin-top: 44px;
}

.\!k-scroll-mt-11 {
  scroll-margin-top: 44px !important;
}

.k-scroll-mt-12 {
  scroll-margin-top: 48px;
}

.\!k-scroll-mt-12 {
  scroll-margin-top: 48px !important;
}

.k-scroll-mt-13 {
  scroll-margin-top: 52px;
}

.\!k-scroll-mt-13 {
  scroll-margin-top: 52px !important;
}

.k-scroll-mt-14 {
  scroll-margin-top: 56px;
}

.\!k-scroll-mt-14 {
  scroll-margin-top: 56px !important;
}

.k-scroll-mt-15 {
  scroll-margin-top: 60px;
}

.\!k-scroll-mt-15 {
  scroll-margin-top: 60px !important;
}

.k-scroll-mt-16 {
  scroll-margin-top: 64px;
}

.\!k-scroll-mt-16 {
  scroll-margin-top: 64px !important;
}

.k-scroll-mt-17 {
  scroll-margin-top: 68px;
}

.\!k-scroll-mt-17 {
  scroll-margin-top: 68px !important;
}

.k-scroll-mt-18 {
  scroll-margin-top: 72px;
}

.\!k-scroll-mt-18 {
  scroll-margin-top: 72px !important;
}

.k-scroll-mt-19 {
  scroll-margin-top: 76px;
}

.\!k-scroll-mt-19 {
  scroll-margin-top: 76px !important;
}

.k-scroll-mt-20 {
  scroll-margin-top: 80px;
}

.\!k-scroll-mt-20 {
  scroll-margin-top: 80px !important;
}

.k-scroll-mt-21 {
  scroll-margin-top: 84px;
}

.\!k-scroll-mt-21 {
  scroll-margin-top: 84px !important;
}

.k-scroll-mt-22 {
  scroll-margin-top: 88px;
}

.\!k-scroll-mt-22 {
  scroll-margin-top: 88px !important;
}

.k-scroll-mt-23 {
  scroll-margin-top: 92px;
}

.\!k-scroll-mt-23 {
  scroll-margin-top: 92px !important;
}

.k-scroll-mt-24 {
  scroll-margin-top: 96px;
}

.\!k-scroll-mt-24 {
  scroll-margin-top: 96px !important;
}

.k-scroll-mr-0 {
  scroll-margin-right: 0;
}

.\!k-scroll-mr-0 {
  scroll-margin-right: 0 !important;
}

.k-scroll-mr-1px {
  scroll-margin-right: 1px;
}

.\!k-scroll-mr-1px {
  scroll-margin-right: 1px !important;
}

.k-scroll-mr-0\.5 {
  scroll-margin-right: 2px;
}

.\!k-scroll-mr-0\.5 {
  scroll-margin-right: 2px !important;
}

.k-scroll-mr-1 {
  scroll-margin-right: 4px;
}

.\!k-scroll-mr-1 {
  scroll-margin-right: 4px !important;
}

.k-scroll-mr-1\.5 {
  scroll-margin-right: 6px;
}

.\!k-scroll-mr-1\.5 {
  scroll-margin-right: 6px !important;
}

.k-scroll-mr-2 {
  scroll-margin-right: 8px;
}

.\!k-scroll-mr-2 {
  scroll-margin-right: 8px !important;
}

.k-scroll-mr-2\.5 {
  scroll-margin-right: 10px;
}

.\!k-scroll-mr-2\.5 {
  scroll-margin-right: 10px !important;
}

.k-scroll-mr-3 {
  scroll-margin-right: 12px;
}

.\!k-scroll-mr-3 {
  scroll-margin-right: 12px !important;
}

.k-scroll-mr-3\.5 {
  scroll-margin-right: 14px;
}

.\!k-scroll-mr-3\.5 {
  scroll-margin-right: 14px !important;
}

.k-scroll-mr-4 {
  scroll-margin-right: 16px;
}

.\!k-scroll-mr-4 {
  scroll-margin-right: 16px !important;
}

.k-scroll-mr-4\.5 {
  scroll-margin-right: 18px;
}

.\!k-scroll-mr-4\.5 {
  scroll-margin-right: 18px !important;
}

.k-scroll-mr-5 {
  scroll-margin-right: 20px;
}

.\!k-scroll-mr-5 {
  scroll-margin-right: 20px !important;
}

.k-scroll-mr-5\.5 {
  scroll-margin-right: 22px;
}

.\!k-scroll-mr-5\.5 {
  scroll-margin-right: 22px !important;
}

.k-scroll-mr-6 {
  scroll-margin-right: 24px;
}

.\!k-scroll-mr-6 {
  scroll-margin-right: 24px !important;
}

.k-scroll-mr-6\.5 {
  scroll-margin-right: 26px;
}

.\!k-scroll-mr-6\.5 {
  scroll-margin-right: 26px !important;
}

.k-scroll-mr-7 {
  scroll-margin-right: 28px;
}

.\!k-scroll-mr-7 {
  scroll-margin-right: 28px !important;
}

.k-scroll-mr-7\.5 {
  scroll-margin-right: 30px;
}

.\!k-scroll-mr-7\.5 {
  scroll-margin-right: 30px !important;
}

.k-scroll-mr-8 {
  scroll-margin-right: 32px;
}

.\!k-scroll-mr-8 {
  scroll-margin-right: 32px !important;
}

.k-scroll-mr-9 {
  scroll-margin-right: 36px;
}

.\!k-scroll-mr-9 {
  scroll-margin-right: 36px !important;
}

.k-scroll-mr-10 {
  scroll-margin-right: 40px;
}

.\!k-scroll-mr-10 {
  scroll-margin-right: 40px !important;
}

.k-scroll-mr-11 {
  scroll-margin-right: 44px;
}

.\!k-scroll-mr-11 {
  scroll-margin-right: 44px !important;
}

.k-scroll-mr-12 {
  scroll-margin-right: 48px;
}

.\!k-scroll-mr-12 {
  scroll-margin-right: 48px !important;
}

.k-scroll-mr-13 {
  scroll-margin-right: 52px;
}

.\!k-scroll-mr-13 {
  scroll-margin-right: 52px !important;
}

.k-scroll-mr-14 {
  scroll-margin-right: 56px;
}

.\!k-scroll-mr-14 {
  scroll-margin-right: 56px !important;
}

.k-scroll-mr-15 {
  scroll-margin-right: 60px;
}

.\!k-scroll-mr-15 {
  scroll-margin-right: 60px !important;
}

.k-scroll-mr-16 {
  scroll-margin-right: 64px;
}

.\!k-scroll-mr-16 {
  scroll-margin-right: 64px !important;
}

.k-scroll-mr-17 {
  scroll-margin-right: 68px;
}

.\!k-scroll-mr-17 {
  scroll-margin-right: 68px !important;
}

.k-scroll-mr-18 {
  scroll-margin-right: 72px;
}

.\!k-scroll-mr-18 {
  scroll-margin-right: 72px !important;
}

.k-scroll-mr-19 {
  scroll-margin-right: 76px;
}

.\!k-scroll-mr-19 {
  scroll-margin-right: 76px !important;
}

.k-scroll-mr-20 {
  scroll-margin-right: 80px;
}

.\!k-scroll-mr-20 {
  scroll-margin-right: 80px !important;
}

.k-scroll-mr-21 {
  scroll-margin-right: 84px;
}

.\!k-scroll-mr-21 {
  scroll-margin-right: 84px !important;
}

.k-scroll-mr-22 {
  scroll-margin-right: 88px;
}

.\!k-scroll-mr-22 {
  scroll-margin-right: 88px !important;
}

.k-scroll-mr-23 {
  scroll-margin-right: 92px;
}

.\!k-scroll-mr-23 {
  scroll-margin-right: 92px !important;
}

.k-scroll-mr-24 {
  scroll-margin-right: 96px;
}

.\!k-scroll-mr-24 {
  scroll-margin-right: 96px !important;
}

.k-scroll-mb-0 {
  scroll-margin-bottom: 0;
}

.\!k-scroll-mb-0 {
  scroll-margin-bottom: 0 !important;
}

.k-scroll-mb-1px {
  scroll-margin-bottom: 1px;
}

.\!k-scroll-mb-1px {
  scroll-margin-bottom: 1px !important;
}

.k-scroll-mb-0\.5 {
  scroll-margin-bottom: 2px;
}

.\!k-scroll-mb-0\.5 {
  scroll-margin-bottom: 2px !important;
}

.k-scroll-mb-1 {
  scroll-margin-bottom: 4px;
}

.\!k-scroll-mb-1 {
  scroll-margin-bottom: 4px !important;
}

.k-scroll-mb-1\.5 {
  scroll-margin-bottom: 6px;
}

.\!k-scroll-mb-1\.5 {
  scroll-margin-bottom: 6px !important;
}

.k-scroll-mb-2 {
  scroll-margin-bottom: 8px;
}

.\!k-scroll-mb-2 {
  scroll-margin-bottom: 8px !important;
}

.k-scroll-mb-2\.5 {
  scroll-margin-bottom: 10px;
}

.\!k-scroll-mb-2\.5 {
  scroll-margin-bottom: 10px !important;
}

.k-scroll-mb-3 {
  scroll-margin-bottom: 12px;
}

.\!k-scroll-mb-3 {
  scroll-margin-bottom: 12px !important;
}

.k-scroll-mb-3\.5 {
  scroll-margin-bottom: 14px;
}

.\!k-scroll-mb-3\.5 {
  scroll-margin-bottom: 14px !important;
}

.k-scroll-mb-4 {
  scroll-margin-bottom: 16px;
}

.\!k-scroll-mb-4 {
  scroll-margin-bottom: 16px !important;
}

.k-scroll-mb-4\.5 {
  scroll-margin-bottom: 18px;
}

.\!k-scroll-mb-4\.5 {
  scroll-margin-bottom: 18px !important;
}

.k-scroll-mb-5 {
  scroll-margin-bottom: 20px;
}

.\!k-scroll-mb-5 {
  scroll-margin-bottom: 20px !important;
}

.k-scroll-mb-5\.5 {
  scroll-margin-bottom: 22px;
}

.\!k-scroll-mb-5\.5 {
  scroll-margin-bottom: 22px !important;
}

.k-scroll-mb-6 {
  scroll-margin-bottom: 24px;
}

.\!k-scroll-mb-6 {
  scroll-margin-bottom: 24px !important;
}

.k-scroll-mb-6\.5 {
  scroll-margin-bottom: 26px;
}

.\!k-scroll-mb-6\.5 {
  scroll-margin-bottom: 26px !important;
}

.k-scroll-mb-7 {
  scroll-margin-bottom: 28px;
}

.\!k-scroll-mb-7 {
  scroll-margin-bottom: 28px !important;
}

.k-scroll-mb-7\.5 {
  scroll-margin-bottom: 30px;
}

.\!k-scroll-mb-7\.5 {
  scroll-margin-bottom: 30px !important;
}

.k-scroll-mb-8 {
  scroll-margin-bottom: 32px;
}

.\!k-scroll-mb-8 {
  scroll-margin-bottom: 32px !important;
}

.k-scroll-mb-9 {
  scroll-margin-bottom: 36px;
}

.\!k-scroll-mb-9 {
  scroll-margin-bottom: 36px !important;
}

.k-scroll-mb-10 {
  scroll-margin-bottom: 40px;
}

.\!k-scroll-mb-10 {
  scroll-margin-bottom: 40px !important;
}

.k-scroll-mb-11 {
  scroll-margin-bottom: 44px;
}

.\!k-scroll-mb-11 {
  scroll-margin-bottom: 44px !important;
}

.k-scroll-mb-12 {
  scroll-margin-bottom: 48px;
}

.\!k-scroll-mb-12 {
  scroll-margin-bottom: 48px !important;
}

.k-scroll-mb-13 {
  scroll-margin-bottom: 52px;
}

.\!k-scroll-mb-13 {
  scroll-margin-bottom: 52px !important;
}

.k-scroll-mb-14 {
  scroll-margin-bottom: 56px;
}

.\!k-scroll-mb-14 {
  scroll-margin-bottom: 56px !important;
}

.k-scroll-mb-15 {
  scroll-margin-bottom: 60px;
}

.\!k-scroll-mb-15 {
  scroll-margin-bottom: 60px !important;
}

.k-scroll-mb-16 {
  scroll-margin-bottom: 64px;
}

.\!k-scroll-mb-16 {
  scroll-margin-bottom: 64px !important;
}

.k-scroll-mb-17 {
  scroll-margin-bottom: 68px;
}

.\!k-scroll-mb-17 {
  scroll-margin-bottom: 68px !important;
}

.k-scroll-mb-18 {
  scroll-margin-bottom: 72px;
}

.\!k-scroll-mb-18 {
  scroll-margin-bottom: 72px !important;
}

.k-scroll-mb-19 {
  scroll-margin-bottom: 76px;
}

.\!k-scroll-mb-19 {
  scroll-margin-bottom: 76px !important;
}

.k-scroll-mb-20 {
  scroll-margin-bottom: 80px;
}

.\!k-scroll-mb-20 {
  scroll-margin-bottom: 80px !important;
}

.k-scroll-mb-21 {
  scroll-margin-bottom: 84px;
}

.\!k-scroll-mb-21 {
  scroll-margin-bottom: 84px !important;
}

.k-scroll-mb-22 {
  scroll-margin-bottom: 88px;
}

.\!k-scroll-mb-22 {
  scroll-margin-bottom: 88px !important;
}

.k-scroll-mb-23 {
  scroll-margin-bottom: 92px;
}

.\!k-scroll-mb-23 {
  scroll-margin-bottom: 92px !important;
}

.k-scroll-mb-24 {
  scroll-margin-bottom: 96px;
}

.\!k-scroll-mb-24 {
  scroll-margin-bottom: 96px !important;
}

.k-scroll-ml-0 {
  scroll-margin-left: 0;
}

.\!k-scroll-ml-0 {
  scroll-margin-left: 0 !important;
}

.k-scroll-ml-1px {
  scroll-margin-left: 1px;
}

.\!k-scroll-ml-1px {
  scroll-margin-left: 1px !important;
}

.k-scroll-ml-0\.5 {
  scroll-margin-left: 2px;
}

.\!k-scroll-ml-0\.5 {
  scroll-margin-left: 2px !important;
}

.k-scroll-ml-1 {
  scroll-margin-left: 4px;
}

.\!k-scroll-ml-1 {
  scroll-margin-left: 4px !important;
}

.k-scroll-ml-1\.5 {
  scroll-margin-left: 6px;
}

.\!k-scroll-ml-1\.5 {
  scroll-margin-left: 6px !important;
}

.k-scroll-ml-2 {
  scroll-margin-left: 8px;
}

.\!k-scroll-ml-2 {
  scroll-margin-left: 8px !important;
}

.k-scroll-ml-2\.5 {
  scroll-margin-left: 10px;
}

.\!k-scroll-ml-2\.5 {
  scroll-margin-left: 10px !important;
}

.k-scroll-ml-3 {
  scroll-margin-left: 12px;
}

.\!k-scroll-ml-3 {
  scroll-margin-left: 12px !important;
}

.k-scroll-ml-3\.5 {
  scroll-margin-left: 14px;
}

.\!k-scroll-ml-3\.5 {
  scroll-margin-left: 14px !important;
}

.k-scroll-ml-4 {
  scroll-margin-left: 16px;
}

.\!k-scroll-ml-4 {
  scroll-margin-left: 16px !important;
}

.k-scroll-ml-4\.5 {
  scroll-margin-left: 18px;
}

.\!k-scroll-ml-4\.5 {
  scroll-margin-left: 18px !important;
}

.k-scroll-ml-5 {
  scroll-margin-left: 20px;
}

.\!k-scroll-ml-5 {
  scroll-margin-left: 20px !important;
}

.k-scroll-ml-5\.5 {
  scroll-margin-left: 22px;
}

.\!k-scroll-ml-5\.5 {
  scroll-margin-left: 22px !important;
}

.k-scroll-ml-6 {
  scroll-margin-left: 24px;
}

.\!k-scroll-ml-6 {
  scroll-margin-left: 24px !important;
}

.k-scroll-ml-6\.5 {
  scroll-margin-left: 26px;
}

.\!k-scroll-ml-6\.5 {
  scroll-margin-left: 26px !important;
}

.k-scroll-ml-7 {
  scroll-margin-left: 28px;
}

.\!k-scroll-ml-7 {
  scroll-margin-left: 28px !important;
}

.k-scroll-ml-7\.5 {
  scroll-margin-left: 30px;
}

.\!k-scroll-ml-7\.5 {
  scroll-margin-left: 30px !important;
}

.k-scroll-ml-8 {
  scroll-margin-left: 32px;
}

.\!k-scroll-ml-8 {
  scroll-margin-left: 32px !important;
}

.k-scroll-ml-9 {
  scroll-margin-left: 36px;
}

.\!k-scroll-ml-9 {
  scroll-margin-left: 36px !important;
}

.k-scroll-ml-10 {
  scroll-margin-left: 40px;
}

.\!k-scroll-ml-10 {
  scroll-margin-left: 40px !important;
}

.k-scroll-ml-11 {
  scroll-margin-left: 44px;
}

.\!k-scroll-ml-11 {
  scroll-margin-left: 44px !important;
}

.k-scroll-ml-12 {
  scroll-margin-left: 48px;
}

.\!k-scroll-ml-12 {
  scroll-margin-left: 48px !important;
}

.k-scroll-ml-13 {
  scroll-margin-left: 52px;
}

.\!k-scroll-ml-13 {
  scroll-margin-left: 52px !important;
}

.k-scroll-ml-14 {
  scroll-margin-left: 56px;
}

.\!k-scroll-ml-14 {
  scroll-margin-left: 56px !important;
}

.k-scroll-ml-15 {
  scroll-margin-left: 60px;
}

.\!k-scroll-ml-15 {
  scroll-margin-left: 60px !important;
}

.k-scroll-ml-16 {
  scroll-margin-left: 64px;
}

.\!k-scroll-ml-16 {
  scroll-margin-left: 64px !important;
}

.k-scroll-ml-17 {
  scroll-margin-left: 68px;
}

.\!k-scroll-ml-17 {
  scroll-margin-left: 68px !important;
}

.k-scroll-ml-18 {
  scroll-margin-left: 72px;
}

.\!k-scroll-ml-18 {
  scroll-margin-left: 72px !important;
}

.k-scroll-ml-19 {
  scroll-margin-left: 76px;
}

.\!k-scroll-ml-19 {
  scroll-margin-left: 76px !important;
}

.k-scroll-ml-20 {
  scroll-margin-left: 80px;
}

.\!k-scroll-ml-20 {
  scroll-margin-left: 80px !important;
}

.k-scroll-ml-21 {
  scroll-margin-left: 84px;
}

.\!k-scroll-ml-21 {
  scroll-margin-left: 84px !important;
}

.k-scroll-ml-22 {
  scroll-margin-left: 88px;
}

.\!k-scroll-ml-22 {
  scroll-margin-left: 88px !important;
}

.k-scroll-ml-23 {
  scroll-margin-left: 92px;
}

.\!k-scroll-ml-23 {
  scroll-margin-left: 92px !important;
}

.k-scroll-ml-24 {
  scroll-margin-left: 96px;
}

.\!k-scroll-ml-24 {
  scroll-margin-left: 96px !important;
}

.k-scroll-mx-0 {
  scroll-margin-inline: 0;
}

.\!k-scroll-mx-0 {
  scroll-margin-inline: 0 !important;
}

.k-scroll-mx-1px {
  scroll-margin-inline: 1px;
}

.\!k-scroll-mx-1px {
  scroll-margin-inline: 1px !important;
}

.k-scroll-mx-0\.5 {
  scroll-margin-inline: 2px;
}

.\!k-scroll-mx-0\.5 {
  scroll-margin-inline: 2px !important;
}

.k-scroll-mx-1 {
  scroll-margin-inline: 4px;
}

.\!k-scroll-mx-1 {
  scroll-margin-inline: 4px !important;
}

.k-scroll-mx-1\.5 {
  scroll-margin-inline: 6px;
}

.\!k-scroll-mx-1\.5 {
  scroll-margin-inline: 6px !important;
}

.k-scroll-mx-2 {
  scroll-margin-inline: 8px;
}

.\!k-scroll-mx-2 {
  scroll-margin-inline: 8px !important;
}

.k-scroll-mx-2\.5 {
  scroll-margin-inline: 10px;
}

.\!k-scroll-mx-2\.5 {
  scroll-margin-inline: 10px !important;
}

.k-scroll-mx-3 {
  scroll-margin-inline: 12px;
}

.\!k-scroll-mx-3 {
  scroll-margin-inline: 12px !important;
}

.k-scroll-mx-3\.5 {
  scroll-margin-inline: 14px;
}

.\!k-scroll-mx-3\.5 {
  scroll-margin-inline: 14px !important;
}

.k-scroll-mx-4 {
  scroll-margin-inline: 16px;
}

.\!k-scroll-mx-4 {
  scroll-margin-inline: 16px !important;
}

.k-scroll-mx-4\.5 {
  scroll-margin-inline: 18px;
}

.\!k-scroll-mx-4\.5 {
  scroll-margin-inline: 18px !important;
}

.k-scroll-mx-5 {
  scroll-margin-inline: 20px;
}

.\!k-scroll-mx-5 {
  scroll-margin-inline: 20px !important;
}

.k-scroll-mx-5\.5 {
  scroll-margin-inline: 22px;
}

.\!k-scroll-mx-5\.5 {
  scroll-margin-inline: 22px !important;
}

.k-scroll-mx-6 {
  scroll-margin-inline: 24px;
}

.\!k-scroll-mx-6 {
  scroll-margin-inline: 24px !important;
}

.k-scroll-mx-6\.5 {
  scroll-margin-inline: 26px;
}

.\!k-scroll-mx-6\.5 {
  scroll-margin-inline: 26px !important;
}

.k-scroll-mx-7 {
  scroll-margin-inline: 28px;
}

.\!k-scroll-mx-7 {
  scroll-margin-inline: 28px !important;
}

.k-scroll-mx-7\.5 {
  scroll-margin-inline: 30px;
}

.\!k-scroll-mx-7\.5 {
  scroll-margin-inline: 30px !important;
}

.k-scroll-mx-8 {
  scroll-margin-inline: 32px;
}

.\!k-scroll-mx-8 {
  scroll-margin-inline: 32px !important;
}

.k-scroll-mx-9 {
  scroll-margin-inline: 36px;
}

.\!k-scroll-mx-9 {
  scroll-margin-inline: 36px !important;
}

.k-scroll-mx-10 {
  scroll-margin-inline: 40px;
}

.\!k-scroll-mx-10 {
  scroll-margin-inline: 40px !important;
}

.k-scroll-mx-11 {
  scroll-margin-inline: 44px;
}

.\!k-scroll-mx-11 {
  scroll-margin-inline: 44px !important;
}

.k-scroll-mx-12 {
  scroll-margin-inline: 48px;
}

.\!k-scroll-mx-12 {
  scroll-margin-inline: 48px !important;
}

.k-scroll-mx-13 {
  scroll-margin-inline: 52px;
}

.\!k-scroll-mx-13 {
  scroll-margin-inline: 52px !important;
}

.k-scroll-mx-14 {
  scroll-margin-inline: 56px;
}

.\!k-scroll-mx-14 {
  scroll-margin-inline: 56px !important;
}

.k-scroll-mx-15 {
  scroll-margin-inline: 60px;
}

.\!k-scroll-mx-15 {
  scroll-margin-inline: 60px !important;
}

.k-scroll-mx-16 {
  scroll-margin-inline: 64px;
}

.\!k-scroll-mx-16 {
  scroll-margin-inline: 64px !important;
}

.k-scroll-mx-17 {
  scroll-margin-inline: 68px;
}

.\!k-scroll-mx-17 {
  scroll-margin-inline: 68px !important;
}

.k-scroll-mx-18 {
  scroll-margin-inline: 72px;
}

.\!k-scroll-mx-18 {
  scroll-margin-inline: 72px !important;
}

.k-scroll-mx-19 {
  scroll-margin-inline: 76px;
}

.\!k-scroll-mx-19 {
  scroll-margin-inline: 76px !important;
}

.k-scroll-mx-20 {
  scroll-margin-inline: 80px;
}

.\!k-scroll-mx-20 {
  scroll-margin-inline: 80px !important;
}

.k-scroll-mx-21 {
  scroll-margin-inline: 84px;
}

.\!k-scroll-mx-21 {
  scroll-margin-inline: 84px !important;
}

.k-scroll-mx-22 {
  scroll-margin-inline: 88px;
}

.\!k-scroll-mx-22 {
  scroll-margin-inline: 88px !important;
}

.k-scroll-mx-23 {
  scroll-margin-inline: 92px;
}

.\!k-scroll-mx-23 {
  scroll-margin-inline: 92px !important;
}

.k-scroll-mx-24 {
  scroll-margin-inline: 96px;
}

.\!k-scroll-mx-24 {
  scroll-margin-inline: 96px !important;
}

.k-scroll-my-0 {
  scroll-margin-block: 0;
}

.\!k-scroll-my-0 {
  scroll-margin-block: 0 !important;
}

.k-scroll-my-1px {
  scroll-margin-block: 1px;
}

.\!k-scroll-my-1px {
  scroll-margin-block: 1px !important;
}

.k-scroll-my-0\.5 {
  scroll-margin-block: 2px;
}

.\!k-scroll-my-0\.5 {
  scroll-margin-block: 2px !important;
}

.k-scroll-my-1 {
  scroll-margin-block: 4px;
}

.\!k-scroll-my-1 {
  scroll-margin-block: 4px !important;
}

.k-scroll-my-1\.5 {
  scroll-margin-block: 6px;
}

.\!k-scroll-my-1\.5 {
  scroll-margin-block: 6px !important;
}

.k-scroll-my-2 {
  scroll-margin-block: 8px;
}

.\!k-scroll-my-2 {
  scroll-margin-block: 8px !important;
}

.k-scroll-my-2\.5 {
  scroll-margin-block: 10px;
}

.\!k-scroll-my-2\.5 {
  scroll-margin-block: 10px !important;
}

.k-scroll-my-3 {
  scroll-margin-block: 12px;
}

.\!k-scroll-my-3 {
  scroll-margin-block: 12px !important;
}

.k-scroll-my-3\.5 {
  scroll-margin-block: 14px;
}

.\!k-scroll-my-3\.5 {
  scroll-margin-block: 14px !important;
}

.k-scroll-my-4 {
  scroll-margin-block: 16px;
}

.\!k-scroll-my-4 {
  scroll-margin-block: 16px !important;
}

.k-scroll-my-4\.5 {
  scroll-margin-block: 18px;
}

.\!k-scroll-my-4\.5 {
  scroll-margin-block: 18px !important;
}

.k-scroll-my-5 {
  scroll-margin-block: 20px;
}

.\!k-scroll-my-5 {
  scroll-margin-block: 20px !important;
}

.k-scroll-my-5\.5 {
  scroll-margin-block: 22px;
}

.\!k-scroll-my-5\.5 {
  scroll-margin-block: 22px !important;
}

.k-scroll-my-6 {
  scroll-margin-block: 24px;
}

.\!k-scroll-my-6 {
  scroll-margin-block: 24px !important;
}

.k-scroll-my-6\.5 {
  scroll-margin-block: 26px;
}

.\!k-scroll-my-6\.5 {
  scroll-margin-block: 26px !important;
}

.k-scroll-my-7 {
  scroll-margin-block: 28px;
}

.\!k-scroll-my-7 {
  scroll-margin-block: 28px !important;
}

.k-scroll-my-7\.5 {
  scroll-margin-block: 30px;
}

.\!k-scroll-my-7\.5 {
  scroll-margin-block: 30px !important;
}

.k-scroll-my-8 {
  scroll-margin-block: 32px;
}

.\!k-scroll-my-8 {
  scroll-margin-block: 32px !important;
}

.k-scroll-my-9 {
  scroll-margin-block: 36px;
}

.\!k-scroll-my-9 {
  scroll-margin-block: 36px !important;
}

.k-scroll-my-10 {
  scroll-margin-block: 40px;
}

.\!k-scroll-my-10 {
  scroll-margin-block: 40px !important;
}

.k-scroll-my-11 {
  scroll-margin-block: 44px;
}

.\!k-scroll-my-11 {
  scroll-margin-block: 44px !important;
}

.k-scroll-my-12 {
  scroll-margin-block: 48px;
}

.\!k-scroll-my-12 {
  scroll-margin-block: 48px !important;
}

.k-scroll-my-13 {
  scroll-margin-block: 52px;
}

.\!k-scroll-my-13 {
  scroll-margin-block: 52px !important;
}

.k-scroll-my-14 {
  scroll-margin-block: 56px;
}

.\!k-scroll-my-14 {
  scroll-margin-block: 56px !important;
}

.k-scroll-my-15 {
  scroll-margin-block: 60px;
}

.\!k-scroll-my-15 {
  scroll-margin-block: 60px !important;
}

.k-scroll-my-16 {
  scroll-margin-block: 64px;
}

.\!k-scroll-my-16 {
  scroll-margin-block: 64px !important;
}

.k-scroll-my-17 {
  scroll-margin-block: 68px;
}

.\!k-scroll-my-17 {
  scroll-margin-block: 68px !important;
}

.k-scroll-my-18 {
  scroll-margin-block: 72px;
}

.\!k-scroll-my-18 {
  scroll-margin-block: 72px !important;
}

.k-scroll-my-19 {
  scroll-margin-block: 76px;
}

.\!k-scroll-my-19 {
  scroll-margin-block: 76px !important;
}

.k-scroll-my-20 {
  scroll-margin-block: 80px;
}

.\!k-scroll-my-20 {
  scroll-margin-block: 80px !important;
}

.k-scroll-my-21 {
  scroll-margin-block: 84px;
}

.\!k-scroll-my-21 {
  scroll-margin-block: 84px !important;
}

.k-scroll-my-22 {
  scroll-margin-block: 88px;
}

.\!k-scroll-my-22 {
  scroll-margin-block: 88px !important;
}

.k-scroll-my-23 {
  scroll-margin-block: 92px;
}

.\!k-scroll-my-23 {
  scroll-margin-block: 92px !important;
}

.k-scroll-my-24 {
  scroll-margin-block: 96px;
}

.\!k-scroll-my-24 {
  scroll-margin-block: 96px !important;
}

.k-scroll-p-0 {
  scroll-padding: 0;
}

.\!k-scroll-p-0 {
  scroll-padding: 0 !important;
}

.k-scroll-p-1px {
  scroll-padding: 1px;
}

.\!k-scroll-p-1px {
  scroll-padding: 1px !important;
}

.k-scroll-p-0\.5 {
  scroll-padding: 2px;
}

.\!k-scroll-p-0\.5 {
  scroll-padding: 2px !important;
}

.k-scroll-p-1 {
  scroll-padding: 4px;
}

.\!k-scroll-p-1 {
  scroll-padding: 4px !important;
}

.k-scroll-p-1\.5 {
  scroll-padding: 6px;
}

.\!k-scroll-p-1\.5 {
  scroll-padding: 6px !important;
}

.k-scroll-p-2 {
  scroll-padding: 8px;
}

.\!k-scroll-p-2 {
  scroll-padding: 8px !important;
}

.k-scroll-p-2\.5 {
  scroll-padding: 10px;
}

.\!k-scroll-p-2\.5 {
  scroll-padding: 10px !important;
}

.k-scroll-p-3 {
  scroll-padding: 12px;
}

.\!k-scroll-p-3 {
  scroll-padding: 12px !important;
}

.k-scroll-p-3\.5 {
  scroll-padding: 14px;
}

.\!k-scroll-p-3\.5 {
  scroll-padding: 14px !important;
}

.k-scroll-p-4 {
  scroll-padding: 16px;
}

.\!k-scroll-p-4 {
  scroll-padding: 16px !important;
}

.k-scroll-p-4\.5 {
  scroll-padding: 18px;
}

.\!k-scroll-p-4\.5 {
  scroll-padding: 18px !important;
}

.k-scroll-p-5 {
  scroll-padding: 20px;
}

.\!k-scroll-p-5 {
  scroll-padding: 20px !important;
}

.k-scroll-p-5\.5 {
  scroll-padding: 22px;
}

.\!k-scroll-p-5\.5 {
  scroll-padding: 22px !important;
}

.k-scroll-p-6 {
  scroll-padding: 24px;
}

.\!k-scroll-p-6 {
  scroll-padding: 24px !important;
}

.k-scroll-p-6\.5 {
  scroll-padding: 26px;
}

.\!k-scroll-p-6\.5 {
  scroll-padding: 26px !important;
}

.k-scroll-p-7 {
  scroll-padding: 28px;
}

.\!k-scroll-p-7 {
  scroll-padding: 28px !important;
}

.k-scroll-p-7\.5 {
  scroll-padding: 30px;
}

.\!k-scroll-p-7\.5 {
  scroll-padding: 30px !important;
}

.k-scroll-p-8 {
  scroll-padding: 32px;
}

.\!k-scroll-p-8 {
  scroll-padding: 32px !important;
}

.k-scroll-p-9 {
  scroll-padding: 36px;
}

.\!k-scroll-p-9 {
  scroll-padding: 36px !important;
}

.k-scroll-p-10 {
  scroll-padding: 40px;
}

.\!k-scroll-p-10 {
  scroll-padding: 40px !important;
}

.k-scroll-p-11 {
  scroll-padding: 44px;
}

.\!k-scroll-p-11 {
  scroll-padding: 44px !important;
}

.k-scroll-p-12 {
  scroll-padding: 48px;
}

.\!k-scroll-p-12 {
  scroll-padding: 48px !important;
}

.k-scroll-p-13 {
  scroll-padding: 52px;
}

.\!k-scroll-p-13 {
  scroll-padding: 52px !important;
}

.k-scroll-p-14 {
  scroll-padding: 56px;
}

.\!k-scroll-p-14 {
  scroll-padding: 56px !important;
}

.k-scroll-p-15 {
  scroll-padding: 60px;
}

.\!k-scroll-p-15 {
  scroll-padding: 60px !important;
}

.k-scroll-p-16 {
  scroll-padding: 64px;
}

.\!k-scroll-p-16 {
  scroll-padding: 64px !important;
}

.k-scroll-p-17 {
  scroll-padding: 68px;
}

.\!k-scroll-p-17 {
  scroll-padding: 68px !important;
}

.k-scroll-p-18 {
  scroll-padding: 72px;
}

.\!k-scroll-p-18 {
  scroll-padding: 72px !important;
}

.k-scroll-p-19 {
  scroll-padding: 76px;
}

.\!k-scroll-p-19 {
  scroll-padding: 76px !important;
}

.k-scroll-p-20 {
  scroll-padding: 80px;
}

.\!k-scroll-p-20 {
  scroll-padding: 80px !important;
}

.k-scroll-p-21 {
  scroll-padding: 84px;
}

.\!k-scroll-p-21 {
  scroll-padding: 84px !important;
}

.k-scroll-p-22 {
  scroll-padding: 88px;
}

.\!k-scroll-p-22 {
  scroll-padding: 88px !important;
}

.k-scroll-p-23 {
  scroll-padding: 92px;
}

.\!k-scroll-p-23 {
  scroll-padding: 92px !important;
}

.k-scroll-p-24 {
  scroll-padding: 96px;
}

.\!k-scroll-p-24 {
  scroll-padding: 96px !important;
}

.k-scroll-pt-0 {
  scroll-padding-top: 0;
}

.\!k-scroll-pt-0 {
  scroll-padding-top: 0 !important;
}

.k-scroll-pt-1px {
  scroll-padding-top: 1px;
}

.\!k-scroll-pt-1px {
  scroll-padding-top: 1px !important;
}

.k-scroll-pt-0\.5 {
  scroll-padding-top: 2px;
}

.\!k-scroll-pt-0\.5 {
  scroll-padding-top: 2px !important;
}

.k-scroll-pt-1 {
  scroll-padding-top: 4px;
}

.\!k-scroll-pt-1 {
  scroll-padding-top: 4px !important;
}

.k-scroll-pt-1\.5 {
  scroll-padding-top: 6px;
}

.\!k-scroll-pt-1\.5 {
  scroll-padding-top: 6px !important;
}

.k-scroll-pt-2 {
  scroll-padding-top: 8px;
}

.\!k-scroll-pt-2 {
  scroll-padding-top: 8px !important;
}

.k-scroll-pt-2\.5 {
  scroll-padding-top: 10px;
}

.\!k-scroll-pt-2\.5 {
  scroll-padding-top: 10px !important;
}

.k-scroll-pt-3 {
  scroll-padding-top: 12px;
}

.\!k-scroll-pt-3 {
  scroll-padding-top: 12px !important;
}

.k-scroll-pt-3\.5 {
  scroll-padding-top: 14px;
}

.\!k-scroll-pt-3\.5 {
  scroll-padding-top: 14px !important;
}

.k-scroll-pt-4 {
  scroll-padding-top: 16px;
}

.\!k-scroll-pt-4 {
  scroll-padding-top: 16px !important;
}

.k-scroll-pt-4\.5 {
  scroll-padding-top: 18px;
}

.\!k-scroll-pt-4\.5 {
  scroll-padding-top: 18px !important;
}

.k-scroll-pt-5 {
  scroll-padding-top: 20px;
}

.\!k-scroll-pt-5 {
  scroll-padding-top: 20px !important;
}

.k-scroll-pt-5\.5 {
  scroll-padding-top: 22px;
}

.\!k-scroll-pt-5\.5 {
  scroll-padding-top: 22px !important;
}

.k-scroll-pt-6 {
  scroll-padding-top: 24px;
}

.\!k-scroll-pt-6 {
  scroll-padding-top: 24px !important;
}

.k-scroll-pt-6\.5 {
  scroll-padding-top: 26px;
}

.\!k-scroll-pt-6\.5 {
  scroll-padding-top: 26px !important;
}

.k-scroll-pt-7 {
  scroll-padding-top: 28px;
}

.\!k-scroll-pt-7 {
  scroll-padding-top: 28px !important;
}

.k-scroll-pt-7\.5 {
  scroll-padding-top: 30px;
}

.\!k-scroll-pt-7\.5 {
  scroll-padding-top: 30px !important;
}

.k-scroll-pt-8 {
  scroll-padding-top: 32px;
}

.\!k-scroll-pt-8 {
  scroll-padding-top: 32px !important;
}

.k-scroll-pt-9 {
  scroll-padding-top: 36px;
}

.\!k-scroll-pt-9 {
  scroll-padding-top: 36px !important;
}

.k-scroll-pt-10 {
  scroll-padding-top: 40px;
}

.\!k-scroll-pt-10 {
  scroll-padding-top: 40px !important;
}

.k-scroll-pt-11 {
  scroll-padding-top: 44px;
}

.\!k-scroll-pt-11 {
  scroll-padding-top: 44px !important;
}

.k-scroll-pt-12 {
  scroll-padding-top: 48px;
}

.\!k-scroll-pt-12 {
  scroll-padding-top: 48px !important;
}

.k-scroll-pt-13 {
  scroll-padding-top: 52px;
}

.\!k-scroll-pt-13 {
  scroll-padding-top: 52px !important;
}

.k-scroll-pt-14 {
  scroll-padding-top: 56px;
}

.\!k-scroll-pt-14 {
  scroll-padding-top: 56px !important;
}

.k-scroll-pt-15 {
  scroll-padding-top: 60px;
}

.\!k-scroll-pt-15 {
  scroll-padding-top: 60px !important;
}

.k-scroll-pt-16 {
  scroll-padding-top: 64px;
}

.\!k-scroll-pt-16 {
  scroll-padding-top: 64px !important;
}

.k-scroll-pt-17 {
  scroll-padding-top: 68px;
}

.\!k-scroll-pt-17 {
  scroll-padding-top: 68px !important;
}

.k-scroll-pt-18 {
  scroll-padding-top: 72px;
}

.\!k-scroll-pt-18 {
  scroll-padding-top: 72px !important;
}

.k-scroll-pt-19 {
  scroll-padding-top: 76px;
}

.\!k-scroll-pt-19 {
  scroll-padding-top: 76px !important;
}

.k-scroll-pt-20 {
  scroll-padding-top: 80px;
}

.\!k-scroll-pt-20 {
  scroll-padding-top: 80px !important;
}

.k-scroll-pt-21 {
  scroll-padding-top: 84px;
}

.\!k-scroll-pt-21 {
  scroll-padding-top: 84px !important;
}

.k-scroll-pt-22 {
  scroll-padding-top: 88px;
}

.\!k-scroll-pt-22 {
  scroll-padding-top: 88px !important;
}

.k-scroll-pt-23 {
  scroll-padding-top: 92px;
}

.\!k-scroll-pt-23 {
  scroll-padding-top: 92px !important;
}

.k-scroll-pt-24 {
  scroll-padding-top: 96px;
}

.\!k-scroll-pt-24 {
  scroll-padding-top: 96px !important;
}

.k-scroll-pr-0 {
  scroll-padding-right: 0;
}

.\!k-scroll-pr-0 {
  scroll-padding-right: 0 !important;
}

.k-scroll-pr-1px {
  scroll-padding-right: 1px;
}

.\!k-scroll-pr-1px {
  scroll-padding-right: 1px !important;
}

.k-scroll-pr-0\.5 {
  scroll-padding-right: 2px;
}

.\!k-scroll-pr-0\.5 {
  scroll-padding-right: 2px !important;
}

.k-scroll-pr-1 {
  scroll-padding-right: 4px;
}

.\!k-scroll-pr-1 {
  scroll-padding-right: 4px !important;
}

.k-scroll-pr-1\.5 {
  scroll-padding-right: 6px;
}

.\!k-scroll-pr-1\.5 {
  scroll-padding-right: 6px !important;
}

.k-scroll-pr-2 {
  scroll-padding-right: 8px;
}

.\!k-scroll-pr-2 {
  scroll-padding-right: 8px !important;
}

.k-scroll-pr-2\.5 {
  scroll-padding-right: 10px;
}

.\!k-scroll-pr-2\.5 {
  scroll-padding-right: 10px !important;
}

.k-scroll-pr-3 {
  scroll-padding-right: 12px;
}

.\!k-scroll-pr-3 {
  scroll-padding-right: 12px !important;
}

.k-scroll-pr-3\.5 {
  scroll-padding-right: 14px;
}

.\!k-scroll-pr-3\.5 {
  scroll-padding-right: 14px !important;
}

.k-scroll-pr-4 {
  scroll-padding-right: 16px;
}

.\!k-scroll-pr-4 {
  scroll-padding-right: 16px !important;
}

.k-scroll-pr-4\.5 {
  scroll-padding-right: 18px;
}

.\!k-scroll-pr-4\.5 {
  scroll-padding-right: 18px !important;
}

.k-scroll-pr-5 {
  scroll-padding-right: 20px;
}

.\!k-scroll-pr-5 {
  scroll-padding-right: 20px !important;
}

.k-scroll-pr-5\.5 {
  scroll-padding-right: 22px;
}

.\!k-scroll-pr-5\.5 {
  scroll-padding-right: 22px !important;
}

.k-scroll-pr-6 {
  scroll-padding-right: 24px;
}

.\!k-scroll-pr-6 {
  scroll-padding-right: 24px !important;
}

.k-scroll-pr-6\.5 {
  scroll-padding-right: 26px;
}

.\!k-scroll-pr-6\.5 {
  scroll-padding-right: 26px !important;
}

.k-scroll-pr-7 {
  scroll-padding-right: 28px;
}

.\!k-scroll-pr-7 {
  scroll-padding-right: 28px !important;
}

.k-scroll-pr-7\.5 {
  scroll-padding-right: 30px;
}

.\!k-scroll-pr-7\.5 {
  scroll-padding-right: 30px !important;
}

.k-scroll-pr-8 {
  scroll-padding-right: 32px;
}

.\!k-scroll-pr-8 {
  scroll-padding-right: 32px !important;
}

.k-scroll-pr-9 {
  scroll-padding-right: 36px;
}

.\!k-scroll-pr-9 {
  scroll-padding-right: 36px !important;
}

.k-scroll-pr-10 {
  scroll-padding-right: 40px;
}

.\!k-scroll-pr-10 {
  scroll-padding-right: 40px !important;
}

.k-scroll-pr-11 {
  scroll-padding-right: 44px;
}

.\!k-scroll-pr-11 {
  scroll-padding-right: 44px !important;
}

.k-scroll-pr-12 {
  scroll-padding-right: 48px;
}

.\!k-scroll-pr-12 {
  scroll-padding-right: 48px !important;
}

.k-scroll-pr-13 {
  scroll-padding-right: 52px;
}

.\!k-scroll-pr-13 {
  scroll-padding-right: 52px !important;
}

.k-scroll-pr-14 {
  scroll-padding-right: 56px;
}

.\!k-scroll-pr-14 {
  scroll-padding-right: 56px !important;
}

.k-scroll-pr-15 {
  scroll-padding-right: 60px;
}

.\!k-scroll-pr-15 {
  scroll-padding-right: 60px !important;
}

.k-scroll-pr-16 {
  scroll-padding-right: 64px;
}

.\!k-scroll-pr-16 {
  scroll-padding-right: 64px !important;
}

.k-scroll-pr-17 {
  scroll-padding-right: 68px;
}

.\!k-scroll-pr-17 {
  scroll-padding-right: 68px !important;
}

.k-scroll-pr-18 {
  scroll-padding-right: 72px;
}

.\!k-scroll-pr-18 {
  scroll-padding-right: 72px !important;
}

.k-scroll-pr-19 {
  scroll-padding-right: 76px;
}

.\!k-scroll-pr-19 {
  scroll-padding-right: 76px !important;
}

.k-scroll-pr-20 {
  scroll-padding-right: 80px;
}

.\!k-scroll-pr-20 {
  scroll-padding-right: 80px !important;
}

.k-scroll-pr-21 {
  scroll-padding-right: 84px;
}

.\!k-scroll-pr-21 {
  scroll-padding-right: 84px !important;
}

.k-scroll-pr-22 {
  scroll-padding-right: 88px;
}

.\!k-scroll-pr-22 {
  scroll-padding-right: 88px !important;
}

.k-scroll-pr-23 {
  scroll-padding-right: 92px;
}

.\!k-scroll-pr-23 {
  scroll-padding-right: 92px !important;
}

.k-scroll-pr-24 {
  scroll-padding-right: 96px;
}

.\!k-scroll-pr-24 {
  scroll-padding-right: 96px !important;
}

.k-scroll-pb-0 {
  scroll-padding-bottom: 0;
}

.\!k-scroll-pb-0 {
  scroll-padding-bottom: 0 !important;
}

.k-scroll-pb-1px {
  scroll-padding-bottom: 1px;
}

.\!k-scroll-pb-1px {
  scroll-padding-bottom: 1px !important;
}

.k-scroll-pb-0\.5 {
  scroll-padding-bottom: 2px;
}

.\!k-scroll-pb-0\.5 {
  scroll-padding-bottom: 2px !important;
}

.k-scroll-pb-1 {
  scroll-padding-bottom: 4px;
}

.\!k-scroll-pb-1 {
  scroll-padding-bottom: 4px !important;
}

.k-scroll-pb-1\.5 {
  scroll-padding-bottom: 6px;
}

.\!k-scroll-pb-1\.5 {
  scroll-padding-bottom: 6px !important;
}

.k-scroll-pb-2 {
  scroll-padding-bottom: 8px;
}

.\!k-scroll-pb-2 {
  scroll-padding-bottom: 8px !important;
}

.k-scroll-pb-2\.5 {
  scroll-padding-bottom: 10px;
}

.\!k-scroll-pb-2\.5 {
  scroll-padding-bottom: 10px !important;
}

.k-scroll-pb-3 {
  scroll-padding-bottom: 12px;
}

.\!k-scroll-pb-3 {
  scroll-padding-bottom: 12px !important;
}

.k-scroll-pb-3\.5 {
  scroll-padding-bottom: 14px;
}

.\!k-scroll-pb-3\.5 {
  scroll-padding-bottom: 14px !important;
}

.k-scroll-pb-4 {
  scroll-padding-bottom: 16px;
}

.\!k-scroll-pb-4 {
  scroll-padding-bottom: 16px !important;
}

.k-scroll-pb-4\.5 {
  scroll-padding-bottom: 18px;
}

.\!k-scroll-pb-4\.5 {
  scroll-padding-bottom: 18px !important;
}

.k-scroll-pb-5 {
  scroll-padding-bottom: 20px;
}

.\!k-scroll-pb-5 {
  scroll-padding-bottom: 20px !important;
}

.k-scroll-pb-5\.5 {
  scroll-padding-bottom: 22px;
}

.\!k-scroll-pb-5\.5 {
  scroll-padding-bottom: 22px !important;
}

.k-scroll-pb-6 {
  scroll-padding-bottom: 24px;
}

.\!k-scroll-pb-6 {
  scroll-padding-bottom: 24px !important;
}

.k-scroll-pb-6\.5 {
  scroll-padding-bottom: 26px;
}

.\!k-scroll-pb-6\.5 {
  scroll-padding-bottom: 26px !important;
}

.k-scroll-pb-7 {
  scroll-padding-bottom: 28px;
}

.\!k-scroll-pb-7 {
  scroll-padding-bottom: 28px !important;
}

.k-scroll-pb-7\.5 {
  scroll-padding-bottom: 30px;
}

.\!k-scroll-pb-7\.5 {
  scroll-padding-bottom: 30px !important;
}

.k-scroll-pb-8 {
  scroll-padding-bottom: 32px;
}

.\!k-scroll-pb-8 {
  scroll-padding-bottom: 32px !important;
}

.k-scroll-pb-9 {
  scroll-padding-bottom: 36px;
}

.\!k-scroll-pb-9 {
  scroll-padding-bottom: 36px !important;
}

.k-scroll-pb-10 {
  scroll-padding-bottom: 40px;
}

.\!k-scroll-pb-10 {
  scroll-padding-bottom: 40px !important;
}

.k-scroll-pb-11 {
  scroll-padding-bottom: 44px;
}

.\!k-scroll-pb-11 {
  scroll-padding-bottom: 44px !important;
}

.k-scroll-pb-12 {
  scroll-padding-bottom: 48px;
}

.\!k-scroll-pb-12 {
  scroll-padding-bottom: 48px !important;
}

.k-scroll-pb-13 {
  scroll-padding-bottom: 52px;
}

.\!k-scroll-pb-13 {
  scroll-padding-bottom: 52px !important;
}

.k-scroll-pb-14 {
  scroll-padding-bottom: 56px;
}

.\!k-scroll-pb-14 {
  scroll-padding-bottom: 56px !important;
}

.k-scroll-pb-15 {
  scroll-padding-bottom: 60px;
}

.\!k-scroll-pb-15 {
  scroll-padding-bottom: 60px !important;
}

.k-scroll-pb-16 {
  scroll-padding-bottom: 64px;
}

.\!k-scroll-pb-16 {
  scroll-padding-bottom: 64px !important;
}

.k-scroll-pb-17 {
  scroll-padding-bottom: 68px;
}

.\!k-scroll-pb-17 {
  scroll-padding-bottom: 68px !important;
}

.k-scroll-pb-18 {
  scroll-padding-bottom: 72px;
}

.\!k-scroll-pb-18 {
  scroll-padding-bottom: 72px !important;
}

.k-scroll-pb-19 {
  scroll-padding-bottom: 76px;
}

.\!k-scroll-pb-19 {
  scroll-padding-bottom: 76px !important;
}

.k-scroll-pb-20 {
  scroll-padding-bottom: 80px;
}

.\!k-scroll-pb-20 {
  scroll-padding-bottom: 80px !important;
}

.k-scroll-pb-21 {
  scroll-padding-bottom: 84px;
}

.\!k-scroll-pb-21 {
  scroll-padding-bottom: 84px !important;
}

.k-scroll-pb-22 {
  scroll-padding-bottom: 88px;
}

.\!k-scroll-pb-22 {
  scroll-padding-bottom: 88px !important;
}

.k-scroll-pb-23 {
  scroll-padding-bottom: 92px;
}

.\!k-scroll-pb-23 {
  scroll-padding-bottom: 92px !important;
}

.k-scroll-pb-24 {
  scroll-padding-bottom: 96px;
}

.\!k-scroll-pb-24 {
  scroll-padding-bottom: 96px !important;
}

.k-scroll-pl-0 {
  scroll-padding-left: 0;
}

.\!k-scroll-pl-0 {
  scroll-padding-left: 0 !important;
}

.k-scroll-pl-1px {
  scroll-padding-left: 1px;
}

.\!k-scroll-pl-1px {
  scroll-padding-left: 1px !important;
}

.k-scroll-pl-0\.5 {
  scroll-padding-left: 2px;
}

.\!k-scroll-pl-0\.5 {
  scroll-padding-left: 2px !important;
}

.k-scroll-pl-1 {
  scroll-padding-left: 4px;
}

.\!k-scroll-pl-1 {
  scroll-padding-left: 4px !important;
}

.k-scroll-pl-1\.5 {
  scroll-padding-left: 6px;
}

.\!k-scroll-pl-1\.5 {
  scroll-padding-left: 6px !important;
}

.k-scroll-pl-2 {
  scroll-padding-left: 8px;
}

.\!k-scroll-pl-2 {
  scroll-padding-left: 8px !important;
}

.k-scroll-pl-2\.5 {
  scroll-padding-left: 10px;
}

.\!k-scroll-pl-2\.5 {
  scroll-padding-left: 10px !important;
}

.k-scroll-pl-3 {
  scroll-padding-left: 12px;
}

.\!k-scroll-pl-3 {
  scroll-padding-left: 12px !important;
}

.k-scroll-pl-3\.5 {
  scroll-padding-left: 14px;
}

.\!k-scroll-pl-3\.5 {
  scroll-padding-left: 14px !important;
}

.k-scroll-pl-4 {
  scroll-padding-left: 16px;
}

.\!k-scroll-pl-4 {
  scroll-padding-left: 16px !important;
}

.k-scroll-pl-4\.5 {
  scroll-padding-left: 18px;
}

.\!k-scroll-pl-4\.5 {
  scroll-padding-left: 18px !important;
}

.k-scroll-pl-5 {
  scroll-padding-left: 20px;
}

.\!k-scroll-pl-5 {
  scroll-padding-left: 20px !important;
}

.k-scroll-pl-5\.5 {
  scroll-padding-left: 22px;
}

.\!k-scroll-pl-5\.5 {
  scroll-padding-left: 22px !important;
}

.k-scroll-pl-6 {
  scroll-padding-left: 24px;
}

.\!k-scroll-pl-6 {
  scroll-padding-left: 24px !important;
}

.k-scroll-pl-6\.5 {
  scroll-padding-left: 26px;
}

.\!k-scroll-pl-6\.5 {
  scroll-padding-left: 26px !important;
}

.k-scroll-pl-7 {
  scroll-padding-left: 28px;
}

.\!k-scroll-pl-7 {
  scroll-padding-left: 28px !important;
}

.k-scroll-pl-7\.5 {
  scroll-padding-left: 30px;
}

.\!k-scroll-pl-7\.5 {
  scroll-padding-left: 30px !important;
}

.k-scroll-pl-8 {
  scroll-padding-left: 32px;
}

.\!k-scroll-pl-8 {
  scroll-padding-left: 32px !important;
}

.k-scroll-pl-9 {
  scroll-padding-left: 36px;
}

.\!k-scroll-pl-9 {
  scroll-padding-left: 36px !important;
}

.k-scroll-pl-10 {
  scroll-padding-left: 40px;
}

.\!k-scroll-pl-10 {
  scroll-padding-left: 40px !important;
}

.k-scroll-pl-11 {
  scroll-padding-left: 44px;
}

.\!k-scroll-pl-11 {
  scroll-padding-left: 44px !important;
}

.k-scroll-pl-12 {
  scroll-padding-left: 48px;
}

.\!k-scroll-pl-12 {
  scroll-padding-left: 48px !important;
}

.k-scroll-pl-13 {
  scroll-padding-left: 52px;
}

.\!k-scroll-pl-13 {
  scroll-padding-left: 52px !important;
}

.k-scroll-pl-14 {
  scroll-padding-left: 56px;
}

.\!k-scroll-pl-14 {
  scroll-padding-left: 56px !important;
}

.k-scroll-pl-15 {
  scroll-padding-left: 60px;
}

.\!k-scroll-pl-15 {
  scroll-padding-left: 60px !important;
}

.k-scroll-pl-16 {
  scroll-padding-left: 64px;
}

.\!k-scroll-pl-16 {
  scroll-padding-left: 64px !important;
}

.k-scroll-pl-17 {
  scroll-padding-left: 68px;
}

.\!k-scroll-pl-17 {
  scroll-padding-left: 68px !important;
}

.k-scroll-pl-18 {
  scroll-padding-left: 72px;
}

.\!k-scroll-pl-18 {
  scroll-padding-left: 72px !important;
}

.k-scroll-pl-19 {
  scroll-padding-left: 76px;
}

.\!k-scroll-pl-19 {
  scroll-padding-left: 76px !important;
}

.k-scroll-pl-20 {
  scroll-padding-left: 80px;
}

.\!k-scroll-pl-20 {
  scroll-padding-left: 80px !important;
}

.k-scroll-pl-21 {
  scroll-padding-left: 84px;
}

.\!k-scroll-pl-21 {
  scroll-padding-left: 84px !important;
}

.k-scroll-pl-22 {
  scroll-padding-left: 88px;
}

.\!k-scroll-pl-22 {
  scroll-padding-left: 88px !important;
}

.k-scroll-pl-23 {
  scroll-padding-left: 92px;
}

.\!k-scroll-pl-23 {
  scroll-padding-left: 92px !important;
}

.k-scroll-pl-24 {
  scroll-padding-left: 96px;
}

.\!k-scroll-pl-24 {
  scroll-padding-left: 96px !important;
}

.k-scroll-px-0 {
  scroll-padding-inline: 0;
}

.\!k-scroll-px-0 {
  scroll-padding-inline: 0 !important;
}

.k-scroll-px-1px {
  scroll-padding-inline: 1px;
}

.\!k-scroll-px-1px {
  scroll-padding-inline: 1px !important;
}

.k-scroll-px-0\.5 {
  scroll-padding-inline: 2px;
}

.\!k-scroll-px-0\.5 {
  scroll-padding-inline: 2px !important;
}

.k-scroll-px-1 {
  scroll-padding-inline: 4px;
}

.\!k-scroll-px-1 {
  scroll-padding-inline: 4px !important;
}

.k-scroll-px-1\.5 {
  scroll-padding-inline: 6px;
}

.\!k-scroll-px-1\.5 {
  scroll-padding-inline: 6px !important;
}

.k-scroll-px-2 {
  scroll-padding-inline: 8px;
}

.\!k-scroll-px-2 {
  scroll-padding-inline: 8px !important;
}

.k-scroll-px-2\.5 {
  scroll-padding-inline: 10px;
}

.\!k-scroll-px-2\.5 {
  scroll-padding-inline: 10px !important;
}

.k-scroll-px-3 {
  scroll-padding-inline: 12px;
}

.\!k-scroll-px-3 {
  scroll-padding-inline: 12px !important;
}

.k-scroll-px-3\.5 {
  scroll-padding-inline: 14px;
}

.\!k-scroll-px-3\.5 {
  scroll-padding-inline: 14px !important;
}

.k-scroll-px-4 {
  scroll-padding-inline: 16px;
}

.\!k-scroll-px-4 {
  scroll-padding-inline: 16px !important;
}

.k-scroll-px-4\.5 {
  scroll-padding-inline: 18px;
}

.\!k-scroll-px-4\.5 {
  scroll-padding-inline: 18px !important;
}

.k-scroll-px-5 {
  scroll-padding-inline: 20px;
}

.\!k-scroll-px-5 {
  scroll-padding-inline: 20px !important;
}

.k-scroll-px-5\.5 {
  scroll-padding-inline: 22px;
}

.\!k-scroll-px-5\.5 {
  scroll-padding-inline: 22px !important;
}

.k-scroll-px-6 {
  scroll-padding-inline: 24px;
}

.\!k-scroll-px-6 {
  scroll-padding-inline: 24px !important;
}

.k-scroll-px-6\.5 {
  scroll-padding-inline: 26px;
}

.\!k-scroll-px-6\.5 {
  scroll-padding-inline: 26px !important;
}

.k-scroll-px-7 {
  scroll-padding-inline: 28px;
}

.\!k-scroll-px-7 {
  scroll-padding-inline: 28px !important;
}

.k-scroll-px-7\.5 {
  scroll-padding-inline: 30px;
}

.\!k-scroll-px-7\.5 {
  scroll-padding-inline: 30px !important;
}

.k-scroll-px-8 {
  scroll-padding-inline: 32px;
}

.\!k-scroll-px-8 {
  scroll-padding-inline: 32px !important;
}

.k-scroll-px-9 {
  scroll-padding-inline: 36px;
}

.\!k-scroll-px-9 {
  scroll-padding-inline: 36px !important;
}

.k-scroll-px-10 {
  scroll-padding-inline: 40px;
}

.\!k-scroll-px-10 {
  scroll-padding-inline: 40px !important;
}

.k-scroll-px-11 {
  scroll-padding-inline: 44px;
}

.\!k-scroll-px-11 {
  scroll-padding-inline: 44px !important;
}

.k-scroll-px-12 {
  scroll-padding-inline: 48px;
}

.\!k-scroll-px-12 {
  scroll-padding-inline: 48px !important;
}

.k-scroll-px-13 {
  scroll-padding-inline: 52px;
}

.\!k-scroll-px-13 {
  scroll-padding-inline: 52px !important;
}

.k-scroll-px-14 {
  scroll-padding-inline: 56px;
}

.\!k-scroll-px-14 {
  scroll-padding-inline: 56px !important;
}

.k-scroll-px-15 {
  scroll-padding-inline: 60px;
}

.\!k-scroll-px-15 {
  scroll-padding-inline: 60px !important;
}

.k-scroll-px-16 {
  scroll-padding-inline: 64px;
}

.\!k-scroll-px-16 {
  scroll-padding-inline: 64px !important;
}

.k-scroll-px-17 {
  scroll-padding-inline: 68px;
}

.\!k-scroll-px-17 {
  scroll-padding-inline: 68px !important;
}

.k-scroll-px-18 {
  scroll-padding-inline: 72px;
}

.\!k-scroll-px-18 {
  scroll-padding-inline: 72px !important;
}

.k-scroll-px-19 {
  scroll-padding-inline: 76px;
}

.\!k-scroll-px-19 {
  scroll-padding-inline: 76px !important;
}

.k-scroll-px-20 {
  scroll-padding-inline: 80px;
}

.\!k-scroll-px-20 {
  scroll-padding-inline: 80px !important;
}

.k-scroll-px-21 {
  scroll-padding-inline: 84px;
}

.\!k-scroll-px-21 {
  scroll-padding-inline: 84px !important;
}

.k-scroll-px-22 {
  scroll-padding-inline: 88px;
}

.\!k-scroll-px-22 {
  scroll-padding-inline: 88px !important;
}

.k-scroll-px-23 {
  scroll-padding-inline: 92px;
}

.\!k-scroll-px-23 {
  scroll-padding-inline: 92px !important;
}

.k-scroll-px-24 {
  scroll-padding-inline: 96px;
}

.\!k-scroll-px-24 {
  scroll-padding-inline: 96px !important;
}

.k-scroll-py-0 {
  scroll-padding-block: 0;
}

.\!k-scroll-py-0 {
  scroll-padding-block: 0 !important;
}

.k-scroll-py-1px {
  scroll-padding-block: 1px;
}

.\!k-scroll-py-1px {
  scroll-padding-block: 1px !important;
}

.k-scroll-py-0\.5 {
  scroll-padding-block: 2px;
}

.\!k-scroll-py-0\.5 {
  scroll-padding-block: 2px !important;
}

.k-scroll-py-1 {
  scroll-padding-block: 4px;
}

.\!k-scroll-py-1 {
  scroll-padding-block: 4px !important;
}

.k-scroll-py-1\.5 {
  scroll-padding-block: 6px;
}

.\!k-scroll-py-1\.5 {
  scroll-padding-block: 6px !important;
}

.k-scroll-py-2 {
  scroll-padding-block: 8px;
}

.\!k-scroll-py-2 {
  scroll-padding-block: 8px !important;
}

.k-scroll-py-2\.5 {
  scroll-padding-block: 10px;
}

.\!k-scroll-py-2\.5 {
  scroll-padding-block: 10px !important;
}

.k-scroll-py-3 {
  scroll-padding-block: 12px;
}

.\!k-scroll-py-3 {
  scroll-padding-block: 12px !important;
}

.k-scroll-py-3\.5 {
  scroll-padding-block: 14px;
}

.\!k-scroll-py-3\.5 {
  scroll-padding-block: 14px !important;
}

.k-scroll-py-4 {
  scroll-padding-block: 16px;
}

.\!k-scroll-py-4 {
  scroll-padding-block: 16px !important;
}

.k-scroll-py-4\.5 {
  scroll-padding-block: 18px;
}

.\!k-scroll-py-4\.5 {
  scroll-padding-block: 18px !important;
}

.k-scroll-py-5 {
  scroll-padding-block: 20px;
}

.\!k-scroll-py-5 {
  scroll-padding-block: 20px !important;
}

.k-scroll-py-5\.5 {
  scroll-padding-block: 22px;
}

.\!k-scroll-py-5\.5 {
  scroll-padding-block: 22px !important;
}

.k-scroll-py-6 {
  scroll-padding-block: 24px;
}

.\!k-scroll-py-6 {
  scroll-padding-block: 24px !important;
}

.k-scroll-py-6\.5 {
  scroll-padding-block: 26px;
}

.\!k-scroll-py-6\.5 {
  scroll-padding-block: 26px !important;
}

.k-scroll-py-7 {
  scroll-padding-block: 28px;
}

.\!k-scroll-py-7 {
  scroll-padding-block: 28px !important;
}

.k-scroll-py-7\.5 {
  scroll-padding-block: 30px;
}

.\!k-scroll-py-7\.5 {
  scroll-padding-block: 30px !important;
}

.k-scroll-py-8 {
  scroll-padding-block: 32px;
}

.\!k-scroll-py-8 {
  scroll-padding-block: 32px !important;
}

.k-scroll-py-9 {
  scroll-padding-block: 36px;
}

.\!k-scroll-py-9 {
  scroll-padding-block: 36px !important;
}

.k-scroll-py-10 {
  scroll-padding-block: 40px;
}

.\!k-scroll-py-10 {
  scroll-padding-block: 40px !important;
}

.k-scroll-py-11 {
  scroll-padding-block: 44px;
}

.\!k-scroll-py-11 {
  scroll-padding-block: 44px !important;
}

.k-scroll-py-12 {
  scroll-padding-block: 48px;
}

.\!k-scroll-py-12 {
  scroll-padding-block: 48px !important;
}

.k-scroll-py-13 {
  scroll-padding-block: 52px;
}

.\!k-scroll-py-13 {
  scroll-padding-block: 52px !important;
}

.k-scroll-py-14 {
  scroll-padding-block: 56px;
}

.\!k-scroll-py-14 {
  scroll-padding-block: 56px !important;
}

.k-scroll-py-15 {
  scroll-padding-block: 60px;
}

.\!k-scroll-py-15 {
  scroll-padding-block: 60px !important;
}

.k-scroll-py-16 {
  scroll-padding-block: 64px;
}

.\!k-scroll-py-16 {
  scroll-padding-block: 64px !important;
}

.k-scroll-py-17 {
  scroll-padding-block: 68px;
}

.\!k-scroll-py-17 {
  scroll-padding-block: 68px !important;
}

.k-scroll-py-18 {
  scroll-padding-block: 72px;
}

.\!k-scroll-py-18 {
  scroll-padding-block: 72px !important;
}

.k-scroll-py-19 {
  scroll-padding-block: 76px;
}

.\!k-scroll-py-19 {
  scroll-padding-block: 76px !important;
}

.k-scroll-py-20 {
  scroll-padding-block: 80px;
}

.\!k-scroll-py-20 {
  scroll-padding-block: 80px !important;
}

.k-scroll-py-21 {
  scroll-padding-block: 84px;
}

.\!k-scroll-py-21 {
  scroll-padding-block: 84px !important;
}

.k-scroll-py-22 {
  scroll-padding-block: 88px;
}

.\!k-scroll-py-22 {
  scroll-padding-block: 88px !important;
}

.k-scroll-py-23 {
  scroll-padding-block: 92px;
}

.\!k-scroll-py-23 {
  scroll-padding-block: 92px !important;
}

.k-scroll-py-24 {
  scroll-padding-block: 96px;
}

.\!k-scroll-py-24 {
  scroll-padding-block: 96px !important;
}

.k-touch-action-none {
  touch-action: none;
}

.\!k-touch-action-none {
  touch-action: none !important;
}

.k-touch-action-auto {
  touch-action: auto;
}

.\!k-touch-action-auto {
  touch-action: auto !important;
}

.k-touch-action-pan-x {
  touch-action: pan-x;
}

.\!k-touch-action-pan-x {
  touch-action: pan-x !important;
}

.k-touch-action-pan-left {
  touch-action: pan-left;
}

.\!k-touch-action-pan-left {
  touch-action: pan-left !important;
}

.k-touch-action-pan-right {
  touch-action: pan-right;
}

.\!k-touch-action-pan-right {
  touch-action: pan-right !important;
}

.k-touch-action-pan-y {
  touch-action: pan-y;
}

.\!k-touch-action-pan-y {
  touch-action: pan-y !important;
}

.k-touch-action-pan-up {
  touch-action: pan-up;
}

.\!k-touch-action-pan-up {
  touch-action: pan-up !important;
}

.k-touch-action-pan-down {
  touch-action: pan-down;
}

.\!k-touch-action-pan-down {
  touch-action: pan-down !important;
}

.k-touch-action-pinch-zoom {
  touch-action: pinch-zoom;
}

.\!k-touch-action-pinch-zoom {
  touch-action: pinch-zoom !important;
}

.k-touch-action-manipulation {
  touch-action: manipulation;
}

.\!k-touch-action-manipulation {
  touch-action: manipulation !important;
}

.k-user-select-none {
  user-select: none;
}

.\!k-user-select-none {
  user-select: none !important;
}

.k-user-select-auto {
  user-select: auto;
}

.\!k-user-select-auto {
  user-select: auto !important;
}

.k-user-select-text {
  user-select: text;
}

.\!k-user-select-text {
  user-select: text !important;
}

.k-user-select-all {
  user-select: all;
}

.\!k-user-select-all {
  user-select: all !important;
}

.k-user-select-contain {
  user-select: contain;
}

.\!k-user-select-contain {
  user-select: contain !important;
}

.k-will-change-auto {
  will-change: auto;
}

.\!k-will-change-auto {
  will-change: auto !important;
}

.k-will-change-scroll {
  will-change: scroll-position;
}

.\!k-will-change-scroll {
  will-change: scroll-position !important;
}

.k-will-change-contents {
  will-change: contents;
}

.\!k-will-change-contents {
  will-change: contents !important;
}

.k-will-change-transform {
  will-change: transform;
}

.\!k-will-change-transform {
  will-change: transform !important;
}

.k-fill-none {
  fill: none;
}

.\!k-fill-none {
  fill: none !important;
}

.k-fill-inherit {
  fill: inherit;
}

.\!k-fill-inherit {
  fill: inherit !important;
}

.k-fill-current {
  fill: currentColor;
}

.\!k-fill-current {
  fill: currentColor !important;
}

.k-fill-transparent {
  fill: transparent;
}

.\!k-fill-transparent {
  fill: transparent !important;
}

.k-fill-black {
  fill: black;
}

.\!k-fill-black {
  fill: black !important;
}

.k-fill-white {
  fill: white;
}

.\!k-fill-white {
  fill: white !important;
}

.k-stroke-none {
  stroke: none;
}

.\!k-stroke-none {
  stroke: none !important;
}

.k-stroke-inherit {
  stroke: inherit;
}

.\!k-stroke-inherit {
  stroke: inherit !important;
}

.k-stroke-current {
  stroke: currentColor;
}

.\!k-stroke-current {
  stroke: currentColor !important;
}

.k-stroke-transparent {
  stroke: transparent;
}

.\!k-stroke-transparent {
  stroke: transparent !important;
}

.k-stroke-black {
  stroke: black;
}

.\!k-stroke-black {
  stroke: black !important;
}

.k-stroke-white {
  stroke: white;
}

.\!k-stroke-white {
  stroke: white !important;
}

.k-elevation-1 {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-2 {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-3 {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.08), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-4 {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-5 {
  box-shadow: 0 10px 12px rgba(0, 0, 0, 0.16), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-6 {
  box-shadow: 0 12px 14px rgba(0, 0, 0, 0.2), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-7 {
  box-shadow: 0 14px 16px rgba(0, 0, 0, 0.24), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-8 {
  box-shadow: 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-elevation-9 {
  box-shadow: 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12);
}

.k-reset {
  margin: 0;
  padding: 0;
  border-width: 0;
  outline: 0;
  text-decoration: none;
  font: inherit;
  list-style: none;
}

kendo-sortable {
  display: block;
}

.k-link,
.k-link:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

.k-content {
  outline: 0;
}

.k-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-no-click {
  pointer-events: none;
}

.k-pdf-export-shadow {
  position: absolute;
  overflow: hidden;
  left: -15000px;
  width: 14400px;
}

.kendo-pdf-hide-pseudo-elements::before,
.kendo-pdf-hide-pseudo-elements::after {
  display: none !important;
}

.k-text-selection ::selection {
  background-color: #ff6358;
  color: white;
}

.k-block,
.k-panel {
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}
.k-block > .k-header,
.k-panel > .k-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
  padding-inline: 8px;
  padding-block: 4px;
}
.k-block > .k-content,
.k-panel > .k-content {
  padding-inline: 8px;
  padding-block: 4px;
}

.k-content {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-disabled,
.k-widget[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-disabled .k-link,
.k-widget[disabled] .k-link {
  cursor: default;
  outline: 0;
}
.k-disabled [disabled],
.k-disabled .k-disabled,
.k-widget[disabled] [disabled],
.k-widget[disabled] .k-disabled {
  opacity: 1;
  filter: grayscale(0);
}

.k-hr {
  margin: 16px auto;
  padding: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  display: block;
  float: none;
  clear: both;
}

.k-d-flex-row > .k-hr {
  margin: 0;
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  flex: 0 0 auto;
}

.k-d-flex-col > .k-hr {
  margin: 0;
  flex: 0 0 auto;
}

.k-dirty {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  border-width: 3px;
  border-style: solid;
  border-color: #f31700 #f31700 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
}

[dir=rtl] .k-dirty,
.k-rtl .k-dirty {
  border-color: transparent transparent #f31700 #f31700;
  right: auto;
  left: 0;
}

.k-animation-container {
  position: absolute;
  overflow: hidden;
  z-index: 100;
}
.k-animation-container-fixed {
  position: fixed;
}
.k-animation-container-relative {
  position: relative;
  display: inline-block;
}

.k-push-right-enter, .k-push-right-appear {
  transform: translate(-100%, 0);
}
.k-push-right-enter-active, .k-push-right-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-right-exit {
  transform: translate(0, 0);
}
.k-push-right-exit-active {
  transform: translate(100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-left-enter, .k-push-left-appear {
  transform: translate(100%, 0);
}
.k-push-left-enter-active, .k-push-left-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-left-exit {
  transform: translate(0, 0);
}
.k-push-left-exit-active {
  transform: translate(-100%, 0);
  transition: transform 300ms ease-in-out;
}

.k-push-down-enter, .k-push-down-appear {
  transform: translate(0, -100%);
}
.k-push-down-enter-active, .k-push-down-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-down-exit {
  transform: translate(0, 0);
}
.k-push-down-exit-active {
  transform: translate(0, 100%);
  transition: transform 300ms ease-in-out;
}

.k-push-up-enter, .k-push-up-appear {
  transform: translate(0, 100%);
}
.k-push-up-enter-active, .k-push-up-appear-active {
  transform: translate(0, 0);
  transition: transform 300ms ease-in-out;
}
.k-push-up-exit {
  transform: translate(0, 0);
}
.k-push-up-exit-active {
  transform: translate(0, -100%);
  transition: transform 300ms ease-in-out;
}

.k-expand-vertical-enter, .k-expand-vertical-appear {
  transform: scaleY(0);
}
.k-expand-vertical-enter-active, .k-expand-vertical-appear-active {
  transform: scaleY(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-vertical-exit {
  transform: scaleY(1);
}
.k-expand-vertical-exit-active {
  transform: scaleY(0);
  transition: transform 300ms ease-in-out;
}

.k-expand-horizontal-enter, .k-expand-horizontal-appear {
  transform: scaleX(0);
}
.k-expand-horizontal-enter-active, .k-expand-horizontal-appear-active {
  transform: scaleX(1);
  transition: transform 300ms ease-in-out;
}
.k-expand-horizontal-exit {
  transform: scaleX(1);
}
.k-expand-horizontal-exit-active {
  transform: scaleX(0);
  transition: transform 300ms ease-in-out;
}

.k-fade-enter, .k-fade-appear {
  opacity: 0;
}
.k-fade-enter-active, .k-fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit {
  opacity: 1;
}
.k-fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.k-fade-exit-active + .k-fade-exit-active, .k-fade-enter-active + .k-fade-enter-active {
  display: none;
}

.k-zoom-in-enter, .k-zoom-in-appear {
  opacity: 0;
  transform: scale(0);
}
.k-zoom-in-enter-active, .k-zoom-in-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-in-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-in-exit-active {
  opacity: 0;
  transform: scale(2);
  transition: transform, opacity 300ms ease-in-out;
}

.k-zoom-out-enter, .k-zoom-out-appear {
  opacity: 0;
  transform: scale(2);
}
.k-zoom-out-enter-active, .k-zoom-out-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: transform, opacity 300ms ease-in-out;
}
.k-zoom-out-exit {
  opacity: 1;
  transform: scale(1);
}
.k-zoom-out-exit-active {
  opacity: 0;
  transform: scale(0);
  transition: transform, opacity 300ms ease-in-out;
}

.k-slide-in-appear {
  opacity: 0.1;
  transform: translate(0, -3em);
}
.k-slide-in-appear .k-centered {
  transform: translate(-50%, -60%);
}
.k-slide-in-appear-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 0.3s cubic-bezier(0.2, 0.6, 0.4, 1), opacity 0.3s cubic-bezier(0.2, 1, 0.2, 1);
}
.k-slide-in-appear-active .k-centered {
  transform: translate(-50%, -50%);
}

.k-slide-down-enter, .k-slide-down-appear {
  transform: translateY(-100%);
}
.k-slide-down-enter-active, .k-slide-down-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-down-exit {
  transform: translateY(0);
}
.k-slide-down-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-up-enter, .k-slide-up-appear {
  transform: translateY(100%);
}
.k-slide-up-enter-active, .k-slide-up-appear-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-up-exit {
  transform: translateY(0);
}
.k-slide-up-exit-active {
  transform: translateY(100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-right-enter, .k-slide-right-appear {
  transform: translateX(-100%);
}
.k-slide-right-enter-active, .k-slide-right-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-right-exit {
  transform: translateX(0);
}
.k-slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.k-slide-left-enter, .k-slide-left-appear {
  transform: translateX(100%);
}
.k-slide-left-enter-active, .k-slide-left-appear-active {
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}
.k-slide-left-exit {
  transform: translateX(0);
}
.k-slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.k-reveal-vertical-enter, .k-reveal-vertical-appear {
  max-height: 0;
}
.k-reveal-vertical-enter-active, .k-reveal-vertical-appear-active {
  transition: max-height 300ms ease-in-out;
}
.k-reveal-vertical-exit-active {
  max-height: 0 !important;
  transition: max-height 300ms ease-in-out;
}

.k-reveal-horizontal-enter, .k-reveal-horizontal-appear {
  max-width: 0;
}
.k-reveal-horizontal-enter-active, .k-reveal-horizontal-appear-active {
  transition: max-width 300ms ease-in-out;
}
.k-reveal-horizontal-exit-active {
  max-width: 0 !important;
  transition: max-width 300ms ease-in-out;
}

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
  transition: all 350ms ease-out;
}

.k-fx {
  position: relative;
}
.k-fx .k-fx-current {
  z-index: 0;
}
.k-fx .k-fx-next {
  z-index: 1;
}

.k-fx-hidden,
.k-fx-hidden * {
  visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
  z-index: 1;
}
.k-fx-reverse .k-fx-next {
  z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
  transform: scale(0) !important;
}
.k-fx-zoom.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  transform: scale(1) !important;
}
.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}
.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}
.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-content, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-current .km-content, .k-fx-slide.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-end .k-fx-current .km-footer {
  transition: all 350ms ease-out;
}
.k-fx-slide.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-slide.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}
.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}
.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}
.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  transform: translateX(0);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(100%);
}
.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx-tile.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translateX(0);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translateX(0);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(100%);
}
.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next, .k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  transform: translateY(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: none;
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current, .k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  transform: translateX(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  transform: translateY(100%);
}
.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  transform: translateY(-100%);
}

.k-current-time {
  width: 1px;
  position: absolute;
}
.k-current-time.k-current-time-arrow-left, .k-current-time.k-current-time-arrow-right, .k-current-time.k-current-time-arrow-down {
  width: 0;
  height: 0;
  background: transparent;
  border: 4px solid transparent;
}

.k-current-time {
  background: #ff0000;
}
.k-current-time.k-current-time-arrow-left {
  border-right-color: #ff0000;
}
.k-current-time.k-current-time-arrow-right {
  border-left-color: #ff0000;
}
.k-current-time.k-current-time-arrow-down {
  border-top-color: #ff0000;
}

.k-resize-handle,
.k-resize-hint {
  position: absolute;
  border-color: inherit;
  z-index: 200;
}

.k-resize-handle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-resize-handle::before {
  content: "";
  border: 0 solid;
  border-color: inherit;
}

.k-resize-n {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  top: -3px;
  cursor: n-resize;
}

.k-resize-s {
  width: 100%;
  height: 6px;
  flex-direction: row;
  left: 0;
  bottom: -3px;
  cursor: s-resize;
}

.k-resize-w {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  left: -3px;
  cursor: w-resize;
}

.k-resize-e {
  width: 6px;
  height: 100%;
  flex-direction: column;
  top: 0;
  right: -3px;
  cursor: e-resize;
}

.k-resize-sw,
.k-resize-se,
.k-resize-nw,
.k-resize-ne {
  width: 5px;
  height: 5px;
}

.k-resize-sw {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
}

.k-resize-se {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.k-resize-nw {
  cursor: nw-resize;
  top: 0;
  left: 0;
}

.k-resize-ne {
  cursor: ne-resize;
  top: 0;
  right: 0;
}

.k-vertical-resize {
  cursor: row-resize;
}

.k-horizontal-resize {
  cursor: col-resize;
}

.k-resize-hint {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.k-resize-hint .k-resize-hint-handle {
  width: auto;
  height: 20px;
  align-self: stretch;
}
.k-resize-hint .k-resize-hint-marker {
  width: 2px;
  height: auto;
  flex: 1 1 auto;
}

.k-resize-hint-vertical {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.k-resize-hint-vertical .k-resize-hint-handle {
  width: 20px;
  height: auto;
  align-self: stretch;
}
.k-resize-hint-vertical .k-resize-hint-marker {
  width: auto;
  height: 2px;
  flex: 1 1 auto;
}

.k-scrollbar {
  position: absolute;
  overflow: scroll;
}

.k-scrollbar-vertical {
  top: 0;
  right: 0;
  width: 17px;
  height: 100%;
  overflow-x: hidden;
}

.k-touch-scrollbar {
  display: none;
  position: absolute;
  z-index: 200000;
  height: 8px;
  width: 8px;
  border: 1px solid #8a8a8a;
  background-color: #858585;
}

.k-svg-icon {
  width: 16px;
  height: 16px;
  outline: 0;
  line-height: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
}
.k-svg-icon > svg {
  fill: currentColor;
  flex: 1 1 auto;
}

.k-svg-icon.k-icon-xs {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-svg-icon.k-icon-sm {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-svg-icon.k-icon-md {
  width: 16px;
  height: 16px;
}
.k-svg-icon.k-icon-lg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-svg-icon.k-icon-xl {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-svg-icon.k-icon-xxl {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-svg-icon.k-icon-xxxl {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-flip-h.k-svg-icon, .k-svg-icon.k-flip-x,
.k-flip-v.k-svg-icon,
.k-svg-icon.k-flip-y,
.k-flip-h.k-flip-v.k-svg-icon {
  transform: none;
}

.k-flip-h > svg, .k-flip-x > svg {
  transform: scaleX(-1);
}

.k-flip-v > svg, .k-flip-y > svg {
  transform: scaleY(-1);
}

.k-flip-h.k-flip-v > svg, .k-flip-v.k-flip-x > svg, .k-flip-h.k-flip-y > svg, .k-flip-x.k-flip-y > svg,
.k-flip-both > svg {
  transform: scale(-1, -1);
}

.k-rotate-0.k-svg-icon {
  transform: none;
}
.k-rotate-0 > svg {
  transform: rotate(0deg);
}

.k-rotate-45.k-svg-icon {
  transform: none;
}
.k-rotate-45 > svg {
  transform: rotate(45deg);
}

.k-rotate-90.k-svg-icon {
  transform: none;
}
.k-rotate-90 > svg {
  transform: rotate(90deg);
}

.k-rotate-135.k-svg-icon {
  transform: none;
}
.k-rotate-135 > svg {
  transform: rotate(135deg);
}

.k-rotate-180.k-svg-icon {
  transform: none;
}
.k-rotate-180 > svg {
  transform: rotate(180deg);
}

.k-rotate-225.k-svg-icon {
  transform: none;
}
.k-rotate-225 > svg {
  transform: rotate(225deg);
}

.k-rotate-270.k-svg-icon {
  transform: none;
}
.k-rotate-270 > svg {
  transform: rotate(270deg);
}

.k-rotate-315.k-svg-icon {
  transform: none;
}
.k-rotate-315 > svg {
  transform: rotate(315deg);
}

.k-icon-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-icon-wrap::before {
  content: "​";
  width: 0;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-icon-wrapper-host {
  display: contents;
}

.k-icon-with-modifier {
  position: relative;
  margin: 0.25em;
}

.k-icon.k-icon-modifier {
  width: 1em;
  height: 1em;
  position: absolute;
  font-size: 0.5em;
  bottom: 0;
  right: 0;
  margin: 0 -0.5em -0.5em 0;
}

.k-i-none::before {
  content: "";
  display: none;
}

.k-icon-action {
  display: inline-flex;
  padding: 4px;
  line-height: 1;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.k-sprite {
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  font-size: 0;
  line-height: 0;
  text-align: center;
}

.k-image {
  display: inline-block;
}

.k-listgroup {
  border-radius: 4px;
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  line-height: 1.4285714286;
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.k-listgroup > ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}
.k-listgroup .k-listgroup-item + .k-listgroup-item {
  border-top-width: 1px;
}

.k-listgroup-flush {
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.k-listgroup-item {
  padding-block: 8px;
  padding-inline: 8px;
  border-width: 0;
  border-style: solid;
  border-color: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
  position: relative;
}
.k-listgroup-item > .k-link {
  margin-block: -8px;
  margin-inline: -8px;
  padding-block: 8px;
  padding-inline: 8px;
  color: inherit;
  text-decoration: none;
  outline: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-item > .k-link > .k-select {
  padding: 8px;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  transform: translateY(-50%);
}

.k-listgroup-item-segmented {
  align-items: stretch;
}
.k-listgroup-item-segmented > .k-link {
  margin-right: 0;
}
.k-listgroup-item-segmented > .k-select {
  margin: -8px -8px -8px 0;
  padding: 8px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: inherit;
  cursor: pointer;
}

.k-listgroup-form-row {
  margin-block: -8px;
  margin-inline: -8px;
  padding-block: 8px;
  padding-inline: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 auto;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-label {
  width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
  position: relative;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper select,
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=text],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=password],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=date],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=time],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=datetime-local],
.k-listgroup-form-row .k-listgroup-form-field-wrapper input[type=number],
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 100%;
}
.k-listgroup-form-row .k-listgroup-form-field-wrapper textarea {
  min-height: 4em;
  resize: vertical;
}

.k-listgroup-item.k-listgroup-form-row {
  margin: 0;
}

[dir=rtl] .k-listgroup-item > .k-link > .k-select {
  right: auto;
  left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-link {
  margin-right: -8px;
  margin-left: 0;
}
[dir=rtl] .k-listgroup-item-segmented > .k-select {
  margin-right: 0;
  margin-left: -8px;
  border-right-width: 1px;
  border-left-width: 0;
}

.k-noflexbox .k-listgroup,
.k-noflexbox .k-listgroup > ul .k-listgroup-item,
.k-noflexbox .k-listgroup-item > .k-link {
  display: block;
}

.k-listgroup {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-checkbox {
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='white' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ff6358' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
}

.k-checkbox:disabled,
.k-checkbox.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-checkbox-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: flex-start;
  vertical-align: middle;
  position: relative;
}
.k-checkbox-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-checkbox-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-checkbox-label .k-ripple {
  visibility: hidden !important;
}

.k-checkbox + .k-label,
.k-checkbox-wrap + .k-label,
.k-checkbox + .k-checkbox-label,
.k-checkbox-wrap + .k-checkbox-label {
  display: inline;
  margin-inline-start: 4px;
}

.k-checkbox-label:empty {
  display: none !important;
}

.k-checkbox-label.k-no-text {
  min-width: 1px;
}

.k-checkbox-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-checkbox-item,
.k-checkbox-list-item {
  padding-block: 4px;
  padding-inline: 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 4px;
}
.k-checkbox-item .k-checkbox-label,
.k-checkbox-list-item .k-checkbox-label {
  margin: 0;
}

.k-checkbox-list-horizontal,
.k-checkbox-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.k-ripple-container .k-checkbox::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-checkbox:focus,
.k-ripple-container .k-checkbox.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-checkbox:disabled::after,
.k-ripple-container .k-checkbox.k-disabled::after {
  display: none;
}

.k-checkbox-sm {
  width: 12px;
  height: 12px;
}
.k-checkbox-sm::before {
  font-size: 10px;
}

.k-ripple-container .k-checkbox-sm::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-md {
  width: 16px;
  height: 16px;
}
.k-checkbox-md::before {
  font-size: 14px;
}

.k-ripple-container .k-checkbox-md::after {
  width: 300%;
  height: 300%;
}

.k-checkbox-lg {
  width: 20px;
  height: 20px;
}
.k-checkbox-lg::before {
  font-size: 18px;
}

.k-ripple-container .k-checkbox-lg::after {
  width: 300%;
  height: 300%;
}

.k-checkbox {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.k-checkbox:focus,
.k-checkbox.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-checkbox:indeterminate,
.k-checkbox.k-indeterminate {
  border-color: rgba(0, 0, 0, 0.08);
  color: #ff6358;
  background-color: #ffffff;
}

.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}

.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.k-checkbox.k-invalid {
  border-color: #f31700;
}

.k-checkbox.k-invalid + .k-checkbox-label {
  color: #f31700;
}

.k-checkbox-wrap .k-ripple-blob {
  color: #ff6358;
  opacity: 0.25;
}

.k-ripple-container .k-checkbox::after {
  background: #ff6358;
  opacity: 0.25;
}

.k-radio {
  border-radius: 50%;
  margin: 0;
  padding: 0;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  flex: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
}

.k-radio:checked,
.k-radio.k-checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='4' fill='white'/%3e%3c/svg%3e");
}

.k-radio:disabled,
.k-radio.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}

.k-radio-wrap {
  flex: none;
  display: inline-flex;
  flex-flow: row nowrap;
  gap: 0;
  align-items: center;
  align-self: start;
  vertical-align: middle;
  position: relative;
}
.k-radio-wrap::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-radio-label {
  margin: 0;
  padding: 0;
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.k-radio-label .k-ripple {
  visibility: hidden !important;
}

.k-radio + .k-label,
.k-radio-wrap + .k-label,
.k-radio + .k-radio-label,
.k-radio-wrap + .k-radio-label {
  display: inline;
  margin-inline-start: 4px;
}

.k-radio-label:empty {
  display: none !important;
}

.k-radio-label.k-no-text {
  min-width: 1px;
}

.k-radio-list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  list-style: none;
}

.k-radio-item,
.k-radio-list-item {
  padding-block: 4px;
  padding-inline: 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 4px;
}
.k-radio-item .k-radio-label,
.k-radio-list-item .k-radio-label {
  margin: 0;
}

.k-radio-list-horizontal,
.k-radio-list.k-list-horizontal {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.k-ripple-container .k-radio::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 100%;
  z-index: -1;
  transition: opacity 100ms linear, transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center center;
}
.k-ripple-container .k-radio:focus,
.k-ripple-container .k-radio.k-focus {
  box-shadow: none !important;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}
.k-ripple-container .k-radio:disabled::after,
.k-ripple-container .k-radio.k-disabled::after {
  display: none;
}

.k-radio-sm {
  width: 12px;
  height: 12px;
}
.k-radio-sm::before {
  font-size: 10px;
}

.k-ripple-container .k-radio-sm::after {
  width: 300%;
  height: 300%;
}

.k-radio-md {
  width: 16px;
  height: 16px;
}
.k-radio-md::before {
  font-size: 14px;
}

.k-ripple-container .k-radio-md::after {
  width: 300%;
  height: 300%;
}

.k-radio-lg {
  width: 20px;
  height: 20px;
}
.k-radio-lg::before {
  font-size: 18px;
}

.k-ripple-container .k-radio-lg::after {
  width: 300%;
  height: 300%;
}

.k-radio {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.k-radio:focus,
.k-radio.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.06);
}

.k-radio.k-invalid,
.k-radio.ng-invalid.ng-touched,
.k-radio.ng-invalid.ng-dirty {
  border-color: #f31700;
}

.k-radio.k-invalid + .k-radio-label,
.k-radio.ng-invalid.ng-touched + .k-radio-label,
.k-radio.ng-invalid.ng-dirty + .k-radio-label {
  color: #f31700;
}

.k-radio:checked,
.k-radio.k-checked {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(255, 99, 88, 0.3);
}

.k-ripple-container .k-radio::after {
  background: #ff6358;
  opacity: 0.25;
}

.k-switch {
  box-sizing: border-box;
  outline: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  line-height: 1;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-switch *,
.k-switch *::before,
.k-switch *::after {
  box-sizing: border-box;
}
.k-switch [type=checkbox] {
  display: none;
}
.k-switch[aria-readonly=true] {
  pointer-events: none;
}

.k-switch.k-readonly {
  pointer-events: none;
}

.k-switch-track {
  border-width: 1px;
  border-style: solid;
  outline: 0;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  position: relative;
  transition: background-color 200ms ease-in-out;
}

.k-switch-thumb-wrap {
  width: 0;
  height: 0;
  overflow: visible;
  position: absolute;
  transition: left 200ms ease-in-out;
  top: 50%;
}

.k-switch-thumb {
  border-width: 1px;
  border-style: solid;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
}

.k-switch-label-on,
.k-switch-label-off {
  text-transform: uppercase;
  display: inline;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}

.k-switch-sm {
  width: 52px;
  height: 26px;
  font-size: 10px;
}
.k-switch-sm .k-switch-track {
  width: 52px;
  height: 26px;
}
.k-switch-sm .k-switch-label-on {
  left: 4px;
}
.k-switch-sm .k-switch-label-off {
  right: 4px;
}
.k-switch-sm .k-switch-thumb {
  width: 26px;
  height: 26px;
}
.k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 13px - 0px);
}
.k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(13px + 0px);
}

.k-switch-sm[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-sm .k-switch-label-on,
.k-rtl .k-switch-sm .k-switch-label-on {
  left: auto;
  right: 4px;
}
.k-switch-sm[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-sm .k-switch-label-off,
.k-rtl .k-switch-sm .k-switch-label-off {
  right: auto;
  left: 4px;
}
.k-switch-sm[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-on .k-switch-thumb-wrap {
  left: calc(13px + 0px);
}
.k-switch-sm[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-sm.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-sm.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 13px - 0px);
}

.k-switch-md {
  width: 60px;
  height: 30px;
  font-size: 10px;
}
.k-switch-md .k-switch-track {
  width: 60px;
  height: 30px;
}
.k-switch-md .k-switch-label-on {
  left: 6px;
}
.k-switch-md .k-switch-label-off {
  right: 6px;
}
.k-switch-md .k-switch-thumb {
  width: 30px;
  height: 30px;
}
.k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 15px - 0px);
}
.k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(15px + 0px);
}

.k-switch-md[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-md .k-switch-label-on,
.k-rtl .k-switch-md .k-switch-label-on {
  left: auto;
  right: 6px;
}
.k-switch-md[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-md .k-switch-label-off,
.k-rtl .k-switch-md .k-switch-label-off {
  right: auto;
  left: 6px;
}
.k-switch-md[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-on .k-switch-thumb-wrap {
  left: calc(15px + 0px);
}
.k-switch-md[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-md.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-md.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 15px - 0px);
}

.k-switch-lg {
  width: 68px;
  height: 34px;
  font-size: 10px;
}
.k-switch-lg .k-switch-track {
  width: 68px;
  height: 34px;
}
.k-switch-lg .k-switch-label-on {
  left: 8px;
}
.k-switch-lg .k-switch-label-off {
  right: 8px;
}
.k-switch-lg .k-switch-thumb {
  width: 34px;
  height: 34px;
}
.k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(100% - 17px - 0px);
}
.k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(17px + 0px);
}

.k-switch-lg[dir=rtl] .k-switch-label-on,
[dir=rtl] .k-switch-lg .k-switch-label-on,
.k-rtl .k-switch-lg .k-switch-label-on {
  left: auto;
  right: 8px;
}
.k-switch-lg[dir=rtl] .k-switch-label-off,
[dir=rtl] .k-switch-lg .k-switch-label-off,
.k-rtl .k-switch-lg .k-switch-label-off {
  right: auto;
  left: 8px;
}
.k-switch-lg[dir=rtl].k-switch-on .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-on .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-on .k-switch-thumb-wrap {
  left: calc(17px + 0px);
}
.k-switch-lg[dir=rtl].k-switch-off .k-switch-thumb-wrap,
[dir=rtl] .k-switch-lg.k-switch-off .k-switch-thumb-wrap,
.k-rtl .k-switch-lg.k-switch-off .k-switch-thumb-wrap {
  left: calc(100% - 17px - 0px);
}

.k-switch[dir=rtl] .k-switch-thumb,
[dir=rtl] .k-switch .k-switch-thumb,
.k-switch.k-rtl .k-switch-thumb,
.k-rtl .k-switch .k-switch-thumb {
  transform: translate(50%, -50%);
}

.k-switch-off .k-switch-track {
  border-color: #ebebeb;
  color: #424242;
  background-color: #ffffff;
}
.k-switch-off .k-switch-thumb {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}
.k-switch-off:focus .k-switch-track, .k-switch-off.k-focus .k-switch-track {
  outline: 2px solid rgba(0, 0, 0, 0.08);
}
.k-switch-off .k-switch-label-on {
  color: transparent;
}

.k-switch-on .k-switch-track {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-switch-on .k-switch-thumb {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}
.k-switch-on:focus .k-switch-track, .k-switch-on.k-focus .k-switch-track {
  outline: 2px solid rgba(255, 99, 88, 0.25);
}
.k-switch-on .k-switch-label-off {
  color: transparent;
}

.k-badge {
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  box-sizing: border-box;
  color: inherit;
  background-color: transparent;
  font: inherit;
  text-align: center;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-badge *,
.k-badge *::before,
.k-badge *::after {
  box-sizing: border-box;
}

.k-badge > * {
  color: inherit;
}

a.k-badge,
.k-badge > a {
  text-decoration: none;
}

a.k-badge:hover,
.k-badge > a:hover {
  text-decoration: underline;
}

.k-badge-icon {
  max-width: 1em;
  max-height: 1em;
  font-size: inherit;
}

.k-badge-icon.k-svg-icon svg {
  fill: currentColor;
}

.k-badge-sm {
  padding-block: 2px;
  padding-inline: 2px;
  font-size: 10px;
  line-height: 1;
  min-width: calc(1em + 4px + 2px);
}
.k-badge-sm:empty {
  padding: 2px;
  min-width: auto;
}

.k-badge-md {
  padding-block: 4px;
  padding-inline: 4px;
  font-size: 10px;
  line-height: 1;
  min-width: calc(1em + 8px + 2px);
}
.k-badge-md:empty {
  padding: 4px;
  min-width: auto;
}

.k-badge-lg {
  padding-block: 6px;
  padding-inline: 6px;
  font-size: 10px;
  line-height: 1;
  min-width: calc(1em + 12px + 2px);
}
.k-badge-lg:empty {
  padding: 6px;
  min-width: auto;
}

kendo-badge-container {
  display: inline-block;
}

.k-badge-container {
  position: relative;
  overflow: visible;
}

.k-badge-inside {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge {
  position: absolute;
  z-index: 9999;
}
.k-badge-edge.k-top-start {
  transform: translate(-50%, -50%);
}
.k-badge-edge.k-top-end {
  transform: translate(50%, -50%);
}
.k-badge-edge.k-bottom-start {
  transform: translate(-50%, 50%);
}
.k-badge-edge.k-bottom-end {
  transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start,
[dir=rtl].k-badge-edge.k-top-start,
[dir=rtl] .k-badge-edge.k-top-start {
  transform: translate(50%, -50%);
}
.k-rtl .k-badge-edge.k-top-end,
[dir=rtl].k-badge-edge.k-top-end,
[dir=rtl] .k-badge-edge.k-top-end {
  transform: translate(-50%, -50%);
}
.k-rtl .k-badge-edge.k-bottom-start,
[dir=rtl].k-badge-edge.k-bottom-start,
[dir=rtl] .k-badge-edge.k-bottom-start {
  transform: translate(50%, 50%);
}
.k-rtl .k-badge-edge.k-bottom-end,
[dir=rtl].k-badge-edge.k-bottom-end,
[dir=rtl] .k-badge-edge.k-bottom-end {
  transform: translate(-50%, 50%);
}

.k-badge-outside {
  position: absolute;
  z-index: 9999;
}
.k-badge-outside.k-top-start {
  transform: translate(-100%, -100%);
}
.k-badge-outside.k-top-end {
  transform: translate(100%, -100%);
}
.k-badge-outside.k-bottom-start {
  transform: translate(-100%, 100%);
}
.k-badge-outside.k-bottom-end {
  transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start,
[dir=rtl].k-badge-outside.k-top-start,
[dir=rtl] .k-badge-outside.k-top-start {
  transform: translate(100%, -100%);
}
.k-rtl .k-badge-outside.k-top-end,
[dir=rtl].k-badge-outside.k-top-end,
[dir=rtl] .k-badge-outside.k-top-end {
  transform: translate(-100%, -100%);
}
.k-rtl .k-badge-outside.k-bottom-start,
[dir=rtl].k-badge-outside.k-bottom-start,
[dir=rtl] .k-badge-outside.k-bottom-start {
  transform: translate(100%, 100%);
}
.k-rtl .k-badge-outside.k-bottom-end,
[dir=rtl].k-badge-outside.k-bottom-end,
[dir=rtl] .k-badge-outside.k-bottom-end {
  transform: translate(-100%, 100%);
}

.k-badge-border-cutout {
  box-shadow: 0 0 0 2px #ffffff;
}

.k-badge-solid-primary {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}

.k-badge-solid-secondary {
  border-color: #666666;
  color: white;
  background-color: #666666;
}

.k-badge-solid-tertiary {
  border-color: #03a9f4;
  color: white;
  background-color: #03a9f4;
}

.k-badge-solid-info {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
}

.k-badge-solid-success {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}

.k-badge-solid-warning {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
}

.k-badge-solid-error {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
}

.k-badge-solid-dark {
  border-color: #424242;
  color: white;
  background-color: #424242;
}

.k-badge-solid-light {
  border-color: #ebebeb;
  color: black;
  background-color: #ebebeb;
}

.k-badge-solid-inverse {
  border-color: #424242;
  color: white;
  background-color: #424242;
}

.k-badge-outline {
  border-color: currentColor;
  background-color: #ffffff;
}

.k-badge-outline-primary {
  color: #ff6358;
}

.k-badge-outline-secondary {
  color: #666666;
}

.k-badge-outline-tertiary {
  color: #03a9f4;
}

.k-badge-outline-info {
  color: #0058e9;
}

.k-badge-outline-success {
  color: #37b400;
}

.k-badge-outline-warning {
  color: #ffc000;
}

.k-badge-outline-error {
  color: #f31700;
}

.k-badge-outline-dark {
  color: #424242;
}

.k-badge-outline-light {
  color: #ebebeb;
}

.k-badge-outline-inverse {
  color: #424242;
}

.k-button {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  color: inherit;
  background: none;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  position: relative;
  transition: color 0.2s ease-in-out;
}
.k-button *,
.k-button *::before,
.k-button *::after {
  box-sizing: border-box;
}
.k-button:disabled, .k-button.k-disabled {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-button::-moz-focus-inner {
  padding: 0;
  border: 0;
  outline: 0;
}
.k-button:hover, .k-button:focus {
  text-decoration: none;
  outline: 0;
}

.k-icon-button {
  gap: 0;
}
.k-icon-button .k-icon {
  display: inline-flex;
}

.k-button-icon {
  color: inherit;
  align-self: center;
  position: relative;
}

.k-button-sm {
  padding-block: 2px;
  padding-inline: 8px;
  font-size: 14px;
  line-height: 1.4285714286;
}

.k-button-sm.k-icon-button {
  padding: 2px;
}
.k-button-sm.k-icon-button .k-button-icon {
  min-width: calc(14px * 1.4285714286);
  min-height: calc(14px * 1.4285714286);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-sm.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-sm.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-sm.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-md {
  padding-block: 4px;
  padding-inline: 8px;
  font-size: 14px;
  line-height: 1.4285714286;
}

.k-button-md.k-icon-button {
  padding: 4px;
}
.k-button-md.k-icon-button .k-button-icon {
  min-width: calc(14px * 1.4285714286);
  min-height: calc(14px * 1.4285714286);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-md.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-md.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-md.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-lg {
  padding-block: 8px;
  padding-inline: 8px;
  font-size: 16px;
  line-height: 1.5;
}

.k-button-lg.k-icon-button {
  padding: 8px;
}
.k-button-lg.k-icon-button .k-button-icon {
  min-width: calc(16px * 1.5);
  min-height: calc(16px * 1.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.k-button-lg.k-icon-button .k-button-icon.k-svg-icon > svg, .k-button-lg.k-icon-button .k-button-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-button-lg.k-icon-button .k-button-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-button-square {
  aspect-ratio: 1;
}

.k-button-group {
  margin: 0;
  padding: 0;
  border-width: 0;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
  display: inline-flex;
  flex-flow: row nowrap;
  vertical-align: middle;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-button-group > .k-button + .k-button {
  margin-inline-start: -1px;
}
.k-button-group > .k-button:hover,
.k-button-group > .k-button.k-hover,
.k-button-group > .k-button:focus,
.k-button-group > .k-button.k-focus,
.k-button-group > .k-button:active,
.k-button-group > .k-button.k-active,
.k-button-group > .k-button.k-selected {
  z-index: 2;
}
.k-button-group .k-button:not(:first-child):not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group > .k-button:first-child:not(:only-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}
.k-button-group > .k-button:last-child:not(:only-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}
.k-button-group:disabled, .k-button-group[disabled], .k-button-group.k-disabled {
  opacity: 1;
  filter: none;
}

.k-button-group-stretched {
  width: 100%;
}
.k-button-group-stretched > * {
  flex: 1 0 0%;
  overflow: hidden;
}

.k-button-flat {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}
.k-button-flat::before {
  display: block !important;
}
.k-button-flat::after {
  box-shadow: inset 0 0 0 2px currentColor;
  display: block !important;
}
.k-button-flat:focus::after, .k-button-flat.k-focus::after {
  opacity: 0.12;
}

.k-button-outline {
  border-color: currentColor;
  color: inherit;
  background: none;
}

.k-button-link {
  border-color: transparent;
  color: inherit;
  text-decoration: none;
  background: none;
}
.k-button-link:hover, .k-button-link.k-hover {
  text-decoration: underline;
}

.k-button-clear {
  border-color: transparent !important;
  color: inherit;
  background: none !important;
  box-shadow: none !important;
}

.k-button-overlay, .k-button::before {
  border-radius: inherit;
  content: "";
  background: currentColor;
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button:hover::before, .k-button.k-hover::before {
  opacity: 0.04;
}
.k-button.k-no-focus:not(:hover)::before, .k-button.k-no-focus:not(.k-hover)::before {
  opacity: 0;
}
.k-button:active::before, .k-button.k-active::before {
  opacity: 0.16;
}
.k-button.k-selected::before {
  opacity: 0.2;
}

.k-button::after {
  border-radius: inherit;
  content: "";
  opacity: 0;
  display: none;
  pointer-events: none;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  z-index: 0;
  transition: opacity 0.2s ease-in-out;
}

.k-button-solid-base {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-base:hover, .k-button-solid-base.k-hover {
  background-color: #ebebeb;
}
.k-button-solid-base:focus, .k-button-solid-base.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-button-solid-base:active, .k-button-solid-base.k-active {
  background-color: #d8d8d8;
}
.k-button-solid-base.k-selected {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-button-solid-primary {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
  border-color: #f55f54;
  background-color: #f55f54;
}
.k-button-solid-primary:focus, .k-button-solid-primary.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 99, 88, 0.3);
}
.k-button-solid-primary:active, .k-button-solid-primary.k-active {
  border-color: #e0574d;
  background-color: #e0574d;
}
.k-button-solid-primary.k-selected {
  border-color: #e0574d;
  background-color: #e0574d;
}
.k-button-solid-secondary {
  border-color: #666666;
  color: white;
  background-color: #666666;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-secondary:hover, .k-button-solid-secondary.k-hover {
  border-color: #626262;
  background-color: #626262;
}
.k-button-solid-secondary:focus, .k-button-solid-secondary.k-focus {
  box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-solid-secondary:active, .k-button-solid-secondary.k-active {
  border-color: #5a5a5a;
  background-color: #5a5a5a;
}
.k-button-solid-secondary.k-selected {
  border-color: #5a5a5a;
  background-color: #5a5a5a;
}
.k-button-solid-tertiary {
  border-color: #03a9f4;
  color: white;
  background-color: #03a9f4;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-tertiary:hover, .k-button-solid-tertiary.k-hover {
  border-color: #03a2ea;
  background-color: #03a2ea;
}
.k-button-solid-tertiary:focus, .k-button-solid-tertiary.k-focus {
  box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-solid-tertiary:active, .k-button-solid-tertiary.k-active {
  border-color: #0395d7;
  background-color: #0395d7;
}
.k-button-solid-tertiary.k-selected {
  border-color: #0395d7;
  background-color: #0395d7;
}
.k-button-solid-info {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-info:hover, .k-button-solid-info.k-hover {
  border-color: #0054e0;
  background-color: #0054e0;
}
.k-button-solid-info:focus, .k-button-solid-info.k-focus {
  box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-solid-info:active, .k-button-solid-info.k-active {
  border-color: #004dcd;
  background-color: #004dcd;
}
.k-button-solid-info.k-selected {
  border-color: #004dcd;
  background-color: #004dcd;
}
.k-button-solid-success {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-success:hover, .k-button-solid-success.k-hover {
  border-color: #35ad00;
  background-color: #35ad00;
}
.k-button-solid-success:focus, .k-button-solid-success.k-focus {
  box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-solid-success:active, .k-button-solid-success.k-active {
  border-color: #309e00;
  background-color: #309e00;
}
.k-button-solid-success.k-selected {
  border-color: #309e00;
  background-color: #309e00;
}
.k-button-solid-warning {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-warning:hover, .k-button-solid-warning.k-hover {
  border-color: #f5b800;
  background-color: #f5b800;
}
.k-button-solid-warning:focus, .k-button-solid-warning.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-solid-warning:active, .k-button-solid-warning.k-active {
  border-color: #e0a900;
  background-color: #e0a900;
}
.k-button-solid-warning.k-selected {
  border-color: #e0a900;
  background-color: #e0a900;
}
.k-button-solid-error {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-error:hover, .k-button-solid-error.k-hover {
  border-color: #e91600;
  background-color: #e91600;
}
.k-button-solid-error:focus, .k-button-solid-error.k-focus {
  box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-solid-error:active, .k-button-solid-error.k-active {
  border-color: #d61400;
  background-color: #d61400;
}
.k-button-solid-error.k-selected {
  border-color: #d61400;
  background-color: #d61400;
}
.k-button-solid-dark {
  border-color: #424242;
  color: white;
  background-color: #424242;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-dark:hover, .k-button-solid-dark.k-hover {
  border-color: #3f3f3f;
  background-color: #3f3f3f;
}
.k-button-solid-dark:focus, .k-button-solid-dark.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-solid-dark:active, .k-button-solid-dark.k-active {
  border-color: #3a3a3a;
  background-color: #3a3a3a;
}
.k-button-solid-dark.k-selected {
  border-color: #3a3a3a;
  background-color: #3a3a3a;
}
.k-button-solid-light {
  border-color: #ebebeb;
  color: black;
  background-color: #ebebeb;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-light:hover, .k-button-solid-light.k-hover {
  border-color: #e2e2e2;
  background-color: #e2e2e2;
}
.k-button-solid-light:focus, .k-button-solid-light.k-focus {
  box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-solid-light:active, .k-button-solid-light.k-active {
  border-color: #cfcfcf;
  background-color: #cfcfcf;
}
.k-button-solid-light.k-selected {
  border-color: #cfcfcf;
  background-color: #cfcfcf;
}
.k-button-solid-inverse {
  border-color: #424242;
  color: white;
  background-color: #424242;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-button-solid-inverse:hover, .k-button-solid-inverse.k-hover {
  border-color: #3f3f3f;
  background-color: #3f3f3f;
}
.k-button-solid-inverse:focus, .k-button-solid-inverse.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-solid-inverse:active, .k-button-solid-inverse.k-active {
  border-color: #3a3a3a;
  background-color: #3a3a3a;
}
.k-button-solid-inverse.k-selected {
  border-color: #3a3a3a;
  background-color: #3a3a3a;
}
.k-button-outline-primary {
  box-shadow: none;
  border-color: currentColor;
  color: #ff6358;
  background-color: transparent;
}
.k-button-outline-primary:hover, .k-button-outline-primary.k-hover {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-button-outline-primary:focus, .k-button-outline-primary.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 99, 88, 0.3);
}
.k-button-outline-primary:active, .k-button-outline-primary.k-active {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-button-outline-primary.k-selected {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-button-outline-secondary {
  box-shadow: none;
  border-color: currentColor;
  color: #666666;
  background-color: transparent;
}
.k-button-outline-secondary:hover, .k-button-outline-secondary.k-hover {
  border-color: #666666;
  color: white;
  background-color: #666666;
}
.k-button-outline-secondary:focus, .k-button-outline-secondary.k-focus {
  box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-outline-secondary:active, .k-button-outline-secondary.k-active {
  border-color: #666666;
  color: white;
  background-color: #666666;
}
.k-button-outline-secondary.k-selected {
  border-color: #666666;
  color: white;
  background-color: #666666;
}
.k-button-outline-tertiary {
  box-shadow: none;
  border-color: currentColor;
  color: #03a9f4;
  background-color: transparent;
}
.k-button-outline-tertiary:hover, .k-button-outline-tertiary.k-hover {
  border-color: #03a9f4;
  color: white;
  background-color: #03a9f4;
}
.k-button-outline-tertiary:focus, .k-button-outline-tertiary.k-focus {
  box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-outline-tertiary:active, .k-button-outline-tertiary.k-active {
  border-color: #03a9f4;
  color: white;
  background-color: #03a9f4;
}
.k-button-outline-tertiary.k-selected {
  border-color: #03a9f4;
  color: white;
  background-color: #03a9f4;
}
.k-button-outline-info {
  box-shadow: none;
  border-color: currentColor;
  color: #0058e9;
  background-color: transparent;
}
.k-button-outline-info:hover, .k-button-outline-info.k-hover {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
}
.k-button-outline-info:focus, .k-button-outline-info.k-focus {
  box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-outline-info:active, .k-button-outline-info.k-active {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
}
.k-button-outline-info.k-selected {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
}
.k-button-outline-success {
  box-shadow: none;
  border-color: currentColor;
  color: #37b400;
  background-color: transparent;
}
.k-button-outline-success:hover, .k-button-outline-success.k-hover {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.k-button-outline-success:focus, .k-button-outline-success.k-focus {
  box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-outline-success:active, .k-button-outline-success.k-active {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.k-button-outline-success.k-selected {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.k-button-outline-warning {
  box-shadow: none;
  border-color: currentColor;
  color: #ffc000;
  background-color: transparent;
}
.k-button-outline-warning:hover, .k-button-outline-warning.k-hover {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
}
.k-button-outline-warning:focus, .k-button-outline-warning.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-outline-warning:active, .k-button-outline-warning.k-active {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
}
.k-button-outline-warning.k-selected {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
}
.k-button-outline-error {
  box-shadow: none;
  border-color: currentColor;
  color: #f31700;
  background-color: transparent;
}
.k-button-outline-error:hover, .k-button-outline-error.k-hover {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
}
.k-button-outline-error:focus, .k-button-outline-error.k-focus {
  box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-outline-error:active, .k-button-outline-error.k-active {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
}
.k-button-outline-error.k-selected {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
}
.k-button-outline-dark {
  box-shadow: none;
  border-color: currentColor;
  color: #424242;
  background-color: transparent;
}
.k-button-outline-dark:hover, .k-button-outline-dark.k-hover {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-dark:focus, .k-button-outline-dark.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-dark:active, .k-button-outline-dark.k-active {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-dark.k-selected {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-light {
  box-shadow: none;
  border-color: currentColor;
  color: #ebebeb;
  background-color: transparent;
}
.k-button-outline-light:hover, .k-button-outline-light.k-hover {
  border-color: #ebebeb;
  color: black;
  background-color: #ebebeb;
}
.k-button-outline-light:focus, .k-button-outline-light.k-focus {
  box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-outline-light:active, .k-button-outline-light.k-active {
  border-color: #ebebeb;
  color: black;
  background-color: #ebebeb;
}
.k-button-outline-light.k-selected {
  border-color: #ebebeb;
  color: black;
  background-color: #ebebeb;
}
.k-button-outline-inverse {
  box-shadow: none;
  border-color: currentColor;
  color: #424242;
  background-color: transparent;
}
.k-button-outline-inverse:hover, .k-button-outline-inverse.k-hover {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-inverse:focus, .k-button-outline-inverse.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-inverse:active, .k-button-outline-inverse.k-active {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-inverse.k-selected {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-base {
  box-shadow: none;
  border-color: currentColor;
  color: #424242;
  background-color: transparent;
}
.k-button-outline-base:hover, .k-button-outline-base.k-hover {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-base:focus, .k-button-outline-base.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-outline-base:active, .k-button-outline-base.k-active {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-outline-base.k-selected {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-button-flat-primary {
  color: #ff6358;
}
.k-button-flat-secondary {
  color: #666666;
}
.k-button-flat-tertiary {
  color: #03a9f4;
}
.k-button-flat-info {
  color: #0058e9;
}
.k-button-flat-success {
  color: #37b400;
}
.k-button-flat-warning {
  color: #ffc000;
}
.k-button-flat-error {
  color: #f31700;
}
.k-button-flat-dark {
  color: #424242;
}
.k-button-flat-light {
  color: #ebebeb;
}
.k-button-flat-inverse {
  color: #424242;
}
.k-button-flat-base {
  color: inherit;
}
.k-button-link-primary {
  color: #ff6358;
}
.k-button-link-primary:hover, .k-button-link-primary.k-hover {
  color: #d6534a;
}
.k-button-link-primary:focus, .k-button-link-primary.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 99, 88, 0.3);
}
.k-button-link-primary:active, .k-button-link-primary.k-active {
  color: #d6534a;
}
.k-button-link-primary.k-selected {
  color: #d6534a;
}
.k-button-link-secondary {
  color: #666666;
}
.k-button-link-secondary:hover, .k-button-link-secondary.k-hover {
  color: #565656;
}
.k-button-link-secondary:focus, .k-button-link-secondary.k-focus {
  box-shadow: 0 0 0px 2px rgba(102, 102, 102, 0.3);
}
.k-button-link-secondary:active, .k-button-link-secondary.k-active {
  color: #565656;
}
.k-button-link-secondary.k-selected {
  color: #565656;
}
.k-button-link-tertiary {
  color: #03a9f4;
}
.k-button-link-tertiary:hover, .k-button-link-tertiary.k-hover {
  color: #038ecd;
}
.k-button-link-tertiary:focus, .k-button-link-tertiary.k-focus {
  box-shadow: 0 0 0px 2px rgba(3, 169, 244, 0.3);
}
.k-button-link-tertiary:active, .k-button-link-tertiary.k-active {
  color: #038ecd;
}
.k-button-link-tertiary.k-selected {
  color: #038ecd;
}
.k-button-link-info {
  color: #0058e9;
}
.k-button-link-info:hover, .k-button-link-info.k-hover {
  color: #004ac4;
}
.k-button-link-info:focus, .k-button-link-info.k-focus {
  box-shadow: 0 0 0px 2px rgba(0, 88, 233, 0.3);
}
.k-button-link-info:active, .k-button-link-info.k-active {
  color: #004ac4;
}
.k-button-link-info.k-selected {
  color: #004ac4;
}
.k-button-link-success {
  color: #37b400;
}
.k-button-link-success:hover, .k-button-link-success.k-hover {
  color: #2e9700;
}
.k-button-link-success:focus, .k-button-link-success.k-focus {
  box-shadow: 0 0 0px 2px rgba(55, 180, 0, 0.3);
}
.k-button-link-success:active, .k-button-link-success.k-active {
  color: #2e9700;
}
.k-button-link-success.k-selected {
  color: #2e9700;
}
.k-button-link-warning {
  color: #ffc000;
}
.k-button-link-warning:hover, .k-button-link-warning.k-hover {
  color: #d6a100;
}
.k-button-link-warning:focus, .k-button-link-warning.k-focus {
  box-shadow: 0 0 0px 2px rgba(255, 192, 0, 0.3);
}
.k-button-link-warning:active, .k-button-link-warning.k-active {
  color: #d6a100;
}
.k-button-link-warning.k-selected {
  color: #d6a100;
}
.k-button-link-error {
  color: #f31700;
}
.k-button-link-error:hover, .k-button-link-error.k-hover {
  color: #cc1300;
}
.k-button-link-error:focus, .k-button-link-error.k-focus {
  box-shadow: 0 0 0px 2px rgba(243, 23, 0, 0.3);
}
.k-button-link-error:active, .k-button-link-error.k-active {
  color: #cc1300;
}
.k-button-link-error.k-selected {
  color: #cc1300;
}
.k-button-link-dark {
  color: #424242;
}
.k-button-link-dark:hover, .k-button-link-dark.k-hover {
  color: #373737;
}
.k-button-link-dark:focus, .k-button-link-dark.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-dark:active, .k-button-link-dark.k-active {
  color: #373737;
}
.k-button-link-dark.k-selected {
  color: #373737;
}
.k-button-link-light {
  color: #ebebeb;
}
.k-button-link-light:hover, .k-button-link-light.k-hover {
  color: #c5c5c5;
}
.k-button-link-light:focus, .k-button-link-light.k-focus {
  box-shadow: 0 0 0px 2px rgba(235, 235, 235, 0.3);
}
.k-button-link-light:active, .k-button-link-light.k-active {
  color: #c5c5c5;
}
.k-button-link-light.k-selected {
  color: #c5c5c5;
}
.k-button-link-inverse {
  color: #424242;
}
.k-button-link-inverse:hover, .k-button-link-inverse.k-hover {
  color: #373737;
}
.k-button-link-inverse:focus, .k-button-link-inverse.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-inverse:active, .k-button-link-inverse.k-active {
  color: #373737;
}
.k-button-link-inverse.k-selected {
  color: #373737;
}
.k-button-link-base {
  color: #424242;
}
.k-button-link-base:hover, .k-button-link-base.k-hover {
  color: #373737;
}
.k-button-link-base:focus, .k-button-link-base.k-focus {
  box-shadow: 0 0 0px 2px rgba(66, 66, 66, 0.3);
}
.k-button-link-base:active, .k-button-link-base.k-active {
  color: #373737;
}
.k-button-link-base.k-selected {
  color: #373737;
}
.k-button-clear-primary {
  color: #ff6358;
}
.k-button-clear-primary:focus::after, .k-button-clear-primary.k-focus::after, .k-button-clear-primary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-secondary {
  color: #666666;
}
.k-button-clear-secondary:focus::after, .k-button-clear-secondary.k-focus::after, .k-button-clear-secondary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-tertiary {
  color: #03a9f4;
}
.k-button-clear-tertiary:focus::after, .k-button-clear-tertiary.k-focus::after, .k-button-clear-tertiary:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-info {
  color: #0058e9;
}
.k-button-clear-info:focus::after, .k-button-clear-info.k-focus::after, .k-button-clear-info:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-success {
  color: #37b400;
}
.k-button-clear-success:focus::after, .k-button-clear-success.k-focus::after, .k-button-clear-success:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-warning {
  color: #ffc000;
}
.k-button-clear-warning:focus::after, .k-button-clear-warning.k-focus::after, .k-button-clear-warning:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-error {
  color: #f31700;
}
.k-button-clear-error:focus::after, .k-button-clear-error.k-focus::after, .k-button-clear-error:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-dark {
  color: #424242;
}
.k-button-clear-dark:focus::after, .k-button-clear-dark.k-focus::after, .k-button-clear-dark:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-light {
  color: #ebebeb;
}
.k-button-clear-light:focus::after, .k-button-clear-light.k-focus::after, .k-button-clear-light:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-inverse {
  color: #424242;
}
.k-button-clear-inverse:focus::after, .k-button-clear-inverse.k-focus::after, .k-button-clear-inverse:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-button-clear-base {
  color: #424242;
}
.k-button-clear-base:focus::after, .k-button-clear-base.k-focus::after, .k-button-clear-base:active::after {
  background-color: currentColor;
  opacity: 0.1;
  display: block;
}
.k-popup {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.k-popup .k-item {
  outline: none;
}

.k-animation-container {
  border-radius: 0 0 4px 4px;
}

.k-animation-container-shown {
  overflow: visible;
}

.k-popup > .k-colorpalette {
  padding-block: 8px;
  padding-inline: 8px;
}

.k-popup.k-popup-transparent {
  border-width: 0;
  background-color: transparent;
}

.k-popup.k-popup-flush {
  padding: 0;
}

.k-popup > .k-widget,
.k-popup > .k-coloreditor {
  border-width: 0;
}

.k-shadow {
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-popup {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  box-shadow: var(--kendo-elevation-4, 0 8px 10px rgba(0, 0, 0, 0.12), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-animation-container {
  border-radius: 0 0 4px 4px;
}

.k-list-container {
  display: flex;
  flex-flow: column nowrap;
}
.k-list-container > .k-list {
  flex: 1;
  height: 100%;
}

.k-list {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
  display: flex;
  flex-flow: column nowrap;
  outline: none;
  position: relative;
  overflow: hidden;
}
.k-list *,
.k-list *::before,
.k-list *::after {
  box-sizing: border-box;
}

.k-popup > .k-list {
  height: 100%;
  border-width: 0;
}

.k-list-group-sticky-header {
  border-width: 0;
  border-width: 0 0 1px;
  border-style: solid;
  font-weight: 700;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.k-list-content {
  border-color: inherit;
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}

.k-list-ul {
  margin: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  height: auto;
  list-style: none;
}

.k-list-item, .k-list-optionlabel {
  border: 0;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 4px;
  position: relative;
  transition-property: color, background-color, outline-color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.k-list-item.k-first::before, .k-first.k-list-optionlabel::before {
  content: "";
  border-width: 1px 0 0;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.k-list-item-text::before,
.k-list-header-text::before,
.k-list-optionlabel::before {
  content: "​";
  width: 0px;
  overflow: hidden;
}

.k-list-optionlabel {
  column-gap: 0;
}

.k-list-group-item {
  border-width: 0;
  border-width: 1px 0 0;
  border-style: solid;
  font-weight: 700;
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 4px;
  position: relative;
}

.k-list-item-group-label {
  padding-block: 0;
  padding-inline: 0.5em;
  font-size: 0.75em;
  position: absolute;
  top: 0;
  inset-inline-end: 0;
}

.k-virtual-content,
.k-virtual-list .k-list-content {
  overflow-y: scroll;
}

.k-virtual-list .k-list-item, .k-virtual-list .k-list-optionlabel,
.k-virtual-list .k-list-group-item,
.k-virtual-content .k-list-item,
.k-virtual-content .k-list-optionlabel,
.k-virtual-content .k-list-group-item {
  position: absolute;
  width: 100%;
}

.k-virtual-list .k-list-item-text,
.k-virtual-list .k-list-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-virtual-list .k-list-optionlabel {
  position: relative;
}

.k-list-filter {
  display: block;
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  flex: none;
}

.k-list-sm {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-list-sm .k-list-group-sticky-header {
  padding-block: 2px;
  padding-inline: 8px;
}
.k-list-sm .k-list-item, .k-list-sm .k-list-optionlabel {
  padding-block: 2px;
  padding-inline: 8px;
}
.k-list-sm .k-list-group-item {
  padding-block: 2px;
  padding-inline: 8px;
}

.k-list-md {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-list-md .k-list-group-sticky-header {
  padding-block: 4px;
  padding-inline: 8px;
}
.k-list-md .k-list-item, .k-list-md .k-list-optionlabel {
  padding-block: 4px;
  padding-inline: 8px;
}
.k-list-md .k-list-group-item {
  padding-block: 4px;
  padding-inline: 8px;
}

.k-list-lg {
  font-size: 16px;
  line-height: 1.5;
}
.k-list-lg .k-list-group-sticky-header {
  padding-block: 6px;
  padding-inline: 8px;
}
.k-list-lg .k-list-item, .k-list-lg .k-list-optionlabel {
  padding-block: 8px;
  padding-inline: 8px;
}
.k-list-lg .k-list-group-item {
  padding-block: 6px;
  padding-inline: 8px;
}

.k-no-data, .k-nodata {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  text-align: center;
  white-space: normal;
}

.k-list {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-list-group-sticky-header {
  border-color: inherit;
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-list-item:hover, .k-list-optionlabel:hover, .k-list-item.k-hover, .k-hover.k-list-optionlabel {
  color: #424242;
  background-color: #f0f0f0;
}
.k-list-item:focus, .k-list-optionlabel:focus, .k-list-item.k-focus, .k-focus.k-list-optionlabel {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
  color: white;
  background-color: #ff6358;
}
.k-list-item.k-selected:hover, .k-selected.k-list-optionlabel:hover, .k-list-item.k-selected.k-hover, .k-selected.k-hover.k-list-optionlabel {
  color: white;
  background-color: #eb5b51;
}

.k-list-optionlabel,
.k-list-optionlabel.k-hover,
.k-list-optionlabel:hover {
  color: #666666;
}

.k-list-group-item {
  border-color: inherit;
}

.k-list-item-group-label {
  color: #ffffff;
  background-color: #424242;
}

.k-no-data, .k-nodata {
  color: #666666;
}

.k-menu-bar, .k-menu {
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-menu-bar *, .k-menu *,
.k-menu-bar *::before,
.k-menu *::before,
.k-menu-bar *::after,
.k-menu *::after {
  box-sizing: border-box;
}

.k-menu-item {
  border-width: 0;
  outline: 0;
  display: flex;
  flex-flow: column nowrap;
  flex: none;
  position: relative;
  user-select: none;
}

.k-menu-item-content {
  display: block;
  height: auto;
  overflow: visible;
}
.k-menu-item-content .k-actions, .k-menu-item-content .k-form-buttons, .k-menu-item-content .k-edit-buttons,
.k-menu-item-content .k-action-buttons,
.k-menu-item-content .k-columnmenu-actions {
  margin-top: 0;
}

.k-menu-link {
  padding-block: 6px;
  padding-inline: 12px;
  outline: 0;
  color: inherit;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  gap: 4px;
  align-items: center;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

.k-menu-link-text {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-menu-expand-arrow {
  margin-inline-end: -4px;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  flex: none;
  position: relative;
}

.k-menu-horizontal {
  flex-direction: row;
}
.k-menu-horizontal > .k-menu-item + .k-menu-item {
  margin-inline-start: 0;
}
.k-menu-horizontal > .k-separator {
  margin-block: 0;
  margin-inline: 4px;
  width: 0;
  height: auto;
  border-width: 0 0 0 1px;
  border-style: solid;
}

.k-menu-vertical {
  flex-direction: column;
}
.k-menu-vertical > .k-menu-item + .k-menu-item {
  margin-top: 0;
}
.k-menu-vertical > .k-menu-item > .k-menu-link {
  padding-block: 4px;
  padding-inline: 8px;
  padding-inline-end: calc(16px + 16px);
}
.k-menu-vertical > .k-menu-item > .k-menu-link > .k-menu-expand-arrow {
  margin-inline-start: 8px;
  margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}
.k-menu-vertical > .k-separator {
  margin-block: 4px;
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  display: block;
}

.k-menu-popup {
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  overflow: auto;
  max-height: 80vh;
}
.k-menu-popup *,
.k-menu-popup *::before,
.k-menu-popup *::after {
  box-sizing: border-box;
}

.k-menu-group {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
}
.k-menu-group .k-menu-item + .k-menu-item {
  margin-top: 0px;
}
.k-menu-group .k-separator {
  margin-block: 4px;
  margin-inline: 0;
  height: 0;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  display: block;
}

.k-menu-popup .k-menu-group {
  position: relative;
}

.k-menu-group-sm {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-menu-group-sm .k-menu-item {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-menu-group-sm .k-menu-link {
  padding-block: 2px;
  padding-inline: 8px;
  padding-inline-end: calc(16px + 16px);
}
.k-menu-group-sm .k-menu-expand-arrow {
  margin-inline-start: 8px;
  margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-menu-group-md {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-menu-group-md .k-menu-item {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-menu-group-md .k-menu-link {
  padding-block: 4px;
  padding-inline: 8px;
  padding-inline-end: calc(16px + 16px);
}
.k-menu-group-md .k-menu-expand-arrow {
  margin-inline-start: 8px;
  margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-menu-group-lg {
  font-size: 16px;
  line-height: 1.5;
}
.k-menu-group-lg .k-menu-item {
  font-size: 16px;
  line-height: 1.5;
}
.k-menu-group-lg .k-menu-link {
  padding-block: 6px;
  padding-inline: 8px;
  padding-inline-end: calc(16px + 16px);
}
.k-menu-group-lg .k-menu-expand-arrow {
  margin-inline-start: 8px;
  margin-inline-end: calc(-1 * (calc(16px + 16px) - 4px));
}

.k-popups-wrapper {
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
}

.k-context-menu {
  margin: 0;
  border-width: 1px;
  border-style: solid;
}
.k-animation-container .k-context-menu.k-menu-horizontal {
  display: flex !important;
  flex-wrap: nowrap;
}

.k-context-menu-popup {
  z-index: 12000;
}
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-popup .k-context-menu,
.k-context-menu-popup .k-context-menu {
  border-width: 0;
}

.k-menu-scroll-wrapper {
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
}
.k-menu-scroll-wrapper .k-menu {
  overflow: hidden;
  flex-wrap: nowrap;
}
.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-radius: 0;
  padding: 0;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: inherit;
  position: absolute;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-left {
  top: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 16px;
  border-right-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-right {
  top: 0;
  inset-inline-end: 0;
  height: 100%;
  width: 16px;
  border-left-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-up {
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-bottom-width: 1px;
}
.k-menu-scroll-wrapper .k-menu-scroll-button.k-scroll-down {
  bottom: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 16px;
  border-top-width: 1px;
}

.k-menu:not(.k-context-menu) {
  color: #424242;
  background-color: transparent;
}
.k-menu:not(.k-context-menu) > .k-item {
  color: #ff6358;
}
.k-menu:not(.k-context-menu) > .k-item:hover, .k-menu:not(.k-context-menu) > .k-item.k-hover {
  color: #d6534a;
}
.k-menu:not(.k-context-menu) > .k-item:active, .k-menu:not(.k-context-menu) > .k-item.k-active {
  color: #424242;
}
.k-menu:not(.k-context-menu) > .k-item:focus, .k-menu:not(.k-context-menu) > .k-item.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-group,
.k-menu.k-context-menu {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}
.k-menu-group .k-item > .k-link:hover, .k-menu-group .k-item > .k-link.k-hover,
.k-menu.k-context-menu .k-item > .k-link:hover,
.k-menu.k-context-menu .k-item > .k-link.k-hover {
  color: #424242;
  background-color: #f0f0f0;
}
.k-menu-group .k-item > .k-link:active, .k-menu-group .k-item > .k-link.k-active, .k-menu-group .k-item > .k-link.k-selected,
.k-menu.k-context-menu .k-item > .k-link:active,
.k-menu.k-context-menu .k-item > .k-link.k-active,
.k-menu.k-context-menu .k-item > .k-link.k-selected {
  color: white;
  background-color: #ff6358;
}
.k-menu-group .k-item:focus > .k-link,
.k-menu-group .k-item.k-focus > .k-link,
.k-menu.k-context-menu .k-item:focus > .k-link,
.k-menu.k-context-menu .k-item.k-focus > .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}

.k-menu-scroll-wrapper .k-menu-scroll-button {
  border-color: rgba(0, 0, 0, 0.08);
  color: #ff6358;
  background-color: #ffffff;
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover {
  color: #d6534a;
}
.k-menu-scroll-wrapper .k-menu-scroll-button:hover::before {
  opacity: 0;
}

.k-toolbar {
  margin: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-toolbar::before {
  content: "";
  height: calc(1.4285714286em + 8px + 2px);
}
.k-toolbar.k-toolbar-resizable {
  flex-wrap: nowrap;
}
.k-toolbar > * {
  flex-shrink: 0;
  display: inline-flex;
  align-content: center;
  vertical-align: middle;
}
.k-toolbar > * > label {
  align-self: center;
}
.k-toolbar .k-input,
.k-toolbar .k-picker {
  width: 10em;
}
.k-toolbar .k-color-picker {
  width: min-content;
}
.k-toolbar .k-toolbar-overflow-button {
  margin-inline-start: auto;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  width: 0;
  height: calc(1.4285714286 * 1em);
  border-width: 0 0 0 1px;
  border-style: solid;
  align-self: center;
}
.k-toolbar .k-toolbar-spacer {
  height: calc(1.4285714286 * 1em);
  flex: 1 0 0%;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  text-decoration: none;
  outline: 0;
}

.k-toolbar-group {
  display: flex;
  flex-direction: inherit;
  flex-wrap: nowrap;
  gap: inherit;
}

.k-toolbar-renderer {
  display: inline-block;
  border-color: inherit;
}

.k-toolbar-sm {
  padding-block: 4px;
  padding-inline: 4px;
  gap: 6px;
}
.k-toolbar-sm::before {
  margin-inline-start: -6px;
}
.k-toolbar-sm > * > label {
  margin-inline-end: 6px;
}

.k-toolbar-md {
  padding-block: 8px;
  padding-inline: 8px;
  gap: 8px;
}
.k-toolbar-md::before {
  margin-inline-start: -8px;
}
.k-toolbar-md > * > label {
  margin-inline-end: 8px;
}

.k-toolbar-lg {
  padding-block: 10px;
  padding-inline: 10px;
  gap: 10px;
}
.k-toolbar-lg::before {
  margin-inline-start: -10px;
}
.k-toolbar-lg > * > label {
  margin-inline-end: 10px;
}

.k-toolbar-sm::before {
  height: calc(1.4285714286em + 4px + 2px);
}

.k-toolbar-md::before {
  height: calc(1.4285714286em + 8px + 2px);
}

.k-toolbar-lg::before {
  height: calc(1.5em + 16px + 2px);
}

.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  padding-block: 8px !important;
  padding-inline: 8px !important;
  border-width: 1px;
  border-style: solid;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  align-items: center;
}
.k-floating-toolbar .k-toolbar,
.editorToolbarWindow.k-window-content .k-toolbar {
  padding: 0;
  border-width: 0;
  flex-shrink: 1;
  color: inherit;
  background: none;
}

.k-editortoolbar-dragHandle {
  cursor: move;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
}

.k-overflow-container {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-overflow-container > .k-item {
  border-color: inherit;
}
.k-overflow-container .k-separator {
  margin: 4px 0;
}
.k-overflow-container .k-overflow-tool-group {
  display: block;
}
.k-overflow-container .k-overflow-button {
  padding-block: 4px;
  padding-inline: 8px;
  width: 100%;
  height: auto;
  border-width: 0;
  border-radius: 0;
  aspect-ratio: auto;
  color: inherit;
  background-color: transparent;
  background-image: none;
  font: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  gap: 4px;
  justify-content: flex-start;
  position: relative;
}
.k-overflow-container .k-button-group {
  box-shadow: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-overflow-container .k-button-group .k-button {
  margin: 0;
}
.k-overflow-container .k-split-button {
  display: flex;
  flex-direction: column;
}
.k-overflow-container .k-overflow-hidden {
  display: none;
}

.k-toolbar.k-toolbar-flat {
  border-width: 1px 0;
  border-top-color: transparent !important;
  border-bottom-color: inherit;
  color: inherit;
  background: none !important;
  box-shadow: none;
}

.k-toolbar {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}
.k-toolbar .k-separator,
.k-toolbar .k-toolbar-separator {
  border-color: inherit;
}
.k-toolbar .k-toolbar-item:focus,
.k-toolbar .k-toolbar-item.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-floating-toolbar,
.editorToolbarWindow.k-window-content {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}

.k-overflow-container .k-button:hover, .k-overflow-container .k-button.k-hover {
  color: #424242;
  background: #f0f0f0;
}
.k-overflow-container .k-button:focus, .k-overflow-container .k-button.k-focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);
}
.k-overflow-container .k-button:active, .k-overflow-container .k-button.k-active {
  color: #424242;
  background: #f0f0f0;
}
.k-overflow-container .k-button.k-selected {
  color: white;
  background: #ff6358;
}
.k-overflow-container .k-button:disabled, .k-overflow-container .k-button.k-disabled {
  color: inherit;
}

.k-pane-wrapper {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-pane {
  width: 100%;
  height: 100%;
  user-select: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow-x: hidden;
}
.k-pane-wrapper .k-view {
  top: 0;
  left: 0;
  position: relative;
  border: 0;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  vertical-align: top;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-pane-wrapper .k-content {
  min-height: 1px;
  flex: 1;
  align-items: stretch;
  display: block;
  width: auto;
  overflow: hidden;
  position: relative;
}

.k-pane-wrapper > div.k-pane {
  box-shadow: none;
  font-weight: normal;
}
.k-pane-wrapper .k-popup-edit-form .k-content,
.k-pane-wrapper .k-grid-edit-form .k-content,
.k-pane-wrapper .k-grid-header-menu .k-content,
.k-pane-wrapper .k-grid-filter-menu .k-content {
  overflow-y: auto;
}
.k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-header-menu .k-content > .k-scroll-container,
.k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container {
  position: absolute;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
}
.k-pane-wrapper .k-grid-edit-form .k-popup-edit-form,
.k-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
  min-width: auto;
}

.k-grid.k-grid-mobile {
  border-width: 0;
}

.k-grid-mobile .k-resize-handle-inner::before {
  content: "\e01e";
  position: absolute;
  font: 16px/1 "WebComponentsIcons";
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.2em;
}
.k-grid-mobile .k-header a {
  user-select: none;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
  padding: 0;
  flex-direction: column;
  align-items: stretch;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
  margin: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
  width: 100%;
  margin-block: -1px;
  margin-inline: 0;
}
.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
  white-space: nowrap;
}

.k-scheduler.k-scheduler-mobile {
  border-width: 0;
}

.k-scheduler-mobile th {
  font-weight: normal;
}
.k-scheduler-mobile .k-event:hover .k-resize-handle {
  visibility: hidden;
}
.k-scheduler-mobile .k-scheduler-toolbar {
  padding-block: 8px;
  padding-inline: 8px;
}
.k-scheduler-mobile .k-scheduler-toolbar > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
  line-height: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 0;
}
.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
  display: inline-block;
}
.k-scheduler-mobile .k-scheduler-footer {
  padding-block: 8px;
  padding-inline: 8px;
  display: flex;
  justify-content: space-between;
}
.k-scheduler-mobile .k-scheduler-footer > * {
  margin: 0;
}
.k-scheduler-mobile .k-scheduler-footer::before {
  display: none;
}
.k-scheduler-mobile .k-scheduler-monthview .k-hidden {
  height: 40px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
  height: 40px;
  vertical-align: top;
  text-align: center;
}
.k-scheduler-mobile .k-scheduler-monthview .k-events-container {
  position: absolute;
  text-align: center;
  height: 6px;
  line-height: 6px;
}
.k-scheduler-mobile .k-scheduler-monthview .k-event {
  position: static;
  padding: 4px;
  border-radius: 50%;
  display: inline-block;
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child {
  display: none;
}
.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group {
  display: none;
}
.k-scheduler-mobile .k-scheduler-header-wrap > div {
  overflow: visible;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
  display: none;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
  width: 1%;
}
.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table td {
  white-space: normal;
}
.k-scheduler-mobile .k-mobile-header .k-scheduler-table td,
.k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
  height: 1.5em;
}
.k-scheduler-mobile .k-time-text,
.k-scheduler-mobile .k-time-period {
  display: block;
  line-height: 1;
}
.k-scheduler-mobile .k-time-period {
  font-size: 0.7em;
}
.k-scheduler-mobile .k-scheduler-table td,
.k-scheduler-mobile .k-scheduler-table th {
  height: 2em;
  vertical-align: middle;
}
.k-scheduler-mobile .k-scheduler-datecolumn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.k-scheduler-mobile .k-task {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.k-scheduler-mobile .k-task .k-scheduler-mark {
  border-radius: 50%;
  margin: 0;
}
.k-scheduler-mobile .k-task .k-scheduler-task-text {
  flex: 1 1 0%;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn {
  vertical-align: top;
}
.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text,
.k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: nowrap;
}
.k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
  border-bottom-width: 0;
}

.k-pane-wrapper .k-appbar {
  padding: 4px;
}
.k-pane-wrapper .k-list-title,
.k-pane-wrapper .k-filter-help-text {
  padding-block: 8px;
  padding-inline: 8px;
  display: block;
}
.k-pane-wrapper .k-listgroup-title {
  padding-block: 8px;
  padding-inline: 8px;
  font-weight: bold;
  text-transform: uppercase;
}
.k-pane-wrapper .k-listgroup .k-listgroup-item {
  border-color: inherit;
}
.k-pane-wrapper .k-listgroup + .k-listgroup {
  margin-top: 16px;
}
.k-pane-wrapper .k-column-menu {
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
  pointer-events: none;
}
.k-pane-wrapper .k-column-menu .k-list-title,
.k-pane-wrapper .k-column-menu .k-listgroup-title {
  padding: 0;
}
.k-pane-wrapper .k-column-menu .k-listgroup {
  margin-inline: -8px;
}
.k-pane-wrapper .k-filter-menu {
  padding: 8px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.k-pane-wrapper .k-filter-menu .k-list-title {
  padding: 0;
}
.k-pane-wrapper .k-filter-menu .k-list-filter {
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-filter-tools {
  display: flex;
  flex-flow: row nowrap;
  gap: inherit;
}
.k-pane-wrapper .k-filter-menu .k-listgroup {
  margin-inline: -8px;
}
.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
  display: flex;
}
.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1 1 auto;
}
.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
  overflow-y: auto;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm,
.k-pane-wrapper .k-pager.k-pager-sm {
  justify-content: center;
}
.k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh,
.k-pane-wrapper .k-pager.k-pager-sm .k-pager-refresh {
  display: none;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-scheduler-toolbar > ul > li, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
  border: 0;
}
.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload, .k-pane-wrapper[dir=rtl] .k-scheduler-mobile .k-task .k-i-reload, [dir=rtl] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
  margin-left: 0.5em;
  margin-right: 0;
}

.k-pane-wrapper,
.k-view {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-pane-wrapper .k-pane > .k-view > .k-content {
  background-position: 50% 50%;
  background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #424242;
}
.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner::before {
  background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
  color: #ff6358;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate {
  color: inherit;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday {
  color: #666666;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell,
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day {
  background-color: #fafafa;
}
.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-tools > li {
  display: inline-block;
  background-color: inherit;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-item,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-link,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-link,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-item,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-link,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
  color: #424242;
}
.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-header-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete,
.k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
  color: #ff6358;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected {
  color: #ff6358;
  background: none;
}
.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-selected .k-link {
  color: inherit;
}

.k-input,
.k-picker {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: normal;
  text-align: start;
  box-shadow: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input *,
.k-input *::before,
.k-input *::after,
.k-picker *,
.k-picker *::before,
.k-picker *::after {
  box-sizing: border-box;
}
.k-input > .k-input,
.k-input > .k-picker,
.k-picker > .k-input,
.k-picker > .k-picker {
  border-width: 0 !important;
  color: inherit;
  background: none;
  font: inherit;
  outline: 0;
  box-shadow: none;
}
.k-input > *,
.k-picker > * {
  margin: 0;
}
.k-input::placeholder,
.k-picker::placeholder {
  color: #666666;
  opacity: 1;
  user-select: none;
}

input.k-input,
textarea.k-textarea {
  padding-block: 4px;
  padding-inline: 8px;
}
input.k-input:disabled, input.k-input[disabled],
textarea.k-textarea:disabled,
textarea.k-textarea[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] input.k-input:disabled, .k-disabled input.k-input:disabled, [disabled] input.k-input[disabled], .k-disabled input.k-input[disabled],
[disabled] textarea.k-textarea:disabled,
.k-disabled textarea.k-textarea:disabled,
[disabled] textarea.k-textarea[disabled],
.k-disabled textarea.k-textarea[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

textarea.k-textarea {
  overflow: auto;
}

.k-picker {
  cursor: pointer;
}

select.k-picker {
  padding-block: 4px;
  padding-inline: 8px;
  appearance: auto;
}
select.k-picker:disabled, select.k-picker[disabled] {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
[disabled] select.k-picker:disabled, .k-disabled select.k-picker:disabled, [disabled] select.k-picker[disabled], .k-disabled select.k-picker[disabled] {
  opacity: 1;
  filter: grayscale(0);
}

.k-input-inner {
  padding-block: 4px;
  padding-inline: 8px;
  width: 100%;
  border: 0;
  outline: 0;
  color: inherit;
  background: none;
  font: inherit;
  flex: 1;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}
.k-input-inner::placeholder {
  color: #666666;
  opacity: 1;
  user-select: none;
}
.k-input-inner:invalid {
  box-shadow: none;
}

span.k-input-inner {
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

textarea.k-input-inner {
  margin: 0;
}

.k-input-value-icon {
  flex: none;
}

.k-input-value-text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-input-value-text::before {
  content: "​";
  width: 0px;
  overflow: hidden;
  flex: none;
  display: inline-block;
  vertical-align: top;
}

.k-input-values {
  min-width: 0px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: text;
}

.k-input-values .k-chip-list {
  display: contents;
}

.k-icon-picker .k-input-inner {
  padding: 4px;
  justify-content: center;
}

.k-input-prefix,
.k-input-suffix {
  border-color: inherit;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex: none;
}
.k-input-prefix > *,
.k-input-suffix > * {
  flex-shrink: 0;
}

.k-input-separator {
  margin: 0;
  width: 0;
  height: 16px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: inherit;
  align-self: center;
}

.k-input-icon,
.k-input-validation-icon,
.k-input-loading-icon,
.k-input-prefix > .k-icon,
.k-input-suffix > .k-icon {
  flex: none;
  align-self: center;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
}

.k-input-loading-icon {
  width: 1em;
  height: 1em;
  font-size: 16px;
}

.k-input-icon.k-svg-icon > svg, .k-input-icon.k-icon-md > svg,
.k-input-validation-icon.k-svg-icon > svg,
.k-input-validation-icon.k-icon-md > svg,
.k-input-loading-icon.k-svg-icon > svg,
.k-input-loading-icon.k-icon-md > svg {
  width: 16px;
  height: 16px;
}
.k-input-icon.k-icon-xs > svg,
.k-input-validation-icon.k-icon-xs > svg,
.k-input-loading-icon.k-icon-xs > svg {
  width: calc(16px * 0.75);
  height: calc(16px * 0.75);
}
.k-input-icon.k-icon-sm > svg,
.k-input-validation-icon.k-icon-sm > svg,
.k-input-loading-icon.k-icon-sm > svg {
  width: calc(16px * 0.875);
  height: calc(16px * 0.875);
}
.k-input-icon.k-icon-lg > svg,
.k-input-validation-icon.k-icon-lg > svg,
.k-input-loading-icon.k-icon-lg > svg {
  width: calc(16px * 1.25);
  height: calc(16px * 1.25);
}
.k-input-icon.k-icon-xl > svg,
.k-input-validation-icon.k-icon-xl > svg,
.k-input-loading-icon.k-icon-xl > svg {
  width: calc(16px * 1.5);
  height: calc(16px * 1.5);
}
.k-input-icon.k-icon-xxl > svg,
.k-input-validation-icon.k-icon-xxl > svg,
.k-input-loading-icon.k-icon-xxl > svg {
  width: calc(16px * 2);
  height: calc(16px * 2);
}
.k-input-icon.k-icon-xxxl > svg,
.k-input-validation-icon.k-icon-xxxl > svg,
.k-input-loading-icon.k-icon-xxxl > svg {
  width: calc(16px * 3);
  height: calc(16px * 3);
}

.k-clear-value {
  outline: 0;
  flex: none;
  align-self: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
}

.k-clear-value:hover,
.k-clear-value.k-hover {
  opacity: 1;
}

.k-clear-value:focus-visible {
  outline: 1px dotted;
  outline-offset: -1px;
}

.k-input-button {
  width: calc(1.4285714286em + 8px);
  border-width: 0;
  border-inline-start-width: 1px;
  flex: none;
  aspect-ratio: auto;
  box-shadow: none;
}
.k-input-button > .k-button-icon {
  min-width: auto !important;
}
.k-input-button:focus {
  box-shadow: none;
}

.k-picker .k-input-button {
  color: inherit;
  background: none;
  border-color: transparent;
}

.k-input-spinner {
  width: calc(1.4285714286em + 8px);
  flex: none;
  display: flex;
  flex-flow: column nowrap;
}
.k-input-spinner .k-spinner-increase,
.k-input-spinner .k-spinner-decrease {
  border-width: 0;
  border-inline-start-width: 1px;
  flex: 1 1 50%;
  box-shadow: none;
  position: relative;
  aspect-ratio: auto;
}
.k-input-spinner .k-spinner-increase .k-button-icon,
.k-input-spinner .k-spinner-decrease .k-button-icon {
  margin-block: -1em;
  margin-inline: 0;
  min-width: auto !important;
  min-height: auto !important;
}
.k-input-spinner .k-spinner-increase .k-icon {
  bottom: auto;
}
.k-input-spinner .k-spinner-decrease .k-icon {
  top: auto;
}

.k-searchbar {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
}

.k-picker-wrap,
.k-dropdown-wrap,
.k-dateinput-wrap,
.k-multiselect-wrap,
.k-numeric-wrap {
  width: 100%;
  border-width: 0;
  border-color: inherit;
  box-sizing: border-box;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  position: relative;
}

.k-input-flat,
.k-picker-flat {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 1px 0;
  border-top-color: transparent !important;
  background-image: none !important;
}

.k-input-outline {
  background: none !important;
}

.k-input-sm,
.k-picker-sm {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-input-sm .k-input-values,
.k-picker-sm .k-input-values {
  padding: 1px;
  gap: 1px;
}
.k-input-sm .k-input-values > .k-searchbar,
.k-input-sm .k-input-values > .k-input-inner,
.k-picker-sm .k-input-values > .k-searchbar,
.k-picker-sm .k-input-values > .k-input-inner {
  margin: -1px;
}
.k-input-sm .k-input-inner,
.k-picker-sm .k-input-inner {
  padding-block: 2px;
  padding-inline: 8px;
}
.k-input-sm .k-input-button,
.k-input-sm .k-spinner-increase,
.k-input-sm .k-spinner-decrease,
.k-picker-sm .k-input-button,
.k-picker-sm .k-spinner-increase,
.k-picker-sm .k-spinner-decrease {
  padding-block: 2px;
  padding-inline: 2px;
}
.k-input-sm .k-input-icon,
.k-input-sm .k-input-validation-icon,
.k-input-sm .k-input-loading-icon,
.k-input-sm .k-clear-value,
.k-input-sm .k-input-prefix > .k-icon,
.k-input-sm .k-input-suffix > .k-icon,
.k-picker-sm .k-input-icon,
.k-picker-sm .k-input-validation-icon,
.k-picker-sm .k-input-loading-icon,
.k-picker-sm .k-clear-value,
.k-picker-sm .k-input-prefix > .k-icon,
.k-picker-sm .k-input-suffix > .k-icon {
  padding-block: 2px;
  padding-inline: 2px;
  box-sizing: content-box;
}
.k-input-sm.k-icon-picker .k-input-inner,
.k-picker-sm.k-icon-picker .k-input-inner {
  width: calc(1.4285714286em);
  height: calc(1.4285714286em);
  padding: 2px;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-sm .k-input-button {
  padding: 2px;
}

select.k-picker-sm {
  padding-block: 2px;
  padding-inline: 8px;
}

.k-input-md,
.k-picker-md {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-input-md .k-input-values,
.k-picker-md .k-input-values {
  padding: 2px;
  gap: 2px;
}
.k-input-md .k-input-values > .k-searchbar,
.k-input-md .k-input-values > .k-input-inner,
.k-picker-md .k-input-values > .k-searchbar,
.k-picker-md .k-input-values > .k-input-inner {
  margin: -2px;
}
.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding-block: 4px;
  padding-inline: 8px;
}
.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
  padding-block: 4px;
  padding-inline: 4px;
}
.k-input-md .k-input-icon,
.k-input-md .k-input-validation-icon,
.k-input-md .k-input-loading-icon,
.k-input-md .k-clear-value,
.k-input-md .k-input-prefix > .k-icon,
.k-input-md .k-input-suffix > .k-icon,
.k-picker-md .k-input-icon,
.k-picker-md .k-input-validation-icon,
.k-picker-md .k-input-loading-icon,
.k-picker-md .k-clear-value,
.k-picker-md .k-input-prefix > .k-icon,
.k-picker-md .k-input-suffix > .k-icon {
  padding-block: 4px;
  padding-inline: 4px;
  box-sizing: content-box;
}
.k-input-md.k-icon-picker .k-input-inner,
.k-picker-md.k-icon-picker .k-input-inner {
  width: calc(1.4285714286em);
  height: calc(1.4285714286em);
  padding: 4px;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-md .k-input-button {
  padding: 4px;
}

select.k-picker-md {
  padding-block: 4px;
  padding-inline: 8px;
}

.k-input-lg,
.k-picker-lg {
  font-size: 16px;
  line-height: 1.5;
}
.k-input-lg .k-input-values,
.k-picker-lg .k-input-values {
  padding: 4px;
  gap: 4px;
}
.k-input-lg .k-input-values > .k-searchbar,
.k-input-lg .k-input-values > .k-input-inner,
.k-picker-lg .k-input-values > .k-searchbar,
.k-picker-lg .k-input-values > .k-input-inner {
  margin: -4px;
}
.k-input-lg .k-input-inner,
.k-picker-lg .k-input-inner {
  padding-block: 8px;
  padding-inline: 8px;
}
.k-input-lg .k-input-button,
.k-input-lg .k-spinner-increase,
.k-input-lg .k-spinner-decrease,
.k-picker-lg .k-input-button,
.k-picker-lg .k-spinner-increase,
.k-picker-lg .k-spinner-decrease {
  padding-block: 8px;
  padding-inline: 8px;
}
.k-input-lg .k-input-icon,
.k-input-lg .k-input-validation-icon,
.k-input-lg .k-input-loading-icon,
.k-input-lg .k-clear-value,
.k-input-lg .k-input-prefix > .k-icon,
.k-input-lg .k-input-suffix > .k-icon,
.k-picker-lg .k-input-icon,
.k-picker-lg .k-input-validation-icon,
.k-picker-lg .k-input-loading-icon,
.k-picker-lg .k-clear-value,
.k-picker-lg .k-input-prefix > .k-icon,
.k-picker-lg .k-input-suffix > .k-icon {
  padding-block: 8px;
  padding-inline: 8px;
  box-sizing: content-box;
}
.k-input-lg.k-icon-picker .k-input-inner,
.k-picker-lg.k-icon-picker .k-input-inner {
  width: calc(1.5em);
  height: calc(1.5em);
  padding: 8px;
  box-sizing: content-box;
}

.k-dropdown-operator.k-picker-lg .k-input-button {
  padding: 8px;
}

select.k-picker-lg {
  padding-block: 8px;
  padding-inline: 8px;
}

.k-input > kendo-popup,
.k-picker > kendo-popup {
  position: fixed;
}

@keyframes autoFillStart {}
@keyframes autoFillEnd {}
.k-autofill:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-autofill:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input:-webkit-autofill {
  animation-name: autoFillStart;
}

.k-input:not(:-webkit-autofill) {
  animation-name: autoFillEnd;
}

.k-input-solid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}
.k-input-solid:hover, .k-input-solid.k-hover {
  border-color: rgba(0, 0, 0, 0.16);
}
.k-input-solid:focus, .k-input-solid.k-focus {
  border-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-solid:focus-within {
  border-color: rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-solid.k-invalid, .k-input-solid.ng-invalid.ng-touched, .k-input-solid.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-input-solid.k-invalid .k-input-validation-icon, .k-input-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-input-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-picker-solid {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-picker-solid:hover, .k-picker-solid.k-hover {
  background-color: #ebebeb;
}
.k-picker-solid:focus, .k-picker-solid.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-solid:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-solid.k-invalid, .k-picker-solid.ng-invalid.ng-touched, .k-picker-solid.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-picker-solid.k-invalid .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-solid.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-input-outline {
  border-color: rgba(66, 66, 66, 0.5);
  color: #424242;
}
.k-input-outline > .k-input-button {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner {
  border-color: inherit;
}
.k-input-outline > .k-input-spinner > .k-spinner-increase, .k-input-outline > .k-input-spinner > .k-spinner-decrease {
  border-color: inherit;
}
.k-input-outline:hover, .k-input-outline.k-hover {
  border-color: rgba(66, 66, 66, 0.8);
}
.k-input-outline:focus, .k-input-outline.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-outline:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-outline.k-invalid, .k-input-outline.ng-invalid.ng-touched, .k-input-outline.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-input-outline.k-invalid .k-input-validation-icon, .k-input-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-input-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-picker-outline {
  border-color: rgba(66, 66, 66, 0.5);
  color: #424242;
}
.k-picker-outline:hover, .k-picker-outline.k-hover {
  border-color: #424242;
  color: white;
  background-color: #424242;
}
.k-picker-outline:focus, .k-picker-outline.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-outline:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-outline.k-invalid, .k-picker-outline.ng-invalid.ng-touched, .k-picker-outline.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-picker-outline.k-invalid .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-outline.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-input-flat {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
}
.k-input-flat:hover, .k-input-flat.k-hover {
  border-color: rgba(0, 0, 0, 0.16);
}
.k-input-flat:focus, .k-input-flat.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-flat:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-input-flat.k-invalid, .k-input-flat.ng-invalid.ng-touched, .k-input-flat.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-input-flat.k-invalid .k-input-validation-icon, .k-input-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-input-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-picker-flat {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
}
.k-picker-flat:hover, .k-picker-flat.k-hover {
  border-color: rgba(0, 0, 0, 0.16);
  background-color: rgba(66, 66, 66, 0.04);
}
.k-picker-flat:focus, .k-picker-flat.k-focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-flat:focus-within {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-picker-flat.k-invalid, .k-picker-flat.ng-invalid.ng-touched, .k-picker-flat.ng-invalid.ng-dirty {
  border-color: #f31700;
}
.k-picker-flat.k-invalid .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-touched .k-input-validation-icon, .k-picker-flat.ng-invalid.ng-dirty .k-input-validation-icon {
  color: #f31700;
}
.k-calendar {
  inline-size: var(--INTERNAL--kendo-calendar-width, min-content);
  block-size: var(--INTERNAL--kendo-calendar-height, min-content);
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-calendar > .k-calendar {
  border: 0;
}

.k-calendar-table {
  margin-block: 0;
  margin-inline: auto;
  border-width: 0;
  border-color: inherit;
  border-spacing: 0;
  border-collapse: separate;
  table-layout: fixed;
  text-align: center;
  outline: 0;
  display: table;
  position: relative;
  z-index: 1;
}

.k-calendar-caption,
.k-calendar-caption.k-calendar-th,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  padding-block: 4px;
  padding-inline: 8px;
  height: 32px;
  box-sizing: border-box;
  text-transform: none;
  text-align: start;
  font-weight: bold;
  cursor: default;
}

.k-calendar-th,
.k-calendar-td {
  border-width: 0;
  padding: 0;
  text-align: center;
  border-style: solid;
  border-color: inherit;
  font-weight: normal;
  cursor: default;
}

.k-calendar-th {
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  font-size: 12px;
  line-height: 2;
  text-transform: uppercase;
}

.k-calendar-td {
  border-radius: 4px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, min-content);
  border-color: transparent;
}

.k-calendar .k-calendar-cell-inner, .k-calendar .k-link {
  border-radius: 4px;
  padding-inline: var(--INTERNAL--kendo-calendar-cell-padding-x, 0.25em);
  padding-block: var(--INTERNAL--kendo-calendar-cell-padding-y, 0.25em);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: normal;
  position: relative;
  overflow: hidden;
}

.k-calendar .k-header {
  padding-block: 4px;
  padding-inline: 4px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  z-index: 2;
}

.k-calendar-header {
  padding-block: 4px;
  padding-inline: 4px;
  min-width: 256px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-calendar-header .k-title,
.k-calendar-header .k-calendar-title {
  font-weight: bold;
}
.k-calendar-header .k-calendar-nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.k-calendar-footer,
.k-calendar .k-footer {
  padding-block: 8px;
  padding-inline: 16px;
  text-align: center;
  clear: both;
}

.k-calendar-view {
  margin: auto;
  padding-block: 0;
  padding-inline: 4px;
  width: 256px;
  inline-size: var(--INTERNAL--kendo-calendar-view-width, 256px);
  min-height: 224px;
  box-sizing: content-box;
  gap: 16px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.k-month-calendar .k-calendar-td,
.k-calendar-monthview .k-calendar-td {
  width: 32px;
  height: 32px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 32px);
}

.k-year-calendar .k-calendar-td,
.k-calendar-yearview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-decade-calendar .k-calendar-td,
.k-calendar-decadeview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}

.k-century-calendar .k-calendar-td,
.k-calendar-centuryview .k-calendar-td {
  width: 64px;
  height: 64px;
  inline-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
  block-size: var(--INTERNAL--kendo-calendar-cell-size, 64px);
}
.k-century-calendar .k-calendar-cell-inner, .k-century-calendar .k-link,
.k-calendar-centuryview .k-calendar-cell-inner,
.k-calendar-centuryview .k-link {
  text-align: start;
}

.k-popup .k-calendar {
  height: 100%;
}

.k-calendar-container,
.k-datetime-container {
  padding: 0;
}
.k-calendar-container .k-calendar,
.k-datetime-container .k-calendar {
  border-width: 0;
}

.k-calendar .k-content.k-scrollable,
.k-calendar .k-calendar-content.k-scrollable {
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}

.k-calendar .k-scrollable-placeholder {
  position: absolute;
  z-index: -1;
  width: 1px;
  top: 0;
  right: 0;
}

.k-calendar-sm {
  --INTERNAL--kendo-calendar-view-width: 224px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-sm-cell-padding-x, 2px );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-sm-cell-padding-y, 2px );
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-calendar-sm.k-month-calendar,
.k-calendar-sm .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-month-cell-size, 28px );
}
.k-calendar-sm.k-year-calendar,
.k-calendar-sm .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-year-cell-size, 56px );
}
.k-calendar-sm.k-decade-calendar,
.k-calendar-sm .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-decade-cell-size, 56px );
}
.k-calendar-sm.k-century-calendar,
.k-calendar-sm .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-sm-century-cell-size, 56px );
}

.k-calendar-md {
  --INTERNAL--kendo-calendar-view-width: 256px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-md-cell-padding-x, 4px );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-md-cell-padding-y, 4px );
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-calendar-md.k-month-calendar,
.k-calendar-md .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-month-cell-size, 32px );
}
.k-calendar-md.k-year-calendar,
.k-calendar-md .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-year-cell-size, 64px );
}
.k-calendar-md.k-decade-calendar,
.k-calendar-md .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-decade-cell-size, 64px );
}
.k-calendar-md.k-century-calendar,
.k-calendar-md .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-md-century-cell-size, 64px );
}

.k-calendar-lg {
  --INTERNAL--kendo-calendar-view-width: 320px;
  --INTERNAL--kendo-calendar-cell-padding-x: var( --kendo-calendar-lg-cell-padding-x, 8px );
  --INTERNAL--kendo-calendar-cell-padding-y: var( --kendo-calendar-lg-cell-padding-y, 8px );
  font-size: 16px;
  line-height: 1.5;
}
.k-calendar-lg.k-month-calendar,
.k-calendar-lg .k-calendar-monthview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-month-cell-size, 40px );
}
.k-calendar-lg.k-year-calendar,
.k-calendar-lg .k-calendar-yearview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-year-cell-size, 80px );
}
.k-calendar-lg.k-decade-calendar,
.k-calendar-lg .k-calendar-decadeview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-decade-cell-size, 80px );
}
.k-calendar-lg.k-century-calendar,
.k-calendar-lg .k-calendar-centuryview {
  --INTERNAL--kendo-calendar-cell-size: var( --kendo-calendar-lg-century-cell-size, 80px );
}

.k-calendar-infinite {
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: row nowrap;
}
.k-calendar-infinite .k-calendar-view {
  padding-block: 0px;
  padding-inline: 16px;
  height: 288px;
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 0;
  overflow: hidden;
}
.k-calendar-infinite .k-calendar-view .k-content.k-scrollable {
  position: relative;
}
.k-calendar-infinite .k-calendar-view::after {
  display: block;
  position: absolute;
  bottom: 0;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 150%;
  left: -25%;
  box-shadow: 0 0 32px 16px #ffffff;
}
.k-calendar-infinite .k-calendar-header {
  margin-left: -16px;
  margin-right: -16px;
  padding-block: 8px;
  padding-inline: 16px;
  width: auto;
  min-width: 0;
}
.k-calendar-infinite .k-calendar-weekdays {
  flex: 0 0 auto;
}

.k-calendar-navigation {
  width: 5em;
  text-align: center;
  flex: 0 0 auto;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.k-calendar-navigation::before, .k-calendar-navigation::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
  box-shadow: 0 0 6em 3em #fafafa;
}
.k-calendar-navigation::before {
  top: 0;
}
.k-calendar-navigation::after {
  bottom: 0;
}
.k-calendar-navigation .k-content,
.k-calendar-navigation .k-calendar-content {
  background: transparent;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.k-calendar-navigation .k-content ul,
.k-calendar-navigation .k-calendar-content ul {
  width: 5em;
}
.k-calendar-navigation .k-content li,
.k-calendar-navigation .k-calendar-content li {
  height: 2em;
  line-height: 2em;
  cursor: pointer;
  padding-block: 0;
  padding-inline: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
.k-calendar-navigation .k-calendar-navigation-marker {
  font-weight: bold;
}
.k-calendar-navigation .k-calendar-navigation-highlight {
  width: 100%;
  border-width: 1px 0;
  border-style: solid;
  height: 2em;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-calendar-range {
  width: auto;
}
.k-calendar-range .k-calendar-view {
  width: auto;
  white-space: nowrap;
}
.k-calendar-range .k-calendar-view::after {
  display: none;
}
.k-calendar-range .k-calendar-view:focus {
  outline: 0;
}
.k-calendar-range .k-range-start {
  border-color: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.k-calendar-range .k-range-end {
  border-color: inherit;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.k-calendar-range .k-range-mid {
  border-color: inherit;
  border-radius: 0;
}

.k-rtl .k-calendar .k-content.k-scrollable,
.k-rtl .k-calendar .k-calendar-content.k-scrollable,
[dir=rtl] .k-calendar .k-content.k-scrollable,
[dir=rtl] .k-calendar .k-calendar-content.k-scrollable,
.k-calendar.k-rtl .k-content.k-scrollable,
.k-calendar.k-rtl .k-calendar-content.k-scrollable,
.k-calendar[dir=rtl] .k-content.k-scrollable,
.k-calendar[dir=rtl] .k-calendar-content.k-scrollable {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}

.k-calendar {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}
.k-calendar .k-header {
  border-color: inherit;
  color: #424242;
  background-color: #fafafa;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.k-calendar .k-calendar-th,
.k-calendar .k-calendar-caption,
.k-calendar .k-meta-header,
.k-calendar .k-month-header {
  color: #666666;
}
.k-calendar .k-calendar-view .k-today {
  color: #ff6358;
}
.k-calendar .k-other-month {
  color: #666666;
}
.k-calendar .k-alt {
  background-color: transparent;
  color: #666666;
}
.k-calendar .k-out-of-range {
  pointer-events: none;
}
.k-calendar .k-calendar-td:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td:hover .k-link,
.k-calendar .k-calendar-td.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-hover .k-link {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f0f0f0;
}
.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected .k-link {
  border-color: rgba(0, 0, 0, 0.08);
  color: white;
  background-color: #ff6358;
}
.k-calendar .k-calendar-td.k-selected:hover .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:hover .k-link,
.k-calendar .k-calendar-td.k-selected.k-hover .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-hover .k-link {
  color: white;
  background-color: #f55f54;
}
.k-calendar .k-calendar-td:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td:focus .k-link,
.k-calendar .k-calendar-td.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-td.k-selected:focus .k-calendar-cell-inner, .k-calendar .k-calendar-td.k-selected:focus .k-link,
.k-calendar .k-calendar-td.k-selected.k-focus .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected.k-focus .k-link {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation {
  color: #424242;
  background-color: #fafafa;
  box-shadow: inset -1px 0 rgba(0, 0, 0, 0.08);
}
.k-calendar .k-calendar-navigation li:hover,
.k-calendar .k-calendar-navigation li.k-hover {
  color: #d6534a;
}
.k-calendar .k-calendar-navigation-highlight {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}
.k-calendar[dir=rtl] .k-calendar-navigation, .k-rtl .k-calendar .k-calendar-navigation {
  box-shadow: inset 1px 0 rgba(0, 0, 0, 0.08);
}
.k-calendar.k-invalid, .k-calendar.k-invalid:hover, .k-calendar.ng-invalid.ng-touched, .k-calendar.ng-invalid.ng-dirty {
  border-color: #f31700;
}

.k-scheduler .k-calendar .k-other-month {
  color: #666666;
  background-color: transparent;
}

.k-calendar .k-range-start,
.k-calendar .k-range-end,
.k-calendar .k-range-mid {
  background-color: rgba(255, 99, 88, 0.25);
}
.k-calendar .k-range-start.k-range-end {
  background-image: none;
  background-color: transparent;
}
.k-calendar .k-range-start .k-calendar-cell-inner, .k-calendar .k-range-start .k-link,
.k-calendar .k-range-end .k-calendar-cell-inner,
.k-calendar .k-range-end .k-link {
  background-color: #ff6358;
}
.k-calendar .k-range-start.k-active .k-calendar-cell-inner, .k-calendar .k-range-start.k-active .k-link,
.k-calendar .k-range-end.k-active .k-calendar-cell-inner,
.k-calendar .k-range-end.k-active .k-link {
  box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.2);
}
.k-calendar .k-range-split-start,
.k-calendar .k-range-split-end {
  position: relative;
}
.k-calendar .k-range-split-start::after,
.k-calendar .k-range-split-end::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
}
.k-calendar .k-range-split-start::after {
  left: -5px;
  right: auto;
  background-image: linear-gradient(to left, rgba(255, 99, 88, 0.25), transparent 100%);
}
.k-calendar .k-range-split-end::after {
  right: -5px;
  left: auto;
  background-image: linear-gradient(to right, rgba(255, 99, 88, 0.25), transparent 100%);
}

.k-actions, .k-form-buttons, .k-edit-buttons,
.k-action-buttons,
.k-columnmenu-actions {
  padding-block: 8px;
  padding-inline: 8px;
  box-sizing: border-box;
  border-width: 0px 0 0 0;
  border-style: solid;
  border-color: inherit;
  flex-shrink: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  clear: both;
}
.k-actions *, .k-form-buttons *, .k-edit-buttons *,
.k-action-buttons *,
.k-columnmenu-actions *,
.k-actions *::before,
.k-form-buttons *::before,
.k-edit-buttons *::before,
.k-action-buttons *::before,
.k-columnmenu-actions *::before,
.k-actions *::after,
.k-form-buttons *::after,
.k-edit-buttons *::after,
.k-action-buttons *::after,
.k-columnmenu-actions *::after {
  box-sizing: border-box;
}

.k-popup .k-actions, .k-popup .k-form-buttons, .k-popup .k-edit-buttons,
.k-popup .k-action-buttons,
.k-popup .k-columnmenu-actions {
  margin-top: 16px;
}

.k-actions-start {
  justify-content: flex-start;
}

.k-actions-center {
  justify-content: center;
}

.k-actions-end {
  justify-content: flex-end;
}

.k-actions-stretched > * {
  flex: 1 0 0%;
}

.k-actions-horizontal {
  width: 100%;
  flex-flow: row nowrap;
}

.k-actions-vertical {
  flex-flow: column nowrap;
}

.k-timeselector {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-popup > .k-timeselector {
  border-width: 0;
}

.k-time-header,
.k-time-selector-header {
  padding-block: 8px;
  padding-inline: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
}
.k-time-header .k-title,
.k-time-header .k-time-selector-header-title,
.k-time-selector-header .k-title,
.k-time-selector-header .k-time-selector-header-title {
  padding-block: 4px;
  padding-inline: 8px;
  font-weight: bold;
  display: inline-block;
}
.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  border-width: 0;
  line-height: inherit;
  cursor: pointer;
}

.k-actions.k-time-fast-selection, .k-time-fast-selection.k-form-buttons, .k-time-fast-selection.k-edit-buttons,
.k-time-fast-selection.k-action-buttons,
.k-time-fast-selection.k-columnmenu-actions {
  margin-top: 0;
}

.k-time-list-container {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
}

.k-time-highlight,
.k-time-list-highlight {
  width: 100%;
  height: calc(20px + 8px);
  border-width: 1px 0px;
  border-style: solid;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% + 9px);
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.k-time-list-wrapper {
  min-width: 4em;
  height: 240px;
  box-sizing: content-box;
  display: inline-flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
}
.k-time-list-wrapper .k-title {
  font-size: 12px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  text-transform: capitalize;
  display: block;
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  display: block;
  content: "​";
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  height: calc(50% - calc(20px + 8px)/2);
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}
.k-time-list-wrapper.k-focus::before {
  top: 18px;
}
.k-time-list-wrapper.k-focus::after {
  bottom: 0;
}

.k-time-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  flex: 1;
  position: relative;
  z-index: 1;
  outline: 0;
  overflow: hidden;
}
.k-time-list::before, .k-time-list::after {
  display: block;
  position: absolute;
  content: "​";
  height: 0;
  line-height: 0;
  z-index: 1;
  width: 200%;
  left: -50%;
}
.k-time-list::before {
  top: 0;
}
.k-time-list::after {
  bottom: 0;
}

.k-time-container,
.k-time-list-content {
  position: relative;
  flex: 1 1 auto;
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 100px;
  padding-left: 100px;
  margin-left: -100px;
  margin-right: calc(-100px - var(--kendo-scrollbar-width, 17px));
}
.k-time-container > ul,
.k-time-list-content > ul {
  height: auto;
  width: 4em;
  margin: auto;
}
.k-time-container .k-scrollable-placeholder,
.k-time-list-content .k-scrollable-placeholder {
  position: absolute;
  width: 1px;
  top: 0;
  right: 0;
}

.k-time-list-item,
.k-time-list .k-item {
  padding-block: 4px;
  padding-inline: 8px;
}

.k-time-separator {
  width: 0;
  height: calc(20px + 8px);
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 11;
  top: calc(9px);
}

.k-timeselector-sm {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-timeselector-sm .k-time-highlight,
.k-timeselector-sm .k-time-list-highlight {
  height: calc(20px + 4px);
}
.k-timeselector-sm .k-time-separator {
  height: calc(20px + 4px);
}
.k-timeselector-sm .k-time-list-item,
.k-timeselector-sm .k-time-list .k-item {
  padding-block: 2px;
  padding-inline: 8px;
}

.k-timeselector-md {
  font-size: 14px;
  line-height: 1.4285714286;
}
.k-timeselector-md .k-time-highlight,
.k-timeselector-md .k-time-list-highlight {
  height: calc(20px + 8px);
}
.k-timeselector-md .k-time-separator {
  height: calc(20px + 8px);
}
.k-timeselector-md .k-time-list-item,
.k-timeselector-md .k-time-list .k-item {
  padding-block: 4px;
  padding-inline: 8px;
}

.k-timeselector-lg {
  font-size: 16px;
  line-height: 1.5;
}
.k-timeselector-lg .k-time-highlight,
.k-timeselector-lg .k-time-list-highlight {
  height: calc(24px + 16px);
}
.k-timeselector-lg .k-time-separator {
  height: calc(24px + 16px);
}
.k-timeselector-lg .k-time-list-item,
.k-timeselector-lg .k-time-list .k-item {
  padding-block: 8px;
  padding-inline: 8px;
}

.k-rtl .k-time-container,
.k-rtl .k-time-list-content,
[dir=rtl] .k-time-container,
[dir=rtl] .k-time-list-content {
  padding-right: 100px;
  padding-left: 100px;
  margin-left: calc(-100px - var(--kendo-scrollbar-width, 17px));
  margin-right: -100px;
}

.k-timeselector {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-time-header .k-time-now,
.k-time-selector-header .k-time-now {
  color: #ff6358;
}
.k-time-header .k-time-now:hover,
.k-time-selector-header .k-time-now:hover {
  color: #d6534a;
}

.k-time-list-wrapper .k-title {
  color: #666666;
}
.k-time-list-wrapper.k-focus .k-title {
  color: #424242;
}
.k-time-list-wrapper.k-focus::before, .k-time-list-wrapper.k-focus::after {
  background-color: rgba(0, 0, 0, 0.04);
}

.k-time-list::before, .k-time-list::after {
  box-shadow: 0 0 3em 1.5em #ffffff;
}
.k-time-list .k-item:hover {
  color: #ff6358;
}

.k-time-container {
  background: transparent;
}

.k-time-highlight {
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.k-datetime-selector {
  display: flex;
  transition: transform 0.2s;
}

.k-datetime-wrap {
  width: calc(256px + 32px);
  overflow: hidden;
}
.k-datetime-wrap .k-datetime-buttongroup {
  padding-block: 8px;
  padding-inline: 8px;
}
.k-datetime-wrap .k-calendar,
.k-datetime-wrap .k-timeselector {
  width: 100%;
  border-width: 0;
}

.k-datetime-calendar-wrap,
.k-datetime-time-wrap {
  text-align: center;
  flex: 0 0 calc(256px + 32px);
}

.k-date-tab .k-datetime-selector {
  transform: translateX(0);
}

.k-time-tab .k-datetime-selector {
  transform: translateX(-100%);
}

.k-time-tab .k-datetime-selector.k-rtl, .k-time-tab .k-datetime-selector[dir=rtl], .k-rtl .k-time-tab .k-datetime-selector, [dir=rtl] .k-time-tab .k-datetime-selector {
  transform: translateX(100%);
}

.k-floating-label-container {
  padding-top: calc(1.4285714286 * 14px);
  box-sizing: border-box;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
  flex-direction: column;
  justify-content: stretch;
}
.k-floating-label-container *,
.k-floating-label-container *::before,
.k-floating-label-container *::after {
  box-sizing: border-box;
}
.k-floating-label-container > .k-label {
  max-width: 90%;
  font-size: 14px;
  line-height: 1.4285714286;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: calc(calc(1.4285714286 * 14px) + 1px + 4px);
  left: calc(8px + 1px);
  overflow: hidden;
  cursor: text;
  transform-origin: left center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, left 0.2s ease-out;
}
.k-floating-label-container > .k-widget {
  flex: 1 1 auto;
  width: auto;
}
.k-floating-label-container.k-empty > .k-label {
  top: calc(calc(1.4285714286 * 14px) + 1px + 4px);
  left: calc(8px + 1px);
  transform: scale(1);
  pointer-events: none;
}
.k-floating-label-container > .k-label, .k-floating-label-container.k-focus > .k-label {
  top: 0;
  left: 0;
  transform: scale(1);
}
.k-floating-label-container:focus-within > .k-label {
  top: 0;
  left: 0;
  transform: scale(1);
}
.k-floating-label-container.k-empty:not(.k-focus):not(:focus-within) ::placeholder {
  color: transparent;
}
[dir=rtl] .k-floating-label-container > .k-label, .k-rtl .k-floating-label-container > .k-label, .k-floating-label-container[dir=rtl] > .k-label {
  transform-origin: right center;
  transition: transform 0.2s ease-out, color 0.2s ease-out, top 0.2s ease-out, right 0.2s ease-out;
}
[dir=rtl] .k-floating-label-container.k-empty > .k-label, .k-rtl .k-floating-label-container.k-empty > .k-label, .k-floating-label-container[dir=rtl].k-empty > .k-label {
  left: auto;
  right: calc(8px + 1px);
}
[dir=rtl] .k-floating-label-container > .k-label, [dir=rtl] .k-floating-label-container.k-focus > .k-label, .k-rtl .k-floating-label-container > .k-label, .k-rtl .k-floating-label-container.k-focus > .k-label, .k-floating-label-container[dir=rtl] > .k-label, .k-floating-label-container[dir=rtl].k-focus > .k-label {
  left: auto;
  right: 0;
}
[dir=rtl] .k-floating-label-container:focus-within > .k-label, .k-rtl .k-floating-label-container:focus-within > .k-label, .k-floating-label-container[dir=rtl]:focus-within > .k-label {
  left: auto;
  right: 0;
}

.k-floating-label-container.k-invalid > .k-label, .k-floating-label-container.ng-invalid.ng-touched > .k-label, .k-floating-label-container.ng-invalid.ng-dirty > .k-label {
  color: #f31700;
}

.k-dropdown-operator {
  width: min-content;
  flex: none;
}
.k-dropdown-operator .k-input-button {
  border-width: 0;
  width: min-content;
}
.k-dropdown-operator .k-input-button .k-button-icon {
  width: auto;
  aspect-ratio: 1;
}
.k-dropdown-operator .k-input-inner {
  display: none;
}

.k-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
}

.k-overlay {
  background-color: #000000;
}

.k-overlay-primary {
  background-color: #ff6358;
}

.k-overlay-secondary {
  background-color: #666666;
}

.k-overlay-tertiary {
  background-color: #03a9f4;
}

.k-overlay-info {
  background-color: #0058e9;
}

.k-overlay-success {
  background-color: #37b400;
}

.k-overlay-warning {
  background-color: #ffc000;
}

.k-overlay-error {
  background-color: #f31700;
}

.k-overlay-dark {
  background-color: #424242;
}

.k-overlay-light {
  background-color: #ebebeb;
}

.k-overlay-inverse {
  background-color: #424242;
}

.k-form,
.k-form-inline {
  font-size: 14px;
  line-height: 1.4285714286;
}

.k-fieldset {
  margin: 30px;
  border-width: 1px 0 0;
  border-style: solid;
  padding: 25px 0 0;
}
.k-fieldset > legend {
  margin-left: 0;
  padding: 0 8px 0 0;
  text-transform: uppercase;
}

kendo-label > .k-label {
  margin: 0;
}

.k-label-optional {
  margin-left: 6px;
  font-size: 12px;
  font-style: italic;
  align-self: center;
}

.k-label-empty::before {
  content: "";
  display: inline-block;
}

.k-input-label {
  margin-right: 10px;
  z-index: 1;
}
.k-input-label:dir(rtl), .k-rtl .k-input-label, .k-input-label.k-rtl, [dir=rtl] .k-input-label, .k-input-label[dir=rtl] {
  margin-right: 0;
  margin-left: 10px;
}

.k-form {
  border: 0;
}
.k-form .k-form-field > .k-label,
.k-form .k-form-field > kendo-label,
.k-form .k-form-label {
  margin-bottom: 0px;
  display: flex;
  flex-flow: row nowrap;
}
.k-form .k-label + .k-radio {
  margin-left: 0;
}
.k-form .k-form-fieldset {
  margin: 2em 0 0;
  padding: 0px;
  border: 0;
}
.k-form .k-form-fieldset > *:not(.k-hidden):first-child, .k-form .k-form-fieldset > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-legend {
  margin: 0 0 1em;
  padding: 0px;
  border-width: 0 0 2px;
  border-style: solid;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
}
.k-form .k-form-group {
  padding: 0;
}
.k-form > *:not(.k-hidden):first-child, .k-form > *.k-hidden + :not(.k-hidden) {
  margin-top: 0;
}
.k-form .k-form-field,
.k-form .k-form-field-wrap {
  display: block;
}
.k-form .k-form-field-disabled .k-label,
.k-form .k-form-field-disabled .k-form-label {
  outline: none;
  cursor: default;
  opacity: 0.6;
  filter: grayscale(0.1);
  pointer-events: none;
  box-shadow: none;
}
.k-form .k-alert-error {
  font-size: 12px;
  margin-top: 8px;
}
.k-form .k-field-info {
  display: inline-block;
  font-size: 10px;
  line-height: 1;
  margin-block: 0;
  margin-inline: 8px;
}
.k-form .k-multiselect,
.k-form .k-floating-label-container,
.k-form .k-daterangepicker .k-dateinput,
.k-form .k-signature {
  display: inline-flex;
  width: 100%;
}

.k-form-buttons {
  padding: 0;
  overflow: visible;
}

.k-form-hint,
.k-form-error {
  margin-top: 4px;
  display: flex;
  font-size: 12px;
  font-style: italic;
}

.k-text-start,
.k-buttons-start {
  justify-content: flex-start;
}

.k-text-end,
.k-buttons-end {
  justify-content: flex-end;
}

.k-form-separator {
  margin: 14px 0 0;
  border-width: 1px 0 0;
  border-style: solid;
  display: block;
  flex: 0 0 auto;
}

.k-form-horizontal .k-form-field {
  display: flex;
}
.k-form-horizontal .k-form-field > .k-label,
.k-form-horizontal .k-form-field > kendo-label,
.k-form-horizontal .k-form-field > .k-form-label {
  margin-right: 10px;
  padding-top: 5px;
  width: 25%;
  text-align: end;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.k-form-horizontal .k-form-field .k-label-optional {
  margin: 0;
  align-self: inherit;
}
.k-form-horizontal .k-form-field-wrap {
  max-width: calc(100% - 25% - 10px);
  flex: 1 1 auto;
}

.k-form [dir=rtl] .k-label-optional, .k-rtl .k-form .k-label-optional {
  margin-left: 0;
  margin-right: 6px;
}

.k-form-horizontal [dir=rtl].k-form-field > .k-label,
.k-form-horizontal [dir=rtl].k-form-field > kendo-label,
.k-form-horizontal [dir=rtl].k-form-field > .k-form-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-label,
.k-form-horizontal [dir=rtl] .k-form-field > kendo-label,
.k-form-horizontal [dir=rtl] .k-form-field > .k-form-label, .k-rtl .k-form-horizontal.k-form-field > .k-label,
.k-rtl .k-form-horizontal.k-form-field > kendo-label,
.k-rtl .k-form-horizontal.k-form-field > .k-form-label,
.k-rtl .k-form-horizontal .k-form-field > .k-label,
.k-rtl .k-form-horizontal .k-form-field > kendo-label,
.k-rtl .k-form-horizontal .k-form-field > .k-form-label {
  margin-right: 0;
  margin-left: 10px;
}

.k-form-sm .k-form-field,
.k-form-sm .k-form-buttons {
  margin-top: 10px;
}

.k-form-md .k-form-field,
.k-form-md .k-form-buttons {
  margin-top: 14px;
}

.k-form-lg .k-form-field,
.k-form-lg .k-form-buttons {
  margin-top: 18px;
}

.k-form-inline {
  padding: 16px;
}
.k-form-inline fieldset {
  border-width: 1px 0 0;
  border-style: solid;
  margin: 32px 0;
  padding: 0;
}
.k-form-inline fieldset:first-child:first-of-type {
  margin-top: 0;
}
.k-form-inline fieldset:last-child:last-of-type {
  margin-bottom: 0;
}
.k-form-inline legend {
  font-size: 12px;
  text-align: start;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 12px;
  text-transform: uppercase;
  padding: 0 8px 0 0;
  width: auto;
}
.k-form-inline .k-form-field {
  display: flex;
  align-items: flex-start;
  text-align: start;
  margin-bottom: 12px;
}
.k-form-inline .k-form-field > span:not(.k-widget) {
  width: 25%;
  text-align: end;
  line-height: 1.4285714286;
  padding: calc(4px + 1px) 0;
  padding-right: 12px;
  align-self: center;
}
.k-form-inline .k-form-field > input {
  align-self: center;
}
.k-form-inline .k-form-field > input:not(.k-checkbox):not(.k-radio) {
  flex: 1 1 auto;
}
.k-form-inline .k-form-field .k-alert-error {
  font-size: 12px;
  margin-top: 8px;
}
.k-form-inline .k-form-field .k-field-info {
  display: block;
  font-size: 10px;
  line-height: 1;
  margin: 0;
}
.k-form-inline .k-form-field:last-child {
  margin-bottom: 0;
}

.k-edit-form-container {
  width: 400px;
  min-width: 400px;
  border-color: inherit;
  position: relative;
}

.k-popup-edit-form > .k-actions, .k-popup-edit-form > .k-edit-buttons,
.k-popup-edit-form > .k-action-buttons,
.k-popup-edit-form > .k-columnmenu-actions, .k-popup-edit-form > .k-form-buttons,
.k-edit-form-container .k-actions,
.k-edit-form-container .k-edit-buttons,
.k-edit-form-container .k-action-buttons,
.k-edit-form-container .k-columnmenu-actions,
.k-edit-form-container .k-form-buttons {
  margin: 16px -16px -16px;
}

.k-edit-label {
  margin: 0 0 16px 0;
  padding: calc(4px + 1px) 0;
  width: 30%;
  line-height: 1.4285714286;
  text-align: end;
  float: left;
  clear: both;
}

.k-edit-field {
  margin: 0 0 16px 0;
  width: 65%;
  float: right;
  clear: right;
  position: relative;
}
.k-edit-field.k-no-editor {
  padding: calc(4px + 1px) 0;
}

.k-edit-field > .k-widget {
  width: 100%;
  box-sizing: border-box;
}
.k-edit-field input[type=radio]:not(.k-radio),
.k-edit-field input[type=checkbox]:not(.k-checkbox) {
  margin-right: 0.4ex;
}
.k-edit-field .k-radio-label,
.k-edit-field .k-checkbox-label {
  margin-right: 16px;
}
.k-edit-field > .k-reset > li + li {
  margin-top: 8px;
}
.k-edit-field .k-reset .k-widget {
  margin: 0 0.4ex 0 1ex;
}

.k-form,
.k-form-inline {
  color: #424242;
}
.k-form fieldset legend,
.k-form-inline fieldset legend {
  color: #373737;
}
.k-form .k-form-legend,
.k-form-inline .k-form-legend {
  border-color: rgba(0, 0, 0, 0.08);
}
.k-form .k-field-info,
.k-form-inline .k-field-info {
  color: #666666;
}
.k-form .k-alert-error,
.k-form-inline .k-alert-error {
  color: #f31700;
}

.k-form-error,
.k-text-error,
.k-form-field-error .k-label {
  color: #f31700;
}

.k-form-separator {
  border-color: rgba(0, 0, 0, 0.08);
}

.k-window {
  border-radius: 0px;
  padding: 0;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.25;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  z-index: 10002;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.k-window.k-window-maximized {
  max-width: 100vw;
  max-height: 100vh;
  box-shadow: none;
}

.k-window-sm {
  width: 300px;
}

.k-window-md {
  width: 800px;
}

.k-window-lg {
  width: 1200px;
}

.k-window-titlebar {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding-block: 12px;
  padding-inline: 16px;
  border-width: 0 0 1px;
  border-style: solid;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
}
.k-window-minimized .k-window-titlebar {
  border-width: 0;
}

.k-window-title {
  padding-block: 0.5em;
  padding-inline: 0;
  margin-block: -0.5em;
  margin-inline: 0;
  font-size: 16px;
  line-height: 1.25;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
}
.k-window-title::before {
  content: "​";
}

.k-window-titlebar-actions {
  margin-block: -5em;
  margin-inline: 0;
  margin-inline-end: calc(12px - 16px);
  line-height: 1;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  vertical-align: top;
}

.k-window-titlebar-action {
  flex-shrink: 0;
}

.k-window-content,
.k-prompt-container {
  padding-block: 16px;
  padding-inline: 16px;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background: none;
  outline: 0;
  overflow: auto;
  position: relative;
  flex: 1 1 auto;
}

.k-window-content + .k-prompt-container {
  margin-top: -8px;
}

.k-window-content:first-child {
  padding-top: clamp(16px, 12px, 12px);
}

.k-window-content:last-child {
  padding-bottom: clamp(16px, 12px, 12px);
}

.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}
.k-window-iframecontent .k-content-frame {
  vertical-align: top;
  border: 0;
  width: 100%;
  height: 100%;
}

.k-window-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: 8px;
  padding-inline: 8px;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  overflow: hidden;
}

.k-prompt-container > .k-textarea {
  width: 100%;
}

.k-window .k-resize-n {
  top: 0;
}
.k-window .k-resize-e {
  right: 0;
}
.k-window .k-resize-s {
  bottom: 0;
}
.k-window .k-resize-w {
  left: 0;
}

.k-window {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
  box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window:focus,
.k-window.k-focus {
  box-shadow: var(--kendo-elevation-9, 0 32px 34px rgba(0, 0, 0, 0.32), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-window-titlebar {
  border-color: inherit;
  color: #424242;
  background-color: #fafafa;
}

.k-window-primary .k-window-titlebar {
  color: white;
  background-color: #ff6358;
}

.k-window-light .k-window-titlebar {
  color: black;
  background-color: #ebebeb;
}

.k-window-dark .k-window-titlebar {
  color: white;
  background-color: #424242;
}

.k-dialog-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
}
.k-dialog-wrapper .k-dialog {
  position: relative;
}

.k-dialog {
  padding: 0;
  position: fixed;
  box-sizing: border-box;
}
.k-dialog.k-dialog-centered {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.k-dialog .k-multiselect {
  width: 100%;
}

.k-dialog-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: 8px;
  padding-inline: 8px;
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 8px;
  overflow: hidden;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: #424242;
  background-color: #fafafa;
}

.k-dialog-primary .k-dialog-titlebar {
  color: white;
  background-color: #ff6358;
}

.k-dialog-light .k-dialog-titlebar {
  color: black;
  background-color: #ebebeb;
}

.k-dialog-dark .k-dialog-titlebar {
  color: white;
  background-color: #424242;
}

.k-tooltip {
  border-radius: 4px;
  margin: 0;
  padding-block: 4px;
  padding-inline: 8px;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  background-repeat: repeat-x;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.25;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  position: absolute;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-tooltip *,
.k-tooltip *::before,
.k-tooltip *::after {
  box-sizing: border-box;
}

.k-tooltip-icon {
  margin-inline-end: 4px;
  flex-shrink: 0;
}

.k-tooltip-title {
  margin-bottom: 0.25em;
  font-size: 17.5px;
  line-height: 1;
}

.k-tooltip-content {
  align-self: stretch;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.k-tooltip-button {
  margin-inline-start: 4px;
  flex-shrink: 0;
}
.k-tooltip-button .k-icon {
  color: inherit;
  vertical-align: top;
}

.k-callout {
  width: 12px;
  height: 12px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent;
  position: absolute;
  pointer-events: none;
}

.k-callout-n {
  margin-left: -6px;
  border-bottom-color: currentColor;
  top: -12px;
  left: 50%;
  pointer-events: none;
}

.k-callout-e {
  margin-top: -6px;
  border-left-color: currentColor;
  top: 50%;
  right: -12px;
  pointer-events: none;
}

.k-callout-s {
  margin-left: -6px;
  border-top-color: currentColor;
  bottom: -12px;
  left: 50%;
  pointer-events: none;
}

.k-callout-w {
  margin-top: -6px;
  border-right-color: currentColor;
  top: 50%;
  left: -12px;
  pointer-events: none;
}

.k-tooltip-wrapper .k-tooltip {
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}

.k-tooltip {
  border-color: rgba(0, 0, 0, 0.75);
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
}
.k-tooltip .k-callout {
  color: rgba(0, 0, 0, 0.75);
}

.k-tooltip-primary {
  border-color: #ff6358;
  color: white;
  background-color: #ff6358;
}
.k-tooltip-primary .k-callout {
  color: #ff6358;
}

.k-tooltip-info {
  border-color: #0058e9;
  color: white;
  background-color: #0058e9;
}
.k-tooltip-info .k-callout {
  color: #0058e9;
}

.k-tooltip-success {
  border-color: #37b400;
  color: white;
  background-color: #37b400;
}
.k-tooltip-success .k-callout {
  color: #37b400;
}

.k-tooltip-warning {
  border-color: #ffc000;
  color: black;
  background-color: #ffc000;
}
.k-tooltip-warning .k-callout {
  color: #ffc000;
}

.k-tooltip-error {
  border-color: #f31700;
  color: white;
  background-color: #f31700;
}
.k-tooltip-error .k-callout {
  color: #f31700;
}

.k-scheduler {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.k-scheduler table,
.k-scheduler thead,
.k-scheduler tfoot,
.k-scheduler tbody,
.k-scheduler tr,
.k-scheduler th,
.k-scheduler td,
.k-scheduler div,
.k-scheduler .k-scheduler-edit-dialog,
.k-scheduler > * {
  border-color: inherit;
}

kendo-scheduler.k-scheduler {
  overflow: hidden;
}
kendo-scheduler.k-scheduler.k-readonly-scheduler .k-event-delete {
  display: none;
}

.k-scheduler-table {
  width: 100%;
  max-width: none;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}
.k-scheduler-table td,
.k-scheduler-table th {
  padding-block: 8px;
  padding-inline: 8px;
  height: calc(1.4285714286 * 1em);
  overflow: hidden;
  white-space: nowrap;
  border-style: solid;
  border-width: 0 0 1px 1px;
  vertical-align: top;
  box-sizing: content-box;
}
.k-scheduler-table td:first-child,
.k-scheduler-table th:first-child {
  border-left-width: 0;
}
.k-scheduler-table .k-middle-row td {
  border-bottom-style: dotted;
}
.k-scheduler-table .k-link {
  cursor: pointer;
}

.k-scheduler-layout-flex {
  overflow: auto;
}
.k-scheduler-layout-flex .k-scheduler-head {
  position: sticky;
  top: 0;
  z-index: 3;
}
.k-scheduler-layout-flex .k-scheduler-body {
  position: relative;
}
.k-scheduler-layout-flex .k-scheduler-head,
.k-scheduler-layout-flex .k-scheduler-body {
  display: flex;
  flex: 100%;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-row {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  min-width: 0;
}
.k-scheduler-layout-flex .k-scheduler-group {
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  min-width: 0;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-group .k-group-cell {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: auto;
}
.k-scheduler-layout-flex .k-scheduler-group .k-group-content {
  padding: 0;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
  border-width: 0;
  flex-wrap: wrap;
}
.k-scheduler-layout-flex .k-scheduler-group.k-group-horizontal .k-group-cell {
  flex-basis: 100%;
  border-width: 0;
  padding: 0;
}
.k-scheduler-layout-flex.k-scheduler-timeline-view .k-scheduler-body .k-scheduler-cell {
  flex-basis: auto;
}
.k-scheduler-layout-flex .k-more-events {
  bottom: 2px;
  left: 0;
  width: 100%;
}
.k-scheduler-layout-flex .k-scheduler-cell {
  display: flex;
  flex: 1 1 100%;
  padding-block: 8px;
  padding-inline: 8px;
  min-height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  border-style: solid;
  border-width: 0 1px 1px 0;
  vertical-align: top;
  box-sizing: content-box;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-slot-cell {
  position: relative;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-heading-cell {
  justify-content: center;
  font-weight: bold;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-side-cell {
  justify-content: flex-end;
  flex-grow: 0;
  flex-basis: auto;
  overflow: visible;
}
.k-scheduler-layout-flex .k-scheduler-cell.k-major-cell {
  border-bottom-width: 0;
}
.k-scheduler-layout-flex .k-middle-row .k-scheduler-cell {
  border-bottom-style: dotted;
}
.k-scheduler-layout-flex .k-resource-cell {
  flex: none;
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
}
.k-scheduler-layout-flex .k-resource-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  border-width: 0;
}
.k-scheduler-layout-flex .k-sticky-cell {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 0;
  position: sticky;
  left: 0;
  z-index: 3;
}

.k-scheduler-toolbar {
  border-width: 0;
  border-bottom-width: 1px;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.k-scheduler-toolbar .k-widget {
  font-size: inherit;
}
.k-scheduler-toolbar .k-nav-current {
  color: inherit;
  outline: 0;
  text-decoration: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.k-scheduler-toolbar .k-scheduler-search {
  display: inline-flex;
  flex-flow: row nowrap;
}

.k-scheduler-toolbar .k-views-dropdown {
  width: auto;
  font: inherit;
}

.k-scheduler-footer {
  box-shadow: none;
  border-width: 0;
  border-top-width: 1px;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
}

.k-scheduler-layout {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
}
.k-scheduler-layout > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}
.k-scheduler-layout td.k-selected,
.k-scheduler-layout .k-scheduler-cell.k-selected {
  outline: none;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr th,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr th {
  border-bottom-color: transparent;
}
.k-scheduler-layout tr + tr .k-scheduler-times tr .k-slot-cell,
.k-scheduler-layout tr + tr .k-scheduler-times tr .k-scheduler-times-all-day, .k-scheduler-layout tr + tr .k-scheduler-times tr:last-child th,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr .k-slot-cell,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr .k-scheduler-times-all-day,
.k-scheduler-layout .k-scheduler-pane + .k-scheduler-pane .k-scheduler-times tr:last-child th {
  border-bottom-color: inherit;
}

.k-scheduler-layout.k-scheduler-flex-layout {
  display: flex;
  flex-direction: column;
}
.k-scheduler-layout.k-scheduler-flex-layout.k-scheduler-weekview .k-scheduler-pane:first-child .k-scheduler-table, .k-scheduler-layout.k-scheduler-flex-layout.k-scheduler-dayview .k-scheduler-pane:first-child .k-scheduler-table {
  table-layout: fixed;
}

.k-scheduler-header,
.k-scheduler-view-header {
  padding-inline-end: var(--kendo-scrollbar-width, 17px);
}

.k-scheduler-header th {
  text-align: center;
}

.k-scheduler-header,
.k-scheduler-header-wrap,
.k-scheduler-header-wrap > div {
  border-color: inherit;
  overflow: hidden;
}

.k-scheduler-header-wrap {
  border-width: 0;
  border-style: solid;
  position: relative;
}

.k-scheduler-times {
  border-color: inherit;
  position: relative;
  overflow: hidden;
}
.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}
.k-scheduler-times th {
  border-width: 0 1px 1px 0;
  text-align: end;
}
.k-scheduler-times .k-slot-cell,
.k-scheduler-times .k-scheduler-times-all-day {
  border-bottom-color: inherit;
}
.k-scheduler-times .k-slot-cell.k-last-resource {
  border-right: 0;
}
.k-scheduler-times .k-slot-cell.k-empty-slot {
  padding-left: 0;
  padding-right: 0;
}

.k-scheduler-datecolumn {
  width: 12em;
}

.k-scheduler-timecolumn {
  width: 11em;
  white-space: nowrap;
}

.k-scheduler-content {
  border-color: inherit;
  position: relative;
  overflow: auto;
}
.k-scheduler-content:focus {
  outline: none;
}

kendo-scheduler .k-event,
.k-event {
  border-radius: 4px;
  min-height: 25px;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-radius: 4px;
  text-align: start;
  outline: 0;
  cursor: default;
  position: absolute;
  overflow: hidden;
  padding-right: 8px;
}
kendo-scheduler .k-event > div,
.k-event > div {
  position: relative;
  z-index: 2;
}
kendo-scheduler .k-event .k-event-template,
.k-event .k-event-template {
  line-height: calc(25px - 2 * 4px);
  padding-block: 4px;
  padding-inline: 8px;
}
kendo-scheduler .k-event .k-event-time,
.k-event .k-event-time {
  padding-bottom: 0;
  font-size: 0.875em;
  white-space: nowrap;
  display: none;
}
kendo-scheduler .k-event .k-event-actions,
.k-event .k-event-actions {
  white-space: nowrap;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  z-index: 2;
}
kendo-scheduler .k-event .k-event-actions .k-event-delete,
.k-event .k-event-actions .k-event-delete {
  opacity: 0.5;
  visibility: hidden;
}
kendo-scheduler .k-event .k-event-actions:hover .k-event-delete, kendo-scheduler .k-event .k-event-actions.k-hover .k-event-delete,
.k-event .k-event-actions:hover .k-event-delete,
.k-event .k-event-actions.k-hover .k-event-delete {
  opacity: 1;
}
kendo-scheduler .k-event .k-event-actions a,
.k-event .k-event-actions a {
  color: inherit;
}
kendo-scheduler .k-event .k-event-actions:first-child,
.k-event .k-event-actions:first-child {
  margin: 2px 0.4ex 0 4px;
  top: 0;
  right: 0;
  float: left;
  position: relative;
  opacity: 1;
  visibility: visible;
  line-height: normal;
}
kendo-scheduler .k-event .k-resize-handle,
.k-event .k-resize-handle {
  z-index: 4;
  opacity: 0.5;
  visibility: hidden;
}
kendo-scheduler .k-event .k-resize-handle::before,
.k-event .k-resize-handle::before {
  border-color: currentColor;
}
kendo-scheduler .k-event .k-resize-n,
.k-event .k-resize-n {
  height: 0.5em;
  top: 0;
}
kendo-scheduler .k-event .k-resize-s,
.k-event .k-resize-s {
  height: 0.5em;
  bottom: 0;
}
kendo-scheduler .k-event .k-resize-n::before,
kendo-scheduler .k-event .k-resize-s::before,
.k-event .k-resize-n::before,
.k-event .k-resize-s::before {
  width: 32px;
  border-bottom-width: 1px;
}
kendo-scheduler .k-event .k-resize-w,
.k-event .k-resize-w {
  width: 0.5em;
  left: 0;
}
kendo-scheduler .k-event .k-resize-e,
.k-event .k-resize-e {
  width: 0.5em;
  right: 0;
}
kendo-scheduler .k-event .k-resize-w::before,
kendo-scheduler .k-event .k-resize-e::before,
.k-event .k-resize-w::before,
.k-event .k-resize-e::before {
  height: 32px;
  border-left-width: 1px;
}
kendo-scheduler .k-event:hover .k-event-actions .k-event-delete,
kendo-scheduler .k-event:hover .k-resize-handle, kendo-scheduler .k-event.k-hover .k-event-actions .k-event-delete,
kendo-scheduler .k-event.k-hover .k-resize-handle,
.k-event:hover .k-event-actions .k-event-delete,
.k-event:hover .k-resize-handle,
.k-event.k-hover .k-event-actions .k-event-delete,
.k-event.k-hover .k-resize-handle {
  visibility: visible;
}
kendo-scheduler .k-event.k-event-drag-hint .k-event-time,
.k-event.k-event-drag-hint .k-event-time {
  display: block;
}
kendo-scheduler .k-event .k-event-top-actions,
kendo-scheduler .k-event .k-event-bottom-actions,
.k-event .k-event-top-actions,
.k-event .k-event-bottom-actions {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
kendo-scheduler .k-event .k-event-bottom-actions,
.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}

.k-scheduler-mark {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}

.k-more-events {
  padding: 0;
  height: 13px;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  position: absolute;
  justify-content: center;
}
.k-more-events > .k-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-event-drag-hint {
  opacity: 0.5;
  z-index: 3;
}
.k-event-drag-hint .k-event-actions,
.k-event-drag-hint .k-event-top-actions,
.k-event-drag-hint .k-event-bottom-actions,
.k-event-drag-hint .k-resize-handle {
  display: none;
}
.k-event-drag-hint .k-event-time {
  display: block;
}

.k-scheduler-marquee {
  border-width: 0;
  border-style: solid;
}
.k-scheduler-marquee .k-label-top,
.k-scheduler-marquee .k-label-bottom {
  font-size: 0.75em;
  position: absolute;
}
.k-scheduler-marquee .k-label-top {
  left: 4px;
  top: 2px;
}
.k-scheduler-marquee .k-label-bottom {
  right: 4px;
  bottom: 2px;
}
.k-scheduler-marquee.k-first::before, .k-scheduler-marquee.k-last::after {
  content: "";
  border-width: 3px;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
}
.k-scheduler-marquee.k-first::before {
  top: 0;
  left: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.k-scheduler-marquee.k-last::after {
  bottom: 0;
  right: 0;
  border-left-color: transparent;
  border-top-color: transparent;
}

.k-pdf-export-shadow .k-scheduler,
.k-scheduler-pdf-export .k-scheduler-content,
.k-scheduler-pdf-export .k-scheduler-times {
  height: auto !important;
  overflow: visible !important;
}

.k-scheduler-pdf-export {
  overflow: hidden;
}
.k-scheduler-pdf-export .k-scheduler-header {
  padding: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header-wrap {
  border-width: 0 !important;
}
.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table,
.k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table {
  width: 100% !important;
}

.k-recurrence-editor {
  display: flex;
  flex-direction: column;
}

kendo-scheduler .k-recurrence-editor {
  display: block;
}

.k-scheduler-monthview .k-scheduler-table {
  height: 100%;
}
.k-scheduler-monthview .k-scheduler-table td {
  height: 80px;
  text-align: end;
}
.k-scheduler-monthview .k-scheduler-body .k-scheduler-cell {
  min-height: 80px;
}
.k-scheduler-monthview .k-hidden {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-right-width: 0 !important;
}
.k-scheduler-monthview .k-scheduler-table-auto,
.k-scheduler-monthview .k-scheduler-table-auto td,
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto {
  height: auto;
}
.k-scheduler-monthview .k-scheduler-content {
  overflow-y: scroll;
}
.k-scheduler-monthview.k-scheduler-flex-layout .k-scheduler-content {
  overflow-y: auto;
}

.k-scheduler-agendaview .k-scheduler-mark {
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
}
.k-scheduler-agendaview .k-scheduler-table th:first-child,
.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}
.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}
.k-scheduler-agendaview .k-task > .k-event-delete {
  color: inherit;
  position: absolute;
  top: 2px;
  right: 2px;
  opacity: 0.5;
  visibility: hidden;
}
.k-scheduler-agendaview .k-hover .k-task > .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row:hover .k-event-delete,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row.k-hover .k-event-delete {
  visibility: visible;
}

.k-scheduler-agendaday {
  margin: 0 0.2em 0 0;
  font-size: 3em;
  line-height: 1;
  font-weight: 400;
  float: left;
}

.k-scheduler-agendaweek {
  display: block;
  margin: 0.4em 0 0;
  line-height: 1;
  font-style: normal;
}

.k-scheduler-agendadate {
  font-size: 0.75em;
}

.k-scheduler-timelineview .k-slot-cell {
  overflow: hidden;
}
.k-scheduler-timelineview .k-scheduler-content {
  overflow: auto;
}

.k-scheduler-pane {
  display: flex;
  flex-direction: row;
}

.k-scheduler-pane .k-scheduler-times {
  flex: 0 0 auto;
}

.k-scheduler-pane .k-scheduler-times .k-scheduler-table {
  height: 100%;
}

.k-scheduler-pane .k-scheduler-header,
.k-scheduler-pane .k-scheduler-content {
  flex: 1 1 auto;
}

.k-scheduler-yearview .k-scheduler-body {
  padding-block: 12px;
  padding-inline: 12px;
  justify-content: center;
}
.k-scheduler-yearview .k-calendar {
  width: 100%;
  border-width: 0;
}
.k-scheduler-yearview .k-calendar .k-calendar-view {
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}
.k-scheduler-yearview .k-calendar .k-content {
  flex: 0;
}
.k-scheduler-yearview .k-calendar .k-link {
  position: relative;
}
.k-scheduler-yearview .k-calendar td.k-selected {
  background-color: inherit;
}
.k-scheduler-yearview .k-day-indicator {
  margin-top: calc(32px - 0.25em * 2);
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 1.5px);
}

.k-scheduler-tooltip {
  padding-block: 8px;
  padding-inline: 8px;
  border-width: 0;
  color: inherit;
}
.k-scheduler-tooltip .k-tooltip-title {
  margin-bottom: 12px;
}
.k-scheduler-tooltip .k-tooltip-title .k-month {
  font-size: 12px;
  text-transform: uppercase;
}
.k-scheduler-tooltip .k-tooltip-title .k-day {
  font-size: 24px;
}
.k-scheduler-tooltip .k-tooltip-events-container {
  overflow: auto;
}
.k-scheduler-tooltip .k-tooltip-events {
  max-height: 250px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.k-scheduler-tooltip .k-tooltip-event {
  padding-block: 4px;
  padding-inline: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  gap: 4px;
}
.k-scheduler-tooltip .k-tooltip-event .k-event-time {
  display: flex;
  flex-shrink: 0;
  font-size: inherit;
}
.k-scheduler-tooltip .k-no-data, .k-scheduler-tooltip .k-nodata {
  height: auto;
  min-height: auto;
  color: inherit;
}

.k-scheduler-edit-dialog .k-dialog {
  max-height: 100vh;
}

.k-scheduler-edit-form .k-edit-form-container {
  width: 100%;
}
.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}
.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}
.k-scheduler-edit-form .k-edit-field > ul > li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.k-scheduler-edit-form .k-recurrence-editor .k-radio-list .k-radio-wrap,
.k-scheduler-edit-form .k-recurrence-editor .k-checkbox-list .k-checkbox-wrap {
  align-self: center;
}
.k-scheduler-edit-form .k-recur-interval,
.k-scheduler-edit-form .k-recur-count,
.k-scheduler-edit-form .k-recur-monthday {
  width: 5em;
}
.k-scheduler-edit-form .k-recur-until,
.k-scheduler-edit-form .k-recur-month,
.k-scheduler-edit-form .k-recur-weekday,
.k-scheduler-edit-form .k-recur-weekday-offset {
  width: 10em;
}
.k-scheduler-edit-form .k-scheduler-datetime-picker {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
}

.k-rtl .k-scheduler-header th,
.k-rtl .k-scheduler-table td {
  border-width: 0 1px 1px 0;
}
.k-rtl .k-scheduler-table td:first-child,
.k-rtl .k-scheduler-table th:first-child {
  border-right-width: 0;
}
.k-rtl .k-scheduler-times th {
  border-width: 0 0 1px 1px;
}
.k-rtl .k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 0;
  border-left-width: 1px;
}
.k-rtl .k-event {
  padding-right: 0;
  padding-left: 8px;
}
.k-rtl .k-event .k-resize-w {
  left: auto;
  right: 0;
}
.k-rtl .k-event .k-resize-e {
  right: auto;
  left: 0;
}
.k-rtl .k-event .k-event-actions {
  right: auto;
  left: 2px;
}
.k-rtl .k-event .k-event-actions:first-child {
  margin: 2px 4px 0 0.4ex;
  float: right;
}
.k-rtl .k-scheduler-agendaview .k-task > .k-event-delete {
  left: 2px;
  right: auto;
}
.k-rtl .k-scheduler-agendaview .k-task .k-scheduler-mark {
  margin-left: 0.5em;
  margin-right: 0;
}
.k-rtl .k-scheduler-marquee .k-label-top {
  left: auto;
  right: 4px;
}
.k-rtl .k-scheduler-marquee .k-label-bottom {
  left: 4px;
  right: auto;
}
.k-rtl .k-scheduler-edit-form .k-scheduler-delete {
  float: right;
}
.k-rtl .k-scheduler .k-scheduler-header-wrap, .k-rtl.k-scheduler .k-scheduler-header-wrap {
  border-right-width: 0;
}
.k-rtl .k-scheduler .k-scheduler-agendaday, .k-rtl.k-scheduler .k-scheduler-agendaday {
  float: right;
  margin: 0 0 0 0.2em;
}
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table td:first-child,
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table th:first-child, .k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table td:first-child,
.k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table th:first-child {
  border-left-width: 0;
}
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr td:first-child,
.k-rtl .k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr th:first-child, .k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr td:first-child,
.k-rtl.k-scheduler .k-scheduler-agendaview .k-scheduler-table tr + tr th:first-child {
  border-right-width: 1px;
}

.k-safari .k-scheduler-tooltip .k-tooltip-event:not(:last-child) {
  margin-bottom: 4px;
}
.k-safari .k-scheduler-yearview .k-calendar-view .k-month {
  margin-block: 0;
  margin-inline: 6px;
}

.k-scheduler {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}

.k-scheduler-toolbar {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}

.k-scheduler-footer {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #fafafa;
}

.k-scheduler-header {
  background-color: #fafafa;
}

.k-scheduler-header-wrap {
  background-color: #ffffff;
}

.k-scheduler-nonwork,
.k-scheduler .k-nonwork-hour {
  background-color: whitesmoke;
}

.k-scheduler-other-month,
.k-scheduler .k-other-month {
  background-color: whitesmoke;
}

.k-scheduler-layout td.k-selected,
.k-scheduler-layout .k-scheduler-cell.k-selected {
  background-color: rgba(255, 99, 88, 0.25);
}

.k-scheduler-layout-flex .k-scheduler-head,
.k-scheduler-layout-flex .k-sticky-cell {
  background-color: #ffffff;
}

.k-event {
  color: white;
  background-color: #ff7c73;
}
.k-event.k-selected {
  color: white;
  background-color: #ff6358;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.13);
}
.k-event.k-event-inverse {
  color: black;
}
.k-event.k-event-ongoing {
  box-shadow: inset 0px 0px 0px 1px #ff0000;
}

.k-scheduler-marquee::before,
.k-scheduler-marquee::after {
  border-color: #ff6358;
}

.k-scheduler-edit-dialog .k-dialog-actions {
  border-color: rgba(0, 0, 0, 0.08);
}

.k-scheduler-agendaview .k-scheduler-content tr:hover,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row:hover,
.k-scheduler-agendaview .k-scheduler-content .k-scheduler-row.k-hover {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #f0f0f0;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr:hover .k-scheduler-groupcolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-hover .k-scheduler-groupcolumn {
  border-color: rgba(0, 0, 0, 0.08);
  color: #424242;
  background-color: #ffffff;
}
.k-scheduler-agendaview .k-scheduler-content tr.k-selected {
  background-color: rgba(255, 99, 88, 0.25);
}
.k-scheduler-agendaview .k-scheduler-content tr.k-selected .k-scheduler-datecolumn,
.k-scheduler-agendaview .k-scheduler-content tr.k-selected .k-scheduler-groupcolumn {
  background-color: #ffffff;
}

.k-scheduler-yearview .k-day-indicator {
  background-color: #ff6358;
}
.k-scheduler-yearview .k-selected .k-day-indicator {
  background-color: white;
}

.k-tooltip.k-scheduler-tooltip {
  color: #424242;
  background-color: white;
  box-shadow: var(--kendo-elevation-2, 0 4px 6px rgba(0, 0, 0, 0.06), 0 4px 16px rgba(0, 0, 0, 0.12));
}
.k-tooltip.k-scheduler-tooltip .k-callout {
  color: white;
}

/*timepicker tr.text-center {
    display:none;

}*/
@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.blink {
  animation: blink 1s infinite;
}

.nav-link.active .badge.badge-info {
  background-color: #005598;
}

.info-box .reset-margins .form-group {
  margin-bottom: 0px;
  margin-top: -7px;
  padding: 0px;
}

.info-box .reset-margins label.btn {
  margin: 0;
}
.info-box .reset-margins button.btn {
  margin-top: -8px;
}

body {
  font-family: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  color: #444D58 !important;
  font-weight: normal;
  font-size: 0.9375rem;
}

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("/assets/fonts/glyphicons-regular.eot");
  src: url("/assets/fonts/glyphicons-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/glyphicons-regular.woff2") format("woff2"), url("/assets/fonts/glyphicons-regular.woff") format("woff"), url("/assets/fonts/glyphicons-regular.ttf") format("truetype");
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-chevron-up:before {
  content: "\e601";
}

.glyphicon-chevron-down:before {
  content: "\e602";
}

.sidebar .nav .nav-item .nav-link {
  font-weight: normal;
}

.sidebar {
  background: #031930;
}

.sidebar .nav .nav-item {
  border-bottom: 0;
  position: relative;
  padding: 2px 5px;
}

.sidebar .nav .nav-item .nav-link.active {
  background: #031930;
  color: #5dc4f0;
  position: relative;
}

.sidebar .nav .nav-item .nav-link.active::before,
.sidebar .nav .nav-item .nav-link:hover::before,
.page-dossiers .sidebar .nav .dossiers-overview .nav-link::before {
  content: "";
  position: absolute;
  left: -4px;
  width: 2px;
  height: 100%;
  background: #5dc4f0;
  display: inline-block;
  top: 0;
  border-radius: 5px;
}

.sidebar .nav .nav-item .nav-link.active i {
  color: #5dc4f0;
}

.sidebar .nav .nav-item .nav-link.active i .ui-stroke {
  stroke: #5dc4f0;
}

.sidebar .nav .nav-item .nav-link.active i .ui-fill,
.sidebar .nav .nav-item .nav-link.active i #Combined-Shape {
  fill: #5dc4f0;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active i,
.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active,
.page-dossiers .sidebar .nav .nav-item .nav-link.active,
.page-dossiers .sidebar .nav .nav-item .nav-link.active i {
  color: #fff;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active i .ui-stroke,
.page-dossiers .sidebar .nav .nav-item .nav-link.active i .ui-stroke {
  stroke: #fff;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active::before,
.page-dossiers .sidebar .nav .nav-item .nav-link.active::before,
.page-dossiers .sidebar .nav .nav-item .nav-link:hover {
  background: transparent;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link i,
.page-dossiers .sidebar .nav .dossiers-overview .nav-link {
  color: #5dc4f0 !important;
}

.page-dossiers .sidebar .nav .nav-item .nav-link:hover::before,
.page-dossiers .sidebar .nav .nav-item .nav-link:hover {
  background: transparent !important;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link i .ui-stroke {
  stroke: #5dc4f0;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link::before {
  background: #5dc4f0 !important;
}

.k-scheduler-weekview .k-scheduler-table th:hover {
  background: #f6f9fd;
}

.k-scheduler-weekview .k-scheduler-table th .k-link {
  display: block;
  cursor: pointer;
}

.k-scheduler-calendar {
  padding: 5px;
}

.k-scheduler-calendar .k-footer {
  background: #f6f9fd;
}

body.sidebar-compact .sidebar .sidebar-nav {
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-nav .nav-item .nav-link.active:hover:after {
  border-left-color: #fff;
}

.ad-banner {
  padding: 15px 10px;
  background: #5484bd;
  border-radius: 3px;
  border: 1px solid #86aad4;
  margin-top: 25px;
}

.btn-primary {
  color: #fff;
  background-color: #005598;
  border-color: #005598;
  text-transform: uppercase;
}

.btn-primary:hover {
  color: #fff;
  background-color: #003b6a;
  border-color: #003b6a;
}

.btn-default {
  background-color: #eaeff4;
  color: #466279;
}

.form-control {
  color: #005598;
}

.btn-primary.disabled:hover,
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #b3cce0;
  background-color: #b3cce0;
}

header.navbar .navbar-brand {
  background: transparent url("/assets/img/logo-horizons-small.png") no-repeat 0 24px;
  width: 51px;
  height: 75px;
  background-size: 82%;
  display: block;
  border-bottom: 0;
  margin: 0 auto;
}

.sidebar-compact .logowrapper {
  padding-left: 2px;
  width: 70px;
}

.sidebar-compact header.navbar .navbar-brand {
  background: transparent url("/assets/img/logo-horizons-small.png") no-repeat 8px 10px;
  width: 51px;
  height: 54px;
  background-size: 70%;
}

.logout-tagline {
  font-size: 0.9rem;
  color: #000;
  position: absolute;
  right: 5px;
  top: 10px;
}

.navbar-right {
  right: 10;
  padding: 0 5px;
  display: flex;
  height: 100%;
}
.navbar-right .brand-tagline {
  align-self: flex-end;
  font-size: 0.9rem;
  color: #005598;
  margin-right: 5px;
}
.navbar-right .user-profile-dropdown {
  align-self: flex-start;
  height: 100%;
}

.toaster {
  position: fixed;
  bottom: 40px;
  right: 0;
}

.app-footer .brand-tagline {
  color: #005598;
  float: right;
}

.parent-menu .fa-chevron-up {
  display: inline-block !important;
}

.parent-menu .fa-chevron-down {
  display: none !important;
}

.parent-menu.submenu-collapsed .fa-chevron-up {
  display: none !important;
}

.parent-menu.submenu-collapsed .fa-chevron-down {
  display: inline-block !important;
}

.app-footer {
  border-top: 0;
  background: #ffffff url("/assets/img/footer_logo_bg.png") no-repeat left top;
}

.address .fa-check {
  display: none;
}

.active .address .fa-check {
  display: inherit;
  float: right;
  position: relative;
}

.active .address {
  padding-right: 25px;
}

.table-inverse {
  color: #ffffff;
  background-color: #7b90a9;
}

.formblock-header {
  margin-top: 25px;
  margin-bottom: 20px;
  padding-top: 15px;
  border-top: 1px solid #f1f1f1;
}

.sidebar-fixed.sidebar-compact .sidebar-toggler:before {
  display: none;
}

.sidebar-fixed.sidebar-compact .sidebar-toggler:after {
  display: inline-block;
}

.sidebar-fixed .sidebar-toggler:after {
  display: none;
}

.sidebar-toggler:before {
  right: 75%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #88b7d5;
  border-width: 4px;
  margin-top: -4px;
}

.sidebar-toggler:after {
  left: 75%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #88b7d5;
  border-width: 4px;
  margin-top: -4px;
}

@keyframes login-panel {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.spinner {
  margin: auto;
  width: 70px;
  text-align: center;
  -webkit-animation-name: login-panel;
  animation-name: login-panel;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.spinner > div {
  width: 15px;
  height: 15px;
  background-color: #bfbdbd;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.spinner-wrapper {
  position: relative;
}

.spinner-wrapper > .row {
  max-width: 100%;
}

.spinner-wrapper .spinner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border: 1px solid #f1f1f1;
  height: 71px;
  padding: 10px;
  min-width: 140px;
  font-size: 0.8em;
  border-radius: 3px;
  background: #ffffff;
  color: #9a9a9a;
}

.spinner-wrapper .spinner > span {
  display: block;
  padding-top: 8px;
}

.spinner-wrapper .overlay {
  position: absolute;
  width: 100%;
  background: rgba(134, 134, 134, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 999;
}

.overlayWrapper {
  position: absolute;
  width: 100%;
  background: rgba(134, 134, 134, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  z-index: 999;
  text-align: center;
}

.overlayWrapper span {
  padding: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.9411764706);
  margin: 10px;
  border-radius: 4px;
  position: relative;
}

.filter-block,
.list-block {
  border: 1px solid transparent;
  padding: 15px 20px 0;
  background: transparent;
}

.filter-block {
  border-radius: 5px 5px 0 0;
}

.list-block {
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-bottom: 25px;
}

.panel-box {
  border: 1px solid #f6f9fd;
  padding: 15px 20px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 25px;
}

.panel-head {
  border: 1px solid #f6f9fd;
  padding: 15px 20px;
  background: #fff;
  border-radius: 5px 5px 0 0;
}

.panel-body {
  border: 1px solid #f6f9fd;
  padding: 15px 20px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-bottom: 25px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border: 1px solid #f6f9fd;
  border-width: 0 0 1px 0;
  color: #031930;
}

.card {
  border: 1px solid #f6f9fd;
}

.card-header .card-actions a,
.card-header .card-actions button {
  color: #031930;
}

.btn-primary {
  text-transform: capitalize;
}

.table-block th {
  font-size: 1rem;
  font-weight: normal;
}

table .custom-control.custom-checkbox {
  margin-bottom: 0;
}

.table-striped {
  border: 1px solid #f1f1f1;
}

.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.table .thead-light th {
  background-color: #ffffff;
  border-color: #e9ecef;
  color: #005598;
  font-weight: bold;
}

.search-block {
  padding: 15px;
  background: #fff;
  border: 1px solid #eaeff4;
  border-radius: 3px 3px 0;
}

.badge,
.badge.badge-pill {
  padding: 5px 15px;
}

a:hover .badge-light {
  background: #d2dee7;
}

.btn-status {
  padding: 5px 20px 5px 35px;
  position: relative;
  min-width: 130px;
  cursor: default;
  display: inline-block;
}

.badge.badge-light {
  border: 1px solid #e4e4e4;
  color: #031930;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-status .fa {
  position: absolute;
  right: 7px;
  font-size: 0.8rem;
  top: 9px;
  color: inherit;
}

.btn-status:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border: 0;
  border-radius: 50%;
  display: block;
  background: #d2dee7;
  left: 10px;
  top: 12px;
}

.btn-sm.btn-status:before {
  top: 9px;
}

.btn-status.status-active:before {
  border: #f78908;
  background: #f78908;
}

.btn-status.status-invoiced:before {
  border: #0ebd1a;
  background: #0ebd1a;
}

.btn-status.status-archived:before {
  border: #55697b;
  background: #55697b;
}

.btn-status.status-ready:before {
  border: #478cde;
  background: #478cde;
}

.btn-status.status-Unknown:before {
  border: #292a2c;
  background: #292a2c;
}

.btn-status.status-Compleet:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-job-RequestInvoice:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-job-PendingRequest:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-job-InvoiceCreated:before {
  border: #00ff00;
  background: #00ff00;
}

.btn-status.status-job-RequestFailed:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-requestvalidation:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-request_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-approve_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reject_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-request_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-authorize_dossier:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reject_dossier_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.status-dossier-validate_financial_validation,
.status-dossier-reject_financial_validation,
.btn-status.status-dossier-reopen_financial_validation,
.btn-status.status-dossier-request_financial_validation {
  background: #f1f1f1;
}

.btn-status.status-dossier-request_financial_validation:before {
  border: #ffee00;
  background: #ffee00;
}

.btn-status.status-dossier-reject_financial_validation:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-validate_financial_validation:before {
  border: #00ff00;
  background: #00ff00;
}

.btn-status.status-dossier-reopen_financial_validation:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-check_out_job:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-unknown:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-newdossier:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-pendingvalidation:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reopeneddossier:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-validationrejected:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-fitterinvoicevalidated:before {
  border: #ffee00;
  background: #ffee00;
}

.btn-status.status-dossier-active:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-compleet:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.filter-block .btn-primary,
.panel-head .btn-primary {
  margin-left: 5px;
}

.header-page-title ul {
  padding: 0 10px;
}

.header-page-title ul li {
  list-style: none;
  font-size: 1.2rem;
  padding: 19px 0 0;
  font-family: "Roboto", Arial, sans-serif;
  color: #031930;
  font-weight: normal;
}

.header-page-title ul li a {
  text-transform: none;
  color: #031930;
  font-weight: normal;
}

.header-page-title ul li span {
  font-size: 1rem;
}

.header-page-title .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.2rem;
  padding-left: 0.6rem;
  margin-bottom: 10px;
  content: "-";
}

.app-body .main {
  padding-top: 30px;
}

.app-body .main .breadcrumb {
  display: none;
}

.table-striped.table-hover tbody tr.active:hover {
  color: #fff !important;
}

.table-striped tbody tr.active,
.table-hover tr.active {
  background: #031930 !important;
  color: #fff;
}

.table-striped tbody tr.active span {
  color: #fff;
}

.table-hover tr:hover {
  background-color: #edf6fd !important;
}

.invoice-details-wrapper {
  margin: 15px 0;
}

.control-group button,
.control-group .btn {
  margin-left: 5px;
}

.qty-input {
  width: 60px;
  display: inline-block;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.collapse-wrapper {
  padding: 15px;
  margin-bottom: 5px;
}

.drag-section {
  border: 1px dashed rgba(199, 197, 197, 0.933);
  margin: 15px 0;
  border-radius: 5px;
  min-height: 80px;
  background: #f2f2f3;
}

.uploadfile-list {
  border-bottom: 1px solid rgba(238, 238, 238, 0.9333333333);
  padding: 10px 15px;
  background: #f1f1f1;
  border-radius: 2px;
}

.form-inline .form-group > label {
  margin-right: 5px;
}

.grandtotal-box {
  padding: 15px 10px 15px 0;
  background: #eaeff4;
  margin: 0 0 15px 0;
  border-radius: 5px;
  width: 100%;
}

.accordion-wrapper .card-header {
  background-color: #ecf3f9;
  border: 1px solid #c9d0d7;
  border-radius: 5px;
}

.accordion-wrapper .panel-open .card-header {
  border-radius: 5px 5px 0 0;
}

.grandtotal-block .control-group {
  margin: 10px;
}

.sub-head span {
  font-size: 1.1rem;
}

.grandtotal-block {
  margin-bottom: 20px;
}

.accordion-wrapper .card {
  border: 1px solid #f6f9fd;
  background: #fff;
}

.accordion-wrapper .panel-title h3 {
  font-size: 1rem;
  display: inline-block;
}

.accordion-wrapper .panel-collapse.show .panel-body {
  background: #fff;
  border-top: 1px solid #f6f9fd;
}

.accordion-wrapper .panel-body .section-header {
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  color: #031930;
}

.accordion-wrapper .panel-body .section-body {
  margin-bottom: 20px;
}

.tab-pane .card-block > .card-header {
  margin-bottom: 25px;
  padding-left: 0;
  font-size: 1.1rem;
  padding-top: 0;
}

.dossier-tab .nav-tabs .nav-item {
  margin-right: 10px;
  margin-bottom: 15px;
}

.dossier-tab .nav-tabs .nav-link.active {
  background: #005598;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

.dossier-tab .nav-tabs .nav-link.active:after {
  content: "";
  display: inline-block;
  background: transparent url("/assets/img/tab-arrow.png") no-repeat 0 0px;
  width: 14px;
  height: 13px;
  position: absolute;
  bottom: -18px;
  margin: 0 auto;
  left: 40%;
}

.dossier-tab .nav-tabs {
  border-bottom: 0;
}

.dossier-tab .tab-content {
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 1px 1px #e3e8ec;
}

.header-wrapper h4,
.panel-head h4.sub-head {
  font-size: 1.1rem;
  color: #031930;
  padding-top: 5px;
}

.header-wrapper h4 span {
  font-size: 0.9rem;
}

.btn-secondary {
  background: #f6f8fa;
  border: 1px solid #a7bdd5;
}

.nav-tabs .nav-link, .nav-tabs .navbar .dropdown-toggle, .navbar .nav-tabs .dropdown-toggle {
  color: #94A0B2;
}

div.instruction-checkin-modal > * > ul.nav {
  flex-wrap: nowrap !important;
}

a {
  white-space: nowrap;
}

.btn-toggle-arrow {
  background: #7490af;
  border: 0;
  margin-left: 20px !important;
}

.btn:focus {
  outline: none !important;
  border: none;
}

.dossier-tab .nav-tabs .nav-link {
  background: #e9edf2;
  border-radius: 5px;
  position: relative;
  color: #005598;
  text-align: center;
  min-width: 90px;
  font-size: 0.82rem;
}

.dossier-tab .nav-link i {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.btn-toggle-arrow .fa-chevron-up {
  display: none;
}

.panel-open .btn-toggle-arrow .fa-chevron-down {
  display: none;
}

.panel-open .btn-toggle-arrow .fa-chevron-up {
  display: inline-block;
}

.accordion-wrapper .card {
  margin-bottom: 5px;
  border: 0;
}

.invoic-prd-label {
  font-size: 1rem;
}

.accordion-wrapper .panel-head {
  border-bottom: 0;
}

.accordion-wrapper .panel-body .panel-head {
  border-bottom: 1px solid #f6f9fd;
  padding: 10px 20px 0;
}

.accordion-wrapper .subtotal {
  font-size: 1rem;
  color: #005598;
}

.accordion-wrapper .panel-body {
  border: 1px solid #c9d0d7;
  padding: 15px 20px;
  background: #ecf3f9;
  border-top: 0;
  margin-top: -1px;
}

.btn-info.btn-add {
  background: #5DC4F0;
  color: #fff;
  font-size: 0.85rem;
  padding: 5px 10px;
  min-width: 145px;
}

td .form-group,
.card-header-inner .form-group {
  margin-bottom: 0.3rem;
}

.child-row-header {
  background: #e8f5fb !important;
  font-size: 0.9rem;
}

.child-row-header td {
  font-size: 1rem !important;
}

table tr.child-row-header td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.accordion-wrapper table tr td,
.accordion-wrapper table tr td span {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.83rem;
}

.child-row-body td {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.85rem;
}

.accordion-wrapper .panel-body .panel-body {
  background: #ffffff;
  border: 1px solid #f6f9fd;
  border-top: 0;
}

.subtotal td {
  background-color: #eaeff4;
  color: #005598;
  font-size: 1.1rem !important;
  font-weight: bold;
  padding: 0.65rem !important;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body {
  background: #ecf3f9;
  border-top: 0;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body .panel-body {
  background: #fff;
  border-top: 0;
}

.table-striped.table-with-filter tbody tr:nth-of-type(odd),
.table-striped.table-with-filter tbody tr:nth-of-type(even) {
  background-color: transparent;
  border-bottom: 1px solid #efeeee;
}

.k-scheduler-views li.k-state-selected {
  background-color: #031930;
}

.k-scheduler-layout .k-scheduler-table tr > td:hover {
  background: #f0f4f8;
}

.k-scheduler-layout .k-scheduler-table tr > td.unavailableHours:hover {
  background: #e8e8e8;
  cursor: no-drop;
}

.availability-modal .modal-header {
  background: #d2dee7;
  padding: 10px 15px;
}

.availability-modal .modal-footer {
  padding: 10px 15px;
}

.availability-modal .modal-title {
  font-size: 1rem;
  color: #031930;
}

.sidebar-compact .sidebar .sidebar-nav,
.sidebar .nav {
  width: auto;
}

.accordion-wrapper .pannel-wrapper {
  margin-bottom: 15px;
}

.accordion-wrapper .pannel-body {
  margin-bottom: 3px;
}

main.main {
  transition: none;
}

.agenda-info {
  float: right;
  padding: 15px 15px 10px;
  text-align: right;
}

.agenda-info > span {
  margin-right: 15px;
  font-size: 0.85rem;
  top: -3px;
  position: relative;
}

.agenda-info > span.repair-info:before {
  content: " ";
  display: inline-block;
  height: 12px;
  width: 16px;
  background: #5DC4F0;
  margin-right: 5px;
}

.agenda-info > span.replace-info:before {
  content: " ";
  display: inline-block;
  height: 12px;
  width: 16px;
  background: #55697b;
  margin-right: 5px;
}

.jobtype {
  min-width: 150px;
  display: inline-block;
}

.card-wrapper {
  background: #fbfbfb;
  border: 1px solid #f6f9fd;
}

.card-wrapper .card-body {
  padding: 5px 15px 15px;
}

.card-wrapper .card-header {
  background: transparent;
  padding: 10px 15px 0;
  border-bottom: 0;
}

.card-wrapper .card-header h3 {
  font-size: 1rem;
  float: left;
}

.btn-details-link {
  display: block;
  background: #f0f4f8;
  border: 1px solid #d2dee7;
  padding: 8px 40px 8px 10px;
  position: relative;
  color: #031930;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 0;
}

.job-details-row {
  display: block;
  background: #f0f4f8;
  border: 1px solid #d2dee7;
  padding: 8px 40px 8px 10px;
  position: relative;
  color: #031930;
  text-decoration: none;
  margin-bottom: 0;
}

.status-details {
  font-size: 0.85rem;
  color: #55697b;
}

.status-details .status-text {
  font-weight: bold;
}

.status-details .status-text:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #5DC4F0;
}

.btn-details-link:hover {
  background: #d2dee7;
  text-decoration: none;
  color: #031930;
}

.info-box {
  padding: 8px 15px;
  font-size: 0.9rem;
  border: 1px solid #e9edf2;
  background: #fff;
  color: #55697b;
}

.date-info {
  font-weight: bold;
  margin-left: 15px;
}

.btn-details-link .link-arrow-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.3rem;
}

.card-body {
  padding: 15px;
}

.progressbar-wrapper {
  margin: 5px 0 10px;
}

.urgent-text {
  color: #d10007;
  padding: 2px 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Dossire Damage */
.section-body {
  border: 1px solid #f5f7fb;
  padding: 15px 15px 5px;
  border-radius: 0 0 5px 5px;
}

.section-header {
  padding: 10px;
  background: #f6f8fa;
  margin-top: 35px;
  color: #031930;
  margin-bottom: 0;
}

.douments-wrapper {
  max-height: 240px;
  overflow-y: auto;
}

.discount-input,
.price-input {
  min-width: 62px;
  padding: 5px 5px;
}

.discount-input {
  text-align: right;
}

.input-group-addon {
  padding-left: 6px;
  padding-right: 6px;
  min-width: 30px;
}

.input-group {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.input-group > .input-group-addon {
  padding-top: 12px !important;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.25;
  color: #94A0B2;
  text-align: center;
  background-color: #E3E8EC;
  border-radius: 0.25rem;
}

.btn-request-auth {
  margin-bottom: 10px;
}

.col-lg-12 > .card > .card-header {
  padding: 10px 20px;
}

.card-header .card-actions {
  position: relative;
  top: 0;
  right: 0;
  float: right;
}

.card-header .card-actions a.btn-setting, .btn-setting {
  color: #fff;
  background-color: #005598;
  border-color: #065695;
  text-transform: uppercase;
  border-radius: 24px;
  height: 38px;
  line-height: 12px;
  margin-left: 5px !important;
  padding-top: 11px !important;
}

a.btn-setting:hover {
  background-color: #031930;
}

.img-responsive {
  max-width: 100%;
}

.btn-img-link {
  display: block;
  height: 130px;
  text-align: center;
}

.btn-img-link img {
  max-width: 100%;
  max-height: 100%;
}

.document-image-wrapper {
  display: block;
  background: #f0f4f8;
  border: 1px solid #d2dee7;
  color: #031930;
  margin-bottom: 20px;
}

.document-image-wrapper p {
  padding: 5px 10px;
  border-bottom: 1px solid #d2dee7;
  margin-bottom: 0;
}

.spinner-wrapper {
  position: relative;
}

body .ui-rating.ui-state-disabled {
  opacity: 0.7;
}

.ui-rating .fa-star {
  color: #fff000 !important;
  text-shadow: 1px 0 0 #352101, 0px 1px 0 #352101, -1px 0 0 #352101, 0px -1px 0 #352101;
}

.image-uploader-wrapper {
  min-height: 150px;
  border: 1px solid #f1f1f1;
  background: #f9f9f9;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}

.image-uploader-wrapper .image-type-label {
  display: block;
  margin-top: 10px;
}

.uploaded-image-footer {
  margin-top: 5px;
}

.uploaded-image-footer .btn {
  margin-right: 5px;
}

.glyphicon-triangle-top {
  background: transparent url("/assets/img/sort_asc.png") no-repeat -5px -2px;
  width: 8px;
  height: 10px;
}

.glyphicon-triangle-bottom {
  background: transparent url("/assets/img/sort_desc.png") no-repeat -5px -10px;
  width: 8px;
  height: 10px;
}

.uploaded-image-header {
  padding-bottom: 10px;
}

.uploaded-image-footer {
  padding-top: 5px;
}

.single-image-display .uploaded-image-header {
  text-align: left;
}

.job-material-item {
  background-color: #f6f8fa;
  padding: 10px;
  margin-bottom: 20px;
}

.job-material-item .glass-position-title,
.job-material-item .job-title,
.job-material-item .components-list-title {
  margin-top: 15px;
}

.uploaded-image-box > a,
.document-image-wrapper .btn-img-link {
  position: relative;
  display: block;
}

.uploaded-image-box-pdf {
  font-size: 4vw;
}

.row-no-padding {
  padding: inherit;
  padding-top: 0px;
  padding-bottom: 0px;
}
.row-no-padding [class*=col-] {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.zoom-icon {
  position: absolute;
  padding: 10px 15px;
  background: rgba(33, 33, 33, 0.4);
  border-radius: 3px;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
}

.zoom-icon .fa {
  color: #fff;
  font-size: 1.2rem;
  margin-top: 25%;
}

.animate {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.uploaded-image-box:hover .zoom-icon,
.btn-img-link:hover .zoom-icon {
  opacity: 1;
}

.zoom-icon {
  opacity: 0;
}

.dossier-created-date {
  white-space: nowrap;
}

.subsection-heading h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
  padding-top: 15px;
}

.check-in-tab .nav-link.active,
.check-out-tab .nav-link.active {
  background: #005598;
  text-align: center;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.image-uploader-wrapper.upload-btn {
  min-height: 135px;
}

.uploaded-Document-name {
  display: none;
}

/* New Dark Theme Changes */
.table-block {
  margin: 15px 0;
}

.list-block .table-block {
  margin: 0;
}

body .table-block .ui-paginator {
  background-color: transparent;
  border: 0;
}

.tab-content .tab-pane {
  padding: 0;
}

.tab-pane .card-block {
  padding: 0;
}

.tab-pane .card-block > .card-header,
.card-header.validation-header {
  margin-bottom: 0;
  padding: 15px;
  font-size: 1.1rem;
  color: #005598;
}

.card-wrapper {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.accordion-wrapper .card-header {
  background-color: #ecf3f9;
  border: 0;
  border-radius: 0;
}

.accordion-wrapper .card {
  margin-bottom: 2px;
  border: 0;
}

.accordion-wrapper .panel-title {
  color: #005598;
}

.btn-toggle-arrow {
  background: transparent;
  color: #005598;
}

.btn-toggle-arrow {
  background: transparent;
  color: #005598;
}

.accordion-wrapper .panel-open .card-header {
  background-color: #031930;
  border: 0;
  border-radius: 0;
  color: #fff;
}

.section-body .header-wrapper,
.header-wrapper h4 {
  font-weight: bold;
  font-size: 1rem;
  color: #005598;
}

.accordion-wrapper .panel-body .section-header {
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 0;
  color: #005598;
  margin-top: 10px;
  background: transparent;
  font-weight: bold;
  margin-bottom: 0;
}

.accordion-wrapper .panel-body .section-body {
  margin-bottom: 0;
  border-top: 0;
  border-radius: 0;
}

.accordion-wrapper .panel-collapse.show .panel-body {
  background: #fff;
  border: 0;
  margin-bottom: 0;
}

.accordion-wrapper .panel-open .panel-title,
.accordion-wrapper .panel-open .btn-toggle-arrow {
  color: #fff;
}

.tab-pane .card-footer {
  border-top: 0;
}

.section-header {
  margin-top: 0;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body {
  background: transparent;
  border-top: 0;
  padding: 15px;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body .panel-body {
  padding: 15px;
}

.invoice-details-wrapper {
  margin: 0;
}

#invoice-accordion .pannel-wrapper .panel-head {
  padding: 10px 15px 0;
}

.panel-head h4.sub-head {
  color: #005598;
}

.invoice-details-wrapper .pannel-wrapper .panel-head {
  padding: 10px 15px 0;
}

.invoice-details-wrapper .pannel-wrapper .panel-head,
.invoice-details-wrapper .pannel-wrapper .panel-body {
  background: #eaeff4 !important;
}

.accordion-wrapper .panel-body .panel-head {
  border-bottom: 1px solid #f6f9fd;
  padding: 10px 0px 0;
  border: 0;
}

.k-scheduler-toolbar {
  background: #012949;
  color: #fff;
}

.card-header-inner {
  padding: 10px 15px;
}

.accordion-toggle .card-header-inner {
  padding: 2px;
}

#invoice-accordion .sub-head {
  margin-bottom: 0;
}

/*  Define the background color for all the ODD table columns  */
.k-scheduler-header-wrap .k-scheduler-table tr th.k-slot-cell:nth-child(odd):before {
  content: " ";
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #5DC4F0;
  margin-right: 5px;
}

.k-scheduler-header-wrap .k-scheduler-table tr th.k-slot-cell:nth-child(even):before {
  content: " ";
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #55697b;
  margin-right: 5px;
}

.k-view-week .k-link {
  border: 1px solid #fff;
  border-radius: 0 5px 5px 0;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .card-header {
  background-color: #ecf3f9;
  color: #031930;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .panel-title {
  color: #031930;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .btn-toggle-arrow {
  color: #031930;
}

.protocal-list-item {
  margin-bottom: 15px;
}

.new-dossier-link .fa {
  color: #1dd826 !important;
}

.subactive + li .active,
.subactive + li .active .fa {
  background: #031930 !important;
  color: #fff !important;
}

.summary-container .panel-body {
  min-height: 200px;
}

.k-scheduler-content {
  min-height: 600px;
}

.status-newdossier:before,
.status-Complete:before,
.status-complete:before,
.status-fitterinvoicevalidated:before,
.status-account_invoice_readytosend:before,
.status-financial_validated:before,
.status-data_collection_not_started:before,
.status-pre_authorization_approved:before,
.status-authorized:before,
.status-account_payment_received:before,
.status-status-closed:before,
.status-invoice_created:before,
.status-pro_forma_created:before,
.status-credit_note_created:before {
  background: #41ca74;
  border: none;
}

.status-active:before,
.status-account_invoice_created:before,
.status-reopeneddossier:before,
.status-job_checked_out:before,
.status-financial_validation_reopened:before,
.status-account_invoice_created:before,
.status-data_collection_wip:before,
.status-appointment_request:before,
.status-pre_authorization_requested:before,
.status-credit_note_created:before,
.status-fee_calculated:before,
.status-fee_invoiced:before {
  background: #5dc4f0;
  border: none;
}

.status-pendingRequest:before,
.status-requestcustomerinvoice:before,
.status-requestcentraldepartmentinvoice:before,
.status-pendingvalidation:before,
.status-pending:before,
.status-financial_validation_pending:before,
.status-account_invoice_scheduled:before,
.status-account_invoice_sent:before,
.status-account_invoice_pending:before,
.status-authorization_pending:before,
.status-authorization_read:before,
.status-credit_note_pending:before,
.status-account_invoice_pending_confirmation:before,
.status-order_pending:before,
.status-invoice_pending:before,
.status-pro_forma_pending:before,
.status-on_hold:before,
.status-credit_note_requested:before {
  background: #eea633;
  border: none;
}

.status-requestfailed:before,
.status-validationrejected:before,
.status-financial_validation_rejected:before,
.status-account_invoice_rejected:before,
.status-pre_authorization_rejected:before,
.status-authorization_rejected:before,
.status-account_invoice_blocked:before,
.status-cancelled:before,
.status-credit_note_rejected:before {
  background: #e75b5e;
}

.appointment-time {
  font-weight: bold;
}

.file-list-container {
  padding: 5px;
  border-bottom: 1px solid #f1f1f1;
}

.file-list-container:last-child {
  border-bottom: 0;
}

.photo-filename {
  display: none;
}

.sidebar-compact .sidebar-nav .nav-link.active:after {
  display: none;
}

.sub-menu .nav-link {
  padding-left: 30px !important;
}

.card-tab-padding .tab-content {
  padding: 15px 10px;
}

.sidebar-compact .sub-menu .nav-link {
  padding-left: 0 !important;
}

.sidebar-compact .parent-menu .nav-link {
  position: relative;
  padding: 0 25px 0 0;
  margin: 0;
  line-height: 20px;
  white-space: nowrap;
  border-left: 0 !important;
}

.jobtype-separator {
  height: 15px;
  clear: both;
  width: 100%;
}

.sidebar-compact .sidebar .nav-link i {
  margin: 0 0.85rem 0 0 !important;
}

.sidebar-compact .nav-item.parent-menu .nav-link i {
  width: 35px;
}

.sidebar-compact .sub-menu {
  overflow: hidden !important;
}

.brand-tagline2 {
  display: block;
  line-height: 0;
  font-size: 0.72rem;
  text-align: right;
}

input.text-uppercase {
  text-transform: uppercase;
}

.username {
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  display: inline-block !important;
}

.gm-style .search-box {
  background: #fff;
  margin-top: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #d8dde4;
  border-radius: 5px 5px 0 0;
}

.main > .container-fluid {
  height: 100%;
}

.dashboard-wrapper,
.iframe-wrapper {
  height: 100vh;
}

.dashboard-wrapper > h1 {
  font-size: 1.5rem;
}

.table-hover tfoot .pagination-footer:hover {
  background: transparent !important;
}

tfoot .pagination {
  align-items: center;
}

.modal-body .tab-content .tab-pane {
  padding: 1rem 1rem;
}

.card-header .btn-showall-document {
  margin-left: 20px;
  margin-bottom: 5px;
}

.silder-modal,
.silder-modal .modal-content.lightbox {
  height: 100%;
}

/*validation page*/
.stacked-buttons {
  margin-bottom: 10px;
  padding: 10px;
}

.stacked-button-wrapper {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
}

.selected-doc-title {
  margin: 20px -15px 30px;
  background: #031930;
  color: #fff;
  padding: 20px 25px;
}

.selected-doc-title h2 {
  margin-bottom: 0;
  font-size: 1.1rem;
}

.validation-body .spinner-wrapper {
  padding-left: 10px;
}

.validation-body .documents-slider {
  border: 1px solid #55697b;
}

.documetn-buttons-wrapper {
  padding: 10px;
}

/* Mignifier */
.ImageZoomFullContainer {
  display: none;
  position: absolute;
  border: 3px solid red;
  cursor: crosshair;
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 6px 5px 13px #989393;
  border-radius: 5px;
}

.ImageZoomContainer {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.ImageZoomFullContainer > img {
  position: absolute;
  max-width: none;
  max-height: none;
  top: 0;
  left: 0;
}

/* dashboard additionla notes */
.additional-notes {
  border: 1px solid #d2dee7;
  margin-left: 15px;
  margin-right: 15px;
}

.additional-notes .card-header {
  padding: 10px 10px 5px;
  background: #5DC4F0;
  color: #fff;
  border-radius: 0;
}

.additional-notes .card-body {
  padding: 10px;
}

.notes-item {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.notes-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.notes-info {
  font-size: 0.82rem;
  opacity: 0.8;
}

.textarea-lg {
  min-height: 100px;
}

.header-button-item,
.header-button-wrapper > button {
  display: inline-block;
  margin-right: 10px;
}

.header-button-wrapper {
  text-align: right;
  position: relative;
}

.no-percentage {
  color: #005598;
}

/*Wizard optimaztion changes */
.ownership-option {
  padding-right: 10px;
}

.label-Private {
  float: left;
}

.bs-datepicker-body .week {
  background: #ffe3e3;
}

.bs-datepicker-body .weeks tr td:nth-last-child(2) {
  background: #e8e7e7;
}

.bs-datepicker-body .weeks tr td:last-child {
  background: #e8e7e7;
}

.appointment-btn-wrapper {
  margin: 30px 0 15px;
}

.summary-container .summary-row > label {
  font-weight: bold;
}

/* pagination changes */
tfoot .pagination {
  justify-content: center;
}

tfoot .pagination a.page-link {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5em 0.625em;
  min-width: 2em;
  color: #0275d8;
}

tfoot .pagination .disabled a.page-link {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5em 0.625em;
  min-width: 2em;
}

tfoot .pagination a.page-link.active {
  color: #ffffff;
  background-color: #0275d8;
}

tfoot .pagination .page-item:first-child a,
tfoot .pagination .page-item:last-child a {
  font-size: 0 !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

tfoot .pagination .page-item.disabled {
  padding: 10px;
}

tfoot .pagination .page-item:first-child a:before {
  content: "\f04a";
  font-size: 1rem;
  padding: 10px;
}

tfoot .pagination .page-item:last-child a:before {
  content: "\f04e";
  font-size: 1rem;
  padding: 10px;
}

tfoot .pagination.float-sm-right .page-item:first-child a,
tfoot .pagination.float-sm-right .page-item:last-child a {
  font-size: 0.9rem !important;
  display: inline-block;
  font: inherit;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1em;
}

tfoot .pagination.float-sm-right .page-item:first-child a:before {
  display: none;
}

tfoot .pagination.float-sm-right .page-item:last-child a:before {
  display: none;
}

tfoot .pagination.float-sm-right a.page-link {
  background-color: #fff;
  border-color: #f1f1f1;
  color: #0275d8;
}

.radio-wrapper input {
  box-shadow: none !important;
}

.header-badge-light {
  background: #f0f4f8;
  border-radius: 10px;
  padding: 6px 10px;
  color: #8191a0;
}

/* float Input Label -New UI changes  */
.float-input-wrapper .validation-error,
.float-wrapper .validation-error {
  position: absolute;
  font-size: 0.8em;
  bottom: -35px;
  height: 35px;
}

.float-wrapper .validation-error {
  line-height: 1.4em;
}

.form-group,
.float-input-wrapper {
  position: relative;
}

.radio-wrapper .input-warning-border:after {
  display: none !important;
}

.float-wrapper > small {
  position: absolute;
}

.col-md-6.col-lg-5.col-xl-4 > .input-warning-border:after {
  left: 14px;
}

label.float-label {
  position: absolute;
  top: 0.65rem;
  left: 0;
  -ms-transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  cursor: text;
  color: #757575;
  font-size: 1rem;
  padding-left: 15px;
  pointer-events: none;
}

input.active + label.float-label,
select.active + label.float-label,
textarea.active + label.float-label,
.custom-float-input.active + label.float-label,
label.custom-float-label {
  -webkit-transform: translateY(-140%);
  -ms-transform: translateY(-140%);
  transform: translateY(-140%);
  padding-left: 14px;
  font-size: 0.8rem;
  padding-left: 18px;
  top: 1.8rem;
}

textarea.active + label.float-label {
  -webkit-transform: translateY(-140%);
  transform: translateY(-140%);
  padding-left: 12px;
  font-size: 0.8rem;
  top: 1.56rem;
  width: 84%;
  margin-left: 7px;
  right: 20px;
  background: #fff;
  margin-top: 7px;
  padding-top: 3px;
}

input[type=date].float-input,
input[type=datetime-local].float-input,
input[type=email].float-input,
input[type=number].float-input,
input[type=password].float-input,
input[type=search-md].float-input,
input[type=search].float-input,
input[type=tel].float-input,
input[type=text].float-input,
input[type=time].float-input,
input[type=url].float-input,
textarea.float-input,
.custom-float-input {
  padding-top: 21px;
  padding-bottom: 3px;
  box-shadow: none;
  border-color: #dce3e8;
}

select.float-select {
  padding-top: 21px;
  padding-bottom: 3px;
  box-shadow: none;
  border-color: #dfe7ec;
  padding-right: 25px;
  background-size: 10px 20px !important;
}

.float-select + .float-label {
  background: #fff;
  left: 7px;
  padding-bottom: 5px;
  width: 85%;
  pointer-events: none;
  padding-left: 7px;
}

.float-select:disabled + .float-label {
  background: #e3e8ec;
}

.float-select.active + .float-label {
  background: transparent;
  left: 0;
  padding-bottom: 0;
}

.block-label {
  display: block;
}

.hidden {
  display: none !important;
}

.float-wrapper label {
  margin-bottom: 0.1rem;
}

.ng-invalid.active {
  margin-bottom: 2.5rem;
}

.float-wrapper {
  margin-bottom: 1.6rem;
  display: block;
  float: none;
  padding: 0 5px;
}

.tabsectioncard-header {
  font-size: 1.25rem;
  padding-top: 10px;
  float: left;
}

.label-info {
  float: right;
  font-size: 0.8rem;
}

.validation-badge {
  width: 10px;
  height: 10px;
  background: #d83c3c;
  display: inline-block;
  margin-right: 5px;
}

.card-header.info-card-header {
  border-bottom: 0;
  margin-bottom: 15px;
  padding-left: 0;
}

.authorization-badge {
  width: 10px;
  height: 10px;
  background: #f5ac0e;
  display: inline-block;
  margin-right: 5px;
}

.subtitle {
  font-size: 1.15rem;
  color: #031930;
}

.uploaded-image-block {
  width: 245px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.sub-wrapper {
  padding-left: 27px;
  border-left: 2px dashed #e9eef3;
  margin-left: 17px;
  padding-bottom: 15px;
}

.form-completion-status {
  width: 35px;
  height: 35px;
  border: 4px solid #e9eef3;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.9rem;
  text-align: center;
  padding-top: 7px;
  position: relative;
  margin-right: 10px;
}

.form-completion-status-procedure {
  margin-right: 5px;
}

.tabsectioncard-header span {
  font-size: 0.75rem;
  display: inline-block;
  top: -3px;
  position: relative;
  margin-left: 5px;
}

.infomenu-section-wrapper {
  margin-top: 15px;
  padding-top: 90px;
  margin-top: -90px;
}

.authorization-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #f5ac0e;
  border-radius: 5px;
}

.validation-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #d83c3c;
  border-radius: 5px;
}

.sending-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #3786e6;
  border-radius: 5px;
}

.quote-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #f5ac0e;
  border-radius: 5px;
}

.ngc-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #d83c3c;
  border-radius: 5px;
}

.authorization-image,
.validation-image,
.ngc-image,
.sending-image {
  position: relative;
}

.sending-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #f5ac0e;
}

.authorization-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #f5ac0e;
}

.validation-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.ngc-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.quote-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.authorization-element.input-warning-border,
.sending-element.input-warning-border,
.validation-element.input-warning-border,
.quote-element.input-warning-border,
.ngc-element.input-warning-border {
  box-shadow: none;
}

.authorization-element.input-warning-border:after {
  background-color: #f5ac0e;
}

.validation-element.input-warning-border:after {
  background-color: #d83c3c;
}

.sending-element.input-warning-border:after {
  background-color: #3786e6;
}

.ngc-element.input-warning-border:after {
  background-color: #d83c3c;
}

.quote-element.input-warning-border:after {
  background-color: #f5ac0e;
}

label.input-warning-border.authorization-element:after,
label.input-warning-border.validation-element:after,
label.input-warning-border.ngc-element:after,
label.input-warning-border.quote-element:after {
  display: none;
}

.input-warning-border:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 75%;
  top: 6px;
  left: 9px;
  border-radius: 1px;
}

select {
  background-position: right 8px center !important;
}

select.default-select {
  color: #031930;
  border: 1px solid #ccc !important;
}

.sub-header-wrapper h4 {
  margin-bottom: 15px;
  margin-top: 20px;
  padding-left: 5px;
}

.custom-card-footer {
  padding: 15px 45px;
}

.info-menu {
  box-shadow: 0 0 1px 1px #d2dee7;
}

.info-menu ul {
  margin-bottom: 0;
}

.info-menu .menu-item {
  display: block;
  padding: 0 25px;
  border-left: 2px solid #fff;
  background: #fff;
  color: #005598;
  cursor: pointer;
}

.info-menu .item {
  padding: 20px 0;
  border-bottom: 1px solid #ecf3f9;
  display: block;
}

.info-menu .menu-item:hover,
.info-menu .active {
  background: #fafbfc;
  border-left: 2px solid #5DC4F0;
  text-decoration: none;
}

.info-menu li:last-child .item {
  border-bottom: 0;
}

.info-menu .form-completion-status {
  padding-top: 4px;
}

.info-menu-wrapper {
  position: fixed;
  min-width: 285px;
  right: 50px;
  top: 35%;
}

input[type=text],
textarea,
select {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

input[type=text]:focus,
textarea:focus,
select:focus {
  box-shadow: 0 0 5px rgba(81, 204, 238, 0.6);
  -ms-box-shadow: 0 0 5px rgba(81, 204, 238, 0.6);
}

.modal-lg.store-locator-modal {
  max-width: 80%;
}

.store-locator-iframe {
  position: relative;
  width: 100%;
  height: 750px;
  top: 0px;
  left: 0px;
  border: none;
}

.edit-dossier-iframe {
  position: relative;
  width: 100%;
  height: 750px;
  top: 0px;
  left: 0px;
  border: none;
}

.email-text {
  word-break: break-word;
}

.dossiers-overview .nav-link.active {
  pointer-events: initial;
}

.menu-group-label {
  color: #779fca;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.sidebar .nav .nav-item .nav-link:hover {
  border-radius: 5px;
  background: rgb(30, 58, 87) !important;
  background: -moz-linear-gradient(90deg, rgb(30, 58, 87) 42%, rgba(255, 255, 255, 0) 100%) !important;
  background: -webkit-linear-gradient(90deg, rgb(30, 58, 87) 42%, rgba(255, 255, 255, 0) 100%) !important;
  background: linear-gradient(90deg, rgb(30, 58, 87) 42%, rgba(255, 255, 255, 0) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e3a57", endColorstr="#ffffff", GradientType=1) !important;
}

.navseprator {
  position: relative;
}

.navseprator:before {
  content: "";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  background: rgb(3, 25, 48);
  background: -moz-linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#031930", endColorstr="#ffffff", GradientType=1);
  z-index: 99;
}

.navseprator-rev {
  position: relative;
}

.navseprator-rev:before {
  content: "";
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0;
  background: rgb(3, 25, 48);
  background: -moz-linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(180deg, rgb(3, 25, 48) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#031930", GradientType=0); /* Changez "startColorstr" et "endColorstr" */
  z-index: 99;
}

.sidebar-compact .menu-group-label,
.sidebar-compact .userdetails-wrapper {
  display: none;
}

.sidebar-compact .sidebar .nav .nav-item {
  padding: 0;
}

@media (min-width: 768px) {
  .sidebar-compact .logowrapper,
  body.sidebar-compact .sidebar {
    display: none;
  }
  .sidebar-compact.sidebar-fixed .main,
  .sidebar-compact.sidebar-fixed .app-footer {
    margin-left: 0px;
  }
}
.sidebar .nav .nav-item .nav-link:hover .ui-stroke {
  stroke: #cfe5fc;
}

.sidebar .nav .nav-item .nav-link:hover .ui-fill,
.sidebar .nav .nav-item .nav-link:hover #Combined-Shape {
  fill: #cfe5fc;
}

.sidebar .nav .nav-item .nav-link:hover i.fa {
  color: #cfe5fc !important;
}

.sidebar .nav .nav-item .nav-link {
  color: #f8f9fa;
}

.sidebar .nav .nav-item .nav-link i {
  color: #779fca;
}

.time-zone {
  font-size: 80%;
  padding: 7px 10px 0 16px;
  opacity: 0.5;
}

.userdetails-wrapper .btn:focus {
  box-shadow: none;
}

.top-spacing {
  top: -2px;
  position: relative;
}

.sidebar-fixed-nav .nav-link,
.sidebar-fixed-nav .navbar .dropdown-toggle,
.navbar .sidebar-fixed-nav .dropdown-toggle {
  font-size: 1rem;
}

.sidebar-fixed-nav {
  padding-bottom: 15px;
}

.btn-link.dropdown-toggle:hover,
.btn-link.dropdown-toggle:focus,
.btn-link.dropdown-toggle:hover span,
.btn-link.dropdown-toggle:focus span {
  text-decoration: none;
}

#invoice-accordion .sub-head {
  padding-bottom: 10px;
}

.table-wrapper {
  padding: 10px;
}

.table-list-wrapper {
  padding: 15px;
}

.notification-list-wrapper {
  background: #e3e8eb;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  color: #466279;
}

.notification-list-wrapper ul {
  margin-bottom: 0;
}

.table-panel-body {
  background: #eaeff4;
  border-radius: 5px;
}

.table-panel-head {
  padding: 10px 15px;
  border-bottom: 1px solid #f6f9fd;
  border: 0;
}

.table-panel-head h4.sub-head {
  color: #466279;
  font-size: 1.1rem;
  padding-top: 5px;
}

.customer-sub-header {
  color: #031930;
  font-size: 1rem;
  padding-top: 5px;
}

.table-wrapper .subtotal span {
  font-size: 0.85em;
}

.table-wrapper tbody {
  font-size: 0.83rem;
}

.grandtotal-block {
  padding: 15px 0 0;
}

.actions-buttons {
  padding: 15px;
}

.panel-header {
  background-color: #031930;
  border: 0;
  border-radius: 0;
  color: #fff;
  padding: 10px 20px;
}

.label-id {
  float: left;
  color: #fff;
}

.label-total {
  float: right;
  color: #fff;
}

.panel-detail-wrapper {
  padding: 20px 15px;
}

.customer-details {
  padding: 10px 0;
  color: #031930;
}

.customer-details label {
  display: inline-block;
  margin-bottom: 5px;
  color: #9aadbc;
  font-size: 0.83rem;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;
  min-height: 40px;
}

.total-amount {
  border-top: 1px solid #eee;
  padding-top: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-form-wrapper.grey-box {
  padding: 20px 10px;
  background: #eaeff4;
  border-radius: 5px;
}

.custom-form-wrapper.box {
  padding: 20px 10px;
  border-radius: 5px;
}

.msg-primary,
.msg-info {
  border: 1px solid #bae6f9;
  background: #e9f7fd;
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-success {
  border: 1px solid #91e0ae;
  background: #cdf1da;
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-warning {
  border: 1px solid #f6cf91;
  background: #fceed8;
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-danger {
  border: 1px solid #f4b4b5;
  background: #fef7f7;
  padding: 5px 8px;
  border-radius: 5px;
}

.floar-wrapper-alignment {
  padding-left: 8px;
}

.no-validation-margin .float-wrapper {
  margin-bottom: 0.8rem;
}

.custom-input-serach-dropdown {
  height: 116px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 12px;
  padding: 5px 10px;
  position: absolute;
  width: 92%;
  background: #fff;
  box-shadow: 3px 4px 7px 0px #e0dcdc;
  z-index: 99;
  border: 1px solid #efeeee;
}

.custom-input-serach-dropdown article {
  cursor: pointer;
  display: block;
  padding: 2px 4px;
}

.custom-input-serach-dropdown article:hover {
  background-color: #f7f7f7;
}

.companyname-input-loading {
  background: #fff url(/assets/img/select2-spinner.gif) no-repeat 98% 7px;
}

textarea.active:disabled + label.float-label,
textarea.active:read-only + label.float-label {
  background: transparent;
  margin-top: 7px;
  padding-top: 3px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* lic plate search */
.lic-result-item {
  background: #eaeff5;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.lic-info-box {
  border-radius: 5px;
  padding: 5px 10px;
  background: #d1dae2;
  margin-top: 5px;
}

.header-badge-dark {
  background: #d1dae2;
  border-radius: 10px;
  padding: 4px 10px;
  color: #516273;
  font-size: 0.75rem;
  display: inline-block;
  position: relative;
  margin-left: 5px;
}

.lic-checkbox-wrapper {
  padding: 10px;
}

.modal-dialog-centered {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
}
@media (max-width: 768px) {
  .modal-dialog-centered {
    min-height: calc(100vh - 20px);
  }
}

.page-margin-wrapper {
  margin: 0 -30px;
}

.page-margin-wrapper .card-header {
  font-size: 1.5em;
  padding: 0 10px 30px 30px;
  border-bottom: 0;
}

.page-block-wrapper {
  padding: 0 30px 20px;
}

.subsections-title {
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.block-grey {
  background-color: #d2dee7;
}

.block-white {
  background-color: #ffffff;
  padding: 20px 30px;
  margin-top: 10px;
}

.border-block-white {
  background-color: #ffffff;
  border: 1px solid #dbe4ed;
  border-radius: 3px;
  padding: 20px;
  margin-top: 10px;
}

.page-block-wrapper.block-grey {
  padding-top: 15px;
  padding-bottom: 15px;
}

.block-white .table-panel-body {
  background: #fff;
  border-radius: 5px;
}

.block-white .table-panel-head {
  padding: 0 0 5px;
}

.block-white .actions-buttons {
  padding: 10px 0;
}

.detail-container-gray {
  background-color: #eaeff4;
  padding: 15px 20px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.custom-panel .panel-header {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 5px;
}

.column-header,
section .section-header {
  color: #082B46;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 25px;
}

.block-grey {
  background-color: #f6f9fd;
  border: 1px solid #dbe4ed;
  border-radius: 3px;
  padding: 20px;
  margin: 0 0 30px;
}

.block-white-rounded {
  background-color: #fff;
  border: 1px solid #dbe4ed;
  border-radius: 3px;
  padding: 20px;
  margin: 0 0 30px;
}

.svg-icon {
  vertical-align: middle;
  padding-right: 5px;
}

.block-white-rounded label {
  color: #556472;
  margin-bottom: 0.25rem;
}

.input-content {
  color: #082B46;
}

.table-wrapper-white {
  border: 1px solid #dbe4ed;
  border-radius: 3px;
  background: #ffffff;
  margin-bottom: 20px;
}
.table-wrapper-white .table {
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0;
}
.table-wrapper-white .table td {
  padding-top: 9px;
  padding-bottom: 9px;
}
.table-wrapper-white .table .subtotal td {
  padding: 0.75rem !important;
}
.table-wrapper-white .table .thead-light th,
.table-wrapper-white .table .subtotal.thead-light span {
  color: #466279;
  font-size: 0.75rem;
  font-weight: bold;
}
.table-wrapper-white .grandtotal td span {
  font-size: 0.75rem;
}
.table-wrapper-white .grandtotal td {
  background-color: #466279;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.65rem !important;
}

.document-block .upload-box {
  background: #f6f9fd;
  border: 1px solid #dbe4ed;
  padding: 20px 10px 0;
  margin-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  min-height: 177px;
}
.document-block .upload-box .filetype {
  min-height: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.document-block .upload-box .btn-primary {
  background: #5dc4f0;
  border: 1px solid #5dc4f0;
  border-radius: 24px;
  min-width: 80px;
  font-size: 0.9rem;
  color: #fff;
}
.document-block .upload-box button.btn {
  margin-bottom: 0.5rem;
}
.document-block .upload-box .btn-close {
  background: #fff;
  border: 1px solid #f1f1f1;
  position: absolute;
  top: -13px;
  right: 0px;
  width: 25px;
  box-shadow: 0 5px 20px 0 rgba(33, 34, 49, 0.12);
  height: 24px;
  min-width: 24px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  font-size: 0.6rem;
  font-weight: bold;
  cursor: pointer;
}

.upload-disabled .btn {
  pointer-events: none;
  opacity: 0.2;
}

.labelCalculationProgress {
  float: right;
  font-size: 1rem;
  padding: 0px 20px;
  position: absolute;
  right: 15px;
  background: #e75b5e;
  border-radius: 0 0 5px 5px;
  color: #fff;
}

.fs-invoice-list .fs-list {
  display: block !important;
}

.fs-invoice-list .fs-cd-list {
  display: none;
}

.invoice-document-details {
  margin-bottom: 20px;
}

.invoice-document-details label {
  color: #466279;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
}

.invoice-document-details p {
  color: #466279;
  font-size: 0.75rem;
  margin-bottom: 3px;
}

.document-block-section {
  margin-bottom: 64px;
}

.no-material-wrapper {
  padding: 10px 30px;
}

h3.column-header + h3.column-header {
  display: none;
}

.agenda-info.input-info {
  position: absolute;
  top: -5px;
  right: -26px;
}

.input-info .agenda-info-wrap {
  min-width: 450px;
}

.hideTitle .column-header {
  display: none;
}

.fs-invoice-list.hideTitle {
  margin-top: -50px;
}

.card-footer .header-button-wrapper .btn-request-auth,
.btn-dossier_request_reject,
.btn-dossier_request_cancel {
  display: none;
  background-color: #eaeff4;
  color: #466279;
}

.card-footer .header-button-wrapper .btn-dossier_request_reject,
.card-footer .header-button-wrapper .btn-dossier_request_cancel {
  display: inline-block;
  background-color: #eaeff4;
  color: #466279;
  border: 0px;
}

.auth-step1-module .toast-bottom-right {
  display: none;
}
.auth-step1-module h3 {
  font-size: 1.25rem;
  margin-bottom: 20px;
}
.auth-step1-module .tab-content {
  margin-top: 0;
  border: none;
  padding-top: 15px;
}
.auth-step1-module a.nav-link {
  pointer-events: none;
  color: #d2dee7;
  border-width: 0 0 0.3rem 0;
  border-bottom: 0.3rem solid #d2dee7 !important;
  padding: 1rem 0;
}
.auth-step1-module a.nav-link.active {
  padding: 1rem 0;
  color: #005598;
  border-bottom: 0.3rem solid #005598 !important;
  cursor: pointer;
  border-width: 0 0 0.3rem 0;
}
.auth-step1-module .nav-item {
  padding: 0 5px;
}
.auth-step1-module .nav-tabs {
  border: none;
}

/*-========================================================== */
/* Media Query */
/*-========================================================== */
@media (min-width: 768px) {
  .sidebar-fixed .sidebar {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    padding-top: 60px;
  }
  .app-footer .brand-tagline {
    color: #005598;
    float: right;
    margin-top: -15px;
  }
  .logowrapper {
    border-right: 0;
    background: #031930;
    height: 74px;
  }
  .col-lg-12 > .card > .card-header {
    padding: 10px 20px;
    line-height: 33px;
  }
  .hide-filterbutton {
    position: absolute;
    margin-top: 3px;
    right: 20px;
    z-index: 1;
  }
  select.form-control {
    background-size: 15px 20px !important;
  }
  .protocal-list-item .btn {
    margin-top: 30px;
  }
}
@media (min-width: 1200px) {
  .align-to-menu {
    position: absolute;
    right: 0;
  }
  .glassdrive-form .btn.btn-sm {
    padding: 9px 25px !important;
  }
  .page-margin-wrapper .card-header {
    padding: 0 10px 30px 40px;
  }
  .page-block-wrapper {
    padding: 0 40px 20px;
  }
  .block-white {
    padding: 20px 40px;
  }
  .scroll-to-top .btn {
    padding: 8px 11px;
    position: relative;
    margin-right: -8px;
    border-radius: 0.25rem;
  }
}
.table-block .table th a.text-nowrap {
  white-space: normal !important;
  font-size: 0.85rem;
}

.table-block .table td {
  font-size: 0.9rem;
}

.filter-block .form-control {
  margin-bottom: 10px;
}

@media (max-width: 1199.98px) {
  .main .container-fluid {
    padding: 0 15px;
  }
  .page-margin-wrapper {
    margin: 0 -15px;
  }
  .float-wrapper {
    margin-bottom: 1.85rem;
  }
  .float-wrapper .validation-error {
    line-height: 1.4em;
  }
  .dossier-tab .nav-tabs .nav-link {
    font-size: 0.73rem;
    min-width: 77px;
    padding: 10px 5px;
  }
  .dossier-tab .nav-tabs .nav-item {
    margin-right: 3px;
    margin-bottom: 15px;
  }
  .validation-body .documents-slider {
    overflow: auto;
  }
  .show-scroll.scroll-to-top {
    right: 10px !important;
    z-index: 99999;
  }
  .scroll-to-top .btn {
    padding: 8px 11px;
    position: relative;
    margin-right: -8px;
  }
  .scroll-to-top {
    bottom: 160px !important;
  }
  .info-menu-wrapper {
    position: fixed;
    min-width: 220px;
    right: 38px;
  }
  .store-locator-iframe {
    height: 580px;
  }
  .modal-lg.store-locator-modal {
    max-width: 90%;
  }
  .dossier-tab .nav-tabs {
    flex-wrap: wrap;
  }
  .dossier-tab .nav-tabs .nav-link.active:after {
    display: none;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }
}
@media screen and (max-width: 1023px) {
  .navseprator:before {
    display: none;
  }
  .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }
  .k-scheduler-dayview .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }
}
@media (max-width: 991.98px) {
  .protocal-list-item .form-control {
    margin-bottom: 15px;
  }
  .protocal-list-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
  }
  .k-scheduler-header-wrap th.k-slot-cell {
    font-size: 0.75rem;
  }
  .k-slot-cell {
    font-size: 0.75rem;
  }
  .k-scheduler-table th {
    font-size: 0.8rem;
  }
  .dossier-tab .nav-tabs .nav-link {
    font-size: 0.75rem;
    min-width: 77px;
    padding: 10px 5px;
  }
  .dossier-tab .nav-tabs .nav-item {
    margin-right: 3px;
    margin-bottom: 15px;
  }
  header.navbar {
    height: 74px;
  }
  .sidebar-fixed .logowrapper {
    display: none;
  }
  .sidebar-mobile-show .logowrapper {
    display: block;
  }
  .header-page-title ul li {
    padding: 26px 0 0;
  }
  .check-in-tab .image-box {
    overflow: scroll;
  }
  .summary-container .panel-body {
    min-height: 250px;
  }
  .sidebar-mobile-show .main {
    margin-right: auto !important;
    margin-left: 240px !important;
  }
  .logowrapper {
    width: 240px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-scroll-totop {
    display: none;
  }
  .username {
    max-width: 200px;
  }
  .logowrapper {
    padding-left: 65px;
    width: 240px;
  }
  .app-body .main {
    width: 100%;
  }
  .sm-block {
    display: block;
  }
  .section.summary {
    padding: 15px;
  }
  .text-center-mobile {
    text-align: center;
  }
  .summary .sm-block {
    margin-bottom: 8px;
  }
  .logowrapper {
    background: #031930;
  }
  .damage-images-container .damage-image-item image-Windscreen {
    margin: 0;
  }
  .info-menu-wrapper {
    display: none;
  }
  .table-responsive.dossier-overview-table:after,
  .table-scoll-shadow table:after,
  .table-responsive.table-shadow:after {
    content: "";
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: -5px 5px 19px 1px #7e7c7c, 5px -1px 0px 5px #ffffff;
    position: absolute;
    width: 1px;
    background: #dedcdc;
    pointer-events: none;
  }
  .table-scoll-shadow {
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .brand-tagline {
    display: none;
  }
  .tab-pane > .card-block {
    padding: 5px;
  }
  .app-footer.logo_portugal {
    font-size: 0.7rem;
  }
  .logowrapper {
    width: 200px;
  }
  header.navbar .navbar-brand {
    background-size: 81%;
  }
  .dossier-overview-table td:nth-child(5),
  .dossier-overview-table th:nth-child(5),
  .dossier-overview-table td:nth-child(6),
  .dossier-overview-table th:nth-child(6),
  .dossier-overview-table td:nth-child(7),
  .dossier-overview-table th:nth-child(7),
  .dossier-overview-table td:nth-child(8),
  .dossier-overview-table th:nth-child(8),
  .dossier-overview-table td:nth-child(9),
  .dossier-overview-table th:nth-child(9) {
    display: none;
  }
  .file-list-container .btn-only-icon {
    padding: 5px !important;
    background: #f0f4f8 !important;
  }
  .file-list-container {
    padding-bottom: 8px;
    margin-bottom: 5px;
  }
  .file-list-container .col-sm-1,
  .file-list-container .col-sm-2 {
    display: inline-block;
    margin-left: 10px;
    float: none;
    width: auto;
  }
  .btn-dossier-detail,
  .or-text {
    display: none;
  }
}
@media (min-width: 576px) {
  .instruction-checkout-modal,
  .instruction-checkin-modal {
    max-width: 670px;
  }
  .instruction-checkout-modal .tab-content .tab-pane,
  .instruction-checkin-modal .tab-content .tab-pane {
    padding: 0.3rem 1rem;
  }
  .instruction-checkout-modal .modal-header,
  .instruction-checkin-modal .modal-header {
    padding: 5px 15px;
  }
  .instruction-checkout-modal .modal-body,
  .instruction-checkin-modal .modal-body {
    padding: 15px 15px 0;
  }
}
select.validation-Green {
  border: 2px solid green !important;
}

select.validation-Blue {
  border: 2px solid blue !important;
}

select.validation-Yellow {
  border: 2px solid yellow !important;
}

select.validation-sending-element {
  border: 2px solid blue !important;
}

.new-dossier-modal-wrapper {
  padding: 15px 30px;
}

/* Chat containers */
.chat-container {
  border: 1px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 8px 0;
}

/* Darker chat container */
.chat-darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
.chat-container::after {
  content: "";
  clear: both;
  display: table;
}

.chat-text-right {
  margin: 0;
  text-align: right;
}

.chat-text-left {
  margin: 0;
  text-align: left;
}

/* Style time text */
.chat-time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.chat-time-left {
  float: left;
  color: #999;
}

.chat-tick-send {
  color: #999;
}

.chat-tick-processed {
  color: #5ed6e4;
}

.chat-tick-error {
  color: #d05050;
}

.dossier-overview-table tr,
.tr-pointer tr {
  cursor: pointer;
}

.check-container {
  height: 300px;
  width: 100%;
  display: block;
  overflow-x: auto;
}
.check-container .form-check {
  display: initial;
}

.import-list-container {
  height: 300px;
  width: 100%;
  display: block;
  overflow-x: auto;
  font-size: 80%;
}
.import-list-container thead {
  font-weight: bold;
}

/* New UI Common Styling Changes - start  */
/*---------------------------------------------*/
.modal-sucess-message {
  text-align: center;
  padding: 25px 0;
}

.message-block {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 0;
}

.modal-type-Replace .modal-footer {
  border-top: 0;
  display: block;
}

.modal-type-Replace .modal-title {
  color: #031930;
}

.modal-type-Replace .modal-header {
  border-bottom: 0;
}

.modal-type-Replace > .modal-content {
  padding: 20px;
  overflow: hidden;
  border-left: 3px solid #F8DC93;
}

.glassdrive-form .btn {
  padding: 9px 38px;
  border-radius: 24px;
  min-width: 100px;
}

.glassdrive-form .btn.btn-md {
  padding: 9px 20px;
  border-radius: 24px;
  min-width: 100px;
  font-size: 0.95rem;
}

.glassdrive-form .btn.btn-xs {
  padding: 5px 10px;
  border-radius: 24px;
  min-width: 80px;
  font-size: 0.75rem;
}

.glassdrive-form .btn.btn-sm {
  padding: 9px 15px;
  border-radius: 24px;
  min-width: 80px;
  font-size: 0.9rem;
}

.glassdrive-form .btn-primary {
  border-color: #005598;
  background: #005598;
  color: #FFFFFF;
}

.glassdrive-form .btn-primary:hover {
  box-shadow: 0px 2px 0 #003865;
}

.glassdrive-form .btn-primary[disabled] {
  border-color: #b3cce0;
  background: #b3cce0;
}

.glassdrive-form .btn-primary-light, .btn-primary-light {
  border-color: #5dc4f0;
  background: #5dc4f0;
  color: #FFFFFF;
}

.glassdrive-form .btn-primary-light:hover, .btn-primary-light:hover {
  box-shadow: 0px 2px 0 #149ad3;
}

.glassdrive-form .btn-primary-light[disabled], .btn-primary-light[disabled] {
  border-color: #bae6f9;
  background: #bae6f9;
}

.glassdrive-form .form-control[disabled] {
  background-color: #f6f9fd !important;
}

.glassdrive-form .btn-secondary {
  border-color: #0052c8;
  background: #0052c8;
  color: #FFFFFF;
}

.glassdrive-form .btn-secondary:hover {
  box-shadow: 0px 2px 0 #003d95;
}

.glassdrive-form .btn-primary[disabled] {
  border-color: #b3cce0;
  background: #b3cce0;
  box-shadow: none;
}

.glassdrive-form .btn-default,
.glassdrive-form .btn-secondary-light,
.btn-default {
  border-color: #eaeff4;
  background: #eaeff4;
  color: #466279;
}

.glassdrive-form .btn-secondary-light:hover,
.glassdrive-form .btn-default:hover,
.btn-default:hover {
  box-shadow: 0px 2px 0 #c9d6e2;
}

.glassdrive-form .btn-secondary-light[disabled],
.glassdrive-form .btn-default[disabled],
.btn-default[disabled] {
  color: #bbc6d0;
  box-shadow: none;
}

.glassdrive-form .btn-success {
  border-color: #41ca74;
  background: #41ca74;
  color: #FFFFFF;
}

.glassdrive-form .btn-success:hover {
  box-shadow: 0px 2px 0 #2fa95c;
}

.glassdrive-form .btn-success[disabled] {
  border-color: #bef5d3;
  background: #bef5d3;
  color: #99caab;
  box-shadow: none;
}

/* Custom form for New UI */
.glassdrive-form input[type=text].float-input {
  border: 1px solid #dbe4ed;
  box-shadow: none;
}

.input-highlight .form-control {
  border: 1px solid #e75b5e !important;
}

.input-highlight-success .form-control {
  border: 1px solid #41ca74 !important;
}

.input-highlight-warning .form-control {
  border: 1px solid #eea633 !important;
}

.input-highlight-error .form-control {
  border: 1px solid #e75b5e !important;
}

.input-highlight-success .fa-check {
  display: none;
}

.glassdrive-form label.float-label {
  color: #9aadbc;
}

.label-error {
  color: #e75b5e;
  padding: 1px 10px 0;
  background: #fff0f0;
  border-radius: 0 0 3px 3px;
  font-size: 0.7rem;
}

.glassdrive-form.modal-dialog .ng-invalid,
.glassdrive-form.modal-dialog .ng-invalid.active {
  margin-bottom: 20px;
}

.glassdrive-form input[type=text].form-control[readonly],
.glassdrive-form input[type=date].form-control[readonly],
.glassdrive-form input[type=number].form-control[readonly],
.glassdrive-form input[type=email].form-control[readonly] {
  background-color: #f6f9fd;
  opacity: 1;
  cursor: not-allowed;
}

.glassdrive-table .table .thead-light th {
  background-color: #eaeff4;
  color: #466279;
  font-size: 0.9rem;
}

.glassdrive-table.table-block .table th a.text-nowrap {
  font-size: 0.85rem;
}

.glassdrive-table .ui-paginator .ui-paginator-page.ui-state-active {
  border-radius: 50% !important;
  width: 37px;
  color: #466279 !important;
  border: 1px solid #fff !important;
  background: #fff !important;
}

.glassdrive-table .ui-paginator .ui-paginator-pages .ui-paginator-page {
  border-radius: 50% !important;
  width: 37px;
  border: 1px solid #dbe4ed;
  color: #466279 !important;
}

.glassdrive-table .ui-paginator .ui-corner-all {
  border-radius: 50% !important;
  width: 37px;
  border: 1px solid #dbe4ed;
  color: #466279 !important;
}

.glassdrive-table .table-hover tfoot tr,
.glassdrive-table .table-hover tfoot tr:hover {
  background-color: #fff !important;
}

.glassdrive-search.search-block {
  padding: 0;
}

.glassdrive-search .form-control {
  margin-bottom: 0;
  border-right: 0;
}

.glassdrive-search .input-group-addon {
  background: #fff;
  cursor: pointer;
}

.glassdrive-table .table-striped {
  border: 0;
}

.verticle-group-input .float-wrapper {
  margin: 0;
}

.verticle-group-input {
  margin-bottom: 20px;
}

.uploaded-file-wrapper {
  background-color: #f6f9fd;
  border-radius: 3px;
  padding: 20px;
  min-height: 85px;
  margin-bottom: 20px;
}
.uploaded-file-wrapper::after {
  display: block;
  clear: both;
  content: "";
}

.uploaded-file-wrapper .file-size {
  display: block;
  color: #9aadbc;
  font-size: 0.8em;
}

.uploaded-file-wrapper .file-icon {
  width: 15%;
  text-align: center;
  padding-top: 5px;
}

.uploaded-file-wrapper .file-name {
  width: 70%;
}

.file-full-name {
  font-weight: bold;
  color: #005598;
  font-size: 0.9rem;
}

.uploaded-file-wrapper .delete-file-icon {
  width: 15%;
  text-align: right;
  padding-top: 5px;
}

.btn-icon,
.btn-icon-light,
.btn-icon-dark,
.btn-icon-white {
  background: transparent;
  width: 29px;
  height: 28px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.btn-text-underline {
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  color: #5dc4f0;
  text-decoration: underline;
}

.btn-dark-text-underline {
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  color: #082B46;
  text-decoration: underline;
}

.btn-dark-text-disable {
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: #082B46;
  pointer-events: none;
}

.btn-text-underline:hover {
  text-decoration: underline;
}

.btn-icon-light {
  color: #9aadbc;
}

.btn-icon:hover {
  background: #fff;
}

.btn-icon-dark:hover {
  background: #466279;
}

.btn-icon-white:hover {
  background: #fff;
}

.btn-icon-light:hover {
  background: #eaeff4;
}

.light-text {
  color: #9aadbc;
}

.tooltip-inner {
  background-color: #5dc4f0;
}

.tooltip.tooltip-top .tooltip-inner::before,
.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #5dc4f0;
}

.thumb-section li {
  height: 65px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.thumb-section li.active {
  width: 72px;
  height: 65px;
}

.thumb-section li.active:only-child .thumb-img,
.thumb-section li.active:only-child .thumb-name {
  width: 63px;
}

.thumb-section li .thumb-img {
  width: 70px;
  height: 40px;
  overflow: hidden;
  display: block;
  border-radius: 3px 3px 0 0;
  border: 1px solid transparent;
  border-bottom: 0;
}

.thumb-section li .thumb-name {
  width: 70px;
  height: 20px;
  overflow: hidden;
  display: block;
  background: #fff;
  color: #55697b;
  border-radius: 0 0 3px 3px;
  border: 1px solid transparent;
  border-top: 0;
  font-size: 0.75em;
  padding: 2px 5px 0;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.thumb-section li.active .thumb-img {
  border: 1px solid #005598;
  border-bottom: 0;
}

.thumb-section li.active .thumb-name {
  border: 1px solid #005598;
  border-top: 0;
}

.thumb-error-text {
  background: #e75b5e;
  color: #fff;
  text-align: center;
  display: block;
  height: 70px;
  padding-top: 14%;
  font-size: 0.8rem;
}

.documents-slider.webauthority-slider {
  height: auto;
}

.thumb-section li.active:after {
  bottom: -5px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 85, 152, 0);
  border-top-color: #005598;
  border-width: 5px;
  margin-left: -5px;
}

.pdftext {
  background: #d2dee7;
  color: #fff;
  text-align: center;
  display: block;
  height: 70px;
  padding-top: 14%;
  font-size: 0.8rem;
}

.webauthority-slider,
.thumb-slider-wrapper {
  padding: 0 25px;
}

.thumb-section {
  padding: 20px 25px 0;
}

.thumb-section ul {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.webauthority-wrapper,
.validation-wrapper {
  padding: 0 0 30px 0;
}

.custom-form-wrapper {
  padding: 20px 25px;
  background: #fff;
}

.webauthority-wrapper .card-header,
.validation-wrapper .card-header {
  padding: 10px 5px 5px;
  border-bottom: 0;
  font-size: 0.95rem;
}

.picture-gallery-wrapper {
  background-color: #f0f4f8;
}

.thumb-section {
  overflow: hidden;
  max-width: 500px;
  position: relative;
  margin: 0 auto;
}

.thumb-section .list-inline {
  height: 70px;
  left: 0;
  position: relative;
}

.thumb-section .list-inline-item.item {
  display: block;
  float: left;
}

.thumb-slider {
  width: 510px;
  margin: 0 auto;
  position: relative;
}

.thumb-slider .btn-icon {
  background: #fff;
  width: 29px;
  height: 28px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 35%;
}

.thumb-slider .btn-scroll-right {
  right: -35px;
}

.thumb-slider .btn-scroll-left {
  left: -35px;
}

.history-search-count {
  color: #41ca74;
  background: #e8f9ef;
  padding: 5px 15px;
  font-size: 0.85em;
  border-radius: 15px;
  margin-left: 25px;
  display: inline-block;
}

.onwerserachitem {
  background: #eaeff4;
  padding: 22px 25px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.onwerserachitem h4 {
  color: #031930;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 13px;
}

.onwerserachitem .col-6 {
  margin-bottom: 1px;
}

.onwerserachitem-button {
  margin-top: 20px;
}

.onwerserachitem .label-value {
  font-size: 0.8rem;
  text-align: left;
  font-weight: bold;
}

.onwerserachitem .label {
  font-size: 0.8rem;
  text-align: left;
}

.glassdrive-form .dot-pagination {
  margin-bottom: 5px;
}

.glassdrive-form .dot-pagination .page-item .page-link {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: #55697b;
  border-color: #55697b;
  color: #fff;
  padding: 0;
  margin-right: 8px;
  top: 17px;
}

.glassdrive-form .dot-pagination .page-item:first-child .page-link,
.glassdrive-form .dot-pagination .page-item:last-child .page-link {
  width: 37px;
  height: 37px;
  padding: 0.5rem 0.75rem;
  top: 0;
}

.glassdrive-form .dot-pagination .page-item.disabled:first-child .page-link,
.glassdrive-form .dot-pagination .page-item.disabled:last-child .page-link {
  background-color: #d2dee7;
  border-color: #d2dee7;
}

.table-borderless td,
.table-borderless th {
  border: 0;
}

.vehicle-history-wrapper {
  background: #eaeff4;
  padding: 22px 25px;
  border-radius: 3px;
}

.vehicle-history-wrapper .table th {
  color: #889aaa;
  font-size: 0.8em;
}

.box-value {
  padding: 3px 10px;
  display: inline-block;
  border-radius: 15px;
  background-color: #dbe4ed;
  color: #466279;
  font-size: 0.8em;
  font-weight: bold;
  min-width: 60px;
  text-align: center;
}

.licenseplate-value {
  font-weight: bold;
  font-style: 1.1em;
  color: #031930;
}

/* New UI Common Styling Changes - End  */
/*---------------------------------------------*/
.k-event {
  margin: 4px;
}

.k-event .k-event-template {
  padding: 4px 7px 3px;
  font-size: 0.75rem;
}

.k-event-template {
  color: #031930 !important;
  font-weight: bold;
}

.k-scheduler-toolbar {
  background: #fff;
  color: #031930;
}

.k-scheduler-table th {
  color: #031930;
}

.k-scheduler-table th.k-today {
  color: #005598;
}

.k-scheduler-table td,
.k-scheduler-table th {
  border-color: #e2e9f0;
}

.k-scheduler-table .unavailableHours {
  background-color: #f6f9fd !important;
}

.k-scheduler-table .unavailableHoursByCapacity {
  background: repeating-linear-gradient(-55deg, #fff, #fff 10px, #f2f5f7 10px, #f2f5f7 20px);
}

.agenda-info-wrap {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.2);
  padding: 8px 12px;
  display: none;
  text-align: left;
  font-size: 0.75rem;
  min-width: 200px;
}

.agenda-info {
  position: relative;
  z-index: 99999;
}

.agenda-info-icon {
  width: 25px;
  height: 25px;
  float: right;
  background: rgba(93, 196, 240, 0.15);
  text-align: center;
  padding: 3px;
  border-radius: 50%;
  color: #5DC4F0;
  line-height: 20px;
}

.appointment-header {
  margin-top: 10px;
}

.agenda-info-icon:hover + .agenda-info-wrap {
  display: inline-block;
  position: absolute;
  z-index: 9999;
  right: 9px;
  top: 50px;
}

.repair-info,
.replace-info,
.film-info,
.aquacontrol-info,
.cameracalibration-info,
.diagnose-info,
.cabinsanification-info,
.unconfirmed-info,
.other-info {
  display: block;
  margin-bottom: 5px;
  line-height: 20px;
}

.agenda-info span.repair-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #aee1f8;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.replace-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #f8dc93;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.film-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #baf1b2;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.aquacontrol-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #40E0D0;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.cameracalibration-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #FF0000;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.diagnose-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #FF0000;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.cabinsanification-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #EE82EE;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.unconfirmed-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #555555;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.other-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #ced0fa;
  margin-right: 5px;
  border-radius: 50%;
}

.availability-edit-modal .modal-footer {
  padding: 0 15px;
}

.availability-edit-modal .modal-body {
  padding: 15px 15px 0;
}

.availability-edit-modal .form-group.row {
  margin-bottom: 2px;
}

.availability-edit-modal .float-wrapper {
  padding: 0;
}

.k-scheduler-dayview .k-event-template.k-event-time {
  display: block;
  color: #838e96 !important;
}

.k-scheduler-times .k-scheduler-table th {
  color: #838e96 !important;
  font-size: 0.75rem;
  padding: 10px 10px 9px;
}

.date-icon {
  position: relative;
}

.date-icon .icon-wrapper {
  position: absolute;
  top: 13px;
  right: 8px;
  pointer-events: none;
  cursor: default;
}

.float-wrapper select option:disabled {
  color: #a2a2a2;
}

.float-wrapper select option {
  color: #031930;
}

.card-block .table-hover tr.active:hover {
  color: #031930;
}

.ngxdropdown-wrapper .ngx-dropdown-button {
  padding-top: 21px !important;
  padding-bottom: 3px !important;
  box-shadow: none;
  border-color: #dce3e8;
}

.ngx-dropdown-button[disabled] {
  background-color: #E3E8EC !important;
  cursor: not-allowed;
}

.ngxdropdown-wrapper.active + label.float-label {
  transform: translateY(-140%);
  padding-left: 14px;
  font-size: 0.8rem;
  padding-left: 18px;
  top: 1.8rem;
}

.ngxdropdown-wrapper .ngx-dropdown-container .ngx-dropdown-button span {
  color: #005598;
}

.ngxdropdown-wrapper.float-select.active .ngx-dropdown-button {
  padding-top: 19px !important;
  padding-bottom: 2px !important;
  border: 1px solid #e3e8ec;
}

.float-select .nsdicon-angle-down {
  top: -5px;
}

.ngxdropdown-box {
  margin-top: 10px;
  position: relative;
}

.ngxdropdown-box.nolabel {
  margin-top: 0;
}

.ngxdropdown-box .float-wrapper {
  margin-bottom: 0;
}

.ngxdropdown-box.nolabel .float-wrapper {
  padding: 0;
}

.form-group .form-check-label {
  padding-top: 4px;
}

.dropdown-icon {
  position: absolute;
  top: 12px;
  right: 21px;
  display: none;
}

.form-group > .col-lg-2.form-control-label {
  padding-top: calc(0.5rem - 2px);
  padding-bottom: calc(0.5rem - 2px);
  margin-bottom: 0;
}

.ngxdropdown-box.nolabel .ngx-dropdown-button {
  padding: 7px 10px 7px 10px !important;
}

/*instruction page */
.brand-logo .upload-image-box {
  border: 2px solid #0052c8;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(33, 34, 49, 0.12);
  padding: 12px;
  max-height: initial !important;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo .upload-image-box .image-wrapper {
  display: block;
  text-align: center;
}

.brand-logo .upload-image-box .image-wrapper img {
  display: block;
  width: auto;
  max-height: 100px;
  min-width: auto;
  min-height: auto;
  margin: 0 auto;
}

.brand-logo button {
  display: none;
}

.brand-logo img {
  display: block;
  max-width: 100%;
  border-radius: 4px;
}

.upload-image-box .image-wrapper img {
  display: block;
  width: 100%;
  min-height: 200px;
  max-width: 100%;
  min-width: 250px;
}

.glassdrive-form .btn-white {
  border-color: #fff;
  background: #fff;
  color: #5DC4F0;
}

.glassdrive-form .btn-white:hover {
  box-shadow: 0px 2px 0 #bebebe;
}

.glassdrive-form .btn-white[disabled] {
  border-color: white;
  background: white;
}

.modal-type-Replace .btn.popup-close {
  padding: 8px 12px;
  border-radius: 24px;
  min-width: 30px;
  font-size: 0.9rem;
}

.ng2-pdf-viewer-container {
  overflow-x: auto;
  border: 1px solid #d4cdcd;
}

.k-scheduler-header-wrap {
  border-width: 0;
}

.availability-edit-modal input.active + label.float-label,
.availability-edit-modal .float-select.active + label.float-label {
  padding-left: 14px;
}

.ngxdropdown-box .search-container input {
  border: 0 !important;
  padding-left: 10px !important;
}

.ngxdropdown-box .search-container label {
  padding-left: 10px;
  width: 100%;
}

.ngxdropdown-box .search-container {
  border: 1px solid #d6d3d3;
  margin-top: 10px;
  border-radius: 5px;
}

.ngx-dropdown-list-container .selected-items .nsdicon-close {
  display: none;
}

.ngx-dropdown-list-container .selected-items li {
  background: #eaeff4 !important;
  color: #466279 !important;
  padding: 7px !important;
}

.ngx-dropdown-list-container .available-items li {
  color: #466279 !important;
  padding: 3px !important;
}

.ngx-dropdown-list-container .available-items li:hover {
  background: #eaeff4 !important;
}

.ngx-dropdown-list-container .available-items div {
  color: #466279 !important;
  padding: 3px !important;
}

.ngx-dropdown-list-container {
  top: 42px !important;
}

.ngx-dropdown-list-container .available-items div:hover {
  background: #eaeff4 !important;
}

.ngx-dropdown-list-container .nsdicon-search {
  position: absolute;
  right: 10px;
}

.ngxdropdown-box .search-container input:focus + label .nsdicon-search {
  display: none;
}

.ngx-dropdown-container .ngx-dropdown-button span {
  color: #031930 !important;
}

.form-control, .daterangepicker .input-mini, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
  color: #031930 !important;
}

.modal-backdrop {
  background-color: #031930;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal.show.v-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.mobile-icon {
  margin-left: 10px;
}

.modal-sub-details .float-wrapper {
  margin-bottom: 20px;
}

.mobile-icon.icon-wrapper {
  background: #fff;
  padding: 0 3px;
  border-radius: 3px;
}

.k-i-non-recurrence {
  margin: 0 3px;
}

.k-i-non-recurrence::after {
  content: " ";
  width: 14px;
  height: 14px;
  background: url(../assets/img/mobilejob.svg) no-repeat center;
  background-size: contain;
  display: inline-block;
}

.k-i-non-recurrence::before {
  display: none;
}

.damag-section .section-header {
  padding: 10px 10px 10px 0;
  background: transparent;
  color: #002949;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.damag-section .section-body {
  border: 0;
  padding: 5px 15px 5px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 10px;
}

.sidebar-compact .sidebar-nav .nav-link i {
  transform: scale(1);
  display: inline-block;
  top: auto;
  position: relative;
}

.sidebar-nav .nav-link i {
  display: inline-block;
  top: 1px;
  position: relative;
  font-size: 16px !important;
}

.sidebar-nav .nav-link i.fa,
.sidebar-nav .nav-link i.icon-map,
.sidebar-nav .nav-link i.icon-envelope-letter {
  top: -2px;
}

.sidebar-nav .nav-link i.fa {
  transform: scale(1);
  top: 0;
}

.sidebar-compact .icon-circle-wrap {
  padding: 0 12px;
  line-height: 41px;
  background: rgba(255, 255, 255, 0.2509803922);
  border-radius: 50%;
}

.sidebar-compact .icon-circle-wrap svg {
  position: relative;
  top: 2px;
}

.app-header {
  box-shadow: none;
  border-bottom: 1px solid #e2e9f0;
}

.table-striped {
  border: 0;
}

.table.table-striped th {
  background-color: #eaeff4;
}

.table.table-striped .thead-light th {
  background-color: #ffffff;
  border-color: transparent;
  color: #466279;
  font-weight: 500;
  background-color: #eaeff4;
  font-size: 14px;
}

.table.table-striped .active td {
  color: #ffffff;
}

.table.table-striped td {
  color: #031930;
}

.ui-paginator {
  margin-top: 15px !important;
}

.ui-paginator .ui-paginator-element {
  border: 1px solid #dbe4ed;
  border-radius: 50% !important;
  padding: 3px !important;
  min-width: 30px !important;
  height: 30px;
  color: #031930 !important;
}

.ui-paginator .ui-paginator-element.ui-state-active {
  color: #031930 !important;
  background: transparent;
  border: 0;
}

.dossier-communication-table.table-striped .active td {
  color: #031930 !important;
}

.dossier-communication-table.table th,
.dossier-communication-table.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e2e9f0;
}

.info-menu .menu-item.save-btn-wrapper:hover {
  background: #fff;
  border-left: 2px solid transparent;
}

.info-menu .menu-item.save-btn-wrapper {
  padding: 20px;
}

.app-body {
  overflow-y: hidden;
}

.ui-paginator .ui-paginator-next .fa-forward,
.ui-paginator .ui-paginator-prev .fa-backward,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first {
  display: none;
}

.ui-paginator-prev {
  background: url(/assets/img/icon-prev.svg) no-repeat center !important;
}

.ui-paginator-next {
  background: url(/assets/img/icon-next.svg) no-repeat center !important;
}

.ngxdropdown-wrapper .selected-items,
.ngxdropdown-wrapper .selected-items + hr {
  display: none;
}

.sidebar-compact .sidebar .parent-menu .nav-link i.fa {
  margin-right: 14px !important;
}

.appointment-time {
  opacity: 0.6;
  font-size: 12px;
  color: #676767;
  margin-bottom: 0;
  margin: 10px 0 5px 5px;
}

.k-scheduler-dayview .col-l,
.k-scheduler-dayview .col-r {
  float: left;
  width: 50%;
  max-width: 200px;
  display: block;
}

.k-scheduler-dayview .first-col-mgl {
  margin-left: -5px;
}

.damageLocation-text .k-event-template {
  font-weight: normal;
  padding-top: 10px;
  font-size: 14px;
}

.phone-text .k-event-template {
  padding: 0 7px;
  font-weight: normal;
  font-size: 14px;
}

.licensePlate-text .k-event-template {
  font-weight: normal;
  font-size: 14px;
}

.licensePlate-text {
  padding-bottom: 10px;
  display: block;
  text-transform: uppercase;
}

.k-scheduler-dayview .vehicle-name {
  padding-bottom: 1px;
}

.fittingAddressStreet-text .k-event-template,
.licensePlate-text .k-event-template {
  font-weight: normal;
  padding: 0 7px;
}

.fittingAddressCity-text,
.fittingAddressCountryName-text {
  font-weight: normal;
}

.k-scheduler-dayview .day-view,
.k-scheduler-dayview .day-view-text {
  display: inherit;
}

.k-scheduler-dayview .week-view-text,
.day-view-text,
.day-view {
  display: none;
}

.k-event {
  cursor: pointer;
}

.k-scheduler-dayview .k-scheduler-content > .k-event {
  overflow-y: auto;
}

.k-scheduler-content {
  overflow-y: hidden;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

.card-policy {
  flex: 1 0 20%;
  position: relative;
  margin: 0.5em;
  padding: 2em;
  min-height: 4em;
  background: white;
  border: 3px solid grey;
}

.active-card-policy {
  border-color: #003b6a;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

th > * > a {
  color: #466279 !important;
  text-decoration: none !important;
  background-color: transparent;
}

.page-item.active .page-link {
  background-color: #031930;
  border-color: #031930;
}

.pagination li > a {
  color: #6c757d;
}

tfoot .pagination.float-sm-right .active a.page-link {
  color: #ffffff;
  background-color: #031930;
  border-color: #031930;
  line-height: 1em;
}

tfoot .pagination.float-sm-right a.page-link {
  color: #6c757d;
}

tfoot .pagination a.page-link {
  color: #6c757d;
}

.hrz-custom-control-input {
  left: 0;
  width: 1rem;
  height: 1.25rem;
}

.pdf-viewer {
  width: 40vw;
  height: 72vh;
}

.modal-type-1 .modal-content {
  border-left: 3px solid #AEE1F8;
}

.modal-type-Repair .modal-content {
  border-left: 3px solid #AEE1F8;
}

.modal-title.tabsectioncard-header {
  color: #082B46;
  font-size: 1.4rem;
  padding-top: 0;
}

.modal-title .header-badge-light {
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: bold;
}

.modal-header-buttons {
  margin-left: auto;
}

.modal-header-buttons div {
  min-width: 20px;
  margin-left: 20px;
  float: left;
  cursor: pointer;
}

.modal-body .sub-header {
  color: #082B46;
  font-size: 14px;
  font-weight: bold;
}

.modal-body .sub-details {
  color: #466279;
  margin-bottom: 20px;
  font-size: 14px;
}

.modal-sub-header {
  position: relative;
}

.sub-icon {
  position: absolute;
  display: inline-block;
}

.modal-sub-header h5 {
  color: #082B46;
  font-size: 15px;
  font-weight: bold;
  padding-left: 30px;
}

.modal-sub-details {
  padding-left: 30px;
  color: #466279;
  margin-bottom: 20px;
  font-size: 14px;
}

.modal-lg {
  max-width: 650px;
}

.icon-inputs-vcenter {
  padding-top: 10px;
}

.sub-heaader-inputs {
  padding-left: 30px;
}

input.active + label.float-label,
.float-select.active + label.float-label {
  padding-left: 14px;
}

p {
  color: #466279;
  font-size: 14px;
}

.section-wrapper {
  margin-bottom: 30px;
}

.vh-100 {
  height: 100%;
  min-height: calc(100vh - 110px);
}

button,
input,
optgroup,
select,
textarea {
  font-family: "ROBOTO";
}

.danger-element.input-warning-border:after {
  background-color: #e75b5e;
}

.warning-element.input-warning-border:after {
  background-color: #eea633;
}

.form-group .form-control {
  min-height: 40px;
}

.input-warning-border.warning-element-valign:after {
  height: 46%;
  top: 47%;
  left: 4px;
}

.form-control-label {
  color: #55697b;
  margin-bottom: 0.4rem;
}

.label.float-label {
  color: #d2dee7;
}

.btn-dark-icon .fa,
.btn-dark-icon .icon {
  color: #407DBF;
}

.btn-search-input,
.btn-search-input:active,
.btn-search-input:focus {
  border: 0;
  background: transparent url(../assets/img/search-icon.png) no-repeat center;
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 50%;
  box-shadow: none;
}

.float-radio {
  padding-left: 19px;
}

.float-radio label {
  padding-right: 15px;
}

.float-radio input {
  margin-right: 3px;
}

.new-case-footer {
  min-height: 68px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.2);
  position: sticky;
  z-index: 99;
  bottom: 0;
}

.footer-percentage-track {
  height: 10px;
  width: 100%;
  opacity: 0.15;
  border-radius: 3px;
  background-color: #41ca74;
}

.sidebar-compact .new-case-footer {
  width: 100%;
}

.footer-percentage-bar {
  height: 10px;
  width: 30%;
  border-radius: 0 3px 3px 0;
  background-color: #41CA74;
  position: absolute;
  top: 0;
}

.cutoms-card-mb {
  margin-bottom: 0.9rem;
}

.translation-keys-block {
  border: 1px solid #bfd9e4;
  padding: 5px !important;
}

.sidebar-compact .fix-footer-bottom-inner {
  position: fixed;
  width: 61.7%;
}

.fix-footer-bottom-inner {
  position: fixed;
  width: 52.7%;
  bottom: 66px;
  border: 1px solid #bfd9e4;
  background: #f7f8f9;
}

.fix-footer-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding-bottom: 20px;
}

.new-case-footer .btn-action {
  padding: 15px 30px 15px 30px;
}

.new-case-footer .track {
  position: relative;
}

.mandatory-fields-count {
  width: 100%;
  position: absolute;
  left: 0;
  pointer-events: none;
}

.glassdrive-form .btn-search-input {
  padding: 0;
  min-width: auto;
}

.search-block-borderless {
  position: relative;
}

.search-block-borderless .btn-search-input {
  top: 22%;
}

.search-block-borderless input {
  border: 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.1);
  min-height: 38px;
}

.custom-tab-wrapper .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #5dc4f0;
  color: #5dc4f0;
}
.custom-tab-wrapper .nav-tabs .nav-link {
  border: 0;
  color: #466279;
  padding: 16px;
}
.custom-tab-wrapper .tab-content {
  border: 0;
  padding-top: 25px;
  border-top: 1px solid #EAEFF4;
}

.table-filter-section .float-wrapper {
  margin-bottom: 0;
}

.table-filter-section .form-group {
  margin-bottom: 0.4rem;
}

.table-filter-section .filter-item {
  margin-left: 10px;
}

.scrollspy > div {
  list-style-type: none;
  counter-reset: css-counter 0;
}
.scrollspy #clientInfo {
  margin-top: -150px;
  padding-top: 150px;
}
.scrollspy .infomenu-section-wrapper,
.scrollspy .info-menu li {
  counter-increment: css-counter 1;
}
.scrollspy .infomenu-section-wrapper:before,
.scrollspy .info-menu li:before {
  content: counter(css-counter) " ";
  width: 35px;
  height: 35px;
  border: 2px solid #e9eef3;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.85rem;
  text-align: center;
  padding-top: 5px;
  position: relative;
  margin-right: 10px;
  position: absolute;
  margin-top: -3px;
}
.scrollspy .infomenu-section-head {
  margin-left: 50px;
}
.scrollspy .subtitle {
  font-weight: bold;
  font-size: 1rem;
}
.scrollspy .sub-wrapper {
  padding: 15px 30px;
}
.scrollspy .sendemail-wrapper {
  padding: 15px 30px;
}
.scrollspy .info-menu li:before {
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  padding-top: 4px;
  margin-top: 10px;
}
.scrollspy .info-menu .item {
  padding: 15px 0;
}
.scrollspy .info-menu .subtitle {
  font-weight: bold;
  font-size: 0.85rem;
  margin-left: 45px;
}
.scrollspy .white-wrapper {
  padding-bottom: 80px !important;
}

.full-width-wrapper .white-wrapper {
  background: #FFF;
  padding: 30px 15px 30px 30px;
}

.full-width-wrapper .grey-wrapper {
  background: #f6f9fd;
  padding: 30px 30px;
  box-shadow: inset 1px 0 0 0 #E2E9F0;
}

.ngxdropdown-wrapper .search-container label {
  width: 100%;
}

.ngxdropdown-wrapper.no-float-label .ngx-dropdown-button {
  padding-top: 11px !important;
  padding-bottom: 6px !important;
}

option.default-text {
  color: #9aadbc;
}

select.form-control {
  color: unset;
}

select.form-control option {
  color: #082B46;
}

.multiselect {
  min-width: 250px;
}

select.form-control option.default-text {
  color: #9aadbc;
}

.case-history-wrapper {
  background: #f6f9fd;
  border: 1px solid #DBE4ED;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 5px;
  color: #082B46;
}
.case-history-wrapper .header h5 {
  font-weight: bold;
  padding: 20px;
  font-size: 1rem;
  border-bottom: 2px solid #DBE4ED;
  margin-bottom: 0;
}
.case-history-wrapper .case-history-list {
  padding: 0 20px 20px;
}
.case-history-wrapper .case-history-list li {
  padding: 20px 0;
  border-bottom: 1px solid #DBE4ED;
}
.case-history-wrapper .case-history-list .case-date, .case-history-wrapper .view-history-logs {
  font-size: 0.9rem;
}
.case-history-wrapper .case-history-list .case-user-name {
  font-size: 0.75rem;
  padding-top: 2px;
}
.case-history-wrapper .view-history-logs a {
  font-size: 1rem;
  color: #082B46;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.case-activity-overview-wrapper {
  background: #f6f9fd;
  margin-right: 20px;
  margin-left: 5px;
  color: #082B46;
}
.case-activity-overview-wrapper .header h5 {
  font-weight: bold;
  padding: 20px;
  font-size: 1rem;
  border-bottom: 2px solid #DBE4ED;
  margin-bottom: 0;
}
.case-activity-overview-wrapper .case-history-list {
  padding: 0 20px 20px;
}
.case-activity-overview-wrapper .case-history-list li {
  padding: 20px 0;
}
.case-activity-overview-wrapper .case-history-list .case-date, .case-activity-overview-wrapper .view-history-logs {
  font-size: 0.9rem;
}
.case-activity-overview-wrapper .case-history-list .case-user-name {
  font-size: 0.75rem;
  padding-top: 2px;
}
.case-activity-overview-wrapper .view-history-logs a {
  font-size: 1rem;
  color: #082B46;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.expand-table {
  font-size: 0.9rem;
}
.expand-table h6 {
  font-size: 0.8rem;
  font-weight: bold;
}
.expand-table .row-details {
  display: none;
}
.expand-table .active + .row-details {
  display: table-row;
  background-color: #F6F9FD;
  box-shadow: inset 3px 0 0 0 #5dc4f0;
}
.expand-table tbody tr.active,
.expand-table tr.active {
  background-color: #F6F9FD !important;
  box-shadow: inset 3px 0 0 0 #5dc4f0;
}
.expand-table tbody tr.active td,
.expand-table tr.active td {
  color: #082B46 !important;
}
.expand-table tbody td {
  padding: 1.1rem 0.75rem;
}
.expand-table tbody tr:hover {
  background-color: #F6F9FD !important;
}
.expand-table th {
  font-weight: bold !important;
}
.expand-table .exapand-icon {
  height: 21px;
  width: 21px;
  background-color: #ebeff2;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
}
.expand-table .exapand-icon i {
  color: #466279;
}
.expand-table .active i.fa-angle-down,
.expand-table i.fa-angle-up {
  display: none;
}
.expand-table .active i.fa-angle-up,
.expand-table i.fa-angle-down {
  display: inline-block;
}
.expand-table tr.row-details {
  font-size: 0.75rem;
}

.spinner-wrapper .overlay.fixed-overlay {
  position: fixed;
  z-index: 99999;
}

.text-wrap {
  word-break: break-word;
  white-space: initial;
}

.br-0 {
  border: 0 !important;
}

.search-title {
  background: #eaeff4;
  border-radius: 3px 3px 0 0;
  padding: 8px 10px 8px 20px;
  font-size: 0.83rem;
  font-weight: bold;
}

.search-wrapper-section {
  background: #f4f7fa;
  border-radius: 0 0 3px 3px;
}

.auth-result-wrapper {
  border-radius: 10px;
  height: 400px;
  overflow: auto;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1100px;
  }
}
@media (max-width: 1399px) {
  .modal-xl {
    max-width: 992px;
  }
  .full-width-wrapper {
    margin: -30px -15px 0;
  }
  .scrollspy .info-menu-wrapper {
    min-width: 18%;
    right: 15px;
    top: 80px;
  }
  .sidebar-compact .scrollspy .info-menu-wrapper {
    min-width: 22%;
  }
  .scrollspy .info-menu .subtitle {
    font-weight: bold;
    font-size: 0.8rem;
    margin-left: 40px;
  }
  .scrollspy .info-menu .menu-item {
    padding: 0 15px 0 25px;
  }
}
@media (min-width: 1400px) {
  .full-width-wrapper {
    margin: -30px -30px 0;
  }
  .scrollspy .info-menu-wrapper {
    min-width: 18%;
    right: 25px;
    top: 110px;
  }
  .sidebar-compact .scrollspy .info-menu-wrapper {
    min-width: 22%;
  }
}
@media (min-width: 1800px) {
  .scrollspy .info-menu-wrapper {
    min-width: 17%;
    right: 44px;
    top: 105px;
  }
  .sidebar-compact .scrollspy .info-menu-wrapper {
    min-width: 20%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .full-width-wrapper {
    margin: -13px -15px 0;
  }
  .sidebar-mobile-show .scrollspy .info-menu-wrapper {
    min-width: 23%;
  }
  .scrollspy .info-menu-wrapper {
    min-width: 30%;
    top: 90px;
  }
}