@each $variant, $color in (primary: $brand-primary, secondary: $gray-lighter, success: $brand-success, info: $brand-info, warning: $brand-warning, danger: $brand-danger) {
  .modal-#{$variant} {

    .modal-content {
      border-color: $color;
    }

    .modal-header {
      color: #fff;
      background-color: $color;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  outline: 0;
  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog,
  &.fade .modal-dialog-custom {
    @include transition($modal-transition);
    transform: translate(0, -25%);
  }
  &.show .modal-dialog { transform: translate(0, 0); }
}

// Shell div to position the modal with bottom padding
.modal-dialog,
.model-dialog-custom {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
}

.modal.overlayable {
    z-index:1041;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog:has(div.larger-modal) {
    max-width: 60vw !important;
  }
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-sm-up-margin-y auto;
  }
  .modal-dialog-custom {
      margin:$modal-dialog-sm-up-margin-y auto;
  }

  .modal-content {
    @include box-shadow($modal-content-sm-up-box-shadow);
  }

  .modal-sm { max-width: $modal-sm; }

  .modal-customsize { max-width: none; }
}

@media (min-width: 1200px){
  .modal-lg {
      max-width: 800px;
  }
}
