  .radio-container {
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding-left: 1.75rem;
	font-size: 14px;
	input[type="radio"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ {
				.radio-checkmark {
					&:after {
						display: block;
					}
				}
			}
		}
	}
	&:hover {
		input {
			~ {
				.radio-checkmark {
					background-color: $interface-semidark;
				}
			}
		}
	}
	input[disabled] {
		~ {
			.radio-checkmark {
				background-color: #F6F9FD;
			}
		}
	}
	.radio-checkmark {
		&:after {
			top: 5px;
			left: 5px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $header-text;
		}
	}
}
.radio-checkmark {
	border: 1px solid $interface-light;
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: $interface-white;
	border-radius: 50%;
	background-color: $interface-white;
	&:after {
		content: "";
		position: absolute;
		display: none;
	}
}
