.btn {
  font-size: 14px;
  height: 38px;
  border-radius: 24px;
  padding: 9px 38px;
  white-space: nowrap;
  .badge {
    position: absolute;
    top: 2px;
    right: 6px;
    font-size: 9px;
  }
}
.btn:hover,.btn:focus{
  box-shadow: inset 0px -2px 0px rgba(8, 43, 70, 0.15);
}
a.btn-setting { 
    cursor:pointer;
}
a { cursor:pointer; }
timepicker .btn-default,timepicker .btn-link{
  padding: 8px 16px;
}