﻿.photo-list .figure {
    margin-right: 10px;
    float: left;
    background: grey;
    border: 2px solid white;
    cursor: pointer;
    padding-bottom:5px;
}

.photo-list .figure .figure-caption {
    color: white;
}

.photo-list .figure:hover,
.photo-list .figure.active {
    border: 2px solid #E3E8EC;
    background: #E3E8EC;
    
}
.photo-list .figure:hover .figure-caption,
.photo-list .figure.active .figure-caption {
    color: black;
}

.photo-upload {
    background-color: grey;
    width: 100%;
    height: 100%;
}

.photo-upload label {
    color: white;
}