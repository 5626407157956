.section-wrapper {
    margin-bottom: 30px;
}

.vh-100 {
    height: 100%;
    min-height: calc(100vh - 110px);
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'ROBOTO';
}

.danger-element.input-warning-border:after {
    background-color: $brand-danger;
}

.warning-element.input-warning-border:after {
    background-color: $brand-warning;
}

.form-group .form-control {
    min-height: 40px;
}

.input-warning-border.warning-element-valign:after {
    height: 46%;
    top: 47%;
    left: 4px;
}

.form-control-label {
    color: $horizons-ui-light;
    margin-bottom: .4rem;
}

.label.float-label {
    color: $horizons-ui-semilight;
}

.btn-dark-icon .fa,
.btn-dark-icon .icon {
    color: #407DBF;
}

.btn-search-input,
.btn-search-input:active,
.btn-search-input:focus{
    border: 0;
    background: transparent url(../assets/img/search-icon.png) no-repeat center;
    width: 25px;
    height: 25px;
    position: absolute;
    right: 10px;
    top: 50%;
    box-shadow: none;
}

.float-radio {
    padding-left: 19px;
}

.float-radio label {
    padding-right: 15px;
}

.float-radio input {
    margin-right: 3px;
}

.new-case-footer {
    min-height: 68px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.2);
    position: sticky;
    z-index: 99;
    bottom: 0;
}

.footer-percentage-track {
    height: 10px;
    width: 100%;
    opacity: 0.15;
    border-radius: 3px;
    background-color: $brand-success;
}

.sidebar-compact .new-case-footer {
    width: 100%;
}

.footer-percentage-bar {
    height: 10px;
    width: 30%;
    border-radius: 0 3px 3px 0;
    background-color: #41CA74;
    position: absolute;
    top: 0;
}

.cutoms-card-mb {
    margin-bottom: 0.9rem;
}

.translation-keys-block {
    border: 1px solid #bfd9e4;
    padding: 5px !important;
}

.sidebar-compact .fix-footer-bottom-inner {
    position: fixed;
    width: 61.7%;
}

.fix-footer-bottom-inner {
    position: fixed;
    width: 52.7%;
    bottom: 66px;
    border: 1px solid #bfd9e4;
    background: #f7f8f9;
}

.fix-footer-bottom {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-bottom: 20px;
}

.new-case-footer .btn-action {
    padding: 15px 30px 15px 30px;
}

.new-case-footer .track {
    position: relative;
}

.mandatory-fields-count {
    width: 100%;
    position: absolute;
    left: 0;
    pointer-events: none;
}

.glassdrive-form .btn-search-input {
    padding: 0;
    min-width: auto;
}

.search-block-borderless {
    position: relative;
}

.search-block-borderless .btn-search-input {
    top: 22%;
}

.ngx-dropdown-list-container ul.selected-items+.available-items {
    //display:  none;
}

.search-block-borderless input {
    border: 0;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.1);
    min-height: 38px;
}

.custom-tab-wrapper {
    .nav-tabs .nav-link.active {
        border: 0;
        border-bottom: 2px solid $glassdrive-secondary-link;
        color: $glassdrive-secondary-link;
    }

    .nav-tabs .nav-link {
        border: 0;
        color: #466279;
        padding: 16px;
    }

    .tab-content {
        border: 0;
        padding-top: 25px;
        border-top: 1px solid #EAEFF4;
    }
}

.table-filter-section .float-wrapper {
    margin-bottom: 0;
}

.table-filter-section .form-group {
    margin-bottom: 0.4rem;
}

.table-filter-section .filter-item {
    margin-left: 10px;
}

//ScrollSpy

.scrollspy {
    >div {
        list-style-type: none;
        counter-reset: css-counter 0;
    }

    #clientInfo {
        margin-top: -150px;
        padding-top: 150px;
    }

    .infomenu-section-wrapper,
    .info-menu li {
        counter-increment: css-counter 1;
        // padding-top: 0px;
        // margin-top: 0px;
    }

    .infomenu-section-wrapper:before,
    .info-menu li:before {
        content: counter(css-counter) " ";
        width: 35px;
        height: 35px;
        border: 2px solid #e9eef3;
        display: inline-block;
        border-radius: 50%;
        font-size: 0.85rem;
        text-align: center;
        padding-top: 5px;
        position: relative;
        margin-right: 10px;
        position: absolute;
        margin-top: -3px;
    }

    .infomenu-section-head {
        margin-left: 50px;
    }

    .subtitle {
        font-weight: bold;
        font-size: 1rem;
    }

    .sub-wrapper {
        padding: 15px 30px;
    }

    .sendemail-wrapper {
        padding: 15px 30px;
    }

    .info-menu li:before {
        width: 30px;
        height: 30px;
        font-size: 0.8rem;
        padding-top: 4px;
        margin-top: 10px;
    }

    .info-menu .item {
        padding: 15px 0;
    }

    .info-menu .subtitle {
        font-weight: bold;
        font-size: 0.85rem;
        margin-left: 45px;
    }

    .white-wrapper {
        padding-bottom: 80px !important;
    }

}

.full-width-wrapper .white-wrapper {
    background: #FFF;
    padding: 30px 15px 30px 30px;
}

.full-width-wrapper .grey-wrapper {
    background: $interface-extralight;
    padding: 30px 30px;
    box-shadow: inset 1px 0 0 0 #E2E9F0;
}

.ngxdropdown-wrapper .search-container label {
    width: 100%;
}

.ngxdropdown-wrapper.no-float-label .ngx-dropdown-button {
    padding-top: 11px !important;
    padding-bottom: 6px !important;
}

option.default-text {
    color: $interface-semidark;
}

select.form-control {
    color: unset;
}

select.form-control option {
    color:  $header-text;
}

.multiselect {
    min-width: 250px;
}

select.form-control option.default-text {
    color: $interface-semidark;
}


.case-history-wrapper {
    background: $interface-extralight;
    border: 1px solid #DBE4ED;
    border-radius: 3px;
    margin-right: 20px;
    margin-left: 5px;
    color:  $header-text;
    .header h5 {
        font-weight: bold;
        padding: 20px;
        font-size: 1rem;
        border-bottom: 2px solid #DBE4ED;
        margin-bottom: 0;
    }
    .case-history-list{
        padding:0 20px 20px;
    }
    .case-history-list li{
        padding: 20px 0;
        border-bottom: 1px solid #DBE4ED;
    }
    .case-history-list .case-date,.view-history-logs {
      font-size: 0.9rem;
    }
    .case-history-list .case-user-name {
        font-size: 0.75rem;
        padding-top: 2px;
    }
    .view-history-logs a { 
       font-size:1rem;
       color:  $header-text;
       display: inline-block;
       text-decoration: underline;
       cursor: pointer;
      }

}
.case-activity-overview-wrapper {
    background: $interface-extralight;
    margin-right: 20px;
    margin-left: 5px;
    color:  $header-text;
    .header h5 {
        font-weight: bold;
        padding: 20px;
        font-size: 1rem;
        border-bottom: 2px solid #DBE4ED;
        margin-bottom: 0;
    }
    .case-history-list{
        padding:0 20px 20px;
    }
    .case-history-list li{
        padding: 20px 0;
    }
    .case-history-list .case-date,.view-history-logs {
      font-size: 0.9rem;
    }
    .case-history-list .case-user-name {
        font-size: 0.75rem;
        padding-top: 2px;
    }
    .view-history-logs a { 
       font-size:1rem;
       color:  $header-text;
       display: inline-block;
       text-decoration: underline;
       cursor: pointer;
      }

}
.expand-table {
    font-size: 0.9rem;
    h6{
        font-size: 0.8rem;
        font-weight: bold;
    }
    .row-details {
        display: none; 
    }
    
    .active + .row-details {
        display: table-row;
        background-color: #F6F9FD;
        box-shadow: inset 3px 0 0 0 $glassdrive-secondary-link
    }
    
     tbody tr.active,
     tr.active {
        background-color: #F6F9FD !important;
        box-shadow: inset 3px 0 0 0 $glassdrive-secondary-link; 
    }
    tbody tr.active td,
     tr.active  td {
        color: $header-text  !important;
     }
     tbody td {
        padding: 1.1rem 0.75rem;
     }
     tbody tr:hover {
        background-color: #F6F9FD !important;
     }
     th {
         font-weight: bold !important ;
     }
     .exapand-icon{
        height: 21px;
        width: 21px; 
        background-color: #ebeff2;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
     }
     .exapand-icon i { 
       color:#466279;
     }
     .active i.fa-angle-down,
      i.fa-angle-up   {
         display: none;
     }
     .active i.fa-angle-up,
     i.fa-angle-down {
        display: inline-block;
    }
    tr.row-details {
        font-size: 0.75rem; 
    }
} 
.spinner-wrapper .overlay.fixed-overlay{
    position: fixed;
    z-index: 99999;
  }
.text-wrap{
    word-break: break-word; 
    white-space: initial;
}

.br-0{
    border: 0   !important;
}

.search-title{
    background: $interface-semilight; 
    border-radius: 3px 3px 0 0;
    padding: 8px 10px 8px 20px;
    font-size: 0.83rem;
    font-weight: bold;
}
.search-wrapper-section{
    background: #f4f7fa;
    border-radius: 0 0 3px 3px;
}

.auth-result-wrapper{
    border-radius: 10px; 
    height: 400px; 
    overflow: auto
}


@media (min-width: 1200px) {
    .modal-xl{
        max-width:1100px; 
    }
}
@media (max-width: 1399px) {
    .modal-xl{
        max-width: 992px; 
    }
    .full-width-wrapper {
        margin: -30px -15px 0;
    }

    .scrollspy .info-menu-wrapper {
        min-width: 18%;
        right: 15px;
        top: 80px;
    }

    .sidebar-compact .scrollspy .info-menu-wrapper {
        min-width: 22%;
    }

    .scrollspy .info-menu .subtitle {
        font-weight: bold;
        font-size: 0.8rem;
        margin-left: 40px;
    }

    .scrollspy .info-menu .menu-item {
        padding: 0 15px 0 25px;
    }
}

@media (min-width: 1400px) {
    .full-width-wrapper {
        margin: -30px -30px 0;
    }

    .scrollspy .info-menu-wrapper {
        min-width: 18%;
        right: 25px;
        top: 110px;
    }

    .sidebar-compact .scrollspy .info-menu-wrapper {
        min-width: 22%;
    }

}

@media (min-width: 1800px) {
    .scrollspy .info-menu-wrapper {
        min-width: 17%;
        right: 44px;
        top: 105px;
    }

    .sidebar-compact .scrollspy .info-menu-wrapper {
        min-width: 20%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .full-width-wrapper {
        margin: -13px -15px 0;
    }

    .sidebar-mobile-show .scrollspy .info-menu-wrapper {
        min-width: 23%;
    }

    .scrollspy .info-menu-wrapper {
        min-width: 30%;
        top: 90px;
    }
}