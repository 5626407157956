// Core Admin Variables

$enable-sidebar-nav-rounded: false;

$border-color: $gray-lighter;
$layout-transition-speed: .25s;

/* New UI Primary Colors -July19*/
$glassdrive-primary : #005598;
$glassdrive-secondary: #0052c8;
$glassdrive-secondary-link : #5dc4f0;

/* New UI Interface Colors -July19*/
$interface-extradark :#031930;
$interface-dark :#466279;
$interface-semidark :#9aadbc;
$interface-light :#dbe4ed;
$interface-semilight :#eaeff4;
$interface-extralight :#f6f9fd;
$interface-white :#FFFFFF;

/* New UI Job Colors -July19*/
$job-application :#baf1b2;
$job-repair :#aee1f8;
$job-replacement :#f8dc93;
$job-other :#ced0fa;


//horizons Style Guide Colors
$glassdrive-primary-blue:#031930;
$glassdrive-primary-red: #d10007;
$horizons-primary: #005598;
$horizons-primary-light: #5DC4F0;
$horizons-ui-dark: #031930;
$horizons-ui-light: #55697b;
$horizons-ui-semilight: #d2dee7;
$horizons-ui-extralight: #f0f4f8;

$box-border-color:$interface-extralight;

$bg-color-inprogress :#5dc4f0;
$bg-color-ready :#41ca74;
$bg-color-pending :#eea633;
$bg-color-rejected :#e75b5e;


$brand-success: #41ca74;
$brand-info: #5dc4f0;
$brand-warning: #eea633;
$brand-danger: #e75b5e;
$brand-neutral: #e3e8eb;
$brand-primary: $glassdrive-primary-blue;

$horizons-app-bg-color:#f0f3f8;


// Social Colors

$facebook: #3b5998;
$twitter: #00aced;
$linkedin: #4875b4;
$google-plus: #d34836;
$flickr: #ff0084;
$tumblr: #32506d;
$xing: #026466;
$github: #4183c4;
$html5: #e34f26;
$openid: #f78c40;
$stack-overflow: #fe7a15;
$youtube: #b00;
$css3: #0170ba;
$dribbble: #ea4c89;
$google-plus: #bb4b39;
$instagram: #517fa4;
$pinterest: #cb2027;
$vk: #45668e;
$yahoo: #400191;
$behance: #1769ff;
$dropbox: #007ee5;
$reddit: #ff4500;
$spotify: #7ab800;
$vine: #00bf8f;
$foursquare: #1073af;
$vimeo: #aad450;

// Navbar

$navbar-height: 60px;
$navbar-bg: #fff;
$navbar-border: (bottom: (size: 1px,
    style: solid,
    color: $border-color));
$navbar-brand-width: 260px;
$navbar-brand-bg: #fff;
/* 70px auto; */
$navbar-brand-border: (bottom: (size: 1px,
    style: solid,
    color: $border-color));

$navbar-color: $gray-light;
$navbar-hover-color: $gray;
$navbar-active-color: $gray;
$navbar-disabled-color: $gray-lightest;

// Sidebar

$sidebar-width: 260px;
$sidebar-padding: 0;
$sidebar-compact-width: 70px;
$sidebar-compact-height: $sidebar-compact-width;
$sidebar-color: #fff;
$sidebar-bg: $interface-extradark;
$sidebar-header-bg: rgba(0, 0, 0, .2);
$sidebar-footer-bg: rgba(0, 0, 0, .2);
$sidebar-borders: none;
$mobile-sidebar-width: 240px;

// Sidebar Navigation

$sidebar-nav-color: #fff;
$sidebar-nav-title-padding-y: 1rem;
$sidebar-nav-title-padding-x: 1.25rem;
$sidebar-nav-title-color: $gray-lighter;
$sidebar-nav-link-padding-y: 0.95rem;
$sidebar-nav-link-padding-x: 1rem;
$sidebar-nav-link-color: #fff;
$sidebar-nav-link-bg: transparent;
$sidebar-nav-link-icon-color: $text-muted;

$sidebar-nav-link-borders: 0;
$sidebar-nav-link-hover-color: #fff;
$sidebar-nav-link-hover-bg: $horizons-primary;
$sidebar-nav-link-hover-icon-color: #fff !important;

$sidebar-nav-link-hover-borders: 0;
$sidebar-nav-link-active-color: #fff;
$sidebar-nav-link-active-bg: lighten($sidebar-bg, 5%);
$sidebar-nav-link-active-icon-color: $brand-primary;

$sidebar-nav-link-active-borders: 0;

$sidebar-nav-dropdown-color: #fff;
$sidebar-nav-dropdown-bg: rgba(0, 0, 0, .2);
$sidebar-nav-dropdown-borders: 0;

// Top Navigation

$top-nav-bg: #fff;
$top-nav-color: $body-color;
$top-nav-borders: (bottom: (size: 1px,
    style: solid,
    color: $border-color));
$top-nav-ul-borders: (all: (size: 1px,
    style: solid,
    color: $border-color));

$top-nav-hover-color: #fff;
$top-nav-hover-bg: $brand-primary;
$top-nav-active-color: #fff;
$top-nav-active-bg: $brand-primary;
$top-nav-height: $navbar-height - 15px;

// Breadcrumb

$breadcrumb-borders: (bottom: (size: 1px,
    style: solid,
    color: $border-color));

// Aside

$aside-menu-width: 250px;
$aside-menu-color: $gray-dark;
$aside-menu-bg: #fff;
$aside-menu-borders: (left: (size: 1px,
    style: solid,
    color: $border-color));

$aside-menu-nav-padding-y: 1rem;
$aside-menu-nav-padding-x: 1.25rem;

// Footer

$footer-height: 50px;
$footer-bg: $gray-lightest;
$footer-color: $body-color;
$footer-borders: (top: (size: 1px,
    style: solid,
    color: $border-color));

// Cards

$card-icon-bg: transparent;
$card-icon-color: $body-color;


$font-size-base: 0.9375rem;
$box-border-radius: 3px;
$body-bg: #ffffff;

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.eot');
  src: url('../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$header-font-family:'Roboto',
Arial,
sans-serif;
$body-font-family-sans-serif: "Roboto",
-apple-system,
system-ui,
BlinkMacSystemFont,
"Segoe UI",
"Helvetica Neue",
Arial,
sans-serif !default;


// Cards

$card-icon-bg: transparent;
$card-icon-color: $body-color;

$input-color: $horizons-primary !default;

$header-text : #082B46;
$label-text :#556472;
