// Here you can add other styles
/*timepicker tr.text-center {
    display:none;

}*/

@keyframes blink {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}

.nav-link.active {
  .badge.badge-info {
    background-color: $glassdrive-primary;
  }
}

.info-box {
  .reset-margins {
    .form-group {
      margin-bottom: 0px;
      margin-top: -7px;
      padding: 0px;
    }
  }
}

.info-box {
  .reset-margins {
    label.btn {
      margin: 0;
    }

    button.btn {
      margin-top: -8px;
    }
  }
}


body {
  font-family: $body-font-family-sans-serif;
  color: #444D58 !important;
  font-weight: normal ;
  font-size: 0.9375rem;
}

@font-face {
  font-family: "Glyphicons Halflings";
  src: url("/assets/fonts/glyphicons-regular.eot");
  src: url("/assets/fonts/glyphicons-regular.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/glyphicons-regular.woff2") format("woff2"), url("/assets/fonts/glyphicons-regular.woff") format("woff"), url("/assets/fonts/glyphicons-regular.ttf") format("truetype");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Individual icons
.glyphicon-chevron-up {
  &:before {
    content: "\e601";
  }
}

.glyphicon-chevron-down {
  &:before {
    content: "\e602";
  }
}

//Added Styling changes as per the Glassdrive styleguide
.sidebar .nav .nav-item .nav-link {
  font-weight: normal;
}

.sidebar {
  background: $brand-primary;
}

.sidebar .nav .nav-item {
  border-bottom: 0;
  position: relative;
  padding: 2px 5px;
}

.sidebar .nav .nav-item .nav-link.active {
  background: $brand-primary;
  color: $glassdrive-secondary-link;
  position: relative;
}

.sidebar .nav .nav-item .nav-link.active::before,
.sidebar .nav .nav-item .nav-link:hover::before,
.page-dossiers .sidebar .nav .dossiers-overview .nav-link::before {
  content: "";
  position: absolute;
  left: -4px;
  width: 2px;
  height: 100%;
  background: $glassdrive-secondary-link;
  display: inline-block;
  top: 0;
  border-radius: 5px;
}

.sidebar .nav .nav-item .nav-link.active i {
  color: $glassdrive-secondary-link;
}

.sidebar .nav .nav-item .nav-link.active i .ui-stroke {
  stroke: $glassdrive-secondary-link;
}

.sidebar .nav .nav-item .nav-link.active i .ui-fill,
.sidebar .nav .nav-item .nav-link.active i #Combined-Shape {
  fill: $glassdrive-secondary-link;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active i,
.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active,
.page-dossiers .sidebar .nav .nav-item .nav-link.active,
.page-dossiers .sidebar .nav .nav-item .nav-link.active i {
  color: #fff;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active i .ui-stroke,
.page-dossiers .sidebar .nav .nav-item .nav-link.active i .ui-stroke {
  stroke: #fff;
}

.create-new-dossier .sidebar .nav .dossiers-overview .nav-link.active::before,
.page-dossiers .sidebar .nav .nav-item .nav-link.active::before,
.page-dossiers .sidebar .nav .nav-item .nav-link:hover {
  background: transparent;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link i,
.page-dossiers .sidebar .nav .dossiers-overview .nav-link {
  color: $glassdrive-secondary-link !important;
}

.page-dossiers .sidebar .nav .nav-item .nav-link:hover::before,
.page-dossiers .sidebar .nav .nav-item .nav-link:hover {
  background: transparent !important;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link i .ui-stroke {
  stroke: $glassdrive-secondary-link;
}

.page-dossiers .sidebar .nav .dossiers-overview .nav-link::before {
  background: $glassdrive-secondary-link !important;
}

.k-scheduler-weekview .k-scheduler-table th:hover {
  background: #f6f9fd;
}

.k-scheduler-weekview .k-scheduler-table th .k-link {
  display: block;
  cursor: pointer;
}

.k-scheduler-calendar {
  padding: 5px;
}

.k-scheduler-calendar .k-footer {
  background: #f6f9fd;
}

body.sidebar-compact .sidebar .sidebar-nav {
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-nav .nav-item .nav-link.active:hover:after {
  border-left-color: #fff;
}

.ad-banner {
  padding: 15px 10px;
  background: #5484bd;
  border-radius: $box-border-radius;
  border: 1px solid #86aad4;
  margin-top: 25px;
}

// .btn-primary {
//   @include button-variant($horizons-primary, darken($horizons-primary, 7.5%), darken($horizons-primary, 10%), lighten($horizons-primary,5%), lighten($horizons-primary, 10%), darken($horizons-primary,30%));
// }
  
// .btn-outline-primary {
//   @include button-outline-variant($horizons-primary, #222222, lighten($horizons-primary,5%), $horizons-primary);
// }

.btn-primary {
  color: #fff;
  background-color: $horizons-primary ;
  border-color: $horizons-primary ;
  text-transform: uppercase;
}

.btn-primary:hover {
  color: #fff;
  background-color: #003b6a ;
  border-color: #003b6a ;
}

.btn-default {
  background-color: $interface-semilight;
  color: $interface-dark;
}

.form-control {
  color: $horizons-primary;
}

.btn-primary.disabled:hover,
.btn-primary.disabled,
.btn-primary:disabled {
  border-color: #b3cce0;
  background-color: #b3cce0;
}

header.navbar .navbar-brand {
  background: transparent url("/assets/img/logo-horizons-small.png") no-repeat 0 24px;
  width: 51px;
  height: 75px;
  background-size: 82%;
  display: block;
  border-bottom: 0;
  margin: 0 auto;
}

.sidebar-compact .logowrapper {
  padding-left: 2px;
  width: 70px;
}

.sidebar-compact header.navbar .navbar-brand {
  background: transparent url("/assets/img/logo-horizons-small.png") no-repeat 8px 10px;
  width: 51px;
  height: 54px;
  background-size: 70%;
}

.logout-tagline {
  font-size: 0.9rem;
  color: #000;
  position: absolute;
  right: 5px;
  top: 10px;
}

.navbar-right {
  right: 10;
  padding: 0 5px;
  display: flex;
  height: 100%;

  .brand-tagline {
    align-self: flex-end;
    font-size: 0.9rem;
    color: $horizons-primary;
    margin-right: 5px;
  }

  .user-profile-dropdown {
    align-self: flex-start;
    height: 100%;
  }
}

.toaster {
  position: fixed;
  bottom: 40px;
  right: 0;
}

.app-footer .brand-tagline {
  color: $horizons-primary;
  float: right;
}

.parent-menu .fa-chevron-up {
  display: inline-block !important;
}

.parent-menu .fa-chevron-down {
  display: none !important;
}

.parent-menu.submenu-collapsed .fa-chevron-up {
  display: none !important;
}

.parent-menu.submenu-collapsed .fa-chevron-down {
  display: inline-block !important;
}

.app-footer {
  border-top: 0;
  background: $body-bg url("/assets/img/footer_logo_bg.png") no-repeat left top;
}

.address .fa-check {
  display: none;
}

.active .address .fa-check {
  display: inherit;
  float: right;
  position: relative;
}

.active .address {
  padding-right: 25px;
}

.table-inverse {
  color: #ffffff;
  background-color: #7b90a9;
}

.formblock-header {
  margin-top: 25px;
  margin-bottom: 20px;
  padding-top: 15px;
  border-top: 1px solid #f1f1f1;
}

.sidebar-fixed.sidebar-compact .sidebar-toggler:before {
  display: none;
}

.sidebar-fixed.sidebar-compact .sidebar-toggler:after {
  display: inline-block;
}

.sidebar-fixed .sidebar-toggler:after {
  display: none;
}

.sidebar-toggler:before {
  right: 75%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #88b7d5;
  border-width: 4px;
  margin-top: -4px;
}

.sidebar-toggler:after {
  left: 75%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #88b7d5;
  border-width: 4px;
  margin-top: -4px;
}

@keyframes login-panel {
  from {
    -webkit-transform: translate3d(0, -30%, 0);
    transform: translate3d(0, -30%, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.spinner {
  margin: auto;
  width: 70px;
  text-align: center;
  -webkit-animation-name: login-panel;
  animation-name: login-panel;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0.1s;
  -o-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.spinner > div {
  width: 15px;
  height: 15px;
  background-color: #bfbdbd;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.spinner-wrapper {
  position: relative;
}

.spinner-wrapper > .row {
  max-width: 100%;
}

.spinner-wrapper .spinner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border: 1px solid #f1f1f1;
  height: 71px;
  padding: 10px;
  min-width: 140px;
  font-size: 0.8em;
  border-radius: 3px;
  background: #ffffff;
  color: #9a9a9a;
}

.spinner-wrapper .spinner > span {
  display: block;
  padding-top: 8px;
}

.spinner-wrapper .overlay {
  position: absolute;
  width: 100%;
  background: rgba(134, 134, 134, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 999;
}

.overlayWrapper {
  position: absolute;
  width: 100%;
  background: rgba(134, 134, 134, 0.3);
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  z-index: 999;
  text-align: center;
}

.overlayWrapper span {
  padding: 10px;
  display: inline-block;
  background: #fffffff0;
  margin: 10px;
  border-radius: 4px;
  position: relative;
}

.filter-block,
.list-block {
  border: 1px solid transparent;
  padding: 15px 20px 0;
  background: transparent;
}

.filter-block {
  border-radius: 5px 5px 0 0;
}

.list-block {
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-bottom: 25px;
}

.panel-box {
  border: 1px solid $box-border-color;
  padding: 15px 20px;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 25px;
}

.panel-head {
  border: 1px solid $box-border-color;
  padding: 15px 20px;
  background: #fff;
  border-radius: 5px 5px 0 0;
}

.panel-body {
  border: 1px solid $box-border-color;
  padding: 15px 20px;
  background: #fff;
  border-radius: 0 0 5px 5px;
  border-top: 0;
  margin-bottom: 25px;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border: 1px solid $box-border-color;
  border-width: 0 0 1px 0;
  color: $glassdrive-primary-blue;
}

.card {
  border: 1px solid $box-border-color;
}

.card-header .card-actions a,
.card-header .card-actions button {
  color: $glassdrive-primary-blue;
}

.btn-primary {
  text-transform: capitalize;
}

.table-block th {
  font-size: 1rem;
  font-weight: normal;
}

table .custom-control.custom-checkbox {
  margin-bottom: 0;
}

.table-striped {
  border: 1px solid #f1f1f1;
}

.table-striped tbody tr:nth-of-type(odd),
.table-striped tbody tr:nth-of-type(even) {
  background-color: #fff;
}

.table .thead-light th {
  background-color: #ffffff;
  border-color: #e9ecef;
  color: $horizons-primary;
  font-weight: bold;
}

.search-block {
  padding: 15px;
  background: #fff;
  border: 1px solid $interface-semilight;
  border-radius: 3px 3px 00;
}

.badge,
.badge.badge-pill {
  padding: 5px 15px;
}

a:hover .badge-light {
  background: $horizons-ui-semilight;
}

.btn-status {
  padding: 5px 20px 5px 35px;
  position: relative;
  min-width: 130px;
  cursor: default;
  display: inline-block;
}

.badge.badge-light {
  border: 1px solid #e4e4e4;
  color: $glassdrive-primary-blue;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-status .fa {
  position: absolute;
  right: 7px;
  font-size: 0.8rem;
  top: 9px;
  color: inherit;
}

.btn-status:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border: 0;
  border-radius: 50%;
  display: block;
  background: $horizons-ui-semilight;
  left: 10px;
  top: 12px;
}

.btn-sm.btn-status:before {
  top: 9px;
}

.btn-status.status-active:before {
  border: #f78908;
  background: #f78908;
}

.btn-status.status-invoiced:before {
  border: #0ebd1a;
  background: #0ebd1a;
}

.btn-status.status-archived:before {
  border: $horizons-ui-light;
  background: $horizons-ui-light;
}

.btn-status.status-ready:before {
  border: #478cde;
  background: #478cde;
}

.btn-status.status-Unknown:before {
  border: #292a2c;
  background: #292a2c;
}

.btn-status.status-Compleet:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-job-RequestInvoice:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-job-PendingRequest:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-job-InvoiceCreated:before {
  border: #00ff00;
  background: #00ff00;
}

.btn-status.status-job-RequestFailed:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-requestvalidation:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-request_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-approve_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reject_pre_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-request_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-authorize_dossier:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reject_dossier_authorization:before {
  border: #0000ff;
  background: #0000ff;
}

.status-dossier-validate_financial_validation,
.status-dossier-reject_financial_validation,
.btn-status.status-dossier-reopen_financial_validation,
.btn-status.status-dossier-request_financial_validation {
  background: #f1f1f1;
}

.btn-status.status-dossier-request_financial_validation:before {
  border: #ffee00;
  background: #ffee00;
}

.btn-status.status-dossier-reject_financial_validation:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-validate_financial_validation:before {
  border: #00ff00;
  background: #00ff00;
}

.btn-status.status-dossier-reopen_financial_validation:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-check_out_job:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-unknown:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-newdossier:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-pendingvalidation:before {
  border: #0000ff;
  background: #0000ff;
}

.btn-status.status-dossier-reopeneddossier:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-validationrejected:before {
  border: #ff0000;
  background: #ff0000;
}

.btn-status.status-dossier-fitterinvoicevalidated:before {
  border: #ffee00;
  background: #ffee00;
}

.btn-status.status-dossier-active:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.btn-status.status-dossier-compleet:before {
  border: hsl(26, 100%, 50%);
  background: hsl(26, 100%, 50%);
}

.filter-block .btn-primary,
.panel-head .btn-primary {
  margin-left: 5px;
}

.header-page-title ul {
  padding: 0 10px;
}

.header-page-title ul li {
  list-style: none;
  font-size: 1.2rem;
  padding: 19px 0 0;
  font-family: $header-font-family;
  color: $glassdrive-primary-blue;
  font-weight: normal;
}

.header-page-title ul li a {
  text-transform: none;
  color: $glassdrive-primary-blue;
  font-weight: normal;
}

.header-page-title ul li span {
  font-size: 1rem;
}

.header-page-title .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.2rem;
  padding-left: 0.6rem;
  margin-bottom: 10px;
  content: "-";
}

.app-body .main {
  padding-top: 30px;
}

.app-body .main .breadcrumb {
  display: none;
}

.table-striped.table-hover tbody tr.active:hover {
  color: #fff !important;
}

.table-striped tbody tr.active,
.table-hover tr.active {
  background: $brand-primary !important;
  color: #fff;
}

.table-striped tbody tr.active span {
  color: #fff;
}

.table-hover tr:hover {
  background-color: #edf6fd !important;
}

.invoice-details-wrapper {
  margin: 15px 0;
}

.control-group button,
.control-group .btn {
  margin-left: 5px;
}

.qty-input {
  width: 60px;
  display: inline-block;
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.collapse-wrapper {
  padding: 15px;
  margin-bottom: 5px;
}

.drag-section {
  border: 1px dashed rgba(199, 197, 197, 0.933);
  margin: 15px 0;
  border-radius: 5px;
  min-height: 80px;
  background: #f2f2f3;
}

.uploadfile-list {
  border-bottom: 1px solid #eeee;
  padding: 10px 15px;
  background: #f1f1f1;
  border-radius: 2px;
}

.form-inline .form-group > label {
  margin-right: 5px;
}

.grandtotal-box {
  padding: 15px 10px 15px 0;
  background: $interface-semilight;
  margin: 0 0 15px 0;
  border-radius: 5px;
  width: 100%;
}

.accordion-wrapper .card-header {
  background-color: #ecf3f9;
  border: 1px solid #c9d0d7;
  border-radius: 5px;
}

.accordion-wrapper .panel-open .card-header {
  border-radius: 5px 5px 0 0;
}

.grandtotal-block .control-group {
  margin: 10px;
}

.sub-head span {
  font-size: 1.1rem;
}

.grandtotal-block {
  margin-bottom: 20px;
}

.accordion-wrapper .card {
  border: 1px solid $interface-extralight;
  background: #fff;
}

.accordion-wrapper .panel-title h3 {
  font-size: 1rem;
  display: inline-block;
}

.accordion-wrapper .panel-collapse.show .panel-body {
  background: #fff;
  border-top: 1px solid $interface-extralight;
}

.accordion-wrapper .panel-body .section-header {
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;
  color: $glassdrive-primary-blue;
}

.accordion-wrapper .panel-body .section-body {
  margin-bottom: 20px;
}

.tab-pane .card-block > .card-header {
  margin-bottom: 25px;
  padding-left: 0;
  font-size: 1.1rem;
  padding-top: 0;
}

.dossier-tab .nav-tabs .nav-item {
  margin-right: 10px;
  margin-bottom: 15px;
}

.dossier-tab .nav-tabs .nav-link.active {
  background: $horizons-primary;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

.dossier-tab .nav-tabs .nav-link.active:after {
  content: "";
  display: inline-block;
  background: transparent url("/assets/img/tab-arrow.png") no-repeat 0 0px;
  width: 14px;
  height: 13px;
  position: absolute;
  bottom: -18px;
  margin: 0 auto;
  left: 40%;
}

.dossier-tab .nav-tabs {
  border-bottom: 0;
}

.dossier-tab .tab-content {
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 1px 1px #e3e8ec;
}

.header-wrapper h4,
.panel-head h4.sub-head {
  font-size: 1.1rem;
  color: $glassdrive-primary-blue;
  padding-top: 5px;
}

.header-wrapper h4 span {
  font-size: 0.9rem;
}

.btn-secondary {
  background: #f6f8fa;
  border: 1px solid #a7bdd5;
}

.nav-tabs .nav-link, .nav-tabs .navbar .dropdown-toggle, .navbar .nav-tabs .dropdown-toggle {
  color: #94A0B2;
 
}

div.instruction-checkin-modal > * > ul.nav  {
  flex-wrap: nowrap !important;
}

a {
  white-space: nowrap;
} 
.btn-toggle-arrow {
  background: #7490af;
  border: 0;
  margin-left: 20px !important;
}

.btn:focus {
  outline: none !important;
  border: none;
}

.dossier-tab .nav-tabs .nav-link {
  background: #e9edf2;
  border-radius: 5px;
  position: relative;
  color: $horizons-primary;
  text-align: center;
  min-width: 90px;
  font-size: 0.82rem;
}

.dossier-tab .nav-link i {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.btn-toggle-arrow .fa-chevron-up {
  display: none;
}

.panel-open .btn-toggle-arrow .fa-chevron-down {
  display: none;
}

.panel-open .btn-toggle-arrow .fa-chevron-up {
  display: inline-block;
}

.accordion-wrapper .card {
  margin-bottom: 5px;
  border: 0;
}

.invoic-prd-label {
  font-size: 1rem;
}

.accordion-wrapper .panel-head {
  border-bottom: 0;
}

.accordion-wrapper .panel-body .panel-head {
  border-bottom: 1px solid $interface-extralight;
  padding: 10px 20px 0;
}

.accordion-wrapper .subtotal {
  font-size: 1rem;
  color: $horizons-primary;
}

.accordion-wrapper .panel-body {
  border: 1px solid #c9d0d7;
  padding: 15px 20px;
  background: #ecf3f9;
  border-top: 0;
  margin-top: -1px;
}

.btn-info.btn-add {
  background: $horizons-primary-light;
  color: #fff;
  font-size: 0.85rem;
  padding: 5px 10px;
  min-width: 145px;
}

td .form-group,
.card-header-inner .form-group {
  margin-bottom: 0.3rem;
}

.child-row-header {
  background: #e8f5fb !important;
  font-size: 0.9rem;
}

.child-row-header td {
  font-size: 1rem !important;
}

table tr.child-row-header td {
  padding-top: 7px;
  padding-bottom: 7px;
}

.accordion-wrapper table tr td,
.accordion-wrapper table tr td span {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.83rem;
}

.child-row-body td {
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.85rem;
}

.accordion-wrapper .panel-body .panel-body {
  background: #ffffff;
  border: 1px solid $interface-extralight;
  border-top: 0;
}

.subtotal td {
  background-color: $interface-semilight;
  color: $horizons-primary;
  font-size: 1.1rem !important;
  font-weight: bold;
  padding: 0.65rem !important;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body {
  background: #ecf3f9;
  border-top: 0;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body .panel-body {
  background: #fff;
  border-top: 0;
}

.table-striped.table-with-filter tbody tr:nth-of-type(odd),
.table-striped.table-with-filter tbody tr:nth-of-type(even) {
  background-color: transparent;
  border-bottom: 1px solid #efeeee;
}

.k-scheduler-views li.k-state-selected {
  background-color: $horizons-ui-dark;
}

.k-scheduler-layout .k-scheduler-table tr > td:hover {
  background: $horizons-ui-extralight;
}

.k-scheduler-layout .k-scheduler-table tr > td.unavailableHours:hover {
  background: #e8e8e8;
  cursor: no-drop;
}

.availability-modal .modal-header {
  background: $horizons-ui-semilight;
  padding: 10px 15px;
}

.availability-modal .modal-footer {
  padding: 10px 15px;
}

.availability-modal .modal-title {
  font-size: 1rem;
  color: $glassdrive-primary-blue;
}

.sidebar-compact .sidebar .sidebar-nav,
.sidebar .nav {
  width: auto;
}

.accordion-wrapper .pannel-wrapper {
  margin-bottom: 15px;
}

.accordion-wrapper .pannel-body {
  margin-bottom: 3px;
}

main.main {
  transition: none;
}

.agenda-info {
  float: right;
  padding: 15px 15px 10px;
  text-align: right;
}

.agenda-info > span {
  margin-right: 15px;
  font-size: 0.85rem;
  top: -3px;
  position: relative;
}

.agenda-info > span.repair-info:before {
  content: " ";
  display: inline-block;
  height: 12px;
  width: 16px;
  background: $horizons-primary-light;
  margin-right: 5px;
}

.agenda-info > span.replace-info:before {
  content: " ";
  display: inline-block;
  height: 12px;
  width: 16px;
  background: $horizons-ui-light;
  margin-right: 5px;
}

// .table-responsive .thead-light th,.table-responsive .thead-light th a{
//  word-break: break-all;
//  word-wrap: break-word;
//  white-space: pre-wrap !important;
// }

//Dossier Dashboard
.jobtype {
  min-width: 150px;
  display: inline-block;
}

.card-wrapper {
  background: #fbfbfb;
  border: 1px solid $interface-extralight;
}

.card-wrapper .card-body {
  padding: 5px 15px 15px;
}

.card-wrapper .card-header {
  background: transparent;
  padding: 10px 15px 0;
  border-bottom: 0;
}

.card-wrapper .card-header h3 {
  font-size: 1rem;
  float: left;
}

.btn-details-link {
  display: block;
  background: $horizons-ui-extralight;
  border: 1px solid $horizons-ui-semilight;
  padding: 8px 40px 8px 10px;
  position: relative;
  color: $glassdrive-primary-blue;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 0;
}

.job-details-row {
  display: block;
  background: $horizons-ui-extralight;
  border: 1px solid $horizons-ui-semilight;
  padding: 8px 40px 8px 10px;
  position: relative;
  color: $glassdrive-primary-blue;
  text-decoration: none;
  margin-bottom: 0;
}

.status-details {
  font-size: 0.85rem;
  color: $horizons-ui-light;
}

.status-details .status-text {
  font-weight: bold;
}

.status-details .status-text:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $horizons-primary-light;
}

.btn-details-link:hover {
  background: $horizons-ui-semilight;
  text-decoration: none;
  color: $glassdrive-primary-blue;
}

.info-box {
  padding: 8px 15px;
  font-size: 0.9rem;
  border: 1px solid #e9edf2;
  background: #fff;
  color: $horizons-ui-light;
}

.date-info {
  font-weight: bold;
  margin-left: 15px;
}

.btn-details-link .link-arrow-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.3rem;
}

.card-body {
  padding: 15px;
}

.progressbar-wrapper {
  margin: 5px 0 10px;
}

.urgent-text {
  color: #d10007;
  padding: 2px 5px;
  font-size: 0.8rem;
  font-weight: bold;
}

/* Dossire Damage */
.section-body {
  border: 1px solid #f5f7fb;
  padding: 15px 15px 5px;
  border-radius: 0 0 5px 5px;
}

.section-header {
  padding: 10px;
  background: #f6f8fa;
  margin-top: 35px;
  color: $glassdrive-primary-blue;
  margin-bottom: 0;
}

.douments-wrapper {
  max-height: 240px;
  overflow-y: auto;
}

.discount-input,
.price-input {
  min-width: 62px;
  padding: 5px 5px;
}

.discount-input {
  text-align: right;
}

.input-group-addon {
  padding-left: 6px;
  padding-right: 6px;
  min-width: 30px;
}

.input-group  {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
}

.input-group > .input-group-addon {
    padding-top: 12px !important;
    margin-bottom: 0;
    font-weight: normal;
    line-height: 1.25;
    color: #94A0B2;
    text-align: center;
    background-color: #E3E8EC;
    border-radius: 0.25rem;
}

.btn-request-auth {
  margin-bottom: 10px;
}

.col-lg-12 > .card > .card-header {
  padding: 10px 20px;
}

.card-header .card-actions {
  position: relative;
  top: 0;
  right: 0;
  float: right;
}

.card-header .card-actions a.btn-setting, .btn-setting {
  color: #fff;
  background-color: $horizons-primary;
  border-color: #065695;
  text-transform: uppercase;
  border-radius: 24px;
  height: 38px;
  line-height: 12px;
  margin-left: 5px !important;
  padding-top: 11px !important;
}

a.btn-setting:hover {
  background-color: $glassdrive-primary-blue;
}

.img-responsive {
  max-width: 100%;
}

.btn-img-link {
  display: block;
  height: 130px;
  text-align: center;
}

.btn-img-link img {
  max-width: 100%;
  max-height: 100%;
}

.document-image-wrapper {
  display: block;
  background: $horizons-ui-extralight;
  border: 1px solid $horizons-ui-semilight;
  color: $glassdrive-primary-blue;
  margin-bottom: 20px;
}

.document-image-wrapper p {
  padding: 5px 10px;
  border-bottom: 1px solid $horizons-ui-semilight;
  margin-bottom: 0;
}

.spinner-wrapper {
  position: relative;
}

body .ui-rating.ui-state-disabled {
  opacity: 0.7;
}

.ui-rating .fa-star {
  color: #fff000 !important;
  text-shadow: 1px 0 0 #352101, 0px 1px 0 #352101, -1px 0 0 #352101, 0px -1px 0 #352101;
}

.image-uploader-wrapper {
  min-height: 150px;
  border: 1px solid #f1f1f1;
  background: #f9f9f9;
  margin-bottom: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}

.image-uploader-wrapper .image-type-label {
  display: block;
  margin-top: 10px;
}

.uploaded-image-footer {
  margin-top: 5px;
}

.uploaded-image-footer .btn {
  margin-right: 5px;
}

.glyphicon-triangle-top {
  background: transparent url("/assets/img/sort_asc.png") no-repeat -5px -2px;
  width: 8px;
  height: 10px;
}

.glyphicon-triangle-bottom {
  background: transparent url("/assets/img/sort_desc.png") no-repeat -5px -10px;
  width: 8px;
  height: 10px;
}

.uploaded-image-header {
  padding-bottom: 10px;
}

.uploaded-image-footer {
  padding-top: 5px;
}

.single-image-display .uploaded-image-header {
  text-align: left;
}

.job-material-item {
  background-color: #f6f8fa;
  padding: 10px;
  margin-bottom: 20px;
}

.job-material-item .glass-position-title,
.job-material-item .job-title,
.job-material-item .components-list-title {
  margin-top: 15px;
}

.uploaded-image-box > a,
.document-image-wrapper .btn-img-link {
  position: relative;
  display: block;
}

.uploaded-image-box-pdf {
  font-size: 4vw;
}

.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  padding: inherit;
  padding-top: 0px;
  padding-bottom: 0px;
}

.zoom-icon {
  position: absolute;
  padding: 10px 15px;
  background: rgba(33, 33, 33, 0.4);
  border-radius: 3px;
  top: 0;
  bottom: 0;
  display: block;
  margin: auto;
  left: 0;
  right: 0;
}

.zoom-icon .fa {
  color: #fff;
  font-size: 1.2rem;
  margin-top: 25%;
}

.animate {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.uploaded-image-box:hover .zoom-icon,
.btn-img-link:hover .zoom-icon {
  opacity: 1;
}

.zoom-icon {
  opacity: 0;
}

.dossier-created-date {
  white-space: nowrap;
}

.subsection-heading h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0;
  padding-top: 15px;
}

.check-in-tab .nav-link.active,
.check-out-tab .nav-link.active {
  background: $horizons-primary;
  text-align: center;
  color: #fff;
  border-radius: 5px 5px 0 0;
}

.image-uploader-wrapper.upload-btn {
  min-height: 135px;
}

.uploaded-Document-name {
  display: none;
}

/* New Dark Theme Changes */
.table-block {
  margin: 15px 0;
}

.list-block .table-block {
  margin: 0;
}

body .table-block .ui-paginator {
  background-color: transparent;
  border: 0;
}

.tab-content .tab-pane {
  padding: 0;
}

.tab-pane .card-block {
  padding: 0;
}

.tab-pane .card-block > .card-header,
.card-header.validation-header {
  margin-bottom: 0;
  padding: 15px;
  font-size: 1.1rem;
  color: $horizons-primary;
}

.card-wrapper {
  background: transparent;
  border: 0;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
}

.accordion-wrapper .card-header {
  background-color: #ecf3f9;
  border: 0;
  border-radius: 0;
}

.accordion-wrapper .card {
  margin-bottom: 2px;
  border: 0;
}

.accordion-wrapper .panel-title {
  color: $horizons-primary;
}

.btn-toggle-arrow {
  background: transparent;
  color: $horizons-primary;
}

.btn-toggle-arrow {
  background: transparent;
  color: $horizons-primary;
}

.accordion-wrapper .panel-open .card-header {
  background-color: $brand-primary;
  border: 0;
  border-radius: 0;
  color: #fff;
}

.section-body .header-wrapper,
.header-wrapper h4 {
  font-weight: bold;
  font-size: 1rem;
  color: $horizons-primary;
}

.accordion-wrapper .panel-body .section-header {
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 0;
  color: $horizons-primary;
  margin-top: 10px;
  background: transparent;
  font-weight: bold;
  margin-bottom: 0;
}

.accordion-wrapper .panel-body .section-body {
  margin-bottom: 0;
  border-top: 0;
  border-radius: 0;
}

.accordion-wrapper .panel-collapse.show .panel-body {
  background: #fff;
  border: 0;
  margin-bottom: 0;
}

.accordion-wrapper .panel-open .panel-title,
.accordion-wrapper .panel-open .btn-toggle-arrow {
  color: #fff;
}

.tab-pane .card-footer {
  border-top: 0;
}

.section-header {
  margin-top: 0;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body {
  background: transparent;
  border-top: 0;
  padding: 15px;
}

.invoice-details-wrapper.accordion-wrapper .panel-collapse.show .panel-body .panel-body {
  padding: 15px;
}

.invoice-details-wrapper {
  margin: 0;
}

#invoice-accordion .pannel-wrapper .panel-head {
  padding: 10px 15px 0;
}

.panel-head h4.sub-head {
  color: $horizons-primary;
}

.invoice-details-wrapper .pannel-wrapper .panel-head {
  padding: 10px 15px 0;
}

.invoice-details-wrapper .pannel-wrapper .panel-head,
.invoice-details-wrapper .pannel-wrapper .panel-body {
  background: $interface-semilight !important;
}

.accordion-wrapper .panel-body .panel-head {
  border-bottom: 1px solid $interface-extralight;
  padding: 10px 0px 0;
  border: 0;
}

.k-scheduler-toolbar {
  background: #012949;
  color: #fff;
}

.card-header-inner {
  padding: 10px 15px;
}

.accordion-toggle .card-header-inner {
  padding: 2px;
}

#invoice-accordion .sub-head {
  margin-bottom: 0;
}

/*  Define the background color for all the ODD table columns  */
.k-scheduler-header-wrap .k-scheduler-table tr th.k-slot-cell:nth-child(odd):before {
  content: " ";
  display: inline-block;
  height: 10px;
  width: 10px;
  background: $horizons-primary-light;
  margin-right: 5px;
}

.k-scheduler-header-wrap .k-scheduler-table tr th.k-slot-cell:nth-child(even):before {
  content: " ";
  display: inline-block;
  height: 10px;
  width: 10px;
  background: #55697b;
  margin-right: 5px;
}

.k-view-week .k-link {
  border: 1px solid #fff;
  border-radius: 0 5px 5px 0;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .card-header {
  background-color: #ecf3f9;
  color: $brand-primary;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .panel-title {
  color: $brand-primary;
}

.instruction-page-wrapper.accordion-wrapper .panel-open .btn-toggle-arrow {
  color: $brand-primary;
}

.protocal-list-item {
  margin-bottom: 15px;
}

.new-dossier-link .fa {
  color: #1dd826 !important;
}

.subactive + li .active,
.subactive + li .active .fa {
  background: $brand-primary !important;
  color: #fff !important;
}

.summary-container .panel-body {
  min-height: 200px;
}

.k-scheduler-content {
  min-height: 600px;
}

.status-newdossier:before,
.status-Complete:before,
.status-complete:before,
.status-fitterinvoicevalidated:before,
.status-account_invoice_readytosend:before,
.status-financial_validated:before,
.status-data_collection_not_started:before,
.status-pre_authorization_approved:before,
.status-authorized:before,
.status-account_payment_received:before,
.status-status-closed:before,
.status-invoice_created:before,
.status-pro_forma_created:before,
.status-credit_note_created:before {
  background: $bg-color-ready;
  border: none;
}

.status-active:before,
.status-account_invoice_created:before,
.status-reopeneddossier:before,
.status-job_checked_out:before,
.status-financial_validation_reopened:before,
.status-account_invoice_created:before,
.status-data_collection_wip:before,
.status-appointment_request:before,
.status-pre_authorization_requested:before,
.status-credit_note_created:before,
.status-fee_calculated:before,
.status-fee_invoiced:before {
  background: $bg-color-inprogress;
  border: none;
}

.status-pendingRequest:before,
.status-requestcustomerinvoice:before,
.status-requestcentraldepartmentinvoice:before,
.status-pendingvalidation:before,
.status-pending:before,
.status-financial_validation_pending:before,
.status-account_invoice_scheduled:before,
.status-account_invoice_sent:before,
.status-account_invoice_pending:before,
.status-authorization_pending:before,
.status-authorization_read:before,
.status-credit_note_pending:before,
.status-account_invoice_pending_confirmation:before,
.status-order_pending:before,
.status-invoice_pending:before,
.status-pro_forma_pending:before,
.status-on_hold:before,
.status-credit_note_requested:before {
  background: $bg-color-pending;
  border: none;
}

.status-requestfailed:before,
.status-validationrejected:before,
.status-financial_validation_rejected:before,
.status-account_invoice_rejected:before,
.status-pre_authorization_rejected:before,
.status-authorization_rejected:before,
.status-account_invoice_blocked:before,
.status-cancelled:before,
.status-credit_note_rejected:before {
  background: $bg-color-rejected;
}

.appointment-time {
  font-weight: bold;
}

.file-list-container {
  padding: 5px;
  border-bottom: 1px solid #f1f1f1;
}

.file-list-container:last-child {
  border-bottom: 0;
}

.photo-filename {
  display: none;
}

.sidebar-compact .sidebar-nav .nav-link.active:after {
  display: none;
}

.sub-menu .nav-link {
  padding-left: 30px !important;
}

.card-tab-padding .tab-content {
  padding: 15px 10px;
}

.sidebar-compact .sub-menu .nav-link {
  padding-left: 0 !important;
}

.sidebar-compact .parent-menu .nav-link {
  position: relative;
  padding: 0 25px 0 0;
  margin: 0;
  line-height: 20px;
  white-space: nowrap;
  border-left: 0 !important;
}

.jobtype-separator {
  height: 15px;
  clear: both;
  width: 100%;
}

.sidebar-compact .sidebar .nav-link i {
  margin: 0 0.85rem 0 0 !important;
}

.sidebar-compact .nav-item.parent-menu .nav-link i {
  width: 35px;
}

.sidebar-compact .sub-menu {
  overflow: hidden !important;
}

.brand-tagline2 {
  display: block;
  line-height: 0;
  font-size: 0.72rem;
  text-align: right;
}

input.text-uppercase {
  text-transform: uppercase;
}

.username {
  font-size: 0.8rem;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  display: inline-block !important;
}

.gm-style .search-box {
  background: #fff;
  margin-top: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 1px solid #d8dde4;
  border-radius: 5px 5px 0 0;
}

.main > .container-fluid {
  height: 100%;
}

.dashboard-wrapper,
.iframe-wrapper {
  height: 100vh;
}

.dashboard-wrapper > h1 {
  font-size: 1.5rem;
}

.table-hover tfoot .pagination-footer:hover {
  background: transparent !important;
}

tfoot .pagination {
  align-items: center;
}

.modal-body .tab-content .tab-pane {
  padding: 1rem 1rem;
}

.card-header .btn-showall-document {
  margin-left: 20px;
  margin-bottom: 5px;
}

.silder-modal,
.silder-modal .modal-content.lightbox {
  height: 100%;
}

/*validation page*/
.stacked-buttons {
  margin-bottom: 10px;
  padding: 10px;
}

.stacked-button-wrapper {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 5px;
}

.selected-doc-title {
  margin: 20px -15px 30px;
  background: $horizons-ui-dark;
  color: #fff;
  padding: 20px 25px;
}

.selected-doc-title h2 {
  margin-bottom: 0;
  font-size: 1.1rem;
}

.validation-body .spinner-wrapper {
  padding-left: 10px;
}

.validation-body .documents-slider {
  border: 1px solid $horizons-ui-light;
}

.documetn-buttons-wrapper {
  padding: 10px;
}

/* Mignifier */
.ImageZoomFullContainer {
  display: none;
  position: absolute;
  border: 3px solid red;
  cursor: crosshair;
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 6px 5px 13px #989393;
  border-radius: 5px;
}

.ImageZoomContainer {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.ImageZoomFullContainer > img {
  position: absolute;
  max-width: none;
  max-height: none;
  top: 0;
  left: 0;
}

/* dashboard additionla notes */

.additional-notes {
  border: 1px solid $horizons-ui-semilight;
  margin-left: 15px;
  margin-right: 15px;
}

.additional-notes .card-header {
  padding: 10px 10px 5px;
  background: $horizons-primary-light;
  color: #fff;
  border-radius: 0;
}

.additional-notes .card-body {
  padding: 10px;
}

.notes-item {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.notes-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.notes-info {
  font-size: 0.82rem;
  opacity: 0.8;
}

.textarea-lg {
  min-height: 100px;
}

.header-button-item,
.header-button-wrapper > button {
  display: inline-block;
  margin-right: 10px;
}

.header-button-wrapper {
  text-align: right;
  position: relative;
}

.no-percentage {
  color: $horizons-primary;
}

/*Wizard optimaztion changes */

.ownership-option {
  padding-right: 10px;
}

.label-Private {
  float: left;
}

.bs-datepicker-body .week {
  background: #ffe3e3;
}

.bs-datepicker-body .weeks tr td:nth-last-child(2) {
  background: #e8e7e7;
}

.bs-datepicker-body .weeks tr td:last-child {
  background: #e8e7e7;
}

.appointment-btn-wrapper {
  margin: 30px 0 15px;
}

.summary-container .summary-row > label {
  font-weight: bold;
}

/* pagination changes */
tfoot .pagination {
  justify-content: center;
}

tfoot .pagination a.page-link {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5em 0.625em;
  min-width: 2em;
  color: #0275d8;
}

tfoot .pagination .disabled a.page-link {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5em 0.625em;
  min-width: 2em;
}

tfoot .pagination a.page-link.active {
  color: #ffffff;
  background-color: #0275d8;
}

tfoot .pagination .page-item:first-child a,
tfoot .pagination .page-item:last-child a {
  font-size: 0 !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

tfoot .pagination .page-item.disabled {
  padding: 10px;
}

tfoot .pagination .page-item:first-child a:before {
  content: "\f04a";
  font-size: 1rem;
  padding: 10px;
}

tfoot .pagination .page-item:last-child a:before {
  content: "\f04e";
  font-size: 1rem;
  padding: 10px;
}

tfoot .pagination.float-sm-right .page-item:first-child a,
tfoot .pagination.float-sm-right .page-item:last-child a {
  font-size: 0.9rem !important;
  display: inline-block;
  font: inherit;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1em;
}

tfoot .pagination.float-sm-right .page-item:first-child a:before {
  display: none;
}

tfoot .pagination.float-sm-right .page-item:last-child a:before {
  display: none;
}

tfoot .pagination.float-sm-right a.page-link {
  background-color: #fff;
  border-color: #f1f1f1;
  color: #0275d8;
}

.radio-wrapper input {
  box-shadow: none !important;
}

.header-badge-light {
  background: #f0f4f8;
  border-radius: 10px;
  padding: 6px 10px;
  color: #8191a0;
}

/* float Input Label -New UI changes  */
.float-input-wrapper .validation-error,
.float-wrapper .validation-error {
  position: absolute;
  font-size: 0.8em;
  bottom: -35px;
  height: 35px;
}

.float-wrapper .validation-error {
  line-height: 1.4em;
}

.form-group,
.float-input-wrapper {
  position: relative;
}

.radio-wrapper .input-warning-border:after {
  display: none !important;
}

.float-wrapper > small {
  position: absolute;
}

.col-md-6.col-lg-5.col-xl-4 > .input-warning-border:after {
  left: 14px;
}

label.float-label {
  position: absolute;
  top: 0.65rem;
  left: 0;
  -ms-transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  cursor: text;
  color: #757575;
  font-size: 1rem;
  padding-left: 15px;
  pointer-events: none;
}

input.active + label.float-label,
select.active + label.float-label,
textarea.active + label.float-label,
.custom-float-input.active + label.float-label,
label.custom-float-label {
  -webkit-transform: translateY(-140%);
  -ms-transform: translateY(-140%);
  transform: translateY(-140%);
  padding-left: 14px;
  font-size: 0.8rem;
  padding-left: 18px;
  top: 1.8rem;
}

textarea.active + label.float-label {
  -webkit-transform: translateY(-140%);
  transform: translateY(-140%);
  padding-left: 12px;
  font-size: 0.8rem;
  top: 1.56rem;
  width: 84%;
  margin-left: 7px;
  right: 20px;
  background: #fff;
  margin-top: 7px;
  padding-top: 3px;
}

input[type="date"].float-input,
input[type="datetime-local"].float-input,
input[type="email"].float-input,
input[type="number"].float-input,
input[type="password"].float-input,
input[type="search-md"].float-input,
input[type="search"].float-input,
input[type="tel"].float-input,
input[type="text"].float-input,
input[type="time"].float-input,
input[type="url"].float-input,
textarea.float-input,
.custom-float-input {
  padding-top: 21px;
  padding-bottom: 3px;
  box-shadow: none;
  border-color: #dce3e8;
}

select.float-select {
  padding-top: 21px;
  padding-bottom: 3px;
  box-shadow: none;
  border-color: #dfe7ec;
  padding-right: 25px;
  background-size: 10px 20px !important;
}

.float-select + .float-label {
  background: #fff;
  left: 7px;
  padding-bottom: 5px;
  width: 85%;
  pointer-events: none;
  padding-left: 7px;
}

.float-select:disabled + .float-label {
  background: #e3e8ec;
}

.float-select.active + .float-label {
  background: transparent;
  left: 0;
  padding-bottom: 0;
}

.block-label {
  display: block;
}

.hidden {
  display: none !important;
}

.float-wrapper label {
  margin-bottom: 0.1rem;
}

.ng-invalid.active {
  margin-bottom: 2.5rem;
}

.float-wrapper {
  margin-bottom: 1.6rem;
  display: block;
  float: none;
  padding: 0 5px;
}

.tabsectioncard-header {
  font-size: 1.25rem;
  padding-top: 10px;
  float: left;
}

.label-info {
  float: right;
  font-size: 0.8rem;
}

.validation-badge {
  width: 10px;
  height: 10px;
  background: #d83c3c;
  display: inline-block;
  margin-right: 5px;
}

.card-header.info-card-header {
  border-bottom: 0;
  margin-bottom: 15px;
  padding-left: 0;
}

.authorization-badge {
  width: 10px;
  height: 10px;
  background: #f5ac0e;
  display: inline-block;
  margin-right: 5px;
}

.subtitle {
  font-size: 1.15rem;
  color: $brand-primary;
}

.uploaded-image-block {
  width: 245px;
  margin-left: 15px;
  margin-bottom: 15px;
}

.sub-wrapper {
  padding-left: 27px;
  border-left: 2px dashed #e9eef3;
  margin-left: 17px;
  padding-bottom: 15px;
}

.form-completion-status {
  width: 35px;
  height: 35px;
  border: 4px solid #e9eef3;
  display: inline-block;
  border-radius: 50%;
  font-size: 0.9rem;
  text-align: center;
  padding-top: 7px;
  position: relative;
  margin-right: 10px;
}

.form-completion-status-procedure {
  // @extend .form-completion-status;
  margin-right: 5px;
}

.tabsectioncard-header span {
  font-size: 0.75rem;
  display: inline-block;
  top: -3px;
  position: relative;
  margin-left: 5px;
}

.infomenu-section-wrapper {
  margin-top: 15px;
  padding-top: 90px;
  margin-top: -90px;
}

.authorization-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #f5ac0e;
  border-radius: 5px;
}

.validation-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #d83c3c;
  border-radius: 5px;
}

.sending-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #3786e6;
  border-radius: 5px;
}

.quote-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #f5ac0e;
  border-radius: 5px;
}

.ngc-element {
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #d83c3c;
  border-radius: 5px;
}

.authorization-image,
.validation-image,
.ngc-image,
.sending-image {
  position: relative;
}

.sending-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #f5ac0e;
}

.authorization-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #f5ac0e;
}

.validation-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.ngc-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.quote-image:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 95%;
  top: 3px;
  left: 5px;
  border-radius: 5px 0 0 5px;
  background-color: #d83c3c;
}

.authorization-element.input-warning-border,
.sending-element.input-warning-border,
.validation-element.input-warning-border,
.quote-element.input-warning-border,
.ngc-element.input-warning-border {
  box-shadow: none;
}

.authorization-element.input-warning-border:after {
  background-color: #f5ac0e;
}

.validation-element.input-warning-border:after {
  background-color: #d83c3c;
}

.sending-element.input-warning-border:after {
  background-color: #3786e6;
}

.ngc-element.input-warning-border:after {
  background-color: #d83c3c;
}

.quote-element.input-warning-border:after {
  background-color: #f5ac0e;
}

label.input-warning-border.authorization-element:after,
label.input-warning-border.validation-element:after,
label.input-warning-border.ngc-element:after,
label.input-warning-border.quote-element:after {
  display: none;
}

.input-warning-border:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 75%;
  top: 6px;
  left: 9px;
  border-radius: 1px;
}

select {
  background-position: right 8px center !important;
}

select.default-select {
  color: $glassdrive-primary-blue;
  border: 1px solid #ccc !important;
}

.sub-header-wrapper h4 {
  margin-bottom: 15px;
  margin-top: 20px;
  padding-left: 5px;
}

.custom-card-footer {
  padding: 15px 45px;
}

.info-menu {
  box-shadow: 0 0 1px 1px #d2dee7;
}

.info-menu ul {
  margin-bottom: 0;
}

.info-menu .menu-item {
  display: block;
  padding: 0 25px;
  border-left: 2px solid #fff;
  background: #fff;
  color: $horizons-primary;
  cursor: pointer;
}

.info-menu .item {
  padding: 20px 0;
  border-bottom: 1px solid #ecf3f9;
  display: block;
}

.info-menu .menu-item:hover,
.info-menu .active {
  background: #fafbfc;
  border-left: 2px solid $horizons-primary-light;
  text-decoration: none;
}

.info-menu li:last-child .item {
  border-bottom: 0;
}

.info-menu .form-completion-status {
  padding-top: 4px;
}

.info-menu-wrapper {
  position: fixed;
  min-width: 285px;
  right: 50px;
  top: 35%;
}

input[type="text"],
textarea,
select {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none;
}

input[type="text"]:focus,
textarea:focus,
select:focus {
  box-shadow: 0 0 5px rgba(81, 204, 238, 0.6);
  -ms-box-shadow: 0 0 5px rgba(81, 204, 238, 0.6);
}

.modal-lg.store-locator-modal {
  max-width: 80%;
}

.store-locator-iframe {
  position: relative;
  width: 100%;
  height: 750px;
  top: 0px;
  left: 0px;
  border: none;
}

.edit-dossier-iframe {
  position: relative;
  width: 100%;
  height: 750px;
  top: 0px;
  left: 0px;
  border: none;
}

.email-text {
  word-break: break-word;
}

.dossiers-overview .nav-link.active {
  pointer-events: initial;
}

.menu-group-label {
  color: #779fca;
  font-size: 14px;
  letter-spacing: 0.22px;
  line-height: 20px;
  padding-left: 20px;
  margin-top: 20px;
}

.sidebar .nav .nav-item .nav-link:hover {
  border-radius: 5px;
  background: rgb(30, 58, 87) !important;
  background: -moz-linear-gradient(90deg, rgba(30, 58, 87, 1) 42%, rgba(255, 255, 255, 0) 100%) !important;
  background: -webkit-linear-gradient(90deg, rgba(30, 58, 87, 1) 42%, rgba(255, 255, 255, 0) 100%) !important;
  background: linear-gradient(90deg, rgba(30, 58, 87, 1) 42%, rgba(255, 255, 255, 0) 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1e3a57", endColorstr="#ffffff", GradientType=1) !important;
}

.navseprator {
  position: relative;
}

.navseprator:before {
  content: "";
  width: 100%;
  height: 28px;
  position: absolute;
  top: 0;
  background: rgb(3, 25, 48);
  background: -moz-linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#031930", endColorstr="#ffffff", GradientType=1);
  z-index: 99;
}

.navseprator-rev {
  position: relative;
}

.navseprator-rev:before {
  content: "";
  width: 100%;
  height: 28px;
  position: absolute;
  bottom: 0;
  background: rgb(3, 25, 48);
  background: -moz-linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(180deg, rgba(3, 25, 48, 1) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#031930", GradientType=0); /* Changez "startColorstr" et "endColorstr" */
  z-index: 99;
}

.sidebar-compact .menu-group-label,
.sidebar-compact .userdetails-wrapper {
  display: none;
}

.sidebar-compact .sidebar .nav .nav-item {
  padding: 0;
}

@include media-breakpoint-up(md) {
  .sidebar-compact .logowrapper,
  body.sidebar-compact .sidebar {
    display: none;
  }

  .sidebar-compact.sidebar-fixed .main,
  .sidebar-compact.sidebar-fixed .app-footer {
    margin-left: 0px;
  }
}

.sidebar .nav .nav-item .nav-link:hover .ui-stroke {
  stroke: #cfe5fc;
}

.sidebar .nav .nav-item .nav-link:hover .ui-fill,
.sidebar .nav .nav-item .nav-link:hover #Combined-Shape {
  fill: #cfe5fc;
}

.sidebar .nav .nav-item .nav-link:hover i.fa {
  color: #cfe5fc !important;
}

.sidebar .nav .nav-item .nav-link {
  color: #f8f9fa;
}

.sidebar .nav .nav-item .nav-link i {
  color: #779fca;
}

.time-zone {
  font-size: 80%;
  padding: 7px 10px 0 16px;
  opacity: 0.5;
}

.userdetails-wrapper .btn:focus {
  box-shadow: none;
}

.top-spacing {
  top: -2px;
  position: relative;
}

.sidebar-fixed-nav .nav-link,
.sidebar-fixed-nav .navbar .dropdown-toggle,
.navbar .sidebar-fixed-nav .dropdown-toggle {
  font-size: 1rem;
}

.sidebar-fixed-nav {
  padding-bottom: 15px;
}

.btn-link.dropdown-toggle:hover,
.btn-link.dropdown-toggle:focus,
.btn-link.dropdown-toggle:hover span,
.btn-link.dropdown-toggle:focus span {
  text-decoration: none;
}

// .invoice-details-wrapper.fs-section th.mounting-type ,
// .invoice-details-wrapper.fs-section td.svg-icon svg {
//   display: none;
// }

#invoice-accordion .sub-head {
  padding-bottom: 10px;
}

//table with header style
.table-wrapper {
  padding: 10px;
}

.table-list-wrapper {
  padding: 15px;
}

.notification-list-wrapper {
  background: $brand-neutral;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
  color: $interface-dark;
}

.notification-list-wrapper ul {
  margin-bottom: 0;
}

.table-panel-body {
  background: $interface-semilight;
  border-radius: 5px;
}

.table-panel-head {
  padding: 10px 15px;
  border-bottom: 1px solid $interface-extralight;
  border: 0;
}

.table-panel-head h4.sub-head {
  color: $interface-dark;
  font-size: 1.1rem;
  padding-top: 5px;
}

.customer-sub-header {
  color: $interface-extradark;
  font-size: 1rem;
  padding-top: 5px;
}

.table-wrapper .subtotal span {
  font-size: 0.85em;
}

.table-wrapper tbody {
  font-size: 0.83rem;
}

.grandtotal-block {
  padding: 15px 0 0;
}

.actions-buttons {
  padding: 15px;
}

.panel-header {
  background-color: #031930;
  border: 0;
  border-radius: 0;
  color: #fff;
  padding: 10px 20px;
}

.label-id {
  float: left;
  color: #fff;
}

.label-total {
  float: right;
  color: #fff;
}

.panel-detail-wrapper {
  padding: 20px 15px;
}

.customer-details {
  padding: 10px 0;
  color: $interface-extradark;
}

.customer-details label {
  display: inline-block;
  margin-bottom: 5px;
  color: $interface-semidark;
  font-size: 0.83rem;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: 0;
  min-height: 40px;
}

.total-amount {
  border-top: 1px solid #eee;
  padding-top: 8px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-form-wrapper.grey-box {
  padding: 20px 10px;
  background: $interface-semilight;
  border-radius: 5px;
}

.custom-form-wrapper.box {
  padding: 20px 10px;
  border-radius: 5px;
}

.msg-primary,
.msg-info {
  border: 1px solid lighten($brand-info, 20%);
  background: lighten($brand-info, 30%);
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-success {
  border: 1px solid lighten($brand-success, 20%);
  background: lighten($brand-success, 35%);
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-warning {
  border: 1px solid lighten($brand-warning, 20%);
  background: lighten($brand-warning, 35%);
  padding: 5px 8px;
  border-radius: 5px;
}

.msg-danger {
  border: 1px solid lighten($brand-danger, 20%);
  background: lighten($brand-danger, 35%);
  padding: 5px 8px;
  border-radius: 5px;
}

.floar-wrapper-alignment {
  padding-left: 8px;
}

.no-validation-margin .float-wrapper {
  margin-bottom: 0.8rem;
}

.custom-input-serach-dropdown {
  height: 116px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 12px;
  padding: 5px 10px;
  position: absolute;
  width: 92%;
  background: #fff;
  box-shadow: 3px 4px 7px 0px #e0dcdc;
  z-index: 99;
  border: 1px solid #efeeee;
}

.custom-input-serach-dropdown article {
  cursor: pointer;
  display: block;
  padding: 2px 4px;
}

.custom-input-serach-dropdown article:hover {
  background-color: #f7f7f7;
}

.companyname-input-loading {
  background: #fff url(/assets/img/select2-spinner.gif) no-repeat 98% 7px;
}

textarea.active:disabled + label.float-label,
textarea.active:read-only + label.float-label {
  background: transparent;
  margin-top: 7px;
  padding-top: 3px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* lic plate search */
.lic-result-item {
  background: #eaeff5;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 10px;
}

.lic-info-box {
  border-radius: 5px;
  padding: 5px 10px;
  background: #d1dae2;
  margin-top: 5px;
}

.header-badge-dark {
  background: #d1dae2;
  border-radius: 10px;
  padding: 4px 10px;
  color: #516273;
  font-size: 0.75rem;
  display: inline-block;
  position: relative;
  margin-left: 5px;
}

.lic-checkbox-wrapper {
  padding: 10px;
}

.modal-dialog-centered {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  @media (max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}

.page-margin-wrapper {
  margin: 0 -30px;
}

.page-margin-wrapper .card-header {
  font-size: 1.5em;
  padding: 0 10px 30px 30px;
  border-bottom: 0;
}

.page-block-wrapper {
  padding: 0 30px 20px;
}

.subsections-title {
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.block-grey {
  background-color: $horizons-ui-semilight;
}

.block-white {
  background-color: #ffffff;
  padding: 20px 30px;
  margin-top: 10px;
}

.border-block-white {
  background-color: #ffffff;
  border: 1px solid $interface-light;
  border-radius: 3px;
  padding: 20px;
  margin-top: 10px;
}

.page-block-wrapper.block-grey {
  padding-top: 15px;
  padding-bottom: 15px;
}

.block-white .table-panel-body {
  background: #fff;
  border-radius: 5px;
}

.block-white .table-panel-head {
  padding: 0 0 5px;
}

.block-white .actions-buttons {
  padding: 10px 0;
}

.detail-container-gray {
  background-color: $interface-semilight;
  padding: 15px 20px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.custom-panel .panel-header {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 1rem;
  font-weight: bold;
  padding: 0 5px;
}

.column-header,
section .section-header {
  color: $header-text;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 25px;
}

.block-grey {
  background-color: $interface-extralight;
  border: 1px solid $interface-light;
  border-radius: 3px;
  padding: 20px;
  margin: 0 0 30px;
}

.block-white-rounded {
  background-color: #fff;
  border: 1px solid $interface-light;
  border-radius: 3px;
  padding: 20px;
  margin: 0 0 30px;
}

.svg-icon {
  vertical-align: middle;
  padding-right: 5px;
}

.block-white-rounded label {
  color: $label-text;
  margin-bottom: 0.25rem;
}

.input-content {
  color: $header-text;
}

.table-wrapper-white {
  border: 1px solid $interface-light;
  border-radius: 3px;
  background: #ffffff;
  margin-bottom: 20px;

  .table {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 0;

    td {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .subtotal td {
      padding: 0.75rem !important;
    }

    .thead-light th,
    .subtotal.thead-light span {
      color: $interface-dark;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }

  .grandtotal td span {
    font-size: 0.75rem;
  }

  .grandtotal td {
    background-color: $interface-dark;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.65rem !important;
  }
}

.document-block .upload-box {
  background: $interface-extralight;
  border: 1px solid $interface-light;
  padding: 20px 10px 0;
  margin-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  min-height: 177px;

  .filetype {
    min-height: 95px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .btn-primary {
    background: $glassdrive-secondary-link;
    border: 1px solid $glassdrive-secondary-link;
    border-radius: 24px;
    min-width: 80px;
    font-size: 0.9rem;
    color: #fff;
  }

  button.btn {
    margin-bottom: 0.5rem;
  }

  .btn-close {
    background: #fff;
    border: 1px solid #f1f1f1;
    position: absolute;
    top: -13px;
    right: 0px;
    width: 25px;
    box-shadow: 0 5px 20px 0 rgba(33, 34, 49, 0.12);
    height: 24px;
    min-width: 24px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    font-size: 0.6rem;
    font-weight: bold;
    cursor: pointer;
  }
}

.upload-disabled .btn {
  pointer-events: none;
  opacity: 0.2;
}

.labelCalculationProgress {
  float: right;
  font-size: 1rem;
  padding: 0px 20px;
  position: absolute;
  right: 15px;
  background: $brand-danger;
  border-radius: 0 0 5px 5px;
  color: #fff;
}

.fs-invoice-list .fs-list {
  display: block !important;
}

.fs-invoice-list .fs-cd-list {
  display: none;
}

.invoice-document-details {
  margin-bottom: 20px;
}

.invoice-document-details label {
  color: $interface-dark;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0;
}

.invoice-document-details p {
  color: $interface-dark;
  font-size: 0.75rem;
  margin-bottom: 3px;
}

.document-block-section {
  margin-bottom: 64px;
}

.no-material-wrapper {
  padding: 10px 30px;
}

h3.column-header + h3.column-header {
  display: none;
}

.agenda-info.input-info {
  position: absolute;
  top: -5px;
  right: -26px;
}

.input-info .agenda-info-wrap {
  min-width: 450px;
}

.hideTitle .column-header {
  display: none;
}

.fs-invoice-list.hideTitle {
  margin-top: -50px;
}

.card-footer .header-button-wrapper .btn-request-auth,
.btn-dossier_request_reject,
.btn-dossier_request_cancel {
  display: none;
  background-color: $interface-semilight;
  color: $interface-dark;
}

.card-footer .header-button-wrapper .btn-dossier_request_reject,
.card-footer .header-button-wrapper .btn-dossier_request_cancel {
  display: inline-block;
  background-color: $interface-semilight;
  color: $interface-dark;
  border: 0px;
}

.auth-step1-module {
  .toast-bottom-right {
    display: none;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }

  .tab-content {
    margin-top: 0;
    border: none;
    padding-top: 15px;
  }

  a.nav-link {
    pointer-events: none;
    color: $horizons-ui-semilight;
    border-width: 0 0 0.3rem 0;
    border-bottom: 0.3rem solid $horizons-ui-semilight !important;
    padding: 1rem 0;
  }

  a.nav-link.active {
    padding: 1rem 0;
    color: $glassdrive-primary;
    border-bottom: 0.3rem solid $glassdrive-primary !important;
    cursor: pointer;
    border-width: 0 0 0.3rem 0;
  }

  .nav-item {
    padding: 0 5px;
  }

  .nav-tabs {
    border: none;
  }
}
/*-========================================================== */
/* Media Query */
/*-========================================================== */

@include media-breakpoint-up(md) {
  .sidebar-fixed .sidebar {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -60px;
    padding-top: 60px;
  }

  .app-footer .brand-tagline {
    color: $glassdrive-primary;
    float: right;
    margin-top: -15px;
  }

  .logowrapper {
    border-right: 0;
    background: $brand-primary;
    height: 74px;
  }

  .col-lg-12 > .card > .card-header {
    padding: 10px 20px;
    line-height: 33px;
  }

  .hide-filterbutton {
    position: absolute;
    margin-top: 3px;
    right: 20px;
    z-index: 1;
  }

  select.form-control {
    background-size: 15px 20px !important;
  }

  .protocal-list-item .btn {
    margin-top: 30px;
  }
}

@include media-breakpoint-up(xl) {
  .align-to-menu {
    position: absolute;
    right: 0;
  }

  .glassdrive-form .btn.btn-sm {
    padding: 9px 25px !important;
  }

  .page-margin-wrapper .card-header {
    padding: 0 10px 30px 40px;
  }

  .page-block-wrapper {
    padding: 0 40px 20px;
  }

  .block-white {
    padding: 20px 40px;
  }

  .scroll-to-top .btn {
    padding: 8px 11px;
    position: relative;
    margin-right: -8px;
    border-radius: 0.25rem;
  }
}

@include media-breakpoint-down(xl) {
  .table-block .table th a.text-nowrap {
    white-space: normal !important;
    font-size: 0.85rem;
  }

  .table-block .table td {
    font-size: 0.9rem;
  }

  .filter-block .form-control {
    margin-bottom: 10px;
  }
}

@include media-breakpoint-down(lg) {
  .main .container-fluid {
    padding: 0 15px;
  }

  .page-margin-wrapper {
    margin: 0 -15px;
  }

  .float-wrapper {
    margin-bottom: 1.85rem;
  }

  .float-wrapper .validation-error {
    line-height: 1.4em;
  }

  .dossier-tab .nav-tabs .nav-link {
    font-size: 0.73rem;
    min-width: 77px;
    padding: 10px 5px;
  }

  .dossier-tab .nav-tabs .nav-item {
    margin-right: 3px;
    margin-bottom: 15px;
  }

  .validation-body .documents-slider {
    overflow: auto;
  }

  .show-scroll.scroll-to-top {
    right: 10px !important;
    z-index: 99999;
  }

  .scroll-to-top .btn {
    padding: 8px 11px;
    position: relative;
    margin-right: -8px;
  }

  .scroll-to-top {
    bottom: 160px !important;
  }

  .info-menu-wrapper {
    position: fixed;
    min-width: 220px;
    right: 38px;
  }

  .store-locator-iframe {
    height: 580px;
  }

  .modal-lg.store-locator-modal {
    max-width: 90%;
  }

  .dossier-tab .nav-tabs {
    flex-wrap: wrap;
  }

  .dossier-tab .nav-tabs .nav-link.active:after {
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }
}

@media screen and (max-width: 1023px) {
  .navseprator:before {
    display: none;
  }

  .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }

  .k-scheduler-dayview .k-scheduler-table td,
  .k-scheduler-table th {
    padding: 9px;
  }
}

@include media-breakpoint-down(md) {
  .protocal-list-item .form-control {
    margin-bottom: 15px;
  }

  .protocal-list-item {
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
  }

  .k-scheduler-header-wrap th.k-slot-cell {
    font-size: 0.75rem;
  }

  .k-slot-cell {
    font-size: 0.75rem;
  }

  .k-scheduler-table th {
    font-size: 0.8rem;
  }

  .dossier-tab .nav-tabs .nav-link {
    font-size: 0.75rem;
    min-width: 77px;
    padding: 10px 5px;
  }

  .dossier-tab .nav-tabs .nav-item {
    margin-right: 3px;
    margin-bottom: 15px;
  }
  // header.navbar .navbar-brand {
  //   background-size: 20%;
  //   padding-top: 2px;
  //   background: transparent url(/assets/img/Horizons-White.png) no-repeat 45% 9px;
  // }

  header.navbar {
    height: 74px;
  }

  .sidebar-fixed .logowrapper {
    display: none;
  }

  .sidebar-mobile-show .logowrapper {
    display: block;
  }

  .header-page-title ul li {
    padding: 26px 0 0;
  }

  .check-in-tab .image-box {
    overflow: scroll;
  }
  // .app-header .navbar-toggler {
  //   position: relative;
  // }

  .summary-container .panel-body {
    min-height: 250px;
  }

  .sidebar-mobile-show .main {
    margin-right: auto !important;
    margin-left: 240px !important;
  }

  .logowrapper {
    width: 240px;
  }
}

@include media-breakpoint-down(sm) {
  .wrapper-scroll-totop {
    display: none;
  }

  .username {
    max-width: 200px;
  }

  .logowrapper {
    padding-left: 65px;
    width: 240px;
  }

  .app-body .main {
    width: 100%;
  }
  // .app-header .navbar-toggler {
  //   position: relative;
  // }

  .sm-block {
    display: block;
  }

  .section.summary {
    padding: 15px;
  }

  .text-center-mobile {
    text-align: center;
  }

  .summary .sm-block {
    margin-bottom: 8px;
  }

  .logowrapper {
    background: $brand-primary;
  }

  .damage-images-container .damage-image-item image-Windscreen {
    margin: 0;
  }

  .info-menu-wrapper {
    display: none;
  }

  .table-responsive.dossier-overview-table:after,
  .table-scoll-shadow table:after,
  .table-responsive.table-shadow:after {
    content: "";
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: -5px 5px 19px 1px #7e7c7c, 5px -1px 0px 5px #ffffff;
    position: absolute;
    width: 1px;
    background: #dedcdc;
    pointer-events: none;
  }

  .table-scoll-shadow {
    position: relative;
  }
}

@include media-breakpoint-down(xs) {
  .brand-tagline {
    display: none;
  }

  .tab-pane > .card-block {
    padding: 5px;
  }

  .app-footer.logo_portugal {
    font-size: 0.7rem;
  }

  .logowrapper {
    width: 200px;
  }

  header.navbar .navbar-brand {
    background-size: 81%;
  }

  .dossier-overview-table td:nth-child(5),
  .dossier-overview-table th:nth-child(5),
  .dossier-overview-table td:nth-child(6),
  .dossier-overview-table th:nth-child(6),
  .dossier-overview-table td:nth-child(7),
  .dossier-overview-table th:nth-child(7),
  .dossier-overview-table td:nth-child(8),
  .dossier-overview-table th:nth-child(8),
  .dossier-overview-table td:nth-child(9),
  .dossier-overview-table th:nth-child(9) {
    display: none;
  }

  .file-list-container .btn-only-icon {
    padding: 5px !important;
    background: $horizons-ui-extralight !important;
  }

  .file-list-container {
    padding-bottom: 8px;
    margin-bottom: 5px;
  }

  .file-list-container .col-sm-1,
  .file-list-container .col-sm-2 {
    display: inline-block;
    margin-left: 10px;
    float: none;
    width: auto;
  }

  .btn-dossier-detail,
  .or-text {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .instruction-checkout-modal,
  .instruction-checkin-modal {
    max-width: 670px;
  }

  .instruction-checkout-modal,
  .instruction-checkin-modal {
    .tab-content .tab-pane {
      padding: 0.3rem 1rem;
    }

    .modal-header {
      padding: 5px 15px;
    }

    .modal-body {
      padding: 15px 15px 0;
    }
  }
}

select.validation-Green {
  border: 2px solid green !important;
}

select.validation-Blue {
  border: 2px solid blue !important;
}

select.validation-Yellow {
  border: 2px solid yellow !important;
}

select.validation-sending-element {
  border: 2px solid blue !important;
}

.new-dossier-modal-wrapper {
  padding: 15px 30px;
}

/* Chat containers */
.chat-container {
  border: 1px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin: 8px 0;
}

/* Darker chat container */
.chat-darker {
  border-color: #ccc;
  background-color: #ddd;
}

/* Clear floats */
.chat-container::after {
  content: "";
  clear: both;
  display: table;
}

.chat-text-right {
  margin: 0;
  text-align: right;
}

.chat-text-left {
  margin: 0;
  text-align: left;
}

/* Style time text */
.chat-time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.chat-time-left {
  float: left;
  color: #999;
}

.chat-tick-send {
  color: #999;
}

.chat-tick-processed {
  color: #5ed6e4;
}

.chat-tick-error {
  color: #d05050;
}

.dossier-overview-table tr,
.tr-pointer tr {
  cursor: pointer;
}

.check-container {
  height: 300px;
  width: 100%;
  display: block;
  overflow-x: auto;

  .form-check {
    display: initial;
  }
}

.import-list-container {
  height: 300px;
  width: 100%;
  display: block;
  overflow-x: auto;
  font-size: 80%;

  thead {
    font-weight: bold;
  }
}

/* New UI Common Styling Changes - start  */
/*---------------------------------------------*/

.modal-sucess-message {
  text-align: center;
  padding: 25px 0;
}

.message-block {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 0;
}

.modal-type-Replace .modal-footer {
  border-top: 0;
  display: block;
}

.modal-type-Replace .modal-title {
  color: $glassdrive-primary-blue;
}

.modal-type-Replace .modal-header {
  border-bottom: 0;
}

.modal-type-Replace > .modal-content {
  padding: 20px;
  overflow: hidden;
  border-left: 3px solid #F8DC93;
}

.glassdrive-form .btn {
  padding: 9px 38px;
  border-radius: 24px;
  min-width: 100px;
}

.glassdrive-form .btn.btn-md {
  padding: 9px 20px;
  border-radius: 24px;
  min-width: 100px;
  font-size: 0.95rem;
}

.glassdrive-form .btn.btn-xs {
  padding: 5px 10px;
  border-radius: 24px;
  min-width: 80px;
  font-size: 0.75rem;
}

.glassdrive-form .btn.btn-sm {
  padding: 9px 15px;
  border-radius: 24px;
  min-width: 80px;
  font-size: 0.9rem;
}

.glassdrive-form .btn-primary {
  border-color: $glassdrive-primary;
  background: $glassdrive-primary;
  color: $interface-white;
}

.glassdrive-form .btn-primary:hover {
  box-shadow: 0px 2px 0 darken($glassdrive-primary, 10%);
}

.glassdrive-form .btn-primary[disabled] {
  border-color: #b3cce0;
  background: #b3cce0;
}

.glassdrive-form .btn-primary-light, .btn-primary-light {
  border-color: $glassdrive-secondary-link;
  background: $glassdrive-secondary-link;
  color: $interface-white;
}

.glassdrive-form .btn-primary-light:hover, .btn-primary-light:hover {
  box-shadow: 0px 2px 0 darken($glassdrive-secondary-link, 20%);
}

.glassdrive-form .btn-primary-light[disabled], .btn-primary-light[disabled] {
  border-color: lighten($glassdrive-secondary-link, 20%);
  background: lighten($glassdrive-secondary-link, 20%);
}

.glassdrive-form .form-control[disabled] {
  background-color: $interface-extralight !important;
}

.glassdrive-form .btn-secondary {
  border-color: $glassdrive-secondary;
  background: $glassdrive-secondary;
  color: $interface-white;
}

.glassdrive-form .btn-secondary:hover {
  box-shadow: 0px 2px 0 darken($glassdrive-secondary, 10%);
}

.glassdrive-form .btn-primary[disabled] {
  border-color: #b3cce0;
  background: #b3cce0;
  box-shadow: none;
}

.glassdrive-form .btn-default,
.glassdrive-form .btn-secondary-light,
.btn-default {
  border-color: $interface-semilight;
  background: $interface-semilight;
  color: $interface-dark;
}

.glassdrive-form .btn-secondary-light:hover,
.glassdrive-form .btn-default:hover,
.btn-default:hover {
  box-shadow: 0px 2px 0 darken($interface-semilight, 10%);
}

.glassdrive-form .btn-secondary-light[disabled],
.glassdrive-form .btn-default[disabled],
.btn-default[disabled] {
  color: #bbc6d0;
  box-shadow: none;
}

.glassdrive-form .btn-success {
  border-color: $brand-success;
  background: $brand-success;
  color: $interface-white;
}

.glassdrive-form .btn-success:hover {
  box-shadow: 0px 2px 0 darken($brand-success, 10%);
}

.glassdrive-form .btn-success[disabled] {
  border-color: #bef5d3;
  background: #bef5d3;
  color: #99caab;
  box-shadow: none;
}

/* Custom form for New UI */
.glassdrive-form input[type="text"].float-input {
  border: 1px solid $interface-light;
  box-shadow: none;
}

.input-highlight .form-control {
  border: 1px solid $brand-danger !important;
}

.input-highlight-success .form-control {
  border: 1px solid $brand-success !important;
}

.input-highlight-warning .form-control {
  border: 1px solid $brand-warning !important;
}

.input-highlight-error .form-control {
  border: 1px solid $brand-danger !important;
}

.input-highlight-success .fa-check {
  display: none;
}

.glassdrive-form label.float-label {
  color: #9aadbc;
}

.label-error {
  color: $brand-danger;
  padding: 1px 10px 0;
  background: #fff0f0;
  border-radius: 0 0 3px 3px;
  font-size: 0.7rem;
}

.glassdrive-form.modal-dialog .ng-invalid,
.glassdrive-form.modal-dialog .ng-invalid.active {
  margin-bottom: 20px;
}

.glassdrive-form input[type="text"].form-control[readonly],
.glassdrive-form input[type="date"].form-control[readonly],
.glassdrive-form input[type="number"].form-control[readonly],
.glassdrive-form input[type="email"].form-control[readonly] {
  background-color: $interface-extralight;
  opacity: 1;
  cursor: not-allowed;
}

.glassdrive-table .table .thead-light th {
  background-color: $interface-semilight;
  color: $interface-dark;
  font-size: 0.9rem;
}

.glassdrive-table.table-block .table th a.text-nowrap {
  font-size: 0.85rem;
}

.glassdrive-table .ui-paginator .ui-paginator-page.ui-state-active {
  border-radius: 50% !important;
  width: 37px;
  color: $interface-dark !important;
  border: 1px solid #fff !important;
  background: #fff !important;
}

.glassdrive-table .ui-paginator .ui-paginator-pages .ui-paginator-page {
  border-radius: 50% !important;
  width: 37px;
  border: 1px solid $interface-light;
  color: $interface-dark !important;
}

.glassdrive-table .ui-paginator .ui-corner-all {
  border-radius: 50% !important;
  width: 37px;
  border: 1px solid $interface-light;
  color: $interface-dark !important;
}

.glassdrive-table .table-hover tfoot tr,
.glassdrive-table .table-hover tfoot tr:hover {
  background-color: #fff !important;
}

.glassdrive-search.search-block {
  padding: 0;
}

.glassdrive-search .form-control {
  margin-bottom: 0;
  border-right: 0;
}

.glassdrive-search .input-group-addon {
  background: #fff;
  cursor: pointer;
}

.glassdrive-table .table-striped {
  border: 0;
}

.verticle-group-input .float-wrapper {
  margin: 0;
}

.verticle-group-input {
  margin-bottom: 20px;
}

.uploaded-file-wrapper {
  background-color: $interface-extralight;
  border-radius: 3px;
  padding: 20px;
  @include clearfix;
  min-height: 85px;
  margin-bottom: 20px;
}

.uploaded-file-wrapper .file-size {
  display: block;
  color: $interface-semidark;
  font-size: 0.8em;
}

.uploaded-file-wrapper .file-icon {
  width: 15%;
  text-align: center;
  padding-top: 5px;
}

.uploaded-file-wrapper .file-name {
  width: 70%;
}

.file-full-name {
  font-weight: bold;
  color: $glassdrive-primary;
  font-size: 0.9rem;
}

.uploaded-file-wrapper .delete-file-icon {
  width: 15%;
  text-align: right;
  padding-top: 5px;
}

.btn-icon,
.btn-icon-light,
.btn-icon-dark,
.btn-icon-white {
  background: transparent;
  width: 29px;
  height: 28px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

.btn-text-underline {
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  color: $glassdrive-secondary-link;
  text-decoration: underline;
}

.btn-dark-text-underline {
  background: transparent;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  color: $header-text;
  text-decoration: underline;
}

.btn-dark-text-disable {
  background: transparent;
  border: 0;
  border-radius: 50%;
  color: $header-text;
  pointer-events: none;
}

.btn-text-underline:hover {
  text-decoration: underline;
}

.btn-icon-light {
  color: $interface-semidark;
}

.btn-icon:hover {
  background: #fff;
}

.btn-icon-dark:hover {
  background: $interface-dark;
}

.btn-icon-white:hover {
  background: #fff;
}

.btn-icon-light:hover {
  background: $interface-semilight;
}

.light-text {
  color: $interface-semidark;
}

.tooltip-inner {
  background-color: $glassdrive-secondary-link;
}

.tooltip.tooltip-top .tooltip-inner::before,
.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: $glassdrive-secondary-link;
}

.thumb-section li {
  height: 65px;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.thumb-section li.active {
  width: 72px;
  height: 65px;
}

.thumb-section li.active:only-child .thumb-img,
.thumb-section li.active:only-child .thumb-name {
  width: 63px;
}

.thumb-section li .thumb-img {
  width: 70px;
  height: 40px;
  overflow: hidden;
  display: block;
  border-radius: 3px 3px 0 0;
  border: 1px solid transparent;
  border-bottom: 0;
}

.thumb-section li .thumb-name {
  width: 70px;
  height: 20px;
  overflow: hidden;
  display: block;
  background: #fff;
  color: #55697b;
  border-radius: 0 0 3px 3px;
  border: 1px solid transparent;
  border-top: 0;
  font-size: 0.75em;
  padding: 2px 5px 0;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.thumb-section li.active .thumb-img {
  border: 1px solid $glassdrive-primary;
  border-bottom: 0;
}

.thumb-section li.active .thumb-name {
  border: 1px solid $glassdrive-primary;
  border-top: 0;
}

.thumb-error-text {
  background: $brand-danger;
  color: #fff;
  text-align: center;
  display: block;
  height: 70px;
  padding-top: 14%;
  font-size: 0.8rem;
}

.documents-slider.webauthority-slider {
  height: auto;
}

.thumb-section li.active:after {
  bottom: -5px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(0, 85, 152, 0);
  border-top-color: $glassdrive-primary;
  border-width: 5px;
  margin-left: -5px;
}

.pdftext {
  background: #d2dee7;
  color: #fff;
  text-align: center;
  display: block;
  height: 70px;
  padding-top: 14%;
  font-size: 0.8rem;
}

.webauthority-slider,
.thumb-slider-wrapper {
  padding: 0 25px;
}

.thumb-section {
  padding: 20px 25px 0;
}

.thumb-section ul {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.webauthority-wrapper,
.validation-wrapper {
  padding: 0 0 30px 0;
}

.custom-form-wrapper {
  padding: 20px 25px;
  background: #fff;
}

.webauthority-wrapper .card-header,
.validation-wrapper .card-header {
  padding: 10px 5px 5px;
  border-bottom: 0;
  font-size: 0.95rem;
}

.picture-gallery-wrapper {
  background-color: #f0f4f8;
}

.thumb-section {
  overflow: hidden;
  max-width: 500px;
  position: relative;
  margin: 0 auto;
}

.thumb-section .list-inline {
  height: 70px;
  left: 0;
  position: relative;
}

.thumb-section .list-inline-item.item {
  display: block;
  float: left;
}

.thumb-slider {
  width: 510px;
  margin: 0 auto;
  position: relative;
}

.thumb-slider .btn-icon {
  background: #fff;
  width: 29px;
  height: 28px;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 35%;
}

.thumb-slider .btn-scroll-right {
  right: -35px;
}

.thumb-slider .btn-scroll-left {
  left: -35px;
}

.history-search-count {
  color: $bg-color-ready;
  background: lighten($bg-color-ready, 42%);
  padding: 5px 15px;
  font-size: 0.85em;
  border-radius: 15px;
  margin-left: 25px;
  display: inline-block;
}

.onwerserachitem {
  background: $interface-semilight;
  padding: 22px 25px;
  border-radius: 3px;
  margin-bottom: 15px;
}

.onwerserachitem h4 {
  color: $horizons-ui-dark;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 13px;
}

.onwerserachitem .col-6 {
  margin-bottom: 1px;
}

.onwerserachitem-button {
  margin-top: 20px;
}

.onwerserachitem .label-value {
  font-size: 0.8rem;
  text-align: left;
  font-weight: bold;
}

.onwerserachitem .label {
  font-size: 0.8rem;
  text-align: left;
}

.glassdrive-form .dot-pagination {
  margin-bottom: 5px;
}

.glassdrive-form .dot-pagination .page-item .page-link {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background-color: $horizons-ui-light;
  border-color: $horizons-ui-light;
  color: #fff;
  padding: 0;
  margin-right: 8px;
  top: 17px;
}

.glassdrive-form .dot-pagination .page-item:first-child .page-link,
.glassdrive-form .dot-pagination .page-item:last-child .page-link {
  width: 37px;
  height: 37px;
  padding: 0.5rem 0.75rem;
  top: 0;
}

.glassdrive-form .dot-pagination .page-item.disabled:first-child .page-link,
.glassdrive-form .dot-pagination .page-item.disabled:last-child .page-link {
  background-color: $horizons-ui-semilight;
  border-color: $horizons-ui-semilight;
}

.table-borderless td,
.table-borderless th {
  border: 0;
}

.vehicle-history-wrapper {
  background: $interface-semilight;
  padding: 22px 25px;
  border-radius: 3px;
}

.vehicle-history-wrapper .table th {
  color: #889aaa;
  font-size: 0.8em;
}

.box-value {
  padding: 3px 10px;
  display: inline-block;
  border-radius: 15px;
  background-color: $interface-light;
  color: $interface-dark;
  font-size: 0.8em;
  font-weight: bold;
  min-width: 60px;
  text-align: center;
}

.licenseplate-value {
  font-weight: bold;
  font-style: 1.1em;
  color: $glassdrive-primary-blue;
}

/* New UI Common Styling Changes - End  */
/*---------------------------------------------*/
.k-event {
  margin: 4px;
}

.k-event .k-event-template {
  padding: 4px 7px 3px;
  font-size: 0.75rem;
}

.k-event-template {
  color: $glassdrive-primary-blue !important;
  font-weight: bold;
}

.k-scheduler-toolbar {
  background: #fff;
  color: $glassdrive-primary-blue;
}

.k-scheduler-table th {
  color: $glassdrive-primary-blue;
}

.k-scheduler-table th.k-today {
  color: $glassdrive-primary;
}

.k-scheduler-table td,
.k-scheduler-table th {
  border-color: #e2e9f0;
}

.k-scheduler-table .unavailableHours {
  background-color: #f6f9fd !important;
}

.k-scheduler-table .unavailableHoursByCapacity {
  background: repeating-linear-gradient(-55deg, #fff, #fff 10px, #f2f5f7 10px, #f2f5f7 20px);
}

.agenda-info-wrap {
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 30px 0 rgba(8, 43, 70, 0.2);
  padding: 8px 12px;
  display: none;
  text-align: left;
  font-size: 0.75rem;
  min-width: 200px;
}

.agenda-info {
  position: relative;
  z-index: 99999;
}

.agenda-info-icon {
  width: 25px;
  height: 25px;
  float: right;
  background: rgba(93, 196, 240, 0.15);
  text-align: center;
  padding: 3px;
  border-radius: 50%;
  color: $horizons-primary-light;
  line-height: 20px;
}

.appointment-header {
  margin-top: 10px;
}

.agenda-info-icon:hover + .agenda-info-wrap {
  display: inline-block;
  position: absolute;
  z-index: 9999;
  right: 9px;
  top: 50px;
}

.repair-info,
.replace-info,
.film-info,
.aquacontrol-info,
.cameracalibration-info,
.diagnose-info,
.cabinsanification-info,
.unconfirmed-info,
.other-info {
  display: block;
  margin-bottom: 5px;
  line-height: 20px;
}

.agenda-info span.repair-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: $job-repair;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.replace-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #f8dc93;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.film-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: $job-application;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.aquacontrol-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #40E0D0;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.cameracalibration-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #FF0000;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.diagnose-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #FF0000;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.cabinsanification-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #EE82EE;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.unconfirmed-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #555555;
  margin-right: 5px;
  border-radius: 50%;
}

.agenda-info span.other-info:before {
  content: " ";
  display: inline-block;
  height: 6px;
  width: 6px;
  background: #ced0fa;
  margin-right: 5px;
  border-radius: 50%;
}

.availability-edit-modal .modal-footer {
  padding: 0 15px;
}

.availability-edit-modal .modal-body {
  padding: 15px 15px 0;
}

.availability-edit-modal .form-group.row {
  margin-bottom: 2px;
}

.availability-edit-modal .float-wrapper {
  padding: 0;
}

.k-scheduler-dayview .k-event-template.k-event-time {
  display: block;
  color: #838e96 !important;
}

.k-scheduler-times .k-scheduler-table th {
  color: #838e96 !important;
  font-size: 0.75rem;
  padding: 10px 10px 9px;
}

.date-icon {
  position: relative;
}

.date-icon .icon-wrapper {
  position: absolute;
  top: 13px;
  right: 8px;
  pointer-events: none;
  cursor: default;
}

.float-wrapper select option:disabled {
  color: #a2a2a2;
}

.float-wrapper select option {
  color: $glassdrive-primary-blue;
}

.card-block .table-hover tr.active:hover {
  color: $glassdrive-primary-blue;
}

.ngxdropdown-wrapper .ngx-dropdown-button {
  padding-top: 21px !important;
  padding-bottom: 3px !important;
  box-shadow: none;
  border-color: #dce3e8;
}

.ngx-dropdown-button[disabled] {
  background-color: #E3E8EC !important;
  cursor: not-allowed;
}

.ngxdropdown-wrapper.active + label.float-label {
  transform: translateY(-140%);
  padding-left: 14px;
  font-size: 0.8rem;
  padding-left: 18px;
  top: 1.8rem;
}

.ngxdropdown-wrapper .ngx-dropdown-container .ngx-dropdown-button span {
  color: $glassdrive-primary;
}

.ngxdropdown-wrapper.float-select.active .ngx-dropdown-button {
  padding-top: 19px !important;
  padding-bottom: 2px !important;
  border: 1px solid #e3e8ec;
}

.float-select .nsdicon-angle-down {
  top: -5px;
}

.ngxdropdown-box {
  margin-top: 10px;
  position: relative;
}

.ngxdropdown-box.nolabel {
  margin-top: 0;
}

.ngxdropdown-box .float-wrapper {
  margin-bottom: 0;
}

.ngxdropdown-box.nolabel .float-wrapper {
  padding: 0;
}

.form-group .form-check-label {
  padding-top: 4px;
}

.dropdown-icon {
  position: absolute;
  top: 12px;
  right: 21px;
  display: none;
}

.form-group > .col-lg-2.form-control-label {
  padding-top: calc(0.5rem - 1px * 2);
  padding-bottom: calc(0.5rem - 1px * 2);
  margin-bottom: 0;
}

.ngxdropdown-box.nolabel .ngx-dropdown-button {
  padding: 7px 10px 7px 10px !important;
}

/*instruction page */
.brand-logo .upload-image-box {
  border: 2px solid $glassdrive-secondary;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px 0 rgba(33, 34, 49, 0.12);
  padding: 12px;
  max-height: initial !important;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-logo .upload-image-box .image-wrapper {
  display: block;
  text-align: center;
}

.brand-logo .upload-image-box .image-wrapper img {
  display: block;
  width: auto;
  max-height: 100px;
  min-width: auto;
  min-height: auto;
  margin: 0 auto;
}

.brand-logo button {
  display: none;
}

.brand-logo img {
  display: block;
  max-width: 100%;
  border-radius: 4px;
}

.upload-image-box .image-wrapper img {
  display: block;
  width: 100%;
  min-height: 200px;
  max-width: 100%;
  min-width: 250px;
}

.glassdrive-form .btn-white {
  border-color: #fff;
  background: #fff;
  color: $horizons-primary-light;
}

.glassdrive-form .btn-white:hover {
  box-shadow: 0px 2px 0 darken(#f1f1f1, 20%);
}

.glassdrive-form .btn-white[disabled] {
  border-color: lighten(#f1f1f1, 20%);
  background: lighten(#f1f1f1, 20%);
}

.modal-type-Replace .btn.popup-close {
  padding: 8px 12px;
  border-radius: 24px;
  min-width: 30px;
  font-size: 0.9rem;
}

.ng2-pdf-viewer-container {
  overflow-x: auto;
  border: 1px solid #d4cdcd;
}

.k-scheduler-header-wrap {
  border-width: 0;
}

.availability-edit-modal input.active + label.float-label,
.availability-edit-modal .float-select.active + label.float-label {
  padding-left: 14px;
}

.ngxdropdown-box .search-container input {
  border: 0 !important;
  padding-left: 10px !important;
}

.ngxdropdown-box .search-container label {
  padding-left: 10px;
  width: 100%;
}

.ngxdropdown-box .search-container {
  border: 1px solid #d6d3d3;
  margin-top: 10px;
  border-radius: 5px;
}

.ngx-dropdown-list-container .selected-items .nsdicon-close {
  display: none;
}

.ngx-dropdown-list-container .selected-items li {
  background: $interface-semilight !important;
  color: $interface-dark !important;
  padding: 7px !important;
}

.ngx-dropdown-list-container .available-items li {
  color: $interface-dark !important;
  padding: 3px !important;
}

.ngx-dropdown-list-container .available-items li:hover {
  background: $interface-semilight !important;
}

.ngx-dropdown-list-container .available-items div {
  color: $interface-dark !important;
  padding: 3px !important;
}

.ngx-dropdown-list-container {
  top :42px !important;
}

.ngx-dropdown-list-container .available-items div:hover {
  background: $interface-semilight !important;
}

.ngx-dropdown-list-container .nsdicon-search {
  position: absolute;
  right: 10px;
}

.ngxdropdown-box .search-container input:focus + label .nsdicon-search {
  display: none;
}

.ngx-dropdown-container .ngx-dropdown-button span {
  color: $glassdrive-primary-blue !important;
}

.form-control, .daterangepicker .input-mini, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control, .input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
  color: $glassdrive-primary-blue !important;
}

.modal-backdrop {
  background-color: $glassdrive-primary-blue;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal.show.v-center {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.mobile-icon {
  margin-left: 10px;
}

.modal-sub-details .float-wrapper {
  margin-bottom: 20px;
}

.mobile-icon.icon-wrapper {
  background: #fff;
  padding: 0 3px;
  border-radius: 3px;
}

.k-i-non-recurrence {
  margin: 0 3px;
}

.k-i-non-recurrence::after {
  content: " ";
  width: 14px;
  height: 14px;
  background: url(../assets/img/mobilejob.svg) no-repeat center;
  background-size: contain;
  display: inline-block;
}

.k-i-non-recurrence::before {
  display: none;
}

.damag-section .section-header {
  padding: 10px 10px 10px 0;
  background: transparent;
  color: #002949;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.damag-section .section-body {
  border: 0;
  padding: 5px 15px 5px;
  border-radius: 0 0 5px 5px;
  margin-bottom: 10px;
}

.sidebar-compact .sidebar-nav .nav-link i {
  transform: scale(1);
  display: inline-block;
  top: auto;
  position: relative;
}

.sidebar-nav .nav-link i {
  display: inline-block;
  top: 1px;
  position: relative;
  font-size: 16px !important;
}

.sidebar-nav .nav-link i.fa,
.sidebar-nav .nav-link i.icon-map,
.sidebar-nav .nav-link i.icon-envelope-letter {
  top: -2px;
}

.sidebar-nav .nav-link i.fa {
  transform: scale(1);
  top: 0;
}

.sidebar-compact .icon-circle-wrap {
  padding: 0 12px;
  line-height: 41px;
  background: #ffffff40;
  border-radius: 50%;
}

.sidebar-compact .icon-circle-wrap svg {
  position: relative;
  top: 2px;
}

.app-header {
  box-shadow: none;
  border-bottom: 1px solid #e2e9f0;
}

.table-striped {
  border: 0;
}

.table.table-striped th {
  background-color: $interface-semilight;
}

.table.table-striped .thead-light th {
  background-color: #ffffff;
  border-color: transparent;
  color: $interface-dark;
  font-weight: 500;
  background-color: $interface-semilight;
  font-size: 14px;
}

.table.table-striped .active td {
  color: #ffffff;
}

.table.table-striped td {
  color: $glassdrive-primary-blue;
}

.ui-paginator {
  margin-top: 15px !important;
}

.ui-paginator .ui-paginator-element {
  border: 1px solid $interface-light;
  border-radius: 50% !important;
  padding: 3px !important;
  min-width: 30px !important;
  height: 30px;
  color: $glassdrive-primary-blue !important;
}

.ui-paginator .ui-paginator-element.ui-state-active {
  color: $glassdrive-primary-blue !important;
  background: transparent;
  border: 0;
}

.dossier-communication-table.table-striped .active td {
  color: $glassdrive-primary-blue !important;
}

.dossier-communication-table.table th,
.dossier-communication-table.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e2e9f0;
}

.info-menu .menu-item.save-btn-wrapper:hover {
  background: #fff;
  border-left: 2px solid transparent;
}

.info-menu .menu-item.save-btn-wrapper {
  padding: 20px;
}

.app-body {
  overflow-y: hidden;
}

.ui-paginator .ui-paginator-next .fa-forward,
.ui-paginator .ui-paginator-prev .fa-backward,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first {
  display: none;
}

.ui-paginator-prev {
  background: url(/assets/img/icon-prev.svg) no-repeat center !important;
}

.ui-paginator-next {
  background: url(/assets/img/icon-next.svg) no-repeat center !important;
}

.ngxdropdown-wrapper .selected-items,
.ngxdropdown-wrapper .selected-items + hr {
  display: none;
}

.sidebar-compact .sidebar .parent-menu .nav-link i.fa {
  margin-right: 14px !important;
}

.appointment-time {
  opacity: 0.6;
  font-size: 12px;
  color: #676767;
  margin-bottom: 0;
  margin: 10px 0 5px 5px;
}

.k-scheduler-dayview .col-l,
.k-scheduler-dayview .col-r {
  float: left;
  width: 50%;
  max-width: 200px;
  display: block;
}

.k-scheduler-dayview .first-col-mgl {
  margin-left: -5px;
}

.damageLocation-text .k-event-template {
  font-weight: normal;
  padding-top: 10px;
  font-size: 14px;
}

.phone-text .k-event-template {
  padding: 0 7px;
  font-weight: normal;
  font-size: 14px;
}

.licensePlate-text .k-event-template {
  font-weight: normal;
  font-size: 14px;
}

.licensePlate-text {
  padding-bottom: 10px;
  display: block;
  text-transform: uppercase;
}

.k-scheduler-dayview .vehicle-name {
  padding-bottom: 1px;
}

.fittingAddressStreet-text .k-event-template,
.licensePlate-text .k-event-template {
  font-weight: normal;
  padding: 0 7px;
}

.fittingAddressCity-text,
.fittingAddressCountryName-text {
  font-weight: normal;
}

.k-scheduler-dayview .day-view,
.k-scheduler-dayview .day-view-text {
  display: inherit;
}

.k-scheduler-dayview .week-view-text,
.day-view-text,
.day-view {
  display: none;
}

.k-event {
  cursor: pointer;
}

.k-scheduler-dayview .k-scheduler-content > .k-event {
  overflow-y: auto;
}

.k-scheduler-content {
  overflow-y: hidden;
}

//Removes the arrow from HTML 5 datasets https://stackoverflow.com/questions/20937475/remove-datalist-dropdown-arrow-in-chrome
[list]::-webkit-calendar-picker-indicator {
  display: none;
}

.card-policy {
  flex: 1 0 20%;
  position: relative;
  margin: 0.5em;
  padding: 2em;
  min-height: 4em;
  background: white;
  border: 3px solid grey;
}

.active-card-policy {
  border-color: #003b6a;
}

th {
  font-weight: $table-th-font-weight;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

th > * > a {
  color: #466279 !important;
  text-decoration: none !important;
  background-color: transparent;
}

.page-item.active .page-link {

  background-color: #031930;
  border-color: #031930;
}
.pagination li > a  {

  color: #6c757d;

}

tfoot .pagination.float-sm-right .active a.page-link {
  color: #ffffff;
  background-color: #031930;
  border-color: #031930;
  line-height: 1em;
}

tfoot .pagination.float-sm-right a.page-link {
  color: #6c757d;
}
tfoot .pagination a.page-link {

  color: #6c757d;
}

.hrz-custom-control-input {
  left: 0;
  width: 1rem;
  height: 1.25rem;
}

.pdf-viewer {
  width: 40vw;
  height: 72vh;
}



.modal-type-1 .modal-content{
  border-left:3px solid #AEE1F8;
 }

//  .modal-type-Replace .modal-content{
//   border-left:3px solid #F8DC93;
//  }
 

 .modal-type-Repair .modal-content{
  border-left:3px solid #AEE1F8;
 }
 
 .modal-title.tabsectioncard-header   {
  color: #082B46;
  font-size: 1.4rem;
  padding-top: 0;
 }
 .modal-title .header-badge-light { 
  border-radius: 15px; 
  padding: 3px 10px;
  font-weight: bold;
}
 .modal-header-buttons {
  margin-left: auto;
 }
 .modal-header-buttons div{
  min-width: 20px;
  margin-left: 20px;
   float: left;
   cursor: pointer;
 }
 .modal-body .sub-header{
  color:#082B46;
  font-size: 14px; 
  font-weight: bold;
 }
 .modal-body  .sub-details{
  color:#466279;
  margin-bottom: 20px;
  font-size: 14px; 
 }
 .modal-sub-header{
   position: relative;
 }
 .sub-icon{
   position: absolute;
   display: inline-block;
 }

 .modal-sub-header h5{
  color:#082B46;
  font-size: 15px; 
  font-weight: bold;
  padding-left: 30px;
 }

 .modal-sub-details{
   padding-left: 30px;
   color:#466279;
   margin-bottom: 20px;
   font-size: 14px; 
 }
 .modal-lg {
  max-width: 650px;
}
.icon-inputs-vcenter{
  padding-top: 10px;
}
.sub-heaader-inputs{
  padding-left: 30px;
}

input.active+label.float-label,
.float-select.active+label.float-label {
  padding-left: 14px;
}

p {
  color: #466279;
  font-size: 14px;
}