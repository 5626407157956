// Bootstrap overrides

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #444D58;
$gray:                      #94A0B2;
$gray-light:                #BCC5D0;
$gray-lighter:              #E3E8EC;
$gray-lightest:             #F1F3F8;

$brand-primary:             #20a8d8;
$brand-success:             #4dbd74;
$brand-info:                #63c2de;
$brand-warning:             #f8cb00;
$brand-danger:              #f86c6b;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:        true;
$enable-rounded:            true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:   1rem !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$spacers: (
  q: (
    x:   ($spacer-x * 0.25),
    y:   ($spacer-y * 0.25)
  ),
  h: (
    x:   ($spacer-x * 0.5),
    y:   ($spacer-y * 0.5)
  ),
  0: (
    x:   0,
    y:   0
  ),
  1: (
    x:   $spacer-x,
    y:   $spacer-y
  ),
  2: (
    x:   ($spacer-x * 1.5),
    y:   ($spacer-y * 1.5)
  ),
  3: (
    x:   ($spacer-x * 3),
    y:   ($spacer-y * 3)
  )
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                    #EFF3F8;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:             0.875rem;

// Breadcrumbs

$breadcrumb-bg:                 #fff;
$breadcrumb-padding-y:          1rem;
$breadcrumb-padding-x:          1.25rem;

// Cards

$card-border-color:             $gray-lighter;
$card-cap-bg:                   #fff;

// Dropdowns

$dropdown-padding-y:            0;
$dropdown-border-color:         $gray-lighter;
$dropdown-divider-bg:           $gray-lightest;

// Buttons

$btn-secondary-border:          $gray-light;

// Progress bars

$progress-bg:                   $gray-lightest;

// Tables

$table-bg-accent:               $gray-lighter;
$table-bg-hover:                $gray-lighter;


/* Customization IE */
$btn-primary-color:              $white !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             $btn-primary-bg !default;

$btn-secondary-color:            $gray-dark !default;
$btn-secondary-bg:               $white !default;
$btn-secondary-border:           #ccc !default;

$btn-info-color:                 $white !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                $btn-info-bg !default;

$btn-success-color:              $white !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             $btn-success-bg !default;

$btn-warning-color:              $white !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             $btn-warning-bg !default;

$btn-danger-color:               $white !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              $btn-danger-bg !default;

$btn-link-disabled-color:        $gray-light !default;

$grid-gutter-width-base:     30px !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-sm-cell-padding:         .3rem !default;

$table-bg:                      transparent !default;

$table-inverse-bg:              $gray-dark !default;
$table-inverse-color:           $body-bg !default;

$table-bg-accent:               rgba($black,.05) !default;
$table-bg-hover:                rgba($black,.075) !default;
$table-bg-active:               $table-bg-hover !default;

$table-head-bg:                 $gray-lighter !default;
$table-head-color:              $gray !default;

$table-border-width:            $border-width !default;
$table-border-color:            $gray-lighter !default;




// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken($state-success-bg, 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken($state-info-bg, 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;

// Progress bars

$progress-height:               1rem !default;
$progress-font-size:            .75rem !default;
$progress-bg:                   $gray-lighter !default;
$progress-border-radius:        $border-radius !default;
$progress-box-shadow:           inset 0 .1rem .1rem rgba($black,.1) !default;
$progress-bar-color:            $white !default;
$progress-bar-bg:               $brand-primary !important;
$progress-bar-animation-timing: 1s linear infinite !default;


// Cards

$card-spacer-x:            1.25rem !default;
$card-spacer-y:            .75rem !default;
$card-border-width:        1px !default;
$card-border-radius:       $border-radius !default;
$card-border-color:        rgba($black,.125) !default;
$card-border-radius-inner: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:              $gray-lightest !default;
$card-bg:                  $white !default;

$card-link-hover-color:    $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-columns-count:        3 !default;
$card-columns-gap:          1.25rem !default;
$card-columns-margin:       $card-spacer-y !default;

/* modals */
$modal-dialog-sm-up-margin-y: 30px !default;
$modal-content-sm-up-box-shadow: 0 5px 15px rgba($black,.5) !default;






