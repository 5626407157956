
.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

.table-hover {
    tr.active {
      background-color: $table-bg-hover;
    }
    tbody {
        tr { cursor:pointer; }
    }
}


.generic-list {
    .generic-group {
        width:100%;
        clear:both;
        display:block;
        margin-bottom:10px;

        .groupkey {
            font-weight:bold;
            float:left;
            min-width: 150px;
            width: auto;
        }

        .groupvalue {
            min-width: 300px;
            width: auto;
            float:left;
        }
    }

    .generic-item {
        width:100%;
        background-color:transparent;
    }
    .generic-item:hover {
        cursor:pointer;
        background-color: $table-bg-hover;
    }
    .generic-item.active {
        background-color: $table-bg-hover;
    }
}



table tr.child-row-header td {
    border:none;
    padding-top:0;
    padding-bottom:0;
}
table tr.child-row-body td {
    border:none;
    padding-top:0;
    padding-bottom:0;
}
table tr.child-row-body:last-child td {
    padding-bottom:0.75rem;
}

.thead-inverse {
  th {
    color: $table-inverse-color;
    background-color: $table-inverse-bg;
  }
}

.table-inverse {
  color: $table-inverse-color;
  background-color:$horizons-primary;

  th,
  td,
  thead th {
    border-color: $body-bg;
  }

  &.table-bordered {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color:$horizons-ui-extralight;
}

.table-hover tr.active , .table-hover tr:hover {
  background-color:$horizons-ui-semilight !important;
}
.table-hover tfoot tr:hover {
  background-color:#FFF !important;
}