﻿$validation-error-color: #a94442;

input.ng-invalid {
  &.ng-dirty, &.ng-touched {
    border-left: 5px solid $validation-error-color;
    border-color: $validation-error-color;
  }
}

select.ng-invalid {
  &.ng-dirty, &.ng-touched {
    border-left: 5px solid $validation-error-color !important;
    border-color: $validation-error-color !important;
  }
}

/*input[required].ng-untouched.ng-invalid {
    border-color:#a94442;
}*/


 label[required]:after {
    color: #a94442;
    content: ' *';
    display:inline;
 }

 .validation-error { 
     color:red;
 }