.modal-dialog.modal-customsize.lightbox {
    margin:0px;
    .close {
        color:white;
        opacity:1;
    }
}

.modal-content.lightbox {
    background-color:transparent;
    color:white;

    .close {
        position:absolute;
        top: 15px;
        right: 30px;
    }
}

.documents-slider {
    text-align: center;
    height:100%;
    display:block;
    .item {
        margin-left:auto;
        margin-right:auto;
        width:auto;
        display:none;
        min-height:700px;
  

        .title {
            text-align: center;
            background-color: #FFF;
            color: #0a4298;
            line-height: 20px;
            padding-left: 10px;
            padding-right: 10px;
            display: block;
            padding: 8px 10px;
            border-bottom: 1px solid #f1f1f1;
        }
    }

    .item.active {
        display:inline-block;
    }

    .slider-navigator-left,
    .slider-navigator-right {
        min-height: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color:white;
        font-weight:bold;
    }

    .slider-navigator-left {
        cursor:pointer;
        float:left;
        left: 30px;
    }
    .slider-navigator-right {
        cursor:pointer;
        float: right;
        right: 30px;
    }

    .slider-navigator-left:hover,
    .slider-navigator-right:hover {
        color:#cdcdcd;
    }

    .fa-lg {
        font-size: 2em;
        line-height: 1em;
    }
    .fa-chevron-left, .fa-chevron-right {
        color:white;
    }
}


//    @include media-breakpoint-down(lg) {
//     .documents-slider .item {
//         max-height: 710px;
//         overflow: auto;
//        }
//   }